import React from "react"
import { navigate } from "@reach/router"
import Thumbnail from "../../../common/Thumbnail/Thumbnail"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import {
  Wrapper,
  AssetTitle,
  AssetTitleText,
  AssetMeta,
  NotUsedInProject,
  UsesInProject,
  AssetDescription
} from "./Asset.styles"
import { ProjectInterface, StateInterface } from "../../../utils/Interfaces"
import { useSelector } from "react-redux"
import { currentProject } from "../../../utils/State"
import { faSirenOn } from "@fortawesome/pro-solid-svg-icons"

interface AssetProps {
  asset: any
  selectedAssetType: string
  numberOfUsesInProject: number
}

const Asset = (props: AssetProps) => {
  const { asset, selectedAssetType, numberOfUsesInProject } = props
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  return (
    <Wrapper>
      <AssetTitle
        customColor={asset.color}
        onClick={() => {
          navigate(
            `/projects/${project!.id}/assets/${selectedAssetType}/${asset.id}`
          )
        }}
      >
        {asset.thumbnail && asset.thumbnail > "" && (
          <Thumbnail src={asset.thumbnail} size={"40px"} />
        )}
        <AssetTitleText thumbnail={asset.thumbnail && asset.thumbnail > ""}>
          {asset.role && asset.role === "PRIMARY" && (
            <FontAwesomeIcon icon={faStar} />
          )}{" "}
          {asset.title}
        </AssetTitleText>
      </AssetTitle>
      <AssetMeta>
        {numberOfUsesInProject === 0 && (
          <NotUsedInProject title="This Asset Isn't Currently Used in the Project!">
            <FontAwesomeIcon icon={faSirenOn} />
          </NotUsedInProject>
        )}
        {numberOfUsesInProject > 0 && (
          <UsesInProject>
            <span>{numberOfUsesInProject}</span> Use
            {numberOfUsesInProject > 1 && "s"}
          </UsesInProject>
        )}
      </AssetMeta>
      <AssetDescription>{asset.noteMeta.supplemental}</AssetDescription>
    </Wrapper>
  )
}

export default Asset
