import React, { useState } from "react"
import styled from "styled-components"
import Header from "../../common/Header/Header"
import HomeFooter from "../../components/Home/HomeFooter/HomeFooter"
import { db } from "../../_config/firebase"
import SendFeedbackModal from "../../modals/SendFeedbackModal/SendFeedbackModal"

const Wrapper = styled.div``

const Content = styled.div`
  padding: 10px;
`

const Feature = styled.div`
  margin-bottom: 20px;
`

const FeatureTop = styled.div`
  @media (min-width: 480px) {
    display: flex;
  }
`

const FeatureName = styled.div`
  margin-right: 10px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1em;

  @media (max-width: 480px) {
    margin-top: 5px;
  }
`

const FeatureState = styled.div<{ state: number }>`
  width: 78px;
  text-align: center;
  display: inline-block;
  border-radius: 4px;
  margin-right: 4px;
  ${(props) =>
    props.state === 0 && "background-color: var(--themed-subhead-color);"}
  ${(props) =>
    props.state === 1 &&
    "background-color: var(--brand-primary); color: var(--white-color); border-radius: 4px 0px 0px 4px; margin-right: 0px;"}
  ${(props) =>
    props.state === 2 &&
    "background-color: var(--new-color); color: var(--white-color);"}
  padding: 1px 4px;
  font-size: 12px;
  text-transform: uppercase;
`

const FeatureProgress = styled.div<{ progress: number }>`
  display: inline-block;
  border-radius: 0px 4px 4px 0px;
  margin-right: 4px;
  width: 32px;
  text-align: center;
  background-color: ${(props) =>
    `rgb(0, ${158 * (props.progress / 100)}, ${(props.progress / 100) * 102})`};

  color: #ffffff;
  padding: 1px 4px;
  font-size: 12px;
  text-transform: uppercase;
`

const BetaButton = styled.span`
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  color: var(--brand-primary);
`

const FeatureDescription = styled.div`
  box-sizing: border-box;
  padding: 5px 0px;
  color: var(--themed-header-color);
  background: #f1f1f1;
  margin: 10px 0px 0px 0px;
  padding: 10px;
`

const FeatureUpdated = styled.div`
  text-align: right;
  padding-right: 10px;
  font-style: italic;
  font-size: 10px;
`

interface AboutProps {
  path?: string
}

const About = (props: AboutProps) => {
  const [features, setFeatures] = useState<any>([])
  const [featuresLoaded, setFeaturesLoaded] = useState(false)
  const loadFeatures = async () => {
    const snapshot = await db
      .collection("features_in_development")
      .orderBy("updated", "desc")
      .get()

    setFeatures(snapshot.docs.map((doc) => doc.data()))
    setFeaturesLoaded(true)
  }

  const convertToTime = (t: any) => {
    const d = t.toDate()

    // const d = new Date(t)
    // console.log(d)
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }

    return "Updated " + d.toLocaleDateString("en-US", options)
  }

  React.useEffect(() => {
    loadFeatures()
  }, [])

  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  return (
    <Wrapper>
      <Header />
      <Content>
        <h1>About the Beta</h1>
        <p>
          <strong>Welcome!</strong> This is our status page. Right now it
          contains a list of features we're working on or planning to work on,
          as well as the status of the feature; we've included this so you can
          get a sense of what Hilker will be like in the near future!
        </p>

        <BetaButton onClick={() => setShowFeedbackModal(true)}>
          If you want to suggest a feature, click here and fill out the form!
        </BetaButton>
        <hr />

        {featuresLoaded === false && (
          <div>
            <Feature>
              <FeatureTop>
                <FeatureName>LOADING FEATURE LIST</FeatureName>
              </FeatureTop>
              <FeatureDescription>It's worth the wait...</FeatureDescription>
            </Feature>
          </div>
        )}
        {features
          .sort((a: any, b: any) => b.state - a.state)
          .map((feature: any, index: number) => {
            return (
              <Feature key={`feature-in-development-${index}`}>
                <FeatureTop>
                  <FeatureState state={feature.state}>
                    {feature.state === 0 && "not started"}
                    {feature.state === 1 && "in-progress"}
                    {feature.state === 2 && "complete"}
                  </FeatureState>
                  {feature.state === 1 && (
                    <FeatureProgress progress={Number(feature.progress)}>
                      {feature.progress}%
                    </FeatureProgress>
                  )}
                  <FeatureName>{feature.name}</FeatureName>
                </FeatureTop>
                <FeatureDescription>{feature.description}</FeatureDescription>
                <FeatureUpdated>
                  {convertToTime(feature.updated)}
                </FeatureUpdated>
              </Feature>
            )
          })}
      </Content>
      <HomeFooter />
      {showFeedbackModal === true && (
        <SendFeedbackModal
          closeModal={() => setShowFeedbackModal(false)}
        ></SendFeedbackModal>
      )}
    </Wrapper>
  )
}

export default About
