import React, { useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"

import { currentProject } from "../../../utils/State"
import {
  SceneInterface,
  ShallowSceneInterface,
  StateInterface,
  StorySectionInterface,
} from "../../../utils/Interfaces"
import WriteAreaScene from "../WriteAreaScene/WriteAreaScene"
import {
  Droppable,
  DroppableProvided,
  DraggableProvided,
  Draggable,
} from "react-beautiful-dnd"

const Wrapper = styled.div``

const SectionScenes = ({
  selectScene,
  currentSceneId,
  storySectionId,
}: {
  selectScene: Function
  currentSceneId: string
  storySectionId: string
}) => {
  const project = useSelector((state: StateInterface) => currentProject(state))
  const storySection = project!.storySections.find(
    (storySection: StorySectionInterface) => storySection.id === storySectionId
  )
  const [scenes, setScenes] = useState<Array<ShallowSceneInterface>>([])

  React.useEffect(() => {
    if (storySection!) {
      setScenes(storySection!.scenes)
    }
  }, [storySection])

  return (
    <Wrapper>
      <Droppable
        droppableId={storySection! && `story-section-${storySection!.id}`}
        type="droppableScene"
      >
        {(provided: DroppableProvided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ minHeight: "30px" }}
          >
            {scenes.map((scene: ShallowSceneInterface, index: number) => {
              const currentScene:
                | SceneInterface
                | undefined = project!.scenes.find((item: SceneInterface) => {
                if (item && scene) {
                  return item.id === scene.id
                } else {
                  return false
                }
              })

              if (!currentScene) return <div />

              return (
                <Draggable
                  draggableId={scene.id}
                  key={`section-${storySection!.id}-scene-${
                    scene.id
                  }-draggable`}
                  index={index}
                >
                  {(provided: DraggableProvided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <WriteAreaScene
                        project={project!}
                        key={`scene-draggable-${scene.id}`}
                        index={index}
                        id={currentScene!.id}
                        title={currentScene!.title}
                        selectScene={selectScene}
                        currentSceneId={currentSceneId}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              )
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Wrapper>
  )
}

export default SectionScenes
