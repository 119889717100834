import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 0px 0px 25px 10px;

  :hover {
    background-color: var(--themed-input-focus-color);
  }
`

export const TopRow = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr 35px;
  grid-gap: 10px;
`

export const DownloadLink = styled.span`
  font-size: 24px;
  color: var(--themed-black-color);
  text-align: center;
  padding: 2px 2px 2px 2px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 5px;
  z-index: 1;
  :hover {
    color: var(--download-color);
  }
`

export const DeleteLink = styled.span`
  font-size: 24px;
  color: var(--themed-black-color);
  text-align: center;
  padding: 2px 2px 2px 2px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 5px;
  z-index: 1;
  :hover {
    color: var(--error-background-color);
  }
`

export const Downloading = styled.span`
  font-size: 24px;
  color: var(--themed-focus-color);
  text-align: center;
  padding: 2px 2px 2px 2px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 5px;
  z-index: 1;
`

export const ProjectDownload = styled.div``

export const ProjectActions = styled.div`
  text-align: right;
`

export const ProjectName = styled.div`
  font-weight: bold;
  font-family: var(--title-font);
  font-size: 24px;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`
