import React from "react"
import { navigate } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBoxFull,
  faCalendarAlt,
  faLightbulbOn,
  faPenNib,
  faTelescope,
} from "@fortawesome/pro-regular-svg-icons"
import {
  NoteInterface,
  ProjectInterface,
  StateInterface,
} from "../../utils/Interfaces"
import { WordCount } from "../../utils/Numbers"
import moment from "moment"
import { sanitizeText } from "../../utils/Strings"
import {
  Wrapper,
  NoteHeader,
  NoteTitle,
  NoteDetails,
  NoteExcerpt,
  NoteHeaderContent,
  Hr,
  NoNoteContent,
} from "./NoteIndexListItem.styles"
import { useSelector } from "react-redux"
import { currentProject } from "../../utils/State"

const NoteIndexListItem = ({ note }: { note: NoteInterface }) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )

  const noteText = sanitizeText(note.text)

  return (
    <Wrapper
      onClick={() => navigate(`/projects/${project!.id}/note/${note.id}`)}
    >
      <NoteHeader>
        <NoteHeaderContent>
          <NoteTitle>{note.title || note.created_at}</NoteTitle>
        </NoteHeaderContent>
      </NoteHeader>
      <Hr />
      <NoteExcerpt>
        {noteText && noteText.trim().length > 1 && noteText.substr(0, 200)}
        {noteText && noteText.length > 200 && "..."}
        {!noteText ||
          (noteText && noteText.trim().length < 2 && (
            <NoNoteContent>
              This note doesn't have any content yet.
            </NoNoteContent>
          ))}
      </NoteExcerpt>
      <NoteDetails>
        <span title={note.type}>
          {(!note.type || note.type === "" || note.type === "idea") && (
            <FontAwesomeIcon icon={faLightbulbOn} />
          )}
          {note.type === "research" && <FontAwesomeIcon icon={faTelescope} />}
        </span>
        <span>
          <FontAwesomeIcon icon={faBoxFull} />{" "}
          <strong>{note.items.length}</strong>
        </span>
        <span>
          <FontAwesomeIcon icon={faPenNib} />{" "}
          <strong>{WordCount("scene", note.text)}</strong>
        </span>
        <span>
          <FontAwesomeIcon icon={faCalendarAlt} />{" "}
          {note.updated_at && (
            <strong>{moment(note.updated_at).fromNow()}</strong>
          )}
          {!note.updated_at && <strong>{moment().fromNow()}</strong>}
        </span>
      </NoteDetails>
    </Wrapper>
  )
}

export default NoteIndexListItem
