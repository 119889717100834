import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  background-color: var(--alert-box-bg);
  border-top: 5px solid var(--brand-primary);
  padding: 10px;
  margin: 0px 0px 10px 0px;
  display: grid;
  grid-template-columns: 1fr 15px;
  
  a {
    color: var(--black-color);
    font-weight: bold;
  }

  @media (max-width: 480px) {
    padding: 0px 10px 10px 10px;
  }
  `

const CloseAlert = styled.span`
  color: var(--brand-primary);
  cursor: pointer;
`

const AlertBox = (props: any) => {
  const [showAlert, setShowAlert] = useState(true)
  if (showAlert === false) return <div />

  return <Wrapper>
    {props.children}
    {props.allowHide === true &&
      <CloseAlert onClick={() => setShowAlert(false)}><FontAwesomeIcon icon={faTimesCircle} /></CloseAlert>
    }
  </Wrapper>
}

export default AlertBox
