import React from "react"
import styled from "styled-components"
import {
  StateInterface,
  ProjectInterface,
  StorySectionInterface,
  SceneInterface,
  ShallowSceneInterface
} from "../../utils/Interfaces"
import { getUsesInProject, currentProject } from "../../utils/State"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "@reach/router"
import { faSirenOn } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const NotUsedInProject = styled.span`
  color: red;
  opacity: 0.3;
`

const UsedInThisScene = styled.div`
  cursor: pointer;
`

const UsesByScene = styled.div`
  margin: 0px 10px;
  font-size: 14px;
`

const NotUsedInSection = styled.span`
  font-style: italic;
  color: var(--themed-no-content-color);
`

const NoUsesInProject = styled.div`
  font-size: 12px;
  font-style: italic;
  color: var(--themed-no-content-color);
  margin-bottom: 20px;
  `

const UsesPerStorySection = styled.div`
  margin-bottom: 10px;
`

const UsedInThisSceneDescription = styled.div`
  font-size: 14px;
  color: var(--themed-header-color);
  font-style: italic;
  padding-left: 10px;
`

const UsersPerStorySection_Title = styled.div`
  font-weight: bold;
`
const UsesInProject = ({ asset }: { asset: any }) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const uses: Array<any> = getUsesInProject(project!, asset)

  const usesInSection = (storySection: StorySectionInterface) => {
    let theseUses: Array<any> = []

    storySection.scenes.forEach((scene: ShallowSceneInterface) => {
      let inCurrentScene: boolean =
        uses.filter((item: any) => item.id === scene.id).length > 0
      if (inCurrentScene) theseUses.push(scene)
    })

    return theseUses
  }

  const setCurrentSceneAndNavigate = (id: string) => {
    navigate(`/projects/${project!.id}/write/${id}`)
  }

  return (
    <Wrapper>
      <h3>Uses in Project 
      {uses.length === 0 && (
        <NotUsedInProject>
          {" "}<FontAwesomeIcon icon={faSirenOn} />
        </NotUsedInProject>
      )}
      </h3>
      {uses.length === 0 && (
        <NoUsesInProject>
          This isn't used, yet.
        </NoUsesInProject>

      )}
      {uses.length > 0 && (
        <div>
          {project!.storySections.map((storySection: StorySectionInterface) => {
            const usesForThisSection: Array<any> = usesInSection(storySection)
            return (
              <UsesPerStorySection key={`used-in-${storySection.id}`}>
                <UsersPerStorySection_Title>
                  {storySection.title}:
                </UsersPerStorySection_Title>
                <div>
                  {usesForThisSection.length === 0 && (
                    <NotUsedInSection>
                      Not used in this section.
                    </NotUsedInSection>
                  )}
                  <UsesByScene>
                    {usesForThisSection.map((item: any) => {
                      const currentScene:
                        | SceneInterface
                        | undefined = project!.scenes.find(
                        (scene: SceneInterface) => scene.id === item.id
                      )

                      return (
                        <UsedInThisScene
                          key={`use-in-section-${Math.floor(
                            Math.random() * 100000
                          )}`}
                          onClick={() => {
                            setCurrentSceneAndNavigate(item.id)
                          }}
                        >
                          {currentScene!.title}
                          {currentScene!.noteMeta!.supplemental &&
                            currentScene!.noteMeta!.supplemental.length > 0 && (
                              <UsedInThisSceneDescription>
                                {currentScene!.noteMeta!.supplemental!.substr(
                                  0,
                                  150
                                )}
                                {currentScene!.noteMeta!.supplemental!.length >
                                  150 && "..."}
                              </UsedInThisSceneDescription>
                            )}
                          {(!currentScene!.noteMeta!.supplemental ||
                            (currentScene!.noteMeta!.supplemental &&
                              currentScene!.noteMeta!.supplemental.length ===
                                0)) && (
                            <UsedInThisSceneDescription>
                              <NotUsedInSection>
                                This scene has no description.
                              </NotUsedInSection>
                            </UsedInThisSceneDescription>
                          )}
                        </UsedInThisScene>
                      )
                    })}
                  </UsesByScene>
                </div>
              </UsesPerStorySection>
            )
          })}
        </div>
      )}
    </Wrapper>
  )
}

export default UsesInProject
