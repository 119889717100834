import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { currentProject, countUsesInProject } from "../../utils/State";
import Header from "../../common/Header/Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxFull, faPlus } from "@fortawesome/pro-regular-svg-icons";
import AddItemToProjectModal from "../../modals/AddItemToProjectModal/AddItemToProjectModal";
import { StateInterface, ProjectInterface } from "../../utils/Interfaces";
import { navigate } from "@reach/router";
import { getIconForAssetType } from "../../utils/Icons";
import AssetsFooter from "../../components/Assets/AssetsFooter/AssetsFooter";
import Spacer from "../../common/Spacer/Spacer";
import AlertBox from "../../common/AlertBox/AlertBox";
import SubHeaderAssets from "../../components/Assets/SubHeaderAssets/SubHeaderAssets";
import { filterForSearchTerms } from "../../utils/Strings";
import Asset from "../../components/Assets/Asset/Asset";
import { getAssetsByType, assetTypes } from "../../utils/Assets";
import RecentAssets from "../../components/Assets/RecentAssets/RecentAssets";

const NewAssetButton = styled.span<{ type: string }>`
  font-size: 16px;
  color: var(--themed-bg-color);
  text-align: center;
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  margin-left: 5px;

`;

const PageContent = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  grid-gap: 10px;
  

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0px 10px;
  }
`;

const Menu = styled.div`
  padding: 0px 0px 0px 5px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Padding = styled.div`
  width: 100%;
  height: 10px;
`

const AssetSummary = styled.div`
`;

const AssetTypeHeader = styled.div<{ type: string; active: boolean }>`
  display: grid;
  grid-template-columns: 22px 1fr 22px;
  padding: 5px 5px 8px 5px;
  font-size: 16px;
  font-family: var(--title-font);
  color: var(--themed-asset-header-color);
  background-color: var(--themed-bg-color);

  @media (max-width: 480px) {
    grid-template-columns: 22px 22px;
  }

  margin-bottom: 2px;
  cursor: pointer;
  svg {
    color: var(--themed-asset-header-color);
  }
  svg:first-of-type {
    color: ${(props) => `var(--${props.type}-color)`};
  }

  :hover {
    color: var(--brand-primary);
  }

  :hover svg {
    color: var(--brand-primary);
  }
`;

const AssetTypeHeaderTitle = styled.div<{ current: boolean }>`
  ${props => props.current === true && 'font-weight: bold;'}
  color: var(--themed-font-color);
  
  text-transform: capitalize;

  @media (max-width: 480px) {
    display: none;
  }
`;


const PageTitle = styled.div<{ type: string }>`
  font-size: 20px;
  text-transform: capitalize;
  display: grid;
  grid-template-columns: 1fr 100px;
  font-size: 18px;
  font-family: var(--title-font);
  font-weight: bold;
  
  /* color: ${(props) => `var(--${props.type}-color)`}; */
  
  span {
    color: var(--themed-font-color);
    /* color: ${(props) => `var(--${props.type}-color)`} !important; */
  }
  span:hover {
    color: var(--white-color) !important;
    background-color: var(--brand-primary) !important;

  }
`;

const CharacterType = styled.div<{ primary: boolean }>`
  font-size: 14px;
  color: var(--themed-focus-color);
  border-bottom: 1px solid var(--themed-focus-color);
  font-weight: bold;
  font-family: var(--body-font);
  text-transform: uppercase;
  margin: 40px 0px 5px 0px;
  ${(props) => props.primary === true && "margin-top: 5px;"}
`;

const AssetDescription = styled.div`
  font-size: 14px;
  font-style: italic;
  padding: 10px 0px 10px 0px;
`;

const AssetFilter = styled.div``;

const NoAssetsOfType = styled.div`
  color: var(--themed-font-color);
  font-style: italic;
  padding: 10px 0px;
`;

const AssetTypeHeaderIcon = styled.div<{ type: string; active: boolean }>`
  font-size: 16px;
  
`;

const AssetsInfo = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    max-width: 800px;
    margin: 0 auto;
  }
`

interface PlotProps {
  path?: string;
  assetType?: string;
  assetId?: string;
  projectId?: string;
}

const AssetsIndex = (props: PlotProps) => {
  // TODO: Fix this. project returns null or undefined, not sure
  // how that should go with the project destructuring
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state, props.projectId),
  );

  const [assetSearch, setAssetSearch] = useState<string>("");
  const [newItemType, setNewItemType] = useState<string>("");
  const [showAddItemPrompt, setShowAddItemPrompt] = useState<boolean>(false);
  const [selectedAssetType, setSelectedAssetType] = useState<string>(
    props.assetType || "",
  );

  React.useEffect(() => {
    if (props.assetType === undefined) {
      setSelectedAssetType("");
    } else {
      setSelectedAssetType(props.assetType as string);
    }
  }, [props.assetType]);

  // TODO: Refactor this a lot
  const createItem = (type: string) => {
    setNewItemType(type);
    setShowAddItemPrompt(true);
  };

  const getAssetDescription = (assetType: string) => {
    // TODO: Make a bunch of these, randomly referring to different movies.
    
    switch (assetType) {
      case "arc":
        return 'Definition of an arc goes here, like "John McClane and Holly repair their relationship."';
      case "location":
        return "These are locations featured in your story, like The Nakatomi Building.";
      case "dialogue":
        return 'These are snippets of dialogue that go ... somewhere ... in your story, like "Yippie-Kayay."';
      case "character":
        return "These are all of the characters you want in your story, like Hans Gruber.";
      case "storybeat":
        return 'Here are your story beats. These are things that happen, like "The Nakatomi Building Rooftop Explodes."';
      case "macguffin":
        return 'These are items in your story, like "Holly\'s Gold Watch."';
      case "storyline":
        return "These are storylines (made up of story beats) in your story, like \"Hans Robs Nakatomi Tower\""
    }
  };

  return (
    <div>
      <Header />
      <SubHeaderAssets />
      <Padding />
      <PageContent>
        <Menu>
          <AssetTypeHeader
            type={""}
            onClick={() => {
              setSelectedAssetType("");
              setAssetSearch("");
              navigate(`/projects/${project!.id}/assets`);
            }}
            active={selectedAssetType === ""}
            key={`asset-type-header-${Math.floor(Math.random() * 1000000)}`}
          >
            <AssetTypeHeaderIcon
              type={""}
              active={selectedAssetType === ""}
            >
              <FontAwesomeIcon icon={faBoxFull} />
            </AssetTypeHeaderIcon>
            <AssetTypeHeaderTitle current={selectedAssetType === ""}>
              All
            </AssetTypeHeaderTitle>
          </AssetTypeHeader>
          {assetTypes.map((assetType: string) => {
            return (
              <AssetTypeHeader
                type={assetType}
                onClick={() => {
                  setSelectedAssetType(assetType);
                  setAssetSearch("");
                  navigate(`/projects/${project!.id}/assets/${assetType}`);
                }}
                active={assetType === selectedAssetType}
                key={`asset-type-header-${Math.floor(Math.random() * 1000000)}`}
              >
                <AssetTypeHeaderIcon
                  type={assetType}
                  active={assetType === selectedAssetType}
                >
                  <FontAwesomeIcon icon={getIconForAssetType(assetType)} />
                </AssetTypeHeaderIcon>
                <AssetTypeHeaderTitle current={selectedAssetType === assetType}>
                  {assetType}s{" ("}{getAssetsByType(project!, assetType).length}{")"}
                </AssetTypeHeaderTitle>
              </AssetTypeHeader>
            );
          })}
        </Menu>

        <AssetsInfo>
          {window.location.search.indexOf("updated=true") > -1 && (
            <AlertBox allowHide={true}>Success! The asset was updated.</AlertBox>
          )}
          <AssetSummary key={`asset-summary-${setSelectedAssetType}`}>
            {selectedAssetType !== "" && (
              <React.Fragment>
                <PageTitle type={selectedAssetType}>
                  {selectedAssetType}
                  {"s "}
                  <NewAssetButton
                    type={selectedAssetType}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      createItem(selectedAssetType);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add New
                  </NewAssetButton>
                </PageTitle>
                <AssetDescription>
                  {getAssetDescription(selectedAssetType)}
                </AssetDescription>
              </React.Fragment>
            )}
            {selectedAssetType === "" && (
              <React.Fragment>
                <PageTitle type={selectedAssetType}>
                  Assets
                  <NewAssetButton
                    type={selectedAssetType}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      createItem(selectedAssetType);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Add New
                  </NewAssetButton>
                </PageTitle>
                <AssetDescription>
                  Below are some of the assets in your projects that you've most recently worked on. If you don't see any below, click the "Add New" button to the right to get started.
                </AssetDescription>
                {assetTypes.map((assetType: string) => {
                  return <RecentAssets
                    assetType={assetType}
                    key={`recent-asset-${assetType}`}
                  />;
                })}
              </React.Fragment>
            )}
            {selectedAssetType !== "" && (
              <AssetFilter>
                <input
                  type="text"
                  value={assetSearch}
                  placeholder={`Search for ${selectedAssetType}s`}
                  onChange={(e) => setAssetSearch(e.currentTarget.value)}
                />
              </AssetFilter>
            )}

            {selectedAssetType !== "character" &&
              selectedAssetType !== "" &&
              getAssetsByType(project!, selectedAssetType)!.length === 0 && (
                <NoAssetsOfType>
                  No {selectedAssetType}s in project.
                </NoAssetsOfType>
              )}
            {selectedAssetType !== "character" &&
              selectedAssetType !== "" &&
              getAssetsByType(project!, selectedAssetType)!
                .filter((asset: any) =>
                  filterForSearchTerms(asset, assetSearch)
                )
                .sort((a: any, b: any) => {
                  const a_updated: string = a.updated || "0";
                  const b_updated: string = b.updated || "0";
                  if (a_updated > b_updated) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((asset: any, index: number) => {
                  const numberOfUsesInProject: number = countUsesInProject(
                    project!,
                    asset,
                  );

                  return (
                    <div key={`asset-list-${Math.random() * 100000}`}>
                      {index > 0 && <hr />}
                      <Asset
                        key={`arc-${asset.id}`}
                        asset={asset}
                        selectedAssetType={selectedAssetType}
                        numberOfUsesInProject={numberOfUsesInProject}
                      />
                    </div>
                  );
                })}
            {selectedAssetType === "character" && (
              <CharacterType primary={true}>Primary Characters</CharacterType>
            )}

            {selectedAssetType === "character" &&
              getAssetsByType(project!, selectedAssetType)!
                .filter((asset: any) => asset.role && asset.role === "PRIMARY")
                .filter((asset: any) =>
                  filterForSearchTerms(asset, assetSearch)
                )
                .sort((a: any, b: any) => {
                  const a_updated: string = a.updated || "0";
                  const b_updated: string = b.updated || "0";
                  if (a_updated > b_updated) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((asset: any, index: number) => {
                  const numberOfUsesInProject: number = countUsesInProject(
                    project!,
                    asset,
                  );

                  return (
                    <div key={`asset-list-${Math.random() * 100000}`}>
                      {index > 0 && <hr />}
                      <Asset
                        key={`arc-${asset.id}`}
                        asset={asset}
                        selectedAssetType={selectedAssetType}
                        numberOfUsesInProject={numberOfUsesInProject}
                      />
                    </div>
                  );
                })}

            {selectedAssetType === "character" &&
              getAssetsByType(project!, selectedAssetType)!
                  .filter((asset: any) =>
                    asset.role && asset.role === "PRIMARY"
                  )
                  .filter((asset: any) =>
                    filterForSearchTerms(asset, assetSearch)
                  ).length === 0 &&
              (
                <NoAssetsOfType>No primary characters found.</NoAssetsOfType>
              )}

            {selectedAssetType === "character" && (
              <CharacterType primary={false}>
                Secondary Characters
              </CharacterType>
            )}

            {selectedAssetType === "character" &&
              getAssetsByType(project!, selectedAssetType)!
                .filter(
                  (asset: any) =>
                    !asset.role || (asset.role && asset.role !== "PRIMARY"),
                )
                .filter((asset: any) =>
                  filterForSearchTerms(asset, assetSearch)
                )
                .sort((a: any, b: any) => {
                  const a_updated: string = a.updated || "0";
                  const b_updated: string = b.updated || "0";
                  if (a_updated > b_updated) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((asset: any, index: number) => {
                  const numberOfUsesInProject: number = countUsesInProject(
                    project!,
                    asset,
                  );

                  return (
                    <div key={`asset-list-${Math.random() * 100000}`}>
                      {index > 0 && <hr />}
                      <Asset
                        key={`arc-${asset.id}`}
                        asset={asset}
                        selectedAssetType={selectedAssetType}
                        numberOfUsesInProject={numberOfUsesInProject}
                      />
                    </div>
                  );
                })}

            {selectedAssetType === "character" &&
              getAssetsByType(project!, selectedAssetType)!
                  .filter(
                    (asset: any) =>
                      !asset.role || (asset.role && asset.role !== "PRIMARY"),
                  )
                  .filter((asset: any) =>
                    filterForSearchTerms(asset, assetSearch)
                  ).length === 0 &&
              (
                <NoAssetsOfType>No secondary characters found.</NoAssetsOfType>
              )}
          </AssetSummary>
        </AssetsInfo>
      </PageContent>
      <Spacer width={"100%"} height={"40px"} />

      {showAddItemPrompt === true && (
        <AddItemToProjectModal
          closeModal={() => setShowAddItemPrompt(false)}
          type={newItemType}
        />
      )}
      <AssetsFooter />
    </div>
  );
};

export default AssetsIndex;
