import React from "react"
import styled from "styled-components"

const Wrapper = styled.div<{ type: string; grid: boolean }>`
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: ${props => `var(--${props.type}-color)`};
  color: var(--white-color);
  box-sizing: border-box;
  padding: 1px 5px 0px 5px;
  ${props =>
    props.grid === true && "display: grid; grid-template-columns: 1fr 1fr;"}
`

interface AssetsTypeHeaderProps {
  type: string
  grid: boolean
  children?: any
}

const AssetsTypeHeader = (props: AssetsTypeHeaderProps) => {
  return (
    <Wrapper type={props.type} grid={props.grid}>
      {props.children}
    </Wrapper>
  )
}

export default AssetsTypeHeader
