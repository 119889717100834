import styled from "styled-components"

export const Wrapper = styled.div``

export const SelectAssetButton = styled.span`
  background-color: var(--brand-primary);
  color: var(--white-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
  width: 100%;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  border-radius: 4px;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;

  :hover {
    background-color: var(--themed-bg-color);
    color: var(--brand-primary);
  }
`

export const SelectAssetButtonDiv = styled.div`
  background-color: var(--brand-primary);
  text-align: center;
  color: var(--white-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-style: normal;
  width: 100%;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  border-radius: 4px;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 5px;

  :hover {
    background-color: var(--themed-bg-color);
    color: var(--brand-primary);
  }
`

export const SelectedAsset = styled.span<{ customColor: string }>`
  ${props =>
    props.customColor && props.customColor > ""
      ? `color: ${props.customColor};`
      : ""}
  margin-right: 5px;
  padding: 5px;
  font-weight: bold;
`

export const SelectableAsset = styled.div<{ selected: boolean }>`
  margin-right: 5px;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid var(--themed-light-bg);
  margin-bottom: 2px;
  color: var(--themed-font-color);
  background-color: var(--themed-bg-color);

  :hover {
    background-color: var(--themed-font-color);
    color: var(--themed-bg-color);
  }

  ${props =>
    props.selected === true && "background-color: var(--brand-primary);"}
`

export const SelectableWrapper = styled.div``

export const SelectableScrollWrapper = styled.div<{ inModal: boolean }>`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: ${props => (props.inModal === true ? "100px" : "200px")};
  border: 1px solid var(--themed-subhead-color);
`

export const NoAssetSelected = styled.span`
  font-style: italic;
  color: var(--themed-no-content-color);
`
