import {
  faFolder,
  faStickyNote,
  faPuzzlePiece,
  faUserAlt,
  faMapMarked,
  faGem
} from "@fortawesome/free-solid-svg-icons"

import { faCommentLines, faBowArrow, faMapSigns } from "@fortawesome/pro-regular-svg-icons"

export const getIconForAssetType = (assetType: string) => {
  switch (assetType) {
    case "storySection":
      return faFolder
    case "scene":
      return faStickyNote
    case "storybeat":
      return faPuzzlePiece
    case "storyline":
      return faMapSigns
    case "dialogue":
      return faCommentLines
    case "character":
      return faUserAlt
    case "arc":
      return faBowArrow
    case "location":
      return faMapMarked
    case "macguffin":
      return faGem
  }

  return faStickyNote
}
