import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  DragDropContext,
  Droppable,
  DroppableProvided
} from "react-beautiful-dnd"
import styled from "styled-components"

import { currentProject, getConfig } from "../../utils/State"
import Header from "../../common/Header/Header"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlusCircle,
  faEdit,
  IconDefinition,
  faExpandArrows
} from "@fortawesome/pro-regular-svg-icons"
import AddItemToProjectModal from "../../modals/AddItemToProjectModal/AddItemToProjectModal"
import {
  StateInterface,
  ProjectInterface,
  NoteMetaInterface,
  Dispatch_UpdateNoteItemInterface,
  StorySectionInterface,
  ShallowSceneInterface
} from "../../utils/Interfaces"
import EditNoteModal from "../../modals/EditNoteModal/EditNoteModal"
import StorySection from "../../components/Plot/StorySection/StorySection"
import AddStorySectionModal from "../../modals/AddStorySectionModal/AddStorySectionModal"
import EditStorySectionModal from "../../modals/EditStorySectionModal/EditStorySectionModal"
import StorySectionLegend from "../../components/Plot/StorySectionLegend/StorySectionLegend"
import { faEye, faEyeSlash, faSearch } from "@fortawesome/free-solid-svg-icons"
import { getIconForAssetType } from "../../utils/Icons"
import PlotFooter from "../../components/Plot/PlotFooter/PlotFooter"
import Spacer from "../../common/Spacer/Spacer"
import SubHeaderPlot from "../../components/Plot/SubHeaderPlot/SubHeaderPlot"
import { assetTypes } from "../../utils/Assets"
import AlertBox from "../../common/AlertBox/AlertBox"

const Projects = styled.div<{ snap: boolean }>`
  background-color: var(--themed-plot-bg-color);
  display: box;
  display: -webkit-box;
  display: -moz-box;
  /* overflow-x: scroll; */
  padding: 10px;
  ${props => props.snap === true && "display: flex;"}
`

const DontUseInMobile = styled.div`
  @media (min-width: 481px) {
    display: none;
  }
`

const Footer = styled.div<{ plotMode: string }>`
  position: fixed;
  color: var(--themed-font-color);
  width: 100%;
  left: 0px;
  z-index: 10;
  padding: 0px 5px 5px 5px;
  background-color: var(--themed-light-bg);
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;
  height: 56px;
  bottom: 30px;
  left: 0px;
  box-sizing: border-box;
  overflow-y: scroll;

  @media (max-width: 768px) {
    ${props => props.plotMode === "inspect" && "height:72px;"}
  }
`

const FooterContent = styled.div<{ plotMode: string }>`
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  height: 46px;
`

const FooterContentItem = styled.div`
  display: inline-grid;
  height: 46px;
  margin-right: 10px;

  :last-of-type {
    margin-right: 0px;
  }
`

const PlotModeSelection = styled.span<{ active: boolean }>`
  font-size: 18px;
  color: var(--option-inactive-color);
  text-align: center;
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  margin: 0px 5px 0px 5px;
  ${props => props.active && "color: var(--option-active-color)"};
  :hover {
    color: var(--option-active-color);
  }
`

const PlotModeOptions = styled.div``

const FooterSubTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  background-color: var(--themed-subhead-color);
  padding-left: 5px;
  padding-right: 5px;
  @media (max-width: 769px) {
    font-size: 12px;
  }
`

const PlotInspectFilterType = styled.span<{ selected: boolean }>`
  ${props =>
    props.selected === true
      ? "color: var(--option-active-color);"
      : "color: var(--option-inactive-color);"}
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: var(--option-active-color);
  }
`

const PlotHideNonHighlighted = styled.span<{ selected: boolean }>`
  ${props =>
    props.selected === true
      ? "color: var(--option-active-color);"
      : "color: var(--option-inactive-color);"}
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: var(--option-active-color);
  }
`

const StorySectionWrapper = styled.div<{ plotMode: string }>`
  display: grid;
  width: 100%;
  grid-template-columns: ${props =>
    props.plotMode === "edit" ? "1fr" : "250px 1fr"};
`

const StorySections = styled.div`
  width: 100%;
  overflow-x: scroll;
`

const PlotInspectFilters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
`

const FooterScale = styled.div`
  grid-gap: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
`

const FooterScaleSnap = styled.div<{ active: boolean }>`
  font-size: 18px;
  color: ${props =>
    props.active === true
      ? "var(--option-active-color)"
      : "var(--option-inactive-color)"};
  text-align: center;
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  margin-left: 5px;

  :hover {
    color: var(--option-active-color);
  }
`

const Content = styled.div`
  margin: 10px;
`

const NewSectionButton = styled.div`
  cursor: pointer;
  text-align: right;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--themed-bg-color);
  background-color: var(--brand-primary);
  border-radius: 0px 0px 8px 8px;
  padding: 2px 5px;
  font-weight: bold;

  :hover {
    background-color: var(--themed-font-color);
  }
`

const NoSectionsInProject = styled.div`
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
  color: var(--themed-no-content-color);
  font-size: 14px;
  cursor: pointer;
  span {
    font-size: 16px;
    font-weight: bold;
  }

  :hover {
    color: var(--themed-font-color);
  }
`

const ZoomScaleSize = styled.div<{ active: boolean; size: number }>`
  font-size: ${props => props.size}px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  cursor: pointer;

  color: ${props =>
    props.active === true
      ? "var(--option-active-color)"
      : "var(--option-inactive-color)"};

  :hover {
    color: var(--option-active-color);
  }
`
interface PlotProps {
  activeTabId?: string
  path?: string
  projectId?: string
}

const Plot = (props: PlotProps) => {
  // TODO: Fix this. project returns null or undefined, not sure
  // how that should go with the project destructuring
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state, props.projectId)
  )

  let { activeTabId } = props
  if (activeTabId === "" || !activeTabId) activeTabId = "timeline"

  const [storySections, setStorySections] = useState<
    Array<StorySectionInterface>
  >([])
  const [showAddStorySectionPrompt, setShowAddStorySectionPrompt] = useState<
    boolean
  >(false)
  const [showEditStorySectionPrompt, setShowEditStorySectionPrompt] = useState(
    false
  )
  const [storySectionToEdit, setStorySectionToEdit] = useState("")
  const [newItemType, setNewItemType] = useState<string>("")
  const [showAddItemPrompt, setShowAddItemPrompt] = useState<boolean>(false)
  const [showEditItemPrompt, setShowEditItemPrompt] = useState<boolean>(false)
  const [itemToEdit, setItemToEdit] = useState({ id: "", type: "" })

  const [plotFilters, setPlotFilters] = useState<Array<string>>(assetTypes)

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (project!.storySections) {
      setStorySections(project!.storySections)
    } else {
      setStorySections([])
    }
  }, [project])

  const updateSceneSort = (
    newScenes: Array<ShallowSceneInterface>,
    sectionId: string
  ) => {
    const toDispatch = {
      type: "update_scene_sort",
      value: { scenes: newScenes, storySectionId: sectionId }
    }
    dispatch(toDispatch)
  }

  const updateStorySectionsState = (
    newStorySections: Array<StorySectionInterface>
  ) => {
    setStorySections(newStorySections)

    const toDispatch = {
      type: "update_story_section_sort",
      value: { storySections: newStorySections }
    }
    dispatch(toDispatch)
  }

  const toggleHideNonHighlighted = () => {
    const toDispatch = {
      type: "toggle_hide_non_highlighted",
      value: {}
    }
    dispatch(toDispatch)
  }

  // TODO: Can I make this not any?
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId, type } = result

    if (!destination) return

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    if (type === "DEFAULT") {
      let newStorySections = [...storySections]
      const highlightedStorySection:
        | StorySectionInterface
        | undefined = storySections.find(
        (storySection: StorySectionInterface) => storySection.id === draggableId
      )

      newStorySections.splice(source.index, 1)
      newStorySections.splice(
        destination.index,
        0,
        highlightedStorySection as never
      )

      updateStorySectionsState(newStorySections)
    } else {
      const sectionId: string = destination.droppableId.replace(
        "story-section-in-plot-",
        ""
      )
      let storySection: StorySectionInterface | undefined = storySections.find(
        (storySection: StorySectionInterface) => storySection.id === sectionId
      )

      if (destination.droppableId === source.droppableId) {
        // move within same section
        let newScenes = [...storySection!.scenes]
        const highlightedScene:
          | ShallowSceneInterface
          | undefined = storySection!.scenes.find(
          (Scene: ShallowSceneInterface) => Scene.id === draggableId
        )

        newScenes.splice(source.index, 1)
        newScenes.splice(destination.index, 0, highlightedScene as never)

        updateSceneSort(
          newScenes,
          destination.droppableId.replace("story-section-in-plot-", "")
        )
      } else {
        // move to another section
        const sourceSectionId: string = source.droppableId.replace(
          "story-section-in-plot-",
          ""
        )
        const destinationSectionId: string = destination.droppableId.replace(
          "story-section-in-plot-",
          ""
        )

        // remove from source array
        const sourceSection:
          | StorySectionInterface
          | undefined = storySections.find(
          (storySection: StorySectionInterface) =>
            storySection.id === sourceSectionId
        )
        const destinationSection:
          | StorySectionInterface
          | undefined = storySections.find(
          (storySection: StorySectionInterface) =>
            storySection.id === destinationSectionId
        )

        let sourceScenes = [...sourceSection!.scenes]
        let destinationScenes = [...destinationSection!.scenes]

        const SceneToMoveToNewSection:
          | ShallowSceneInterface
          | undefined = sourceScenes.find(
          (Scene: ShallowSceneInterface) => Scene.id === draggableId
        )
        sourceScenes = sourceScenes.filter(
          (Scene: ShallowSceneInterface) => Scene.id !== draggableId
        )

        destinationScenes.splice(
          destination.index,
          0,
          SceneToMoveToNewSection as ShallowSceneInterface
        )

        updateSceneSort(sourceScenes, sourceSectionId)
        updateSceneSort(destinationScenes, destinationSectionId)
      }
    }
  }

  // TODO: Refactor this a lot
  // TODO: Why am I using notemeta here?
  const createStorySection = (title: string, noteMeta: NoteMetaInterface, createScene: Boolean) => {
    const toDispatch = {
      type: "create_story_section",
      value: { title: title, description: noteMeta.supplemental, createScene: createScene }
    }
    dispatch(toDispatch)
    setShowAddStorySectionPrompt(false)
  }

  const updateItem = (updatedNoteItem: Dispatch_UpdateNoteItemInterface) => {
    const toDispatch = {
      type: "update_Note_item",
      value: updatedNoteItem
    }
    dispatch(toDispatch)
    setShowEditItemPrompt(false)
  }

  const updatePlotScale = (amount: number) => {
    const toDispatch = {
      type: "set_plot_scale",
      value: Number(amount) / 100
    }

    dispatch(toDispatch)
  }

  const setPlotMode = (type: string) => {
    const toDispatch = {
      type: "set_plot_mode",
      value: type
    }

    dispatch(toDispatch)
    window.scrollTo(0, 0)
  }

  const togglePlotScaleSnap = (e: any) => {
    const toDispatch = {
      type: "toggle_plot_scale_snap"
    }

    dispatch(toDispatch)
  }

  const config = useSelector((state: StateInterface) => getConfig(state))

  const isFilterSelected = (noteType: string) => {
    return plotFilters.includes(noteType)
  }

  const toggleFilter = (noteType: string) => {
    let newPlotFilters: Array<string> = []
    if (isFilterSelected(noteType)) {
      newPlotFilters = plotFilters.filter(
        (filter: string) => filter !== noteType
      )
    } else {
      newPlotFilters = [...plotFilters, noteType]
    }
    setPlotFilters(newPlotFilters)
  }

  return (
    <div>
      <Header />
      <SubHeaderPlot />
      <Content>
        <Footer plotMode={config.plotMode}>
          <FooterContent plotMode={config.plotMode}>
            <FooterContentItem>
              <FooterSubTitle>Zoom</FooterSubTitle>
              <FooterScale>
                <FooterScaleSnap
                  title="Fit to Screen"
                  onClick={togglePlotScaleSnap}
                  active={config.plotScaleSnap || false}
                >
                  <FontAwesomeIcon icon={faExpandArrows} />
                </FooterScaleSnap>
                <ZoomScaleSize
                  size={8}
                  title="Set to 20% Zoom"
                  onClick={() => updatePlotScale(20)}
                  active={
                    config.plotScale === 0.2 && config.plotScaleSnap !== true
                  }
                >
                  <FontAwesomeIcon icon={faSearch} />
                </ZoomScaleSize>
                <ZoomScaleSize
                  size={10}
                  title="Set to 50% Zoom"
                  onClick={() => updatePlotScale(50)}
                  active={
                    config.plotScale === 0.5 && config.plotScaleSnap !== true
                  }
                >
                  <FontAwesomeIcon icon={faSearch} />
                </ZoomScaleSize>
                <ZoomScaleSize
                  size={14}
                  title="Set to 100% Zoom"
                  onClick={() => updatePlotScale(100)}
                  active={
                    config.plotScale === 1 && config.plotScaleSnap !== true
                  }
                >
                  <FontAwesomeIcon icon={faSearch} />
                </ZoomScaleSize>
                <ZoomScaleSize
                  size={16}
                  title="Set to 125% Zoom"
                  onClick={() => updatePlotScale(125)}
                  active={
                    config.plotScale === 1.25 && config.plotScaleSnap !== true
                  }
                >
                  <FontAwesomeIcon icon={faSearch} />
                </ZoomScaleSize>
                <ZoomScaleSize
                  size={20}
                  title="Set to 150% Zoom"
                  onClick={() => updatePlotScale(150)}
                  active={config.plotScale === 1.5}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </ZoomScaleSize>
              </FooterScale>
            </FooterContentItem>
            <FooterContentItem>
              <FooterSubTitle>Mode</FooterSubTitle>
              <PlotModeOptions>
                <PlotModeSelection
                  active={config.plotMode === "edit"}
                  onClick={() => setPlotMode("edit")}
                  title="Edit Mode"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </PlotModeSelection>
                <PlotModeSelection
                  active={config.plotMode === "inspect"}
                  onClick={() => setPlotMode("inspect")}
                  title="Inspect Mode"
                >
                  <FontAwesomeIcon icon={faEye} />
                </PlotModeSelection>
              </PlotModeOptions>
            </FooterContentItem>
            {config.plotMode === "inspect" && (
              <FooterContentItem>
                <FooterSubTitle>Filter by Type of Asset</FooterSubTitle>

                <PlotInspectFilters>
                  {assetTypes.map((assetType: string) => {
                    return (
                      <PlotInspectFilterType
                        key={`plot-filter-${assetType}`}
                        selected={isFilterSelected(assetType)}
                        onClick={() => toggleFilter(assetType)}
                        title={`Toggle ${assetType} Assets`}
                      >
                        <FontAwesomeIcon
                          icon={
                            getIconForAssetType(assetType) as IconDefinition
                          }
                        />
                      </PlotInspectFilterType>
                    )
                  })}
                </PlotInspectFilters>
              </FooterContentItem>
            )}
            {config.plotMode === "inspect" && (
              <FooterContentItem>
                <FooterSubTitle>Hide Non-Highlighted</FooterSubTitle>

                <PlotHideNonHighlighted
                  selected={config.hideNonHighlighted}
                  onClick={toggleHideNonHighlighted}
                >
                  <FontAwesomeIcon icon={faEyeSlash} />
                </PlotHideNonHighlighted>
              </FooterContentItem>
            )}
          </FooterContent>
        </Footer>
        <DontUseInMobile>
          <AlertBox allowHide={true}>FYI, Plot prefers a screen bigger than a phone.</AlertBox>
        </DontUseInMobile>
        {storySections.length === 0 && (
          <NoSectionsInProject
            onClick={() => setShowAddStorySectionPrompt(true)}
          >
            <span>This project has no sections.</span>
            <br />
            <br />
            Click here to create your first section, and click the "Add Section{" "}
            <FontAwesomeIcon icon={faPlusCircle} />" button to create more.
          </NoSectionsInProject>
        )}

        <StorySectionWrapper plotMode={config.plotMode}>
          {config.plotMode === "inspect" && (
            <StorySectionLegend filters={plotFilters} />
          )}
          <StorySections>
            {storySections.length > 0 && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"1"} direction="horizontal">
                  {(provided: DroppableProvided) => (
                    <Projects
                      snap={config.plotScaleSnap}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {storySections.map(
                        (
                          storySection: StorySectionInterface,
                          index: number
                        ) => {
                          return (
                            <StorySection
                              key={`story-section-draggable-${storySection.id}`}
                              index={index}
                              id={storySection.id}
                              title={storySection.title}
                              storySection={storySection}
                              filters={plotFilters}
                              showEditModal={() => {
                                setStorySectionToEdit(storySection.id)
                                setShowEditStorySectionPrompt(true)
                              }}
                            />
                          )
                        }
                      )}
                      {provided.placeholder}
                    </Projects>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </StorySections>
        </StorySectionWrapper>
        {config.plotMode === "edit" && (
          <NewSectionButton onClick={() => setShowAddStorySectionPrompt(true)}>
            ADD SECTION <FontAwesomeIcon icon={faPlusCircle} />
          </NewSectionButton>
        )}
        <Spacer width="100%" height="100px" />
        <PlotFooter />
      </Content>

      {showAddStorySectionPrompt === true && (
        <AddStorySectionModal
          closeModal={() => setShowAddStorySectionPrompt(false)}
          createStorySection={createStorySection}
        />
      )}
      {showEditStorySectionPrompt === true && (
        <EditStorySectionModal
          closeModal={() => setShowEditStorySectionPrompt(false)}
          storySectionId={storySectionToEdit}
        />
      )}
      {showEditItemPrompt === true && (
        <EditNoteModal
          hideRemoveFromScene={true}
          closeModal={() => setShowEditItemPrompt(false)}
          doCloseModal={() => setShowEditItemPrompt(false)}
          item={itemToEdit}
          updateItem={updateItem}
          mode={"assets"}
        />
      )}

      {showAddItemPrompt === true && (
        <AddItemToProjectModal
          closeModal={() => setShowAddItemPrompt(false)}
          type={newItemType}
        />
      )}
    </div>
  )
}

export default Plot
