const devConfig = {
  apiKey: "AIzaSyB9X-b6RSj_GkkZtqW8y3zKcQgfPN5cqOI",
  authDomain: "hilker-dev.firebaseapp.com",
  databaseURL: "https://hilker-dev.firebaseio.com",
  projectId: "hilker-dev",
  storageBucket: "hilker-dev.appspot.com",
  messagingSenderId: "330037062723",
  appId: "1:330037062723:web:b5d74a50b48463de5ef8f6",
  measurementId: "G-Q078TX7X9F"
}

var prodConfig = {
  apiKey: "AIzaSyB55LGnG7KX1ZKfv8103ObN3f0ju6qwQCA",
  authDomain: "hilker-prod.firebaseapp.com",
  databaseURL: "https://hilker-prod.firebaseio.com",
  projectId: "hilker-prod",
  storageBucket: "hilker-prod.appspot.com",
  messagingSenderId: "391451302799",
  appId: "1:391451302799:web:c18c56f3702ce87ec8c9fa",
  measurementId: "G-TZ2444ZKPW"
}

export const FirebaseDevConfig = devConfig
export const FirebaseProdConfig = prodConfig
