import React, { MouseEventHandler } from "react"
import ModalBase from "../../common/Modal/ModalBase/ModalBase"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle"
import { faBan, faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const DeleteNonLocalProjectModal = ({
  closeModal,
  deleteNonLocalProject,
}: {
  closeModal: MouseEventHandler
  deleteNonLocalProject: Function
}) => {
  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Delete Project From Server?</ModalTitle>
        <p>
          Are you sure you want to delete this project from the server? If you
          do, you won't have access to it anymore.
        </p>
        <ModalActions>
          <ModalActionButton type="cancel" onClick={closeModal}>
            <FontAwesomeIcon icon={faBan} /> No
          </ModalActionButton>
          <ModalActionButton type="okay" onClick={deleteNonLocalProject}>
            <FontAwesomeIcon icon={faCheckCircle} /> Yes
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  )
}

export default DeleteNonLocalProjectModal
