import React from "react"
import styled from "styled-components"
import { WriteMetaItemInterface } from "../../../utils/Interfaces"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckSquare,
  faSquare,
  faStar
} from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"

const Tab = styled.div<{
  customColor: string
  type: string
  completed: boolean
  thumbnail: boolean
}>`
  padding: 5px;
  border-radius: 0px 6px 6px 0px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${props =>
    `var(--${props.type}-color${
      props.completed !== true ? "--uncompleted" : ""
    })`};

  ${props =>
    props.customColor !== "" &&
    `background-color: ${props.customColor}${
      props.completed !== true ? "7F" : ""
    }`}

  -webkit-transition: background-color var(--transition--fast);
  transition: background-color var(--transition--fast);
  color: var(--tab-text-color);
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  display: grid;
  grid-template-columns: ${props =>
    props.thumbnail === true ? "30px 1fr 15px" : "1fr 15px"};

  :hover {
    background-color: var(--brand-primary);
  }
`

const TabText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.73em;
  svg {
    margin-right: 5px;
  }
`

const Wrapper = styled.div`
  margin-bottom: 10px;
`

const TabCompleted = styled.div`
  font-size: 14px;
  color: var(--tab-handle-color);
`

const TabThumbnail = styled.div<{ src: string }>`
  background-image: url(${props => props.src});
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  margin: -5px 0px -5px -5px;
  width: 25px;
  height: 31px;
`

const WriteMetaItem = ({
  item,
  showItemInfo,
  shallowItem
}: {
  item: WriteMetaItemInterface
  showItemInfo: Function
  children?: any
  shallowItem: any
}) => {
  const dispatch = useDispatch()

  const truncated = (txt: string) => {
    if (txt.length < 40) {
      return txt
    }
    return txt.substr(0, 40)
  }

  let itemToDraw: any = item as WriteMetaItemInterface

  const toggleTabCompleted = () => {
    const toDispatch = {
      type: "toggle_tab_completed",
      value: shallowItem.id
    }
    dispatch(toDispatch)
  }
  return (
    <Wrapper>
      <Tab
        customColor={itemToDraw.color}
        type={itemToDraw.type}
        completed={shallowItem.completed}
        id={itemToDraw.id}
        thumbnail={itemToDraw.thumbnail && itemToDraw.thumbnail > ""}
      >
        {itemToDraw.thumbnail && itemToDraw.thumbnail > "" && (
          <TabThumbnail src={itemToDraw.thumbnail} />
        )}
        <TabText onClick={() => showItemInfo(item.id)}>
          {itemToDraw.role && itemToDraw.role === "PRIMARY" && (
            <FontAwesomeIcon icon={faStar} />
          )}
          {itemToDraw.title && truncated(itemToDraw.title)}
          {!itemToDraw.title &&
            itemToDraw.selectedText &&
            truncated(itemToDraw.selectedText)}
          {!itemToDraw.title &&
            !itemToDraw.selectedText &&
            truncated(itemToDraw.id)}
        </TabText>
        <TabCompleted onClick={() => toggleTabCompleted()}>
          <FontAwesomeIcon
            icon={shallowItem.completed === true ? faCheckSquare : faSquare}
          />
        </TabCompleted>
      </Tab>
    </Wrapper>
  )
}

export default WriteMetaItem
