import React, { useState } from "react"
import { navigate } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faColumns,
  faQuestionCircle,
  faBook,
} from "@fortawesome/free-solid-svg-icons"
import {
  faClipboard,
  faPen,
  faBoxFull,
  faBooks,
  faCheckCircle,
  faUserCog,
  faCommentExclamation,
  faCameraMovie,
} from "@fortawesome/pro-regular-svg-icons"
import { useSelector } from "react-redux"
import {
  StateInterface,
  ProjectInterface,
  ConfigObjectInterface,
} from "../../utils/Interfaces"
import { currentProject, getConfig } from "../../utils/State"
import {
  Wrapper,
  MenuWrapper,
  HeaderLink,
  ProjectLink,
  ProjectName,
  ProjectNameLink,
  ProjectDropDown,
  DropDownProjectsWrapper,
  DropDownProject,
  DropDownProjectName,
  IsProjectSelected,
  DropDownOptions,
  AllProjectsLink,
  ProjectTitle,
  MenuLinks,
  HelpButton,
  FeedbackButton,
  UserSettings,
  Logo,
} from "./Header.styles"
import SendFeedbackModal from "../../modals/SendFeedbackModal/SendFeedbackModal"

const Header = (props: any) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )

  const baseProjectList: Array<ProjectInterface> = useSelector(
    (state: StateInterface) => state.projects
  )
  const config: ConfigObjectInterface = useSelector((state: StateInterface) =>
    getConfig(state)
  )

  let projectList: Array<ProjectInterface> = []
  baseProjectList.forEach((project: ProjectInterface) => {
    projectList.unshift(project)
  })

  projectList.sort((a: ProjectInterface, b: ProjectInterface) => {
    const aDate: any = new Date(a.updated_at)
    const bDate: any = new Date(b.updated_at)
    return bDate - aDate
  })

  const [showDropDown, setShowDropDown] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  let focusMode: boolean = config.focusMode
  if (window.location.pathname.indexOf("/write") === -1) {
    focusMode = false
  }

  const checkIfHideMenuShouldBeHidden = (e: any) => {
    setShowDropDown(false)
  }

  React.useEffect(() => {
    window.addEventListener("click", checkIfHideMenuShouldBeHidden)
    return () => {
      window.removeEventListener("click", checkIfHideMenuShouldBeHidden)
    }
  }, [])

  return (
    <Wrapper focusMode={focusMode}>
      <MenuWrapper>
        <HeaderLink
          onClick={() => navigate("/")}
          current={false}
          title="Home"
          style={{
            color: "var(--brand-primary)",
            width: "100%",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <Logo />
        </HeaderLink>

        <ProjectLink>
          {project && (
            <ProjectName
              title={"Click to Switch Projects"}
              onClick={(e: any) => {
                e.stopPropagation()
                setShowDropDown(!showDropDown)
              }}
            >
              {project.name && (
                <ProjectNameLink>
                  {project && (
                    <React.Fragment>
                      <FontAwesomeIcon icon={faBooks} />
                    </React.Fragment>
                  )}
                </ProjectNameLink>
              )}
            </ProjectName>
          )}
          {showDropDown === true && (
            <ProjectDropDown>
              <DropDownProjectsWrapper>
                {projectList.map((proj: ProjectInterface) => {
                  return (
                    <DropDownProject
                      key={`drop-down-project-${proj.id}`}
                      onClick={(e: any) => {
                        e.stopPropagation()
                        setShowDropDown(false)
                        navigate(`/projects/${proj.id}`)
                      }}
                    >
                      <DropDownProjectName>
                        <span>
                          <FontAwesomeIcon
                            icon={
                              proj.config.type === "screenplay"
                                ? faCameraMovie
                                : faBook
                            }
                          />{" "}
                          {proj.name}
                        </span>
                        <IsProjectSelected>
                          {project!.id === proj.id && (
                            <FontAwesomeIcon icon={faCheckCircle} />
                          )}
                        </IsProjectSelected>
                      </DropDownProjectName>
                    </DropDownProject>
                  )
                })}
              </DropDownProjectsWrapper>
              <DropDownOptions>
                <AllProjectsLink
                  onClick={(e: any) => {
                    e.stopPropagation()
                    setShowDropDown(false)
                    navigate("/projects")
                  }}
                >
                  <FontAwesomeIcon icon={faBooks} /> See All
                </AllProjectsLink>
              </DropDownOptions>
            </ProjectDropDown>
          )}
        </ProjectLink>
        <div>
          <ProjectTitle onClick={() => navigate(`/projects/${project!.id}`)}>
            {project && project!.config && (
              <FontAwesomeIcon
                icon={
                  project!.config.type === "screenplay" ? faCameraMovie : faBook
                }
              />
            )}{" "}
            {project && project!.name}
          </ProjectTitle>
          <MenuLinks>
            {project && (
              <React.Fragment>
                <HeaderLink
                  onClick={() => navigate(`/projects/${project!.id}/note`)}
                  current={window.location.pathname.indexOf("/note") > -1}
                  title="Note"
                >
                  <FontAwesomeIcon icon={faClipboard} /> <span>Notes</span>
                </HeaderLink>
                <HeaderLink
                  onClick={() => navigate(`/projects/${project!.id}/assets`)}
                  current={window.location.pathname.indexOf("/assets") > -1}
                  title="Assets"
                >
                  <FontAwesomeIcon icon={faBoxFull} /> <span>Assets</span>
                </HeaderLink>
                <HeaderLink
                  onClick={() => navigate(`/projects/${project!.id}/plot`)}
                  current={window.location.pathname.indexOf("/plot") > -1}
                  title="Plot"
                >
                  <FontAwesomeIcon icon={faColumns} /> <span>Plot</span>
                </HeaderLink>
                <HeaderLink
                  onClick={() => navigate(`/projects/${project!.id}/write`)}
                  current={
                    window.location.pathname.indexOf(
                      `/projects/${project!.id}/write`
                    ) > -1
                  }
                  title="Write"
                >
                  <FontAwesomeIcon icon={faPen} /> <span>Write</span>
                </HeaderLink>
              </React.Fragment>
            )}
          </MenuLinks>
        </div>

        <FeedbackButton
          onClick={() => setShowFeedbackModal(true)}
          title="Leave Feedback!"
        >
          <FontAwesomeIcon icon={faCommentExclamation} />
        </FeedbackButton>

        <HelpButton onClick={() => navigate("/help")} title="Need Help?">
          <FontAwesomeIcon icon={faQuestionCircle} />
        </HelpButton>

        <UserSettings onClick={() => navigate("/user")} title="User Settings">
          <FontAwesomeIcon icon={faUserCog} />
        </UserSettings>
      </MenuWrapper>
      {showFeedbackModal === true && (
        <SendFeedbackModal
          closeModal={() => setShowFeedbackModal(false)}
        ></SendFeedbackModal>
      )}
    </Wrapper>
  )
}

export default Header
