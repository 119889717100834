import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCalendarDay, faCalendarWeek, faCameraMovie, faBook } from '@fortawesome/pro-regular-svg-icons'
import { formatWordCount } from '../../../utils/Numbers'
import { GoalDateInterface, GoalInterface, ProjectInterface, StateInterface } from '../../../utils/Interfaces'
import { useSelector } from 'react-redux'
import { currentProject } from '../../../utils/State'
import moment from 'moment'

const Wrapper = styled.div`
  display: grid;
  margin-bottom: 10px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 5px;
  border-bottom: 1px solid var(--themed-border-color);
`

const ProgressBar = styled.div`
  background-color: var(--themed-no-content-color);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 10px;
`

const ProgressBarInside = styled.div<{progress: number}>`
  background-color: var(--okay-button-color--hover);
  position: absolute;
  width: ${props => props.progress}%;
  height: 100%;
`

const GoalDateWrapper = styled.div`
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
`

const GoalDateItem = styled.span`
  font-size: 12px;
  display: inline-block;
  text-align: center;
  margin-bottom: 5px;
  
`

const RemoveGoal = styled.span`
  color: var(--remove-button-color--hover);
  cursor: pointer;
  text-align: right;
`

const GoalDateStatus = styled.span<{ success: number }>`
  ${props => props.success === 1 && 'color: var(--okay-button-color--hover);'}
  ${props => props.success === 0 && 'color: var(--remove-button-color--hover);'}
`

const TopRow = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr 25px;
  margin-bottom: 5px;
  font-size: 12px;
  text-transform: uppercase;
`

const GoalIcon = styled.div``
const GoalDescription = styled.div``

const GoalSuccess = styled.span`
  font-weight: bold;
  color: var(--okay-button-color);
  padding-left: 5px;
`

const GoalFailure = styled.span`
  font-weight: bold;
  color: var(--remove-button-color);
  padding-left: 5px;
`

const GoalMeta = styled.div`
  padding: 5px;
  box-sizing: border-box;
`

const GoalDateMonth = styled.div``

const GoalDateMonthHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: var(--themed-font-color);
  background-color: var(--themed-active-scene-color);
  text-transform: uppercase;
  padding: 0px 5px;
  box-sizing: border-box;
`

const GoalDateMonthContent = styled.div`
  padding: 5px;
  box-sizing: border-box;
  font-size: 13px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  @media (max-width: 480px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const GoalDateItemCurrentDate = styled.span<{ current: boolean, success: number }>`
  font-size: 13px;
  background-color: var(--themed-bg-color);
  padding: 0px 5px;
  box-sizing: border-box;
  border-radius: 4px 0px 0px 4px;
  ${props => props.success === 0 && 'border: 1px solid var(--cancel-button-color--hover);'}
  ${props => props.success === 1 && 'border: 1px solid var(--okay-button-color--hover); '}
  ${props => props.success === 2 && 'border: 1px solid var(--themed-no-content-color);'}
  ${props => props.current === true && 'font-style: italic; border: 1px solid var(--themed-font-color);'}
`
const GoalDateItemWordsWritten = styled.span<{ success: number, current: boolean}>`
  font-size: 13px;
  padding: 0px 5px;
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;

  ${props => props.success === 0 && 'border: 1px solid var(--cancel-button-color--hover); background-color: var(--cancel-button-color--hover); color: var(--white-color);'}
  ${props => props.success === 1 && 'border: 1px solid var(--okay-button-color--hover); background-color: var(--okay-button-color--hover); color: var(--white-color);'}
  ${props => props.success === 2 && 'border: 1px solid var(--themed-no-content-color); background-color: var(--themed-no-content-color); color: var(--themed.font-color);'}
  ${props => props.current === true && 'border: 1px solid var(--themed-font-color);'}
`
const ProjectGoalItem = ({ goal, removeGoal, index, today, progress }: { goal: GoalInterface, removeGoal: Function, index: number, today: string, progress: number }) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )

  const goalDays = (days: Array<GoalDateInterface>) => {
    let arr: Array<string> = []

    days.forEach((day: GoalDateInterface) => {
      const key: string = day.day.substr(0,7)

      if (arr.includes(key) === false) {
        arr.push(key)
      }
    })
    return arr
  }

  const dateSuccess = (date: GoalDateInterface) => {
    let success = 2
    if (date.day < today) {
      date.wordsWritten >= date.baseWordTarget ? success = 1 : success = 0
    }

    if (date.day >= today && date.wordsWritten >= date.baseWordTarget) {
      success = 1
    }
    
    return success
  }

  const goalDaysByKey = (days: Array<GoalDateInterface>, key: string) => {
    const filtered: Array<GoalDateInterface> = days.filter((day: GoalDateInterface) => day.day.indexOf(key, 0) > -1)
    return filtered
  }

  // TODO: Refactor faCameraMovie/faBook detection into icon ts file.
  return (<Wrapper key={`goal-${index}`}>
    <TopRow>
      <GoalIcon>
        {goal.type === "project" && <FontAwesomeIcon icon={project!.config.type === "screenplay" ? faCameraMovie : faBook } />}
        {goal.type === "today" && <FontAwesomeIcon icon={faCalendarDay} />}
        {goal.type === "date" && <FontAwesomeIcon icon={faCalendarWeek} />}
      </GoalIcon>
      <GoalDescription>
        {goal.type === "today" && <span><strong>{goal.dateStart}:</strong></span>}
        {goal.type === "date" && <span><strong> by {goal.dateEnd}:</strong></span>}
        {" "}<span>{formatWordCount(goal.wordsWritten, false)} /  {formatWordCount(goal.wordCountTarget, true)}</span>
        {goal.wordsWritten >= goal.wordCountTarget && <GoalSuccess>You did it!</GoalSuccess>}
        {goal.active === false && goal.completed !== true && <GoalFailure>You missed it!</GoalFailure>}
      </GoalDescription>

      <RemoveGoal onClick={() => removeGoal(index)}><FontAwesomeIcon icon={faBan} /></RemoveGoal>
    </TopRow>
    <ProgressBar>
      <ProgressBarInside progress={progress} />
    </ProgressBar>
    <GoalMeta>
      {goal.type === "date" &&
        <GoalDateWrapper>
          {goalDays(goal.days).map((key: string) => {
            return <GoalDateMonth key={`goal-date-month-${key}`}>
              <GoalDateMonthHeader>{moment(key).format("MMMM YYYY")}</GoalDateMonthHeader>
              <GoalDateMonthContent>
                {goalDaysByKey(goal.days, key).map((date: GoalDateInterface) => {
                  return (<GoalDateItem key={`goal-date-day-${date.day}`}>
                    <GoalDateItemCurrentDate success={dateSuccess(date)} current={date.day === today}>{date.day.substr(8,2)}</GoalDateItemCurrentDate>
                    <GoalDateItemWordsWritten success={dateSuccess(date)} current={date.day === today}>{date.wordsWritten}</GoalDateItemWordsWritten>
                  </GoalDateItem>)
                })}
              </GoalDateMonthContent>


            </GoalDateMonth>
          }

            // return ()
          )}
        </GoalDateWrapper>
      }
    </GoalMeta>
  </Wrapper>)

}

export default ProjectGoalItem