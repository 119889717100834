import React, { useState, SyntheticEvent } from "react"
import styled from "styled-components"

import { useSelector, useDispatch } from "react-redux"
import {
  currentProject,
  currentSceneObject,
  sceneParentSection,
} from "./../../../utils/State"
import {
  StateInterface,
  ProjectInterface,
  SceneInterface,
  SceneConfigObjectInterface,
  NoteMetaInterface,
  doAddItemProps,
} from "../../../utils/Interfaces"

import WriteNoScene from "../WriteNoScene/WriteNoScene"
import Showdown from "showdown"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEllipsisH,
  faProjectDiagram,
  faBold,
  faItalic,
  faUnderline,
  faTrashAlt,
  faBan,
  faCheck,
} from "@fortawesome/free-solid-svg-icons"
import { faLineHeight, faFileAlt } from "@fortawesome/pro-solid-svg-icons"
import { faMarkdown } from "@fortawesome/free-brands-svg-icons"

import { faPrint, faPageBreak } from "@fortawesome/pro-regular-svg-icons"
import PrintModal from "../../../modals/PrintModal/PrintModal"
import EditableTextContextMenu from "../../Notes/EditableTextContextMenu/EditableTextContextMenu"
import AddNoteModal from "../../../modals/AddNoteModal/AddNoteModal"
import {
  generateRandomId,
  sanitizeLocationName,
  sanitizeCharacterName,
  sanitizeHTMLPaste,
} from "../../../utils/Strings"
import WriteHomeScreen from "../WriteHomeScreen/WriteHomeScreen"
import Spacer from "../../../common/Spacer/Spacer"
import { saveSelection, restoreSelection } from "../../../utils/Selection"
import { scrollWritingAreaIntoViewIfNecessary } from "../../../utils/Cursor"

const Content = styled.div<{
  config: SceneConfigObjectInterface
  showHighlight: 0 | 1 | 2 | 3 | 4
  restructureMode: boolean
}>`
  width: calc(100% - 20px);
  margin: 10px;
  height: 100%;
  outline: 0;
  -webkit-touch-callout: none;
  box-sizing: border-box;
  padding: 5px 5px 100vh 5px;

  line-height: ${(props) => props.config.lineSpacing}em;

  ${(props) =>
    props.config.type === "screenplay" &&
    "font-family: 'Courier Prime', monospace;"}

  ${(props) =>
    props.config.type === "screenplay" &&
    "p { margin: 0px; } h4 { text-transform: uppercase; margin-bottom: 0px; }"}

    ${(props) =>
    props.showHighlight === 1 &&
    `:focus {
    p:not([active="true"]), p:not([activeWrapper="true"]), p:not(:hover) {
      -webkit-filter: blur(2.5px);
      -moz-filter: blur(2.5px);
      -o-filter: blur(2.5px);
      -ms-filter: blur(2.5px);
      filter: blur(2.5px);
    }

    p:hover, p[active="true"], p[activeWrapper="true"] {
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -o-filter: blur(0px);
      -ms-filter: blur(0px);
      filter: blur(0px);
    }
    }`}

    ${(props) =>
    props.showHighlight === 2 &&
    props.config.type !== "screenplay" &&
    `p[active="true"] {
    background-color: var(--screenplay-action-hover-color);
  }
`}
    ${(props) =>
    props.showHighlight === 2 &&
    props.config.type === "screenplay" &&
    `

      .screenplay-character[activeWrapper="true"], .screenplay-dialogue[activeWrapper="true"], .screenplay-parenthetical[activeWrapper="true"] {
        background-color: var(--themed-plot-bg-color);
      }

  .screenplay-scene[active="true"] {
    background-color: var(--screenplay-scene-hover-color);
  }

  .screenplay-action[active="true"] {
    background-color: var(--screenplay-action-hover-color);
  }

  .screenplay-character[active="true"] {
    background-color: var(--screenplay-character-hover-color);
  }

  .screenplay-dialogue[active="true"] {
    background-color: var(--screenplay-dialogue-hover-color);
  }

  .screenplay-parenthetical[active="true"] {
    background-color: var(--screenplay-parenthetical-hover-color);
  }

  .screenplay-transition[active="true"] {
    background-color: var(--screenplay-transition-hover-color);
    }

  @media (min-width: 768px) {
    p[active="true"]:hover {
      background-color: var(--screenplay-action-hover-color);
    }
    .screenplay-scene:hover,
  .screenplay-scene[active="true"] {
    background-color: var(--screenplay-scene-hover-color);
  }

  .screenplay-action:hover,
  .screenplay-action[active="true"] {
    background-color: var(--screenplay-action-hover-color);
  }

  .screenplay-character:hover,
  .screenplay-character[active="true"] {
    background-color: var(--screenplay-character-hover-color);
  }

  .screenplay-dialogue:hover,
  .screenplay-dialogue[active="true"] {
    background-color: var(--screenplay-dialogue-hover-color);
  }

  .screenplay-parenthetical:hover,
  .screenplay-parenthetical[active="true"] {
    background-color: var(--screenplay-parenthetical-hover-color);
  }

  .screenplay-transition:hover,
  .screenplay-transition[active="true"] {
    background-color: var(--screenplay-transition-hover-color);
  }
`}


${(props) =>
    props.showHighlight === 3 &&
    `:focus {
p:not([active="true"]), p:not([activeWrapper="true"]), p:not(:hover) {
  opacity: 0.3;
}

p:hover, p[active="true"], p[activeWrapper="true"] {
  opacity: 1;
}
}`}

${(props) =>
    props.showHighlight === 4 &&
    `:focus {
p:not([active="true"]), p:not([activeWrapper="true"]), p:not(:hover) {
  font-size: 16px;
}

p:hover, p[active="true"], p[activeWrapper="true"] {
  font-size: 20px;
}
}`}
`

const OptionText = styled.span`
  font-size: 12px;
  text-transform: uppercase;
`

const CancelRestructureButton = styled.span`
  color: var(--delete-color);
`

const CommitRestructureButton = styled.span`
  color: var(--new-color);
`

const Title = styled.input`
  width: 100%;
  padding: 5px;
  margin: 0px 10px 10px 10px;
  box-sizing: border-box;
  width: calc(100% - 20px);
  font-size: 18px;
  font-weight: bold;
  border: none;
  background-color: var(--themed-bg-color);
  outline: 0;
  font-family: var(--title-font);

  :focus {
    background-color: var(--themed-input-focus-color);
  }
`

const Summary = styled.textarea`
  padding: 5px;
  font-size: 16px;
  border: 0px;
  outline: 0;
  width: calc(100% - 20px);
  margin: 0px 10px 0px 10px;
  box-sizing: border-box;
  font-family: var(--body-font);
  background-color: var(--themed-bg-color);

  :focus {
    background-color: var(--themed-input-focus-color);
  }
`

const TextRestructureOption = styled.div<{ rollOverColor: string }>`
  text-transform: uppercase;
  color: var(--themed-font-color);
  cursor: pointer;
  background-color: var(--themed-light-bg);
  border: none;
  outline: 0;
  padding: 5px;
  box-sizing: border-box;

  :hover {
    svg {
      color: ${(props) => props.rollOverColor};
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    max-width: 800px;
    margin: 0 auto;
  }

  @media print {
    display: none;
  }
`

const TitleGrid = styled.div<{ large: boolean }>`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr ${(props) =>
      props.large === true ? "205px" : "87px"};
`

const SettingsOption = styled.span`
  font-size: 16px;
  color: var(--themed-font-color);
  text-align: center;
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  margin-left: 5px;

  :hover {
    color: var(--option-active-color);
  }
`

const DeleteOption = styled.span`
  font-size: 16px;
  color: var(--themed-font-color);
  text-align: center;
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  margin-left: 5px;

  :hover {
    color: var(--delete-color);
  }
`

const PrintOption = styled.span`
  font-size: 16px;
  color: var(--themed-font-color);
  text-align: center;
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  margin-left: 5px;

  :hover {
    color: var(--option-active-color);
  }
`
const RestructureOption = styled.span`
  font-size: 16px;
  color: var(--themed-font-color);
  text-align: center;
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  margin-left: 5px;

  :hover {
    color: var(--option-active-color);
  }
`
const TextFormattingOptions = styled.div<{ columns: string }>`
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  transform: translateZ(0);
  top: -1px;
  background-color: var(--themed-light-bg);
  font-size: 12px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: ${(props) => props.columns};
`

const TextFormattingOption = styled.button<{
  current: boolean
  hoverType: string
}>`
  text-transform: uppercase;
  color: var(--themed-font-color);
  cursor: pointer;
  background-color: var(--themed-light-bg);
  border: none;
  outline: 0;
  padding: 5px;
  box-sizing: border-box;

  :hover {
    background-color: ${(props) => `var(--${props.hoverType}-color)`};
    color: var(--themed-light-bg);

    svg {
      background-color: var(--themed-font-color);
      color: var(--themed-light-bg);
    }
  }
  ${(props) =>
    props.current === true &&
    `background-color: var(--${props.hoverType}-color); color: var(--themed-light-bg);`}
`

const SmallerIcon = styled.span`
  font-size: 11px;
`

const WriteOptionNav = styled.div<{ focusMode: boolean }>`
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);
  margin: 0px 10px 0px 0px;
  text-align: right;
  line-height: 2.3em;

  ${(props) =>
    props.focusMode === true
      ? "visibility: hidden; opacity: 0;"
      : "visibility: visible; opacity: 1;"}
`

const WriteTextArea = ({ showItemInfo }: { showItemInfo: Function }) => {
  const converter = new Showdown.Converter()
  const textRef = React.useRef<HTMLDivElement>(null)
  const [showWriteOptions, setShowWriteOptions] = useState(false)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [restructureMode, setRestructureMode] = useState(false)
  const config = useSelector((state: StateInterface) => state.config)
  const [showContextMenu, setShowContextMenu] = useState<number>(0)
  const [selectedText, setSelectedText] = useState("")
  const [showAddItemPrompt, setShowAddItemPrompt] = useState<boolean>(false)
  const [selectionRange, setSelectionRange] = useState<
    Range | null | undefined
  >()
  const [newNoteType, setNewNoteType] = useState<string>("")
  const [newNoteAction, setNewNoteAction] = useState<string>("")
  const [
    numberOfRestructureBreakpoints,
    setNumberOfRestructureBreakpoints,
  ] = useState<number>(0)

  const screenplayClasses: Array<string> = [
    "screenplay-scene",
    "screenplay-action",
    "screenplay-character",
    "screenplay-parenthetical",
    "screenplay-dialogue",
    "screenplay-transition",
  ]

  const getShowHighlight = () => {
    if (restructureMode === true) return 0
    return config.showHighlight
  }

  const isModalVisible = () => {
    return showPrintModal === true && showAddItemPrompt === true
  }

  const dispatch = useDispatch()

  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const scene: SceneInterface = useSelector((state: StateInterface) =>
    currentSceneObject(project)
  )

  const forceRerender: number = useSelector(
    (state: StateInterface) => state.forceRerender
  )

  if (!scene.config) scene.config = project!.config
  const updateSceneView = project!.updateSceneView

  React.useEffect(() => {
    const textFieldElement: any = document.getElementById("scene-text-field")
    if (textFieldElement) textFieldElement.focus()
  }, [scene.id])

  React.useEffect(() => {
    drawTextContent()

    window.addEventListener("keydown", onKeyPress)
    window.addEventListener("keydown", onKeyDown)
    window.addEventListener("resize", (e: any) => {
      onResize(e)
    })

    return () => {
      window.removeEventListener("keydown", onKeyPress)
      window.removeEventListener("keydown", onKeyDown)
      window.removeEventListener("resize", (e: any) => {
        onResize(e)
      })
    }
  }, [])

  React.useEffect(() => {
    const sel: any = saveSelection()
    drawTextContent()
    restoreSelection(sel)
  }, [forceRerender])

  const drawTextContent = () => {
    if (textRef && textRef!.current) {
      if (scene!.config.type === "manuscript") {
        // Strip out classes for p tags?
        textRef!.current!.innerHTML = converter.makeHtml(scene!.text || "")
        if (!scene!.text || scene!.text === "" || scene!.text === null) {
          textRef!.current!.innerHTML = "<p>&#8288;</p>"
        }
      } else if (scene!.config.type === "screenplay") {
        // textRef!.current!.innerHTML = scene!.text || ""
        textRef!.current!.innerHTML = converter.makeHtml(scene!.text || "")

        if (!scene!.text || scene!.text === "" || scene!.text === null) {
          textRef!.current!.innerHTML =
            "<p class='screenplay-scene'>&#8288;</p>"
        }
      }
    }

    setTextFieldFocus()
  }

  React.useEffect(() => {
    if (textRef && textRef!.current) {
      if (scene!.config.type === "manuscript") {
        textRef!.current!.innerHTML = converter.makeHtml(scene!.text || "")
        if (!scene!.text || scene!.text === "" || scene!.text === null) {
          textRef!.current!.innerHTML = "<p>&#8288;</p>"
        }
      } else if (scene!.config.type === "screenplay") {
        // textRef!.current!.innerHTML = scene!.text || ""
        textRef!.current!.innerHTML = converter.makeHtml(scene!.text || "")
        if (!scene!.text || scene!.text === "" || scene!.text === null) {
          textRef!.current!.innerHTML =
            "<p class='screenplay-scene'>&#8288;</p>"
        }
      }
    }

    setRestructureMode(false)
  }, [updateSceneView])

  React.useEffect(() => {
    if (textRef && textRef!.current) {
      let currentText: any = converter.makeHtml(scene!.text || "")
      if (
        scene!.config.render === "markdown" &&
        scene!.config.type !== "screenplay"
      )
        currentText = scene!.text || ""

      if (scene!.config.type === "screenplay") {
        if (
          !scene!.text ||
          scene!.text === "" ||
          (scene!.text === null && scene!.config.type === "screenplay")
        ) {
          currentText = "<p class='screenplay-scene'>&#8288;</p>"
        }
      }

      if (scene!.config.type === "manuscript") {
        if (
          !scene!.text ||
          scene!.text === "" ||
          (scene!.text === null && scene!.config.type === "manuscript")
        ) {
          currentText = "<p>&#8288;</p>"
        }
      }

      textRef!.current!.innerHTML = currentText
    }
  }, [scene.config])

  const setTextFieldFocus = () => {
    const textFieldElement: any = document.getElementById("scene-text-field")
    if (textFieldElement) textFieldElement.focus()
  }

  const onKeyDown = (e: any) => {
    let keynum: number = 0
    if (window.event) {
      // IE
      keynum = e.keyCode
    } else if (e.which) {
      // Netscape/Firefox/Opera
      keynum = e.which
    }

    if (e.ctrlKey && keynum === 80) {
      // trapping p
      setShowPrintModal(true)
      e.preventDefault()
    }

    // TODO: Make this only work on screenplay modes
    if (
      keynum === 9 &&
      scene!.config.type === "screenplay" &&
      isFocusedOnContentArea() === true
    ) {
      cycleThroughScreenplayClasses(e.shiftKey)
      e.preventDefault()
    }
  }

  const updateScreenplayElements = (e: any) => {
    let keynum: number = 0
    if (window.event) {
      // IE
      keynum = e.keyCode
    } else if (e.which) {
      // Netscape/Firefox/Opera
      keynum = e.which
    }

    scrollWritingAreaIntoViewIfNecessary(config.typewriterMode, 28)
    updateScreenplayElementType()

    adjustScreenplayElementHighlight()
  }

  const makeAssociatedElementsActive = (currentElement: any) => {
    // traverse backwards to something non-character-related
    let outOfCharacter: boolean = false
    let thisSibling: any
    const nonCharacterElements: Array<string> = [
      "screenplay-scene",
      "screenplay-transition",
      "screenplay-action",
    ]

    if (currentElement.className !== "screenplay-character") {
      thisSibling = currentElement.previousElementSibling
      while (outOfCharacter === false) {
        if (!thisSibling) {
          outOfCharacter = true
          break
        }
        if (!nonCharacterElements.includes(thisSibling.className)) {
          thisSibling.setAttribute("activeWrapper", "true")

          if (thisSibling.className === "screenplay-character") {
            outOfCharacter = true
          }
        } else {
          outOfCharacter = true
        }

        thisSibling = thisSibling.previousElementSibling
      }
    }

    // traverse forwards to something non-character-related
    thisSibling = currentElement.nextElementSibling
    const nonCharacterElements2: Array<string> = [
      "screenplay-scene",
      "screenplay-transition",
      "screenplay-action",
      "screenplay-character",
    ]
    outOfCharacter = false
    while (outOfCharacter === false) {
      if (!thisSibling) {
        outOfCharacter = true
        break
      }
      if (!nonCharacterElements2.includes(thisSibling.className)) {
        thisSibling.setAttribute("activeWrapper", "true")
      } else {
        outOfCharacter = true
      }

      thisSibling = thisSibling.nextElementSibling
    }
  }

  const adjustScreenplayElementHighlight = () => {
    // remove elements that are currently set to active
    const activeElements: any = document.querySelectorAll('[active="true"]')!
    if (activeElements)
      activeElements.forEach((elem: Element) => elem.removeAttribute("active"))

    // remove active wrapper elements
    const activeWrapperElements: any = document.querySelectorAll(
      '[activeWrapper="true"]'
    )!
    if (activeWrapperElements)
      activeWrapperElements.forEach((elem: Element) =>
        elem.removeAttribute("activeWrapper")
      )

    const selection = window.getSelection()
    if (selection!.anchorNode! === null) return
    const currentElement = selection!.anchorNode!.parentElement
    currentElement!.setAttribute("active", "true")

    if (
      currentElement!.className === "screenplay-character" ||
      currentElement!.className === "screenplay-dialogue" ||
      currentElement!.className === "screenplay-parenthetical"
    ) {
      makeAssociatedElementsActive(currentElement)
    }
  }

  const updateScreenplayElementType = () => {
    const selection = window.getSelection()
    if (selection!.anchorNode! === null) return
    const currentElement = selection!.anchorNode!.parentElement
    const currentClass = currentElement!.className

    const toDispatch = {
      type: "set_screenplay_element_type",
      value: currentClass,
    }
    dispatch(toDispatch)
  }

  const cycleThroughScreenplayClasses = (shiftKey: boolean) => {
    const selection = window.getSelection()
    if (selection!.anchorNode! === null) return
    const currentElement = selection!.anchorNode!.parentElement
    const currentClass = currentElement!.className

    const currentScreenplayClass: number = screenplayClasses.findIndex(
      (sc: string) => sc == currentClass
    )

    let nextScreenplayClass = 0

    if (shiftKey === false) {
      nextScreenplayClass = currentScreenplayClass + 1
      if (nextScreenplayClass >= screenplayClasses.length)
        nextScreenplayClass = 0
    } else {
      nextScreenplayClass = currentScreenplayClass - 1
      if (nextScreenplayClass < 0)
        nextScreenplayClass = screenplayClasses.length - 1
    }
    currentElement!.className = screenplayClasses[nextScreenplayClass]
  }

  const addAssetToSceneIfNecessary = (assetType: string, assetName: string) => {
    let newAssetName: string = assetName.toUpperCase().trim()
    let assetTypeToAdd: string = assetType
    if (assetType === "character") {
      newAssetName = sanitizeCharacterName(newAssetName)
    }
    if (assetType === "scene" || assetType == "location") {
      // TODO: trim stuff for location, after deciding abotu int/ext stuff.
      assetTypeToAdd = "location"
      newAssetName = sanitizeLocationName(newAssetName)

      const splitNewAsset: Array<string> = newAssetName
        .split(" -")
        .filter((asset: string) => {
          return (
            asset > "" &&
            asset > " " &&
            asset.trim() > "" &&
            asset.replace(/[^a-zA-Z0-9+]/g, "").length > 0
          )
        })

      if (!splitNewAsset || splitNewAsset.length === 0) return
      newAssetName = splitNewAsset[0].toUpperCase().trim()
    }

    if (assetTypeToAdd === "location" || assetTypeToAdd === "character") {
      const toDispatch = {
        type: "add_item_to_scene_and_create",
        value: {
          type: assetTypeToAdd,
          title: newAssetName,
        },
      }

      dispatch(toDispatch)
    }
  }

  const addAppropriateNodeToScreenplay = () => {
    const selection: Selection | null = window.getSelection()
    const currentElement = selection!.anchorNode!.parentElement
    const currentClass = currentElement!.className
    let nextClass: string = "screenplay-action"

    if (currentClass === "screenplay-scene") {
      nextClass = "screenplay-action"
    } else if (currentClass === "screenplay-character") {
      nextClass = "screenplay-dialogue"
    } else if (currentClass === "screenplay-dialogue") {
      nextClass = "screenplay-action"
    } else if (currentClass === "screenplay-parenthetical") {
      nextClass = "screenplay-dialogue"
    }
    let insertType: InsertPosition = "afterend"
    if (
      currentClass === "screenplay-scene" ||
      currentClass === "screenplay-character"
    ) {
      addAssetToSceneIfNecessary(
        currentClass.replace("screenplay-", ""),
        currentElement!.textContent as string
      )
    }

    const textToMoveToNextNode =
      currentElement!.textContent!.substr(selection!.anchorOffset) || "&#8288;"
    let posToShiftCursorTo: number = 0
    if (textToMoveToNextNode === "&#8288;") {
      posToShiftCursorTo = 1
    }
    currentElement!.textContent = currentElement!.textContent!.substr(
      0,
      selection!.anchorOffset
    )

    if (currentElement!.textContent === "") {
      currentElement!.innerHTML = "&#8288;"
    }

    const innerDiv = document.createElement("p")
    innerDiv.className = nextClass
    innerDiv.innerHTML = textToMoveToNextNode

    currentElement!.insertAdjacentElement(insertType, innerDiv)
    selection!.collapse(innerDiv.firstChild, posToShiftCursorTo)
  }

  const convertDialogueToParentheticalIfNecessary = () => {
    const selection: Selection | null = window.getSelection()
    const currentElement = selection!.anchorNode!.parentElement
    const currentClass = currentElement!.className

    if (currentClass === "screenplay-dialogue") {
      let insertType: InsertPosition = "afterend"
      if (currentElement!.textContent!.length > 2) {
        const textToMoveToNextNode =
          currentElement!.textContent!.substr(selection!.anchorOffset) ||
          "&#8288;"
        let posToShiftCursorTo: number = 0
        if (textToMoveToNextNode === "&#8288;") {
          posToShiftCursorTo = 1
        }
        currentElement!.textContent = currentElement!.textContent!.substr(
          0,
          selection!.anchorOffset
        )

        const innerDiv = document.createElement("p")
        innerDiv.className = "screenplay-parenthetical"
        innerDiv.innerHTML = textToMoveToNextNode

        currentElement!.insertAdjacentElement(insertType, innerDiv)
        selection!.collapse(innerDiv.firstChild, posToShiftCursorTo)
      } else {
        currentElement!.className = "screenplay-parenthetical"
      }
    }
  }

  const convertParentheticalToDialogueIfNecessary = (e: any) => {
    const selection: Selection | null = window.getSelection()
    const currentElement = selection!.anchorNode!.parentElement
    const currentClass = currentElement!.className
    if (currentClass === "screenplay-parenthetical") {
      let insertType: InsertPosition = "afterend"
      if (currentElement!.textContent!.trim() > "") {
        let textToMoveToNextNode =
          currentElement!.textContent!.substr(selection!.anchorOffset) ||
          "&#8288;"
        let posToShiftCursorTo: number = 0
        if (textToMoveToNextNode === "&#8288;") {
          posToShiftCursorTo = 1
        }

        currentElement!.textContent =
          currentElement!.textContent!.substr(0, selection!.anchorOffset) + ")"
        const innerDiv = document.createElement("p")
        innerDiv.className = "screenplay-dialogue"
        innerDiv.innerHTML = textToMoveToNextNode.replace(")", "")
        currentElement!.insertAdjacentElement(insertType, innerDiv)
        selection!.collapse(innerDiv.firstChild, posToShiftCursorTo)
      }
      e.preventDefault()
    }
  }

  const onResize = (e: any) => {
    scrollWritingAreaIntoViewIfNecessary(config.typewriterMode, 28)
  }

  const isFocusedOnContentArea = () => {
    return (
      document.activeElement && document.activeElement.id === "scene-text-field"
    )
  }

  const onKeyPress = (e: any) => {
    let key: string = e.key

    if (isFocusedOnContentArea() === false) return false

    if (key === "Enter" && scene!.config.type === "screenplay") {
      e.preventDefault()
      addAppropriateNodeToScreenplay()

      return false
    }

    if (key === "(" && scene!.config.type === "screenplay") {
      convertDialogueToParentheticalIfNecessary()
    }

    if (key === ")" && scene!.config.type === "screenplay") {
      convertParentheticalToDialogueIfNecessary(e)
    }
  }

  const isTextOnly = (pastedElements: NodeList) => {
    if (pastedElements.length === 1 && pastedElements[0].nodeName === "#text") {
      return true
    } else {
      return false
    }
  }

  const overrideHTMLPaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const selection = window.getSelection()

    if (e.clipboardData) {
      let sanitizedPasteContent: Array<any> = sanitizeHTMLPaste(
        e.clipboardData.getData("text/plain"),
        scene!.config.type === "screenplay"
      )

      // if (scene!.config.type === "screenplay" && !isTextOnly(pastedElements)) {
      let currentElement: any = selection!.anchorNode!.parentElement
      let currentClass = currentElement!.className
      let insertType: InsertPosition = "afterend"

      if (currentElement!.localName !== "p") {
        currentElement = selection!.anchorNode!.firstChild
        currentClass = currentElement!.className
      }

      if (currentElement!.textContent!.length >= 1) {
        const textToMoveToNextNode =
          currentElement!.textContent!.substr(selection!.anchorOffset) ||
          "&#8288;"
        let posToShiftCursorTo: number = 0
        if (textToMoveToNextNode === "&#8288;") {
          posToShiftCursorTo = 1
        }
        currentElement!.textContent = currentElement!.textContent!.substr(
          0,
          selection!.anchorOffset
        )

        const secondHalfDiv = document.createElement("p")
        secondHalfDiv.className = currentClass
        secondHalfDiv.innerHTML = textToMoveToNextNode

        currentElement!.insertAdjacentElement(insertType, secondHalfDiv)
        for (let i = sanitizedPasteContent.length - 1; i >= 0; i--) {
          currentElement!.insertAdjacentElement(
            insertType,
            sanitizedPasteContent[i]
          )
        }

        selection!.collapse(secondHalfDiv.firstChild, posToShiftCursorTo)
      }

      updateSceneText()
    }
  }

  const toggleOptionsLineSpacing = (e: SyntheticEvent) => {
    let newLineSpacing: number = scene.config.lineSpacing

    switch (scene.config.lineSpacing) {
      case 1:
        newLineSpacing = 1.5
        break
      case 1.5:
        newLineSpacing = 2
        break
      case 2:
        newLineSpacing = 3
        break
      case 3:
        newLineSpacing = 1
        break
    }
    const toDispatch = {
      type: "update_scene_config",
      value: {
        sceneId: scene.id,
        config: { ...scene.config, lineSpacing: newLineSpacing },
      },
    }
    dispatch(toDispatch)
  }

  const toggleOptionsRender = (e: SyntheticEvent) => {
    const newRender: string =
      scene.config.render === "html" ? "markdown" : "html"
    const toDispatch = {
      type: "update_scene_config",
      value: {
        sceneId: scene.id,
        config: { ...scene.config, render: newRender },
      },
    }
    dispatch(toDispatch)
  }

  const selectHandlerToShowContextMenu = () => {
    var sel = window.getSelection()
    if (sel!.getRangeAt && sel!.rangeCount) {
      const range: Range = sel!.getRangeAt(0)
      const locationOfSelectedText: ClientRect = range.getBoundingClientRect()

      if (locationOfSelectedText.width > 0) {
        setShowContextMenu(1 + Math.floor(Math.random() * 1000))
      } else {
        setShowContextMenu(0)
      }
    }
  }

  const addFactoidToItem = ({
    referenceId,
    type,
    selectedText,
    description,
  }: {
    referenceId: string
    type: string
    selectedText: string
    description: string
  }) => {
    const toDispatch = {
      type: "add_factoid_to_asset",
      value: {
        referenceId,
        type,
        selectedText,
        description: description,
        sceneId: scene.id,
      },
    }

    dispatch(toDispatch)
  }

  const addItem = (props: doAddItemProps) => {
    const toDispatch = {
      type: "add_item_from_scene",
      value: props,
    }
    dispatch(toDispatch)
  }

  const doAddItem = (props: doAddItemProps) => {
    const { referenceId, type, noteMeta, title, addToSceneOption, tags } = props
    hidePromptToAddItem()
    restoreSelection(selectionRange)

    const selectedText: Selection | null = document.getSelection()
    const id: string = generateRandomId()

    var span = document.createElement("span")
    if (newNoteAction === "existing") {
      if (referenceId) {
        addFactoidToItem({
          referenceId,
          type,
          selectedText: selectedText!.toString(),
          description: noteMeta.supplemental || "",
        })

        span.id = "selection_" + referenceId
        span.className = "note_selection_" + type
      }
    } else {
      addItem({
        id: id,
        selectedText: selectedText!.toString(),
        title,
        type,
        tags,
        noteMeta,
        referenceId: scene!.id,
        addToSceneOption,
      })

      // update wysiwyg text; initially we'll assume it just gets added
      // const selection: string = window.getSelection().
      span.id = "selection_" + id
      span.className = "note_selection_" + type
    }

    const selection: DocumentFragment = document
      .getSelection()!
      .getRangeAt(0)
      .cloneContents()

    document.getSelection()!.getRangeAt(0).deleteContents()
    const range: Range = document.getSelection()!.getRangeAt(0)
    range.collapse(true)
    span.appendChild(selection)

    // TODO: Add &nbsp; conditionally, if on last character
    range.insertNode(span)
    range.setStartAfter(span)
    range.collapse(true)
    document.getSelection()!.removeAllRanges()
    document.getSelection()!.addRange(range)

    updateSceneText()
  }

  // TODO: Abstract this content editable thing, probably make the text field with all this logic
  // into its own thing.

  const showPromptToAddItem = (assetType: string, newAction: string) => {
    const sel: Selection | null = document.getSelection()
    setSelectedText(sel!.toString())
    setSelectionRange(saveSelection())
    setNewNoteType(assetType)
    setNewNoteAction(newAction)
    setShowAddItemPrompt(true)
    setShowContextMenu(0)
  }

  const hidePromptToAddItem = () => {
    setShowAddItemPrompt(false)
  }

  const updateSceneText = () => {
    if (restructureMode === true) return
    const sceneTitle: HTMLInputElement | null = document.getElementById(
      "scene-title-field"
    ) as HTMLInputElement
    const sceneText: HTMLElement | null = document.getElementById(
      "scene-text-field"
    )
    const summaryText: any = document.getElementById("scene-summary-field")

    let updatedSceneContent = converter.makeMarkdown(sceneText!.innerHTML)

    if (
      scene.config.render === "markdown" &&
      scene.config.type !== "screenplay"
    ) {
      updatedSceneContent = sceneText!.innerHTML
    }

    if (scene.config.type === "screenplay") {
      updatedSceneContent = sceneText!.innerHTML as string
    }

    if (sceneText!.innerHTML === "") {
      sceneText!.innerHTML = "<p class='heading'>&nbsp;</p>"
    }

    const toDispatch = {
      type: "update_scene_content",
      value: {
        text: updatedSceneContent,
        title: sceneTitle!.value,
        summary: summaryText!.value,
      },
    }
    dispatch(toDispatch)
  }

  const deleteScene = (sceneId: string) => {
    const toDispatch = {
      type: "delete_scene",
      value: {
        id: sceneId,
      },
    }
    dispatch(toDispatch)
  }
  const selectScene = (sceneId: string) => {
    const toDispatch = {
      type: "select_scene",
      value: {
        id: sceneId,
      },
    }
    dispatch(toDispatch)
  }

  const elementInTree = (element: any) => {
    const d: any = document.getElementById("scene-text-field")

    return d.contains(element)
  }

  const setScreenplayElementType = (elementType: string) => {
    // TODO: THIS IS WONKY. Fix it.
    setSelectionRange(saveSelection())
    const selection = window.getSelection()
    if (selection!.anchorNode! === null) return
    const currentElement = selection!.anchorNode!.parentElement
    if (currentElement && elementInTree(currentElement)) {
      currentElement!.className = elementType
    }
    restoreSelection(selectionRange)
  }

  const checkForSelectionClick = (e: any) => {
    if (restructureMode === true) {
      if (e.target.className === "restructure-separator") {
        if (e.target.hasAttribute("breakpointSelected")) {
          e.target.removeAttribute("breakpointSelected")
          e.target.setAttribute("my-text", "Add Break")
        } else {
          e.target.setAttribute("breakpointSelected", "true")
          e.target.setAttribute("my-text", "Remove Break")
        }
      }

      // use a setNewElements variable that counts the number of restructure-separators with the selected attribute
      // so the "STATUS" bar will get updated.

      setNumberOfRestructureBreakpoints(
        document.querySelectorAll("[breakpointSelected='true'").length
      )

      return
    }

    if (e.target.id && e.target.id.indexOf("selection_") > -1) {
      const id: string = e.target.id.replace("selection_", "")

      const selectionType: string = e.target.className.replace(
        "note_selection_",
        ""
      )

      if (selectionType === "scene") {
        selectScene(id)
      } else {
        showItemInfo(id)
      }
    }
  }

  const isCurrentElement = (type: string) => {
    const selection = window.getSelection()
    if (selection!.anchorNode! === null) return false
    const currentElement = selection!.anchorNode!.parentElement

    if (currentElement!.localName.toLowerCase() === type.toLowerCase()) {
      return true
    }

    return false
  }

  const discardRestructureChanges = () => {
    clearRestructureSpacers()
    setRestructureMode(false)
    setNumberOfRestructureBreakpoints(0)
    window.scrollTo(0, 0)
  }

  const commitRestructureChanges = () => {
    // loop through all elements in the scene-text-field
    let restructuredScenes: Array<any> = [""]
    let currentScene: number = 0
    const d: any = document.getElementById("scene-text-field")
    d.childNodes.forEach((dc: any) => {
      if (
        dc.className === "restructure-separator" &&
        dc.hasAttribute("breakpointSelected")
      ) {
        currentScene++
        restructuredScenes.push("")
      } else {
        if (
          dc.outerHTML !== undefined &&
          dc.className !== "restructure-separator"
        ) {
          restructuredScenes[currentScene] =
            restructuredScenes[currentScene] + dc.outerHTML + "\n"
        }
      }
    })

    closeRestructureModal()
    restructuredScenes.forEach(
      (restructuredSceneText: string, index: number) => {
        if (index === 0) {
          // update current scene
          const toDispatch = {
            type: "update_scene_content",
            value: {
              text: restructuredSceneText,
              title: scene!.title,
              summary: scene!.noteMeta.supplemental,
            },
          }
          dispatch(toDispatch)
        } else {
          const toDispatch = {
            type: "create_scene",
            value: {
              scene: {
                title: scene!.title + " - " + index,
                noteMeta: { supplemental: "" },
                sectionId: sceneParentSection(project!, scene!.id),
                createNewScene: true,
                text: restructuredSceneText,
                positionOffset: index,
                positionReference: scene!.id,
              },
              redirectToScene: true,
            },
          }

          dispatch(toDispatch)
        }
      }
    )

    window.scrollTo(0, 0)
  }

  const closeRestructureModal = () => {
    clearRestructureSpacers()
    setRestructureMode(false)
    setNumberOfRestructureBreakpoints(0)
  }

  const clearRestructureSpacers = () => {
    const d: any = document.getElementById("scene-text-field")
    d.childNodes.forEach((dc: any) => {
      if (dc.className === "restructure-separator") {
        d.removeChild(dc)
      }
    })
  }

  const insertAfter = (newNode: any, referenceNode: any) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
  }

  const turnOnRestructureMode = () => {
    setShowWriteOptions(false)
    setRestructureMode(true)
    const d: any = document.getElementById("scene-text-field")
    let currentNodeIndex: number = 0
    let targetNodeIndex: number = d.childNodes.length
    let dc: any
    while (currentNodeIndex < targetNodeIndex) {
      dc = d.childNodes[currentNodeIndex]

      if (dc.localName && dc.className !== "restructure-separator") {
        const characterElements: Array<string> = [
          "screenplay-character",
          "screenplay-dialogue",
          "screenplay-parenthetical",
        ]
        if (characterElements.includes(dc.className)) {
          let thisSibling: any = dc.nextElementSibling
          const nonCharacterElements2: Array<string> = [
            "screenplay-scene",
            "screenplay-transition",
            "screenplay-action",
            "screenplay-character",
          ]

          if (
            thisSibling &&
            nonCharacterElements2.includes(thisSibling.className)
          ) {
            const newContentElement: HTMLElement = document.createElement("div")
            newContentElement.className = "restructure-separator"
            newContentElement.setAttribute("my-text", "Add Break")
            newContentElement.innerHTML = ""

            insertAfter(newContentElement, dc)
            targetNodeIndex++
          }
        } else {
          const newContentElement: HTMLElement = document.createElement("div")
          newContentElement.className = "restructure-separator"
          newContentElement.setAttribute("my-text", "Add Break")
          newContentElement.innerHTML = ""

          insertAfter(newContentElement, dc)
          targetNodeIndex++
        }
      }
      currentNodeIndex++
    }
  }

  const toggleTextElement = (type: string) => {
    document.execCommand(type, false)
  }

  if (project!.scenes.length === 0) return <WriteNoScene />
  if (!scene) return <div />

  return (
    <Wrapper
      onKeyUp={updateScreenplayElements}
      onClick={updateScreenplayElements}
    >
      {project!.currentScene === "home" && <WriteHomeScreen />}
      {project!.currentScene !== "home" && (
        <React.Fragment>
          {restructureMode === false && (
            <TitleGrid large={showWriteOptions}>
              <Title
                onChange={updateSceneText}
                type="text"
                id={"scene-title-field"}
                value={scene.title}
                tabIndex={isModalVisible() ? 1 : undefined}
                placeholder="Title"
              />
              <WriteOptionNav focusMode={config.focusMode}>
                {showWriteOptions === true && (
                  <React.Fragment>
                    <RestructureOption
                      title="Delete Scene"
                      onClick={() => deleteScene(scene.id)}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </RestructureOption>
                    <RestructureOption
                      title="Change Render Mode"
                      onClick={toggleOptionsRender}
                    >
                      {scene.config.render === "html" ? (
                        <FontAwesomeIcon icon={faFileAlt} />
                      ) : (
                        <FontAwesomeIcon icon={faMarkdown} />
                      )}{" "}
                      <OptionText>
                        {scene.config.render === "html"
                          ? "Rich Text"
                          : "Markdown"}
                      </OptionText>
                    </RestructureOption>
                    <RestructureOption
                      title="Change Line Spacing"
                      onClick={toggleOptionsLineSpacing}
                    >
                      <FontAwesomeIcon icon={faLineHeight} />{" "}
                      <OptionText>{scene.config.lineSpacing}</OptionText>
                    </RestructureOption>
                  </React.Fragment>
                )}

                {showWriteOptions === false && (
                  <React.Fragment>
                    <RestructureOption
                      title="Split up Scene"
                      onClick={() => turnOnRestructureMode()}
                    >
                      <FontAwesomeIcon icon={faPageBreak} />
                    </RestructureOption>

                    <PrintOption
                      title="Click to Print"
                      onClick={() => setShowPrintModal(true)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      <FontAwesomeIcon icon={faPrint} />
                    </PrintOption>
                  </React.Fragment>
                )}
                <SettingsOption
                  title="Click to change Scene Settings"
                  onClick={() => setShowWriteOptions(!showWriteOptions)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <FontAwesomeIcon
                    icon={showWriteOptions === false ? faEllipsisH : faCheck}
                  />
                </SettingsOption>
              </WriteOptionNav>
            </TitleGrid>
          )}

          {restructureMode === false && (
            <React.Fragment>
              <Summary
                onChange={updateSceneText}
                id={"scene-summary-field"}
                value={scene.noteMeta.supplemental || ""}
                placeholder="Scene Summary"
              />
              <hr />
            </React.Fragment>
          )}
          {/* <RecentChanges sceneId={scene.id} /> */}
          {/* <hr /> */}
          {restructureMode === true && (
            <TextFormattingOptions columns={"1fr 25px 25px"}>
              <TextRestructureOption rollOverColor={""}>
                {numberOfRestructureBreakpoints === 0 &&
                  "Click 'Add Break' to determine where the scene will be broken up."}
                {numberOfRestructureBreakpoints > 0 &&
                  `This scene will be broken up into ${
                    numberOfRestructureBreakpoints + 1
                  } scenes.`}
              </TextRestructureOption>
              <TextRestructureOption
                rollOverColor={"var(--delete-color)"}
                onClick={() => discardRestructureChanges()}
              >
                <CancelRestructureButton>
                  <FontAwesomeIcon icon={faBan} />
                </CancelRestructureButton>
              </TextRestructureOption>
              <TextRestructureOption
                rollOverColor={"var(--new-color)"}
                onClick={() => commitRestructureChanges()}
              >
                <CommitRestructureButton>
                  <FontAwesomeIcon icon={faCheck} />
                </CommitRestructureButton>
              </TextRestructureOption>
            </TextFormattingOptions>
          )}
          {scene!.config.type === "manuscript" && restructureMode === false && (
            <TextFormattingOptions columns={"1fr 1fr 1fr"}>
              <TextFormattingOption
                hoverType="manuscript-bold"
                current={isCurrentElement("b") || isCurrentElement("strong")}
                onClick={() => toggleTextElement("bold")}
                onMouseDown={(e) => e.preventDefault()}
                title="Bold"
              >
                <FontAwesomeIcon icon={faBold} />
              </TextFormattingOption>
              <TextFormattingOption
                hoverType="manuscript-italic"
                current={isCurrentElement("i") || isCurrentElement("em")}
                onClick={() => toggleTextElement("italic")}
                onMouseDown={(e) => e.preventDefault()}
                title="Italic"
              >
                <FontAwesomeIcon icon={faItalic} />
              </TextFormattingOption>
              <TextFormattingOption
                hoverType="manuscript-underline"
                current={isCurrentElement("u")}
                onClick={() => toggleTextElement("underline")}
                onMouseDown={(e) => e.preventDefault()}
                title="Underline"
              >
                <FontAwesomeIcon icon={faUnderline} />
              </TextFormattingOption>
            </TextFormattingOptions>
          )}
          {scene!.config.type === "screenplay" && restructureMode === false && (
            <TextFormattingOptions columns={"1fr 1fr 1fr 1fr 1fr 1fr 1fr"}>
              <TextFormattingOption
                hoverType={"screenplay-scene"}
                current={
                  project!.currentScreenplayElementType === "screenplay-scene"
                }
                onClick={() => setScreenplayElementType("screenplay-scene")}
                onMouseDown={(e) => e.preventDefault()}
                title="Scene"
              >
                🎬
              </TextFormattingOption>
              <TextFormattingOption
                hoverType="screenplay-action"
                current={
                  project!.currentScreenplayElementType === "screenplay-action"
                }
                onClick={() => setScreenplayElementType("screenplay-action")}
                onMouseDown={(e) => e.preventDefault()}
                title="Action"
              >
                💥
              </TextFormattingOption>
              <TextFormattingOption
                hoverType="screenplay-character"
                current={
                  project!.currentScreenplayElementType ===
                  "screenplay-character"
                }
                onClick={() => setScreenplayElementType("screenplay-character")}
                onMouseDown={(e) => e.preventDefault()}
                title="Character"
              >
                👤
              </TextFormattingOption>
              <TextFormattingOption
                hoverType="screenplay-parenthetical"
                current={
                  project!.currentScreenplayElementType ===
                  "screenplay-parenthetical"
                }
                onClick={() =>
                  setScreenplayElementType("screenplay-parenthetical")
                }
                onMouseDown={(e) => e.preventDefault()}
                title="Parenthetical"
              >
                <SmallerIcon>(...)</SmallerIcon>
              </TextFormattingOption>
              <TextFormattingOption
                hoverType="screenplay-dialogue"
                current={
                  project!.currentScreenplayElementType ===
                  "screenplay-dialogue"
                }
                onClick={() => setScreenplayElementType("screenplay-dialogue")}
                onMouseDown={(e) => e.preventDefault()}
                title="Dialogue"
              >
                💬
              </TextFormattingOption>
              <TextFormattingOption
                hoverType="screenplay-transition"
                current={
                  project!.currentScreenplayElementType ===
                  "screenplay-transition"
                }
                onClick={() =>
                  setScreenplayElementType("screenplay-transition")
                }
                onMouseDown={(e) => e.preventDefault()}
                title="Transition"
              >
                <SmallerIcon>
                  <FontAwesomeIcon icon={faProjectDiagram} />
                </SmallerIcon>
              </TextFormattingOption>
            </TextFormattingOptions>
          )}
          <Content
            id={`scene-text-field`}
            restructureMode={restructureMode}
            ref={textRef}
            onChange={updateSceneText}
            onInput={updateSceneText}
            onPaste={overrideHTMLPaste}
            tabIndex={isModalVisible() ? 2 : undefined}
            contentEditable={!restructureMode}
            placeholder={"Start writing..."}
            config={scene.config}
            onSelect={selectHandlerToShowContextMenu}
            onClick={checkForSelectionClick}
            className={`script script-${scene.config.type}`}
            showHighlight={getShowHighlight()}
          />
        </React.Fragment>
      )}
      {showContextMenu > 0 && (
        <EditableTextContextMenu clickHandler={showPromptToAddItem} />
      )}

      {showPrintModal === true && (
        <PrintModal
          printType="text"
          projectId={project!.id}
          selectedId={scene!.id}
          closeModal={() => setShowPrintModal(false)}
        />
      )}

      {showAddItemPrompt === true && (
        <AddNoteModal
          selectedText={selectedText}
          closeModal={hidePromptToAddItem}
          addItem={doAddItem}
          newNoteType={newNoteType}
          newNoteAction={newNoteAction}
          showAddToSceneOption={newNoteAction === "existing" ? false : true}
        />
      )}

      <Spacer width="100%" height="60px" />
    </Wrapper>
  )
}

export default WriteTextArea
