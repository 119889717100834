import {
  StateInterface,
  ProjectInterface,
  NoteInterface,
  SceneInterface,
  StorySectionInterface,
  SceneItemInterface,
  ShallowSceneInterface,
  AssetInterface
} from "./Interfaces"
import { emptyScene, defaultConfig } from "./Data"

export const currentProject = (state: StateInterface, projectId?: string) => {
  // TODO: Only try to update current project if the project exists
  const project: ProjectInterface | undefined = state.projects.find(
    (project: ProjectInterface) => {
      if (!project) {
        return false
      } else if (projectId) {
        return project!.id === projectId
      } else {
        return project!.id === state.currentProject
      }
    }
  )

  if (!project) return null
  return project
}

export const projectById = (
  state: StateInterface,
  projectId: string | null
) => {
  const project: ProjectInterface | undefined = state.projects.find(
    (project: ProjectInterface) => project!.id === projectId
  )

  if (!project) return null
  return project
}

export const currentProjectItems = (state: StateInterface) => {
  if (!state || !state.projects) return null
  const project: ProjectInterface | null | undefined = state.projects.find(
    (project: ProjectInterface) => project!.id === state.currentProject
  )

  if (!project) return null
  return project
}

export const currentNote = (state: StateInterface) => {
  const project: ProjectInterface | null = currentProject(state)
  let note = project!.notes.find(
    (note: NoteInterface) => note.id === project!.currentNote
  )

  if (!note) return null
  return note
}

export const currentNoteFromProject = (project: ProjectInterface | null) => {
  let note = project!.notes.find(
    (note: NoteInterface) => note.id === project!.currentNote
  )

  return note
}

export const projectTypes = (
  project: ProjectInterface | null,
  type: string
) => {
  return project!.assets.filter((asset: AssetInterface) => asset.type === type)
  
}

export const getFullItem = (
  project: ProjectInterface | null,
  type: string,
  id: string
) => {
  // TODO: Instead of type + s, use a pluralization util?
  if (type === "storySection") {
    return project!.storySections.find(
      (item: StorySectionInterface) => item.id === id
    )
  } else if (type === "scene") {
    return project!.scenes.find((item: SceneInterface) => item.id === id)
  } else {
    const projectsByType: any = projectTypes(project, type).find(
      (
        item: AssetInterface
      ) => item.id === id
    )

    return projectsByType
  }
}

export const currentScene = (project: ProjectInterface | null) => {
  return project!.currentScene
}

export const currentSceneObject = (project: ProjectInterface | null) => {
  const scene: SceneInterface | undefined = project!.scenes.find(
    (scene: SceneInterface) => scene.id === project!.currentScene
  )

  if (!scene) return emptyScene
  return scene
}

export const getConfig = (state: StateInterface) => {
  return Object.assign({}, defaultConfig, { ...state.config })
}

export const isUsedInProject = (project: ProjectInterface, item: any) => {
  let usedInProject: boolean = false
  project!.scenes.forEach((scene: SceneInterface) => {
    if (
      scene.items.find(
        (sceneItem: SceneItemInterface) =>
          sceneItem.id === item.id && sceneItem.type === item.type
      )
    ) {
      usedInProject = true
    }
  })
  return usedInProject
}

export const countUsesInProject = (project: ProjectInterface, item: any) => {
  let usesInProject: number = 0
  project!.scenes.forEach((scene: SceneInterface) => {
    if (
      scene.items.find(
        (sceneItem: SceneItemInterface) =>
          sceneItem.id === item.id && sceneItem.type === item.type
      )
    ) {
      usesInProject++
    }
  })
  return usesInProject
}

export const getUser = (state: StateInterface) => {
  if (!state.user || (state.user && !state.user.uid)) return false
  return state.user
}

export const getUsesInProject = (project: ProjectInterface, item: any) => {
  let uses: Array<any> = []
  project!.scenes.forEach((scene: SceneInterface) => {
    if (
      scene.items.find(
        (sceneItem: SceneItemInterface) =>
          sceneItem.id === item.id && sceneItem.type === item.type
      )
    ) {
      uses.push(scene)
    }
  })
  return uses
}

export const detectDarkMode = (mode = "") => {
  if (mode === "on") {
    document.documentElement.setAttribute("data-theme", "dark")
  } else if (
    mode == "system" &&
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    document.documentElement.setAttribute("data-theme", "dark")
  } else {
    document.documentElement.setAttribute("data-theme", "light")
  }
}

export const sceneParentSection = (
  project: ProjectInterface,
  sceneId: string
) => {
  let sectionId: string = ""

  project.storySections.forEach((storySection: StorySectionInterface) => {
    if (
      storySection.scenes.find(
        (scene: ShallowSceneInterface) => scene.id === sceneId
      )
    ) {
      sectionId = storySection.id
    }
  })
  return sectionId
}
