import { SceneInterface } from "./Interfaces"

export const WordCount = (type: string, ref: any) => {
  let numberOfWords: number = 0
  if (type === "project") {
    ref.scenes.forEach((scene: SceneInterface) => {
      // numberOfWords += WordCountOfString(scene.text as string)
      numberOfWords += scene.wordCount
    })

    return numberOfWords
  } else if (type === "scene") {
    return WordCountOfString(ref)
  }

  return 0
}

const WordCountOfString = (str: string) => {
  if (!str || str === null || str === undefined) return 0
  str = str.replace(/(^\s*)|(\s*$)/gi, "") //exclude  start and end white-space
  str = str.replace(/[ ]{2,}/gi, " ") //2 or more space to 1
  str = str.replace(/\n /, "\n") // exclude newline with a start spacing

  return str.split(" ").filter(function (strng) {
    return strng !== ""
  }).length
}

const formatThousands = (count: number) => {
  let num: number
  if (count < 10) {
    num = Math.floor(count*100)/100
  } else if (count >= 10 && count < 100) {
    num = Math.floor(count*10)/10
  } else if (count >= 100) {
    num = Math.floor(count)
  } else {
    num = Math.floor(count)
  }

  return String(num)
}

export const formatWordCount = (count: number, words: boolean) => {
    let wordcount: string
    if (count < 1000) {
      wordcount = formatThousands(count)
    } else if (count >= 1000 && count < 1000000) {
      wordcount = formatThousands(Math.floor(count / 10) / 100) + "K"
    } else if (count >= 1000000 && count < 1000000000) {
      wordcount = formatThousands(Math.floor(count / 10000) / 100) + "M"
    } else {
      wordcount = '∞'
    }

    if (words && wordcount === "1") {
      wordcount += " word"
    } else if (words && wordcount !== "1") {
      wordcount += " words"
    }

    return wordcount
}