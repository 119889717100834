import React, { useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  border-top: 4px solid var(--themed-input-bg-color);
  padding: 30px 30px 0px 30px;

  @media (max-width: 480px) {
    padding: 10px 10px 0px 10px;
  }
`
const Content = styled.div`
  max-width: 800px;
  margin: 0px auto 20px auto;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`

const LeftSide = styled.div`
  font-size: 20px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`

const RightSide = styled.div``

const RightSideFrame = styled.div`
  background-image: url(/images/laptop_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 390px;
  height: 326px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 768px) {
    width: 390px;
    height: 326px;
    margin: 10px auto;
  }

  @media (max-width: 480px) {
    width: 300px;
    height: 300px;
    margin: 10px auto;
  }
`

const RightSideImg = styled.img`
  box-sizing: border-box;
  display: block;
  object-fit: contain;
  width: 100%;
  max-width: 100%;
  min-height: 300px;
  animation: all var(--transition--fast);
  -webkit-animation: all var(--transition--fast);
  position: absolute;
  width: 290px;
  left: 51px;
  top: -30px;

  @media (max-width: 768px) {
    margin: 20px auto 0px auto;
    max-width: 480px;
    min-height: 200px;
    top: 0px;
  }

  @media (max-width: 480px) {
    width: 222px;
    left: 39px;
    top: -3px;
  }
`

const SlideShowTitle = styled.h1`
  margin-top: 0px;
  font-size: 30px;
  font-family: var(--title-font);

  @media (max-width: 480px) {
    font-size: 20px;
  }
`

const Slideshow = () => {
  let imageIdInterval: any

  const [imageId, setImageId] = useState(0)

  const imagesToUse: Array<string> = [
    "/images/screen_notes_small.png",
    "/images/screen_assets_small.png",
    "/images/screen_plot_small.png",
    "/images/screen_reports_small.png",
    "/images/screen_write_small.png",
  ]

  const preloadImages = () => {
    imagesToUse.forEach((image: string) => {
      let img = new Image()
      img.src = image
    })
  }

  React.useEffect(() => {
    preloadImages()
    imageIdInterval = setInterval(() => {
      let newImageId: number = imageId + 1
      if (newImageId === imagesToUse.length) newImageId = 0
      setImageId((prevImageId: number) => {
        return prevImageId >= imagesToUse.length - 1 ? 0 : prevImageId + 1
      })
    }, 5000)
    return () => {
      clearInterval(imageIdInterval)
    }
  }, [])

  const currentSlideShowImage = () => {
    return imagesToUse[imageId]
  }

  return (
    <React.Fragment>
      <Wrapper>
        <Content>
          <LeftSide>
            <SlideShowTitle>Write Better, Now.</SlideShowTitle>
            <strong>Write your story</strong> using a powerful, intuitive
            interface. <strong>Organize your plot</strong> to ensure that every
            plot thread gets tied up perfectly.{" "}
            <strong>Capture your ideas</strong> and turn them into trackable
            assets to catalogue everything in your story.
            <br />
            <br />
            Do it all <strong>in your browser</strong>, right now,{" "}
            <em>for free</em>.
          </LeftSide>
          <RightSide>
            <RightSideFrame>
              <RightSideImg src={currentSlideShowImage()} />
            </RightSideFrame>
          </RightSide>
        </Content>
      </Wrapper>
    </React.Fragment>
  )
}

export default Slideshow
