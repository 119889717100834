import React, { useState } from "react";
import styled from "styled-components";
import Header from "./../../common/Header/Header";
import { navigate } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import { currentProject } from "../../utils/State";
import NoteIndexListItem from "../../common/NoteIndexListItem/NoteIndexListItem";
import NoteNotFound from "../../components/Notes/NoteNotFound/NoteNotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePlus, faLightbulbOn, faTelescope,
} from "@fortawesome/pro-regular-svg-icons";
import {
  StateInterface,
  ProjectInterface,
  NoteInterface,
} from "../../utils/Interfaces";
import NotesFooter from "../../components/Notes/NotesFooter/NotesFooter";
import SubHeaderNoteIndex from "../../components/Notes/SubHeaderNoteIndex/SubHeaderNoteIndex";

const Content = styled.div`
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  grid-gap: 10px;
  height: calc(100vh - 100px);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    padding-left: 10px;
    box-sizing: border-box;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

const PageTitle = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const AddNoteLink = styled.div`
  display: grid;
  grid-template-columns: 24px 1fr;
  cursor: pointer;
  color: var(--themed-add-scene-color);
  font-family: var(--title-font);
  font-weight: bold;
  font-size: 16px;
  padding: 5px 5px 10px 10px;
  :hover {
    color: var(--themed-font-color);
  }

  svg {
    padding-top: 4px;
  }
`

const NoNotes = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
`;

const NoteList = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    max-width: 800px;
    margin: 0 auto;
  }

  h2 {
    margin-top: 0px;
    font-size: 18px;
  }
`

const NoteFilterTypeLink = styled.div<{ current: boolean }>`
  display: grid;
  grid-template-columns: 24px 1fr;
  ${props => props.current === true && 'font-weight: bold;'}
  font-size: 16px;
  cursor: pointer;
  color: var(--themed-font-color);

  :hover {
    color: var(--brand-primary);
  }
  padding: 5px 5px 10px 10px;

  svg {
    padding-top: 4px;
    color: ${(props) =>
      props.current === true
        ? "var(--brand-primary)"
        : "var(--themed-font-color)"};
  }

  svg:hover {
    color: var(--themed-font-color);
  }
`

const Padding = styled.div`
  width: 100%;
  height: 10px;
`


interface NoteIndexProps {
  path?: string;
  projectId?: string;
}

const NoteIndex = (props: NoteIndexProps) => {
  const dispatch = useDispatch();
  const [noteFilterType, setNoteFilterType] = useState("idea")
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state, props.projectId),
  );
  const baseNotes: Array<NoteInterface> = useSelector(
    (state: StateInterface) =>
      (currentProject(state) as ProjectInterface).notes,
  );

  let notes: Array<NoteInterface> = [];
  baseNotes.forEach((note: NoteInterface) => {
    notes.unshift(note);
  });

  notes.sort((a: NoteInterface, b: NoteInterface) => {
    const aDate: any = new Date(a.updated_at);
    const bDate: any = new Date(b.updated_at);
    return bDate - aDate;
  });

  const createNoteAndRedirect = () => {
    const toDispatch = { type: "create_note", value: {type: noteFilterType} };
    dispatch(toDispatch);
    navigate(`/projects/${project!.id}/note/latest`);
  };

  const isNoteCorrectType = (note: NoteInterface) => {
    if ((!note.type || note.type === "" || note.type === "idea") && noteFilterType === "idea") return true
    if (note.type === "research" && noteFilterType === "research") return true
  }
  return (
    <div>
      <Header />
      <SubHeaderNoteIndex noteFilterType={noteFilterType} setNoteFilterType={setNoteFilterType} notes={notes} createNoteAndRedirect={createNoteAndRedirect} />
      <Padding />
      <Content>
        <PageTitle>
          <NoteFilterTypeLink current={noteFilterType === "idea"} onClick={() => setNoteFilterType("idea")}><FontAwesomeIcon icon={faLightbulbOn} /><span>Ideas ({notes.filter((note: NoteInterface) => note.type !== "research").length})</span></NoteFilterTypeLink>
          <NoteFilterTypeLink current={noteFilterType === "research"} onClick={() => setNoteFilterType("research")}><FontAwesomeIcon icon={faTelescope} /><span>Research ({notes.filter((note: NoteInterface) => note.type === "research").length})</span></NoteFilterTypeLink>

          <AddNoteLink onClick={createNoteAndRedirect}>
            <FontAwesomeIcon icon={faFilePlus} />
            <span>New Note</span>
          </AddNoteLink>

        </PageTitle>
        <NoteList>
          <NoteNotFound />
          {notes.filter((note: NoteInterface) => isNoteCorrectType(note)).map((note: NoteInterface) => {
            return (
              <NoteIndexListItem
                key={`note-link-${note.id}`}
                note={note}
              />
            );
          })}
          {notes.length === 0 && (
            <span>
              <h2>This project has no notes.</h2>
              <NoNotes>You haven't yet added any notes for {project!.name}. <a href="#" onClick={createNoteAndRedirect}>Click here</a> to crete your first one and start capturing all of your ideas for this project!</NoNotes>
            </span>
          )}
        </NoteList>
      </Content>
      <NotesFooter />
    </div>
  );
};

export default NoteIndex;
