import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 480px;
  margin: 5px auto;
  text-align: center;

  img {
    max-width: 100%;
  }
`

const HelpImage = ({ src }: { src: string }) => {
  return (
    <Wrapper>
      <img alt={'Help Image'} src={src} />
    </Wrapper>
  )
}

export default HelpImage
