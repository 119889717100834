import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUserAltSlash,
  faCloudUploadAlt,
  faFileExport,
  faBan,
} from "@fortawesome/free-solid-svg-icons"
import { faPhoneLaptop } from "@fortawesome/pro-regular-svg-icons"
import { faFilmAlt } from "@fortawesome/pro-solid-svg-icons"

const Wrapper = styled.div`
  background-color: var(--themed-input-bg-color);
  margin-bottom: 15px;
`

const Content = styled.div`
  padding: 20px 20px 30px 20px;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 800px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  @media (min-width: 610px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }
`

const SmallFeature = styled.div`
  @media (max-width: 400px) {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 10px;
  }
`

const SmallFeatureIcon = styled.div`
  font-size: 45px;
  text-align: center;
  margin-bottom: 15px;
  @media (max-width: 400px) {
    font-size: 30px;
  }
`

const SmallFeatureTitle = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
`

const SmallFeatureDescription = styled.div``

const HomeSmallFeatures = () => {
  const features: any = [
    {
      title: "No Membership Required",
      icon: faUserAltSlash,
      description: "Everything you write is saved in locally your browser.",
    },
    {
      title: "Download and Export",
      icon: faFileExport,
      description:
        "Hilker lets you print your projects, and export to a variety of formats.",
    },
    {
      title: "Need a Backup?",
      icon: faCloudUploadAlt,
      description:
        "Create a free account if you want to backup or access your writing across multiple devices.",
    },
    {
      title: "Screenwriters Welcome!",
      icon: faFilmAlt,
      description:
        "Hilker's editor has both a manuscript and screenwriting option. Hollywood is welcome!",
    },
    {
      title: "Intuitive Interface",
      icon: faPhoneLaptop,
      description: "Hilker is powerful, but it's also easy to use.",
    },
    {
      title: "No App Store",
      icon: faBan,
      description:
        "Hilker's a website, not an app, so you can access it from anywhere!",
    },
  ]

  return (
    <Wrapper>
      <Content>
        {features.map((feature: any, index: number) => {
          return (
            <SmallFeature key={`small-feature-${index}`}>
              <SmallFeatureIcon>
                <FontAwesomeIcon icon={feature.icon} />
              </SmallFeatureIcon>
              <div>
                <SmallFeatureTitle>{feature.title}</SmallFeatureTitle>
                <SmallFeatureDescription>
                  {feature.description}
                </SmallFeatureDescription>
              </div>
            </SmallFeature>
          )
        })}
      </Content>
    </Wrapper>
  )
}

export default HomeSmallFeatures
