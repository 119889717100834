import React from "react"
import styled from "styled-components"
import { Link, navigate } from "@reach/router"
import StandardButton from "../../../common/StandardButton/StandardButton"

const Wrapper = styled.div`
  background-color: var(--brand-primary);
`

const Content = styled.div`
  max-width: 800px;
  margin: 0px auto;
  padding: 20px 10px;
  color: var(--black-color);
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    box-sizing: border-box;
    padding: 0px 12px;
  }

  a,
  a:active,
  a:visited {
    display: block;
    color: var(--black-color) !important;
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: var(--white-color);
  }
`

const FooterContent = styled.div`
  @media (max-width: 480px) {
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 0px;
    }
  }
`

const HomeFooter = () => {
  return (
    <Wrapper>
      <Content>
        <FooterContent>
          <Link to={`/about`}>About</Link>
          <Link to={`/help`}>Help</Link>
          <Link to={`/help#What%20does%20it%20cost?`}>Pricing</Link>
          <Link to={`/privacypolicy`}>Privacy Policy</Link>
        </FooterContent>

        <FooterContent>
          Contact Us:
          <br />
          <a href="mailto:brian@hoggworks.com">Email</a>
        </FooterContent>
        <FooterContent>
          <StandardButton
            mode={"inverse"}
            onClick={() => navigate(`/projects`)}
          >
            START WRITING FOR FREE!
          </StandardButton>
          <br />
          &copy; 2020 Brian Hogg, All Rights Reserved.
        </FooterContent>
      </Content>
    </Wrapper>
  )
}

export default HomeFooter
