import { ProjectInterface, AssetInterface } from "./Interfaces"

export const getAssetsByType = (
  project: ProjectInterface,
  assetType: string
) => {
  return project.assets.filter((asset: AssetInterface) => asset.type === assetType)
}

export const assetTypes: Array<string> = [
  "arc",
  "storyline",
  "storybeat",
  "character",
  "location",
  "dialogue",
  "macguffin",
]

export const assetTypesFull = (storySection: boolean) => {
  let types: Array<string> = [
    "scene",
    "arc",
    "storyline",
    "storybeat",
    "character",
    "location",
    "dialogue",
    "macguffin",
  ]
  if (storySection === true) {
    types.unshift("storySection")
  }
  return types
}
