import React, { MouseEventHandler, SyntheticEvent, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ModalBase from "../../common/Modal/ModalBase/ModalBase";
import {
  NoteMetaInterface,
  SceneInterface,
  ShallowSceneInterface,
  StateInterface,
  StorySectionInterface,
} from "../../utils/Interfaces";
import { currentProject } from "../../utils/State";
import ModalActions from "../../common/Modal/ModalActions/ModalActions";
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton";
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";

const Supplemental = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  overflow-y: hidden;
`;

const SceneTitle = styled.input`
  font-size: 16px;
  padding: 10px;
`;

const AddSceneModal = ({
  closeModal,
  createScene,
}: {
  closeModal: MouseEventHandler;
  createScene: Function;
}) => {
  const [sceneTitle, setSceneTitle] = useState<string>("");
  const [noteMeta, setNoteMeta] = useState({});
  const [createNewScene, setCreateNewScene] = useState(true);
  const [existingSceneId, setExistingSceneId] = useState("");

  const project = useSelector((state: StateInterface) => currentProject(state));

  const updateNoteMeta = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNoteMeta: NoteMetaInterface = {
      supplemental: e.currentTarget.value,
    };

    setNoteMeta(newNoteMeta);
  };

  const updateSceneTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSceneTitle(e.currentTarget.value);
  };

  const createSceneHandler = (e: SyntheticEvent) => {
    e.stopPropagation();
    createScene({ sceneTitle, noteMeta, createNewScene, existingSceneId });
  };

  const orphanedScenes = () => {
    let scenes: Array<SceneInterface> = [];

    project!.scenes.forEach((scene: SceneInterface) => {
      let found: boolean = false;
      project!.storySections.forEach((storySection: StorySectionInterface) => {
        if (
          storySection.scenes.find(
            (shallowScene: ShallowSceneInterface) =>
              shallowScene.id === scene.id,
          )
        ) {
          found = true;
        }
      });

      if (found === false) {
        scenes.push(scene);
      }
    });
    return scenes;
  };

  const chooseExistingSceneToAddToStorySection = (
    e: React.FormEvent<HTMLSelectElement>,
  ) => {
    if (e.currentTarget.value === "") {
      setCreateNewScene(true);
    } else {
      setCreateNewScene(false);
      setExistingSceneId(e.currentTarget.value);
    }
  };

  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Add Scene</ModalTitle>
        {orphanedScenes().length > 0 && (
          <React.Fragment>
            <hr />
            <select onChange={chooseExistingSceneToAddToStorySection}>
              <option value="">Select available Scene</option>
              {orphanedScenes().map((scene: SceneInterface) => {
                return (
                  <option key={`existing-note-id-${scene.id}`} value={scene.id}>
                    {scene.title}
                  </option>
                );
              })}
            </select>
          </React.Fragment>
        )}
        <hr />
        <SceneTitle
          placeholder="Title"
          onChange={updateSceneTitle}
          autoFocus
        />
        <Supplemental
          placeholder="Scene Summary"
          onChange={updateNoteMeta}
        />
        <ModalActions>
          <ModalActionButton
            title="Cancel"
            type="cancel"
            onClick={closeModal}
          >
            <FontAwesomeIcon icon={faBan} /> Cancel
          </ModalActionButton>
          <ModalActionButton
            title="Create Scene"
            type="okay"
            onClick={createSceneHandler}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Create Scene
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  );
};

export default AddSceneModal;
