import React from "react"
import styled from "styled-components"

import SubHeader from "../../../common/SubHeader/SubHeader"
import { useSelector, useDispatch } from "react-redux"
import { currentProject } from "../../../utils/State"
import {
  ProjectInterface,
  StateInterface,
  MobileOptionsInterface
} from "../../../utils/Interfaces"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFolder, faTimes } from "@fortawesome/free-solid-svg-icons"
import { faBoxFull } from "@fortawesome/pro-regular-svg-icons"

interface SubHeaderWriteProps {
  children?: any
}

const Content = styled.div``

const SectionsOption = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: inline-block;
  padding: 6px 0px 5px 5px;
  color: var(--themed-font-color);
  font-size: 12px;
  text-transform: uppercase;
  width: 100px;
  font-weight: bold;
  color: var(--themed-font-color);

  @media (min-width: 768px) {
    display: none;
  }

  ${props => props.active === true && 'background-color: var(--brand-primary); color: var(--white-color);'}
`

const SubHeaderWrite = (props: SubHeaderWriteProps) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const mobileMenu: MobileOptionsInterface = project!.mobileMenu
  const dispatch = useDispatch()

  const toggleMobileActive = (type: string) => {
    const toDispatch = {
      type: "toggle_mobile_menu",
      value: { location: "write", type: type }
    }

    dispatch(toDispatch)
  }

  return (
    <SubHeader>
      <Content>
        <SectionsOption
          active={mobileMenu.write.sections === true}
          onClick={() => toggleMobileActive("sections")}
        >
          {mobileMenu.write.sections !== true && (
            <span>
              <FontAwesomeIcon icon={faFolder} /> Sections
            </span>
          )}
          {mobileMenu.write.sections === true && (
            <span>
              <FontAwesomeIcon icon={faTimes} /> Close
            </span>
          )}
        </SectionsOption>
        {window.location.pathname !== "/write" && (
          <SectionsOption
            active={mobileMenu.write.assets === true}
            onClick={() => toggleMobileActive("assets")}
          >
            {mobileMenu.write.assets !== true && (
              <span>
                <FontAwesomeIcon icon={faBoxFull} /> Assets
              </span>
            )}
            {mobileMenu.write.assets === true && (
              <span>
                <FontAwesomeIcon icon={faTimes} /> Close
              </span>
            )}
          </SectionsOption>
        )}
      </Content>
    </SubHeader>
  )
}

export default SubHeaderWrite
