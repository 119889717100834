import React, { useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDoubleRight } from "@fortawesome/pro-regular-svg-icons"

import { faCaretUp, faPlus } from "@fortawesome/free-solid-svg-icons"
import { getIconForAssetType } from "../../../utils/Icons"
import { assetTypesFull } from "../../../utils/Assets"
import { isMobile } from "../../../utils/Browser"
import { ConfigObjectInterface, StateInterface } from "../../../utils/Interfaces"
import { useSelector } from "react-redux"
import { getConfig } from "../../../utils/State"

const Wrapper = styled.div<{ left: number; top: number; mobile: boolean }>`
  position: absolute;
  z-index: 100001;

  ${props =>
    props.mobile === false &&
    `left: ${props.left}px;
  top: ${props.top}px;`}

  ${props =>
    props.mobile === true &&
    `left: 0px;
  top: 0px;
  
  text-align: center;
  position: fixed;
  width: 100%;
  height: 84px;
  background-color: var(--themed-asset-header-color);
  `}
`

const Caret = styled.div`
  color: var(--themed-font-color);
  text-align: center;
  width: 100%;
  z-index: 100000;
  position: absolute;
  margin-top: -19px;
`

const ButtonsWrapper = styled.div<{ mobile: boolean }>`
  background-color: var(--themed-font-color);
  color: var(--themed-bg-color);
  ${props => props.mobile === false && "border-radius: 8px;"}
  margin-top: -5px;
  overflow: hidden;
  z-index: 100002;
  ${props => props.mobile === true && "padding-top: 5px;"}

  
`

const AddItemLink = styled.button<{ assetType: string; selected: boolean, mobile: boolean }>`
  color: ${props => `var(--${props.assetType}-color)`};
  background-color: transparent;
  border: 0px;
  padding: 7px 10px 5px 10px;
  font-size: 18px;
  cursor: pointer;

  ${props =>
      props.selected === true && "background-color: var(--themed-bg-color);"}
    :hover {
    background-color: var(--themed-bg-color);
  }

  ${props => 
    props.mobile === true &&
    `@media (max-width: 480px) {
      padding: 7px 5px 5px 5px;
    `
  }

`

const AssetActionWrapper = styled.div<{ mobile: boolean }>`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 2fr;

  ${props => props.mobile === true && `height: 29px;`}
`


const CreateNewAsset = styled.button`
  text-align: center;
  padding: 3px 10px 0px 10px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: transparent;
  border: 0px;
  color: var(--themed-bg-color);
  font-weight: bold;

  :hover {
    color: var(--themed-font-color);
    background-color: var(--themed-bg-color);
  }
`

const AddToAsset = styled.button`
  text-align: center;
  padding: 3px 10px 0px 10px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: transparent;
  border: 0px;
  color: var(--themed-bg-color);
  font-weight: bold;
  :hover {
    color: var(--themed-font-color);
    background-color: var(--themed-bg-color);
  }
`

const AddItemLinkHolder = styled.div<{ cols: number, mobile: boolean}>`
  ${props => props.mobile === true && `
    display: grid;
    grid-template-columns: repeat(${props.cols}, 1fr);

    @media (max-width: 480px) {
      grid-template-columns: repeat(${props.cols}, 1fr);
    }
  `}
`

const SelectLabel = styled.div`
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
`

const EditableTextContextMenu = ({
  clickHandler
}: {
  clickHandler: Function
}) => {
  var sel = window.getSelection()
  const range: Range = sel!.getRangeAt(0)

  const locationOfSelectedText: ClientRect = range.getBoundingClientRect()

  const [showAssetOptions, setShowAssetOptions] = useState(false)
  const [selectedAssetType, setSelectedAssetType] = useState("")

  const config: ConfigObjectInterface = useSelector((state: StateInterface) =>
    getConfig(state)
  )

  const selectAssetType = (assetType: string) => {
    if (assetType === "storySection" || assetType === "scene") {
      clickHandler(assetType, "new")
      return
    }
    setSelectedAssetType(assetType)
    setShowAssetOptions(true)
  }

  let leftPos =
    locationOfSelectedText.left + locationOfSelectedText.width / 2 - 198.27 / 2

  let topPos =
    locationOfSelectedText.top +
    locationOfSelectedText.height +
    10 +
    window.scrollY

  if (leftPos < 0) leftPos = 0
  if (leftPos > window.innerWidth - 198.27) leftPos = window.innerWidth - 198.27
  if (topPos > window.scrollY + window.innerHeight - 45)
    topPos = window.scrollY + window.innerHeight - 45

  const performAssetAction = (action: string) => {
    clickHandler(selectedAssetType, action)
  }

  return (
    <Wrapper left={leftPos} top={topPos} mobile={isMobile(config)}>
      {!isMobile(config) && (
        <Caret>
          <FontAwesomeIcon icon={faCaretUp} />
        </Caret>
      )}
      <ButtonsWrapper mobile={isMobile(config)}>
          {isMobile(config) == true && <SelectLabel>{selectedAssetType != "" ? selectedAssetType : "Select Asset Type:"}</SelectLabel>}
        <AddItemLinkHolder cols={assetTypesFull(true).length} mobile={isMobile(config)}>
          {assetTypesFull(true).map((type: string) => {
          return (<AddItemLink
            assetType={type}
            key={`add-item-link-${type}`}
            title={`Convert this text into a ${type}`}
            onClick={() => selectAssetType(type)}
            selected={selectedAssetType === type}
            mobile={isMobile(config)}
          >
            <FontAwesomeIcon icon={getIconForAssetType(type)} />
          </AddItemLink>)
          })}
        </AddItemLinkHolder>
        {showAssetOptions === true && (
          <AssetActionWrapper mobile={isMobile(config)}>
            <CreateNewAsset onClick={() => performAssetAction("new")}>
              <FontAwesomeIcon icon={faPlus} /> New
            </CreateNewAsset>
            <AddToAsset onClick={() => performAssetAction("existing")}>
              <FontAwesomeIcon icon={faChevronDoubleRight} /> Add to existing
            </AddToAsset>
          </AssetActionWrapper>
        )}
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default EditableTextContextMenu
