import React from "react"
import styled from "styled-components"
import ModalBase from "../../common/Modal/ModalBase/ModalBase"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBan } from "@fortawesome/free-solid-svg-icons"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle"
import { UserInterface, StateInterface } from "../../utils/Interfaces"
import UsernameLogin from "../../components/User/UsernameLogin/UsernameLogin"
import {
  faFacebookSquare,
  faGooglePlusSquare,
} from "@fortawesome/free-brands-svg-icons"

const LoginButtons = styled.div`
  max-width: 500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 16px;
  grid-gap: 10px;
`

const FacebookButton = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 22px;
  background-color: var(--facebook-color);
  color: var(--white-color);
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  :hover {
    background-color: var(--facebook-color--hover);
  }
  svg {
    font-size: 24px;
  }
`

const GoogleButton = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 22px;
  background-color: var(--google-color);
  color: var(--white-color);
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  :hover {
    background-color: var(--google-color--hover);
  }
  svg {
    font-size: 24px;
  }
`

const ButtonText = styled.span`
  font-weight: bold;
  color: var(--white-color);
`

const LoginModal = ({ closeModal }: { closeModal: any }) => {
  const user: UserInterface | undefined | null = useSelector(
    (state: StateInterface) => state.user
  )
  const dispatch = useDispatch()

  const facebookLogin = () => {
    const toDispatch = { type: "facebook_login" }
    dispatch(toDispatch)
  }

  const googleLogin = () => {
    const toDispatch = { type: "google_login" }
    dispatch(toDispatch)
  }

  React.useEffect(() => {
    if (user && user.uid) {
      closeModal("")
    }
  }, [user])

  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Login</ModalTitle>
        <div>
          <div>
            <p>
              In order to sync your work and access it with other browsers, you
              need to register/login. It's free.
            </p>
            <UsernameLogin />

            <p>OR</p>

            <LoginButtons>
              <FacebookButton onClick={facebookLogin}>
                <ButtonText>Connect with </ButtonText>
                <FontAwesomeIcon icon={faFacebookSquare} />
              </FacebookButton>
              <GoogleButton onClick={googleLogin}>
                <ButtonText>Connect with </ButtonText>
                <FontAwesomeIcon icon={faGooglePlusSquare} />
              </GoogleButton>
            </LoginButtons>
          </div>
        </div>
        <ModalActions>
          <ModalActionButton type="cancel" title="Cancel" onClick={closeModal}>
            <FontAwesomeIcon icon={faBan} /> Cancel
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  )
}

export default LoginModal
