import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { StateInterface } from "../../../utils/Interfaces"
import { getConfig } from "../../../utils/State"
import SaveState from "../../../common/SaveState/SaveState"

const Container = styled.div`
  @media print {
    visibility: hidden;
  }
  z-index: 100;
  position: fixed;
  bottom: 0px;
  background-color: var(--themed-light-bg);
  color: var(--themed-font-color);
  left: 0px;
  height: 30px;
  display: grid;
  grid-template-columns: 170px 1fr 60px;
  width: 100%;
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);

  @media (max-width: 480px) {
    box-sizing: border-box;
    padding: 0px 12px;
  }
`

const PlotFooter = () => {
  const config = useSelector((state: StateInterface) => getConfig(state))

  return (
    <Container className={"footer"}>
      <SaveState />
    </Container>
  )
}

export default PlotFooter
