import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import * as Sentry from "@sentry/browser"

import "./index.css"
import "./screenplay.css"

// redux stuff
import mainStore from "./stores/mainStore"

import App from "./App/App"
import * as serviceWorker from "./serviceWorker"
Sentry.init({
  dsn: "https://47e28fbc3cfb408a929c04af70b52919@sentry.io/1843505",
})

ReactDOM.render(
  <Provider store={mainStore}>
    <App />
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
