import React, { useState } from "react"
import styled from "styled-components"
import {
  ProjectInterface,
  StateInterface,
  AssetInterface,
  ConfigObjectInterface,
  SceneInterface,
  SceneItemInterface
} from "../../../utils/Interfaces"
import { useSelector, useDispatch } from "react-redux"
import {
  currentProject,
  getConfig,
} from "../../../utils/State"
import AssetsTypeHeader from "../../../common/AssetsTypeHeader/AssetsTypeHeader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { sortCharacters } from "../../../utils/Characters"
import Thumbnail from "../../../common/Thumbnail/Thumbnail"
import { assetTypes } from "../../../utils/Assets"

const Wrapper = styled.div`
  margin-top: 116px;
`

const AssetsWrapper = styled.div<{ type: string }>`
  margin-bottom: 5px;
`

const Assets = styled.div<{ type: string; plotMode: string }>`
  min-height: 21px;
  background-color: ${props => `var(--${props.type}-color--plot-bg)`};
  padding: 5px 5px 5px 5px;
  ${props =>
    props.plotMode === "edit" &&
    `
  min-height: 33px;
  `}
  ${props =>
    props.plotMode === "inspect" &&
    `padding: 5px 5px 5px 5px;
  `}
`

const Asset = styled.div<{
  type: string
  highlighted: boolean
  customColor: string
  isUsedInProject: boolean
  plotMode: string
}>`
  background-color: ${props => `var(--${props.type}-color)`};
  opacity: ${props => (props.highlighted === true ? "1" : "0.7")};
  color: var(--themed-bg-color);
  font-weight: bold;
  height: 29px;
  margin: 0px 0px 5px 0px;
  padding: 5px;
  border-radius: 4px;
  ${props =>
    props.type === "character" &&
    "display: grid; grid-template-columns: 20px 1fr; "}
  ${props =>
    props.plotMode === "inspect" &&
    `
  height: 19px;
  margin: 0px 0px 2px 0px;
  padding: 0px 5px 5px 5px;
  `}
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  ${props =>
    props.customColor !== "" ? `background-color: ${props.customColor}` : ""}
  font-size: 14px;
  line-height: 1.3em;

  ${props => props.customColor === "#ffffff" && "color: var(--black-color)"};
  ${props =>
    props.isUsedInProject === false &&
    "line-height: 1.1em; border: 1px solid var(--error-background-color); background-color: var(--error-background-color); color: var(--error-color);"}

  svg {
    margin-right: 5px;
  }
`

const AssetsTypeHeaderTitle = styled.div``

const AssetsTypeHeaderLinks = styled.div<{ grid: string }>`
  text-align: right;
  ${props =>
    props.grid > "" &&
    `display: grid;
  grid-template-columns: ${props.grid};`}
`

const AssetLinkSortOption = styled.a<{ active: boolean }>`
  cursor: pointer;
  ${props =>
    props.active === true
      ? "color: var(--themed-header-color);"
      : "color: var(--themed-bg-color);"}
    :hover {
    color: var(--themed-header-color);
  }
`

const StorySectionLegend = ({ filters }: { filters: Array<string> }) => {
  const dispatch = useDispatch()

  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )

  const config: ConfigObjectInterface = useSelector((state: StateInterface) =>
    getConfig(state)
  )

  const getProjectAssetsByType = (
    project: ProjectInterface | null,
    assetType: string
  ) => {
    switch (assetType) {
      case "character":
        return sortCharacters(project!.assets.filter((asset: AssetInterface) => asset.type === "character"), config)
      default:
        return project!.assets.filter((asset: AssetInterface) => asset.type === assetType)
    }
  }

  const isHighlighted = (item: any) => {
    if (config.inspectHighlights.length === 0) return true

    if (
      config.inspectHighlights.find(
        (highlight: any) => highlight.id === item.id
      )
    ) {
      return true
    } else {
      return false
    }
  }

  const toggleHighlight = (item: any) => {
    const toDispatch = {
      type: "toggle_highlight",
      value: { id: item.id, type: item.type }
    }

    dispatch(toDispatch)
  }

  const getCustomColor = (item: any) => {
    if (item.type === "storybeat" || item.type === "scene") return ""
    if (!item.color) return ""
    return item.color
  }

  const isUsedInProject = (item: any) => {
    let usedInProject: boolean = false
    project!.scenes.forEach((scene: SceneInterface) => {
      if (
        scene.items.find(
          (sceneItem: SceneItemInterface) =>
            sceneItem.id === item.id && sceneItem.type === item.type
        )
      ) {
        usedInProject = true
      }
    })
    return usedInProject
  }

  const noneOfTypeAreHighlighted = (assetType: string) => {
    return (
      config.inspectHighlights.filter(
        (highlight: any) => highlight.type === assetType
      ).length === 0
    )
  }

  return (
    <Wrapper>
      {assetTypes.map((assetType: string) => {
        if (!filters.includes(assetType)) {
          return (
            <div
              key={`assets-wrapper-${Math.floor(Math.random() * 1000000)}`}
            />
          )
        }
        return (
          <AssetsWrapper
            type={assetType}
            key={`assets-wrapper-${Math.floor(Math.random() * 1000000)}`}
          >
            <AssetsTypeHeader type={assetType} grid={true}>
              <AssetsTypeHeaderTitle>{assetType}s</AssetsTypeHeaderTitle>
            </AssetsTypeHeader>
            <Assets plotMode={config.plotMode} type={assetType}>
              {getProjectAssetsByType(project, assetType)!.map(
                (
                  assetOfType: any
                ) => {
                  if (
                    isHighlighted(assetOfType) === false &&
                    noneOfTypeAreHighlighted(assetOfType.type) === false &&
                    config.hideNonHighlighted === true
                  ) {
                    return <div />
                  }
                  return (
                    <Asset
                      highlighted={
                        isHighlighted(assetOfType) ||
                        noneOfTypeAreHighlighted(assetOfType.type)
                      }
                      onClick={() => toggleHighlight(assetOfType)}
                      title={
                        isUsedInProject(assetOfType)
                          ? assetOfType.title
                          : assetOfType.title + " - NOT USED IN PROJECT"
                      }
                      type={assetType}
                      key={`inspect-asset-type-${assetOfType.id}`}
                      customColor={getCustomColor(assetOfType)}
                      isUsedInProject={isUsedInProject(assetOfType)}
                      plotMode={config.plotMode}
                    >
                      {assetOfType.thumbnail && assetOfType.thumbnail > "" && (
                        <Thumbnail src={assetOfType.thumbnail} size={"15px"} />
                      )}
                      <span>
                        {assetOfType.type === "character" &&
                          assetOfType.role &&
                          assetOfType.role === "PRIMARY" && (
                            <span title="This is a Primary Character">
                              <FontAwesomeIcon icon={faStar} />
                              &nbsp;
                            </span>
                          )}
                        {assetOfType.type === "character" &&
                          (!assetOfType.role ||
                            (assetOfType.role &&
                              assetOfType.role !== "PRIMARY")) && <span></span>}
                        {assetOfType.title}
                      </span>
                    </Asset>
                  )
                }
              )}
            </Assets>
          </AssetsWrapper>
        )
      })}
    </Wrapper>
  )
}

export default StorySectionLegend
