const scrollWritingAreaIntoViewIfNecessary = (
  typewriterMode: Boolean,
  minAllowedScrollAdjustment: number
) => {
  try {
    const caretPosition: any = window
      .getSelection()!
      .getRangeAt(0)
      .getBoundingClientRect()
    if (
      typewriterMode === true &&
      caretPosition.top != window.innerHeight / 2
    ) {
      const maxAllowedScroll: number = window.innerHeight / 2
      const scrollOverage: number = caretPosition.top - maxAllowedScroll
      window.scrollBy(0, scrollOverage)
    } else if (typewriterMode === false) {
      const maxAllowedScroll: number = window.innerHeight - 30
      const minAllowedScroll: number = minAllowedScrollAdjustment
      let scrollOverage: number = 0

      if (caretPosition.bottom > maxAllowedScroll) {
        scrollOverage = caretPosition.bottom - maxAllowedScroll
      } else if (caretPosition.top < minAllowedScroll) {
        scrollOverage = caretPosition.top - minAllowedScroll
      }

      window.scrollBy(0, scrollOverage)
    }
  } catch (e) {
    console.error(e)
  }
}

export { scrollWritingAreaIntoViewIfNecessary }
