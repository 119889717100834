import React from "react"
import styled from "styled-components"
import {
  NoteItemInterface,
  SceneItemInterface
} from "../../../utils/Interfaces"

const Tab = styled.div<{ type: string }>`
  padding: 5px;
  border-radius: 0px 6px 6px 0px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${props => `var(--${props.type}-color)`};
  color: var(--tab-text-color);
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  border: 2px solid ${props => `var(--${props.type}-color)`};

  :hover {
    border: 2px solid var(--themed-font-color);
  }
`

const Wrapper = styled.div`
  margin-bottom: 10px;
`

const NoteItem = ({
  item,
  showItemInfo
}: {
  item: NoteItemInterface | SceneItemInterface
  showItemInfo: Function
  children?: any
}) => {
  const truncated = (txt: string) => {
    if (txt.length < 40) {
      return txt
    }
    return txt.substr(0, 40)
  }

  let itemToDraw: any = item as NoteItemInterface
  if (item.type === "scene") {
    itemToDraw = item as SceneItemInterface
  }

  if (!itemToDraw) return <React.Fragment></React.Fragment>

  return (
    <Wrapper onClick={() => showItemInfo(item.id)}>
      <Tab type={itemToDraw.type} id={itemToDraw.id}>
        {itemToDraw.title && truncated(itemToDraw.title)}
        {!itemToDraw.title &&
          itemToDraw.selectedText &&
          truncated(itemToDraw.selectedText)}
        {!itemToDraw.title &&
          !itemToDraw.selectedText &&
          truncated(itemToDraw.id)}
      </Tab>
    </Wrapper>
  )
}

export default NoteItem
