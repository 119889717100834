import React, { useState } from "react"
import styled from "styled-components"
import { db } from "../../../_config/firebase"
import { navigate } from "@reach/router"

const Wrapper = styled.div`
  margin-top: 10px;
  a {
    color: var(--themed-font-color) !important;
  }
`

const BetaStatus = () => {
  const [features, setFeatures] = useState<any>([])
  const [completedFeatures, setCompletedFeatures] = useState<any>([])
  const [inProgressFeatures, setInProgressFeatures] = useState<any>([])
  const [featuresLoaded, setFeaturesLoaded] = useState(false)
  const loadFeatures = async () => {
    const snapshot = await db
      .collection("features_in_development")
      .orderBy("updated", "desc")
      .get()

    const allFeatures: any = snapshot.docs.map((doc) => doc.data())

    setFeatures(allFeatures)

    setCompletedFeatures(
      allFeatures.filter((feature: any) => feature.state === 2)
    )
    setInProgressFeatures(
      allFeatures.filter((feature: any) => feature.state === 1)
    )
    setFeaturesLoaded(true)
  }

  React.useEffect(() => {
    loadFeatures()
  }, [])

  return (
    <React.Fragment>
      {featuresLoaded === true &&
        (completedFeatures.length > 0 || inProgressFeatures.length > 0) && (
          <Wrapper>
            <p>
              <span>We </span>
              {completedFeatures.length > 0 && (
                <span>
                  just added{" "}
                  <a href="#" onClick={() => navigate("/beta")}>
                    {completedFeatures[0].name}
                  </a>
                </span>
              )}
              {completedFeatures.length > 0 &&
                inProgressFeatures.length > 0 && <span>, and we </span>}
              {inProgressFeatures.length > 0 && (
                <span>
                  are working on{" "}
                  <a href="#" onClick={() => navigate("/beta")}>
                    {inProgressFeatures[0].name} (
                    {inProgressFeatures[0].progress}%)
                  </a>
                </span>
              )}
              <span>.</span>
            </p>
          </Wrapper>
        )}
    </React.Fragment>
  )
}

export default BetaStatus
