import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface AssetProgressBarProps {
  items: Array<any>
  sticky: boolean
}

const Wrapper = styled.div``

const ProgressBar = styled.div<{ sticky: boolean }>`
  height: 20px;
  background-color: var(--themed-subhead-color);
  margin-top: 4px;
  overflow: hidden;
  border-radius: 6px;
  ${props =>
    props.sticky === true
      ? `position: sticky;
top: 5px;`
      : `position: relative;`}
`
const ProgressBarFg = styled.div<{ progress: number }>`
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 20px;
  width: ${props => props.progress}%;
  background-color: ${props =>
    props.progress === 100
      ? "var(--new-color)"
      : "var(--save-indicator-color)"};
  -webkit-transition: all var(--transition--fast);
  transition: all var(--transition--fast);
`

const ProgressBarText = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: var(--white-color);
  font-size: 12px;
  padding: 1px 5px 0px 5px;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
`

const AssetProgressBar = (props: AssetProgressBarProps) => {
  const { items, sticky } = props
  const calcProgress = (): number => {
    let completed: number = items.filter(
      (item: any) => item.completed === true
    )!.length
    return Math.floor((completed / items.length) * 100)
  }

  return (
    <Wrapper>
      <ProgressBar sticky={sticky}>
        <ProgressBarFg progress={calcProgress()}></ProgressBarFg>
        {items.length === 0 && (
          <ProgressBarText>&nbsp; 0 in scene</ProgressBarText>
        )}
        {items.length > 0 && (
          <ProgressBarText>
            &nbsp;
            {
              items.filter((item: any) => item.completed === true)!.length
            } / {items.length} Used{calcProgress() === 100 ? "!" : ""}
          </ProgressBarText>
        )}
      </ProgressBar>
    </Wrapper>
  )
}

export default AssetProgressBar
