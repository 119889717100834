import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 0px 0px 25px 0px;
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 10px;
  border-radius: 8px 0px 0px 8px;
  box-sizing: border-box;
  padding: 5px;
  color: var(--black-color);
`

export const TopRow = styled.div``

export const NewProjectTemplateSelect = styled.select`
  font-size: 16px;
  padding: 5px;
  width: 100%;
`

export const LeftRow = styled.div`
  font-size: 60px;
  cursor: pointer;
  line-height: 1.25em;
  padding-left: 10px;
  box-sizing: border-box;
  color: var(--themed-font-color);
  svg {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    :hover {
      color: var(--brand-primary);
    }
  }
`

export const NewProjectWrapper = styled.div`
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 80px;
  grid-gap: 5px;
`

export const NewProjectTitle = styled.input`
  font-size: 16px;
  padding: 10px;
  margin-bottom: 0px;
  line-height: 3.1em;
`

export const NewProjectFile = styled.input`
  font-size: 16px;
  padding: 10px;
  margin-bottom: 0px;
  line-height: 3.1em;
`

export const NewProjectTypeOption = styled.span`
  font-size: 60px;
  line-height: 3.1em;
  text-align: right;
  background-color: var(--themed-bg-color);
  border: none;

  cursor: pointer;
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-gap: 5px;

  color: var(--brand-primary);
  @media (min-width: 768px) {
    :hover {
      color: var(--themed-font-color);
    }
  }
`

export const JSONError = styled.div`
  border-radius: 4px;
  border: 1px solid red;
  background-color: var(--error-background-color);
  font-size: 16px;
  font-weight: bold;
  color: var(--themed-black-color);
  padding: 5px;
  box-sizing: border-box;
`

export const ProjectTypeOptions = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr 1fr;
`
export const ProjectTypeOption = styled.span`
  cursor: pointer;
  font-size: 12px;
  line-height: 1.2em;
  text-align: center;
  margin-top: 13px;
  color: var(--themed-font-color);
  @media (min-width: 768px) {
    :hover {
      color: var(--brand-primary);
    }
  }

  svg {
    font-size: 40px;
  }
`

export const NewProjectOption = styled.p`
  cursor: pointer;
  font-family: var(--title-font);
  font-size: 24px;
  font-weight: bold;
  color: var(--themed-font-color);

  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 2.3em;
  }
`

export const SupportedTypes = styled.div`
  font-size: 10px;
`

export const ImportError = styled.div`
  font-weight: bold;
  color: var(--error-background-color);
`

export const ImportLinkWrapper = styled.div``
