import {
  NoteInterface,
  AssetInterface,
  SceneInterface,
  ProjectInterface
} from "./Interfaces"
import JSZip from "jszip"
import { saveAs } from "file-saver"

export const DownloadProject = (project: ProjectInterface | null) => {
  if (!project) return

  const zip = new JSZip()

  zip.file(`project-${project.id}.json`, JSON.stringify(project))

  // notes
  project.notes.forEach((note: NoteInterface) => {
    zip.file(
      `notes/${note.id}.txt`,
      `
${note.title}
===================
${note.text}
      `
    )
  })
  // assets

  project.assets.forEach((asset: AssetInterface) => {
    zip.file(
      `assets/${asset.type}/${asset.id}.txt`,
      `
${asset.title}
===================
${asset.description}
      `
    )
  })

  // scenes
  project.scenes.forEach((scene: SceneInterface) => {
    zip.file(
      `scenes/${scene.id}.txt`,
      `
${scene.title}
===================
Items in Scene:
${scene.items.join(",")}
===================
${scene.text}
      `
    )
  })

  zip.generateAsync({ type: "blob" }).then((blob: Blob) => {
    saveAs(blob, `project-${project.id}-export.zip`)
  })
}
