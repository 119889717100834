import { ActivitiesListInterface } from "./Interfaces"

export const Activities: Array<ActivitiesListInterface> = [
  {
    id: "REMOVED_ITEM_FROM_PROJECT",
    label: "Removed Item From Project",
    informalLabel: "removed an asset from the project",
    showLink: false,
    amendable: false
  },
  {
    id: "CREATED_STORY_SECTION",
    label: "Created Story Section",
    informalLabel: "created the story section",
    showLink: true,
    linkPath: "plot",
    amendable: false
  },
  {
    id: "UPDATED_STORY_SECTION",
    label: "Updated Story Section",
    informalLabel: "updated the story section",
    showLink: true,
    linkPath: "plot",
    amendable: true
  },
  {
    id: "UPDATED_STORY_SECTION_SORT",
    label: "Updated Sort Order of Story Sections",
    informalLabel: "updated the sort order of story sections",
    showLink: true,
    linkPath: "plot",
    amendable: true
  },
  {
    id: "DELETED_STORY_SECTION",
    label: "Deleted Story Section",
    informalLabel: "deleted a story section",
    amendable: false,
    showLink: false
  },
  {
    id: "CREATED_SCENE",
    label: "Created Scene",
    informalLabel: "created the scene",
    amendable: false,
    showLink: true,
    linkPath: "write/:referenceId"
  },
  {
    id: "DELETED_SCENE",
    label: "Deleted Scene",
    informalLabel: "deleted a scene",
    amendable: false,
    showLink: false
  },
  {
    id: "UPDATED_SCENE",
    label: "Updated Scene",
    informalLabel: "updated",
    amendable: true,
    showLink: true,
    linkPath: "write/:referenceId"
  },
  {
    id: "UPDATED_SCENE_CONFIG",
    label: "Updated Scene Config",
    informalLabel: "updated the scene config for",
    amendable: true,
    showLink: true,
    linkPath: "write/:referenceId"
  },
  {
    id: "UPDATED_SCENE_SORT",
    label: "Updated Scene Sort",
    informalLabel: "updated the scene sort for",
    amendable: true,
    showLink: true,
    linkPath: "write/:referenceId"
  },
  {
    id: "ADDED_ITEM_TO_SCENE",
    label: "Added Item To Scene",
    informalLabel: "added an item to",
    amendable: false,
    showLink: true,
    linkPath: "assets/:referenceType/:referenceId"
  },
  {
    id: "REMOVED_ITEM_FROM_SCENE",
    label: "Removed Item From Scene",
    informalLabel: "removed an item from",
    amendable: false,
    showLink: false
  },
  {
    id: "CREATED_NOTE",
    label: "Created Note",
    informalLabel: "created a note called",
    amendable: false,
    showLink: true,
    linkPath: "note/:referenceId"
  },
  {
    id: "UPDATED_NOTE",
    label: "Updated Note",
    informalLabel: "updated the note",
    amendable: true,
    showLink: true,
    linkPath: "note/:referenceId"
  },
  {
    id: "ADDED_ITEM_TO_NOTE",
    label: "Added Item to Note",
    informalLabel: "added an item to ",
    amendable: false,
    showLink: true,
    linkPath: "assets/:referenceType/:referenceId"
  },
  {
    id: "REMOVED_ITEM_FROM_NOTE",
    label: "Removed Item From Note",
    informalLabel: "removed an item from",
    amendable: false,
    showLink: true,
    linkPath: "note/:referenceId"
  },
  {
    id: "UPDATED_NOTE_ITEM",
    label: "Updated Note Item",
    informalLabel: "updated a note item in ",
    amendable: true,
    showLink: true,
    linkPath: "assets/:referenceType/:referenceId"
  },
  {
    id: "CREATED_ASSET",
    label: "Created Asset",
    informalLabel: "created the asset ",
    amendable: false,
    showLink: true,
    linkPath: "asset/:referenceType/:referenceId"
  },
  {
    id: "UPDATED_PROJECT_CONFIG",
    label: "Updated Project Config",
    informalLabel: "updated the project config",
    amendable: true,
    showLink: false
  },
  {
    id: "UPDATED_PROJECT_VALUES",
    label: "Updated Project",
    informalLabel: "updated project options",
    amendable: true,
    showLink: false
  }
]

export const activityItem = (type: string) => {
  const item: ActivitiesListInterface | undefined = Activities.find(
    (activity: ActivitiesListInterface) => activity.id === type
  )

  if (item) return item
  return false
}
