import * as firebase from "firebase";

import { FirebaseDevConfig, FirebaseProdConfig } from "./keys";

const host = window.location.host;

// if (host === "hilker.app" || host === "hilker-prod.web.app") {
//   firebase.initializeApp(FirebaseProdConfig)
// } else {
//   firebase.initializeApp(FirebaseDevConfig)
// }
firebase.initializeApp(FirebaseProdConfig);

export const firebasedb = firebase.database;

export const db = firebase.firestore();
export const firestore = firebase.firestore;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
