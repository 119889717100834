import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBan } from "@fortawesome/free-solid-svg-icons"

import {
  FactsInterface,
  ProjectInterface,
  StateInterface
} from "../../../utils/Interfaces"
import { useDispatch, useSelector } from "react-redux"
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons"
import {
  Wrapper,
  NoFactoids,
  Factoid,
  SelectedText,
  FactoidMeta,
  FactoidLink,
  DeleteFactoidLink,
  NewFactoid,
  Supplemental,
  NewFactoidButton
} from "./Factoids.styles"
import { currentProject } from "../../../utils/State"

const Factoids = ({ fullItem }: { fullItem: any }) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const [newFactoid, setNewFactoid] = useState("")
  const dispatch = useDispatch()

  const updateNewFactoid = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewFactoid(e.currentTarget.value)
  }

  const addNewFactoid = (e: any) => {
    const toDispatch = {
      type: "add_factoid_to_asset",
      value: {
        referenceId: fullItem.id,
        type: fullItem.type,
        selectedText: "",
        description: newFactoid
      }
    }

    setNewFactoid("")
    dispatch(toDispatch)
  }

  const deleteFactoid = (id: string) => {
    const toDispatch = {
      type: "delete_factoid",
      value: {
        id: id,
        assetId: fullItem.id,
        type: fullItem.type
      }
    }
    dispatch(toDispatch)
  }

  return (
    <Wrapper>
      <h3>Factoids</h3>
      <p>
        These can be used for whatever extra information you want to save about
        your character, like the name of their childhood pet, what their
        haircolour is, or what kind of foods they like. Go to town!
      </p>
      {((fullItem.factoids && fullItem.factoids.length === 0) ||
        !fullItem.factoids) && (
        <NoFactoids>{fullItem.title} currently has no factoids.</NoFactoids>
      )}
      {fullItem.factoids &&
        fullItem.factoids.map((factoid: FactsInterface) => {
          return (
            <Factoid key={`factoid-${Math.floor(Math.random() * 1000000)}`}>
              {factoid.selectedText > "" && (
                <SelectedText>{factoid.selectedText}</SelectedText>
              )}
              <div>{factoid.description}</div>
              <FactoidMeta>
                {factoid.noteId && factoid.noteId > "" && (
                  <FactoidLink>
                    <Link
                      to={`/projects/${project!.id}/note/${factoid.noteId}`}
                    >
                      From This Note
                    </Link>
                  </FactoidLink>
                )}
                {factoid.sceneId && factoid.sceneId > "" && (
                  <FactoidLink>
                    <Link to={`/projects/${project!.id}/write`}>
                      From This Scene
                    </Link>
                  </FactoidLink>
                )}
                {!factoid.sceneId && !factoid.noteId && (
                  <FactoidLink>Added here.</FactoidLink>
                )}

                <DeleteFactoidLink
                  onClick={() => deleteFactoid(factoid.id || "")}
                >
                  <FontAwesomeIcon icon={faBan} /> Cancel
                </DeleteFactoidLink>
              </FactoidMeta>
            </Factoid>
          )
        })}

      <NewFactoid>
        <Supplemental
          placeholder="Add additional factoid here"
          onChange={updateNewFactoid}
          value={newFactoid}
        />
        <NewFactoidButton onClick={addNewFactoid}>
          <FontAwesomeIcon icon={faPlusCircle} />
        </NewFactoidButton>
      </NewFactoid>
    </Wrapper>
  )
}
export default Factoids
