import React, { MouseEventHandler } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg-color);
  left: 0px;
  top: 0px;
  z-index: -1;
`

const ModalBg = ({ closeModal }: { closeModal: MouseEventHandler }) => {
  return <Wrapper onClick={closeModal} />
}

export default ModalBg
