import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NoteItemLocationInterface, StateInterface } from "../../../utils/Interfaces"
import { getIconForAssetType } from "../../../utils/Icons"
import { currentProject } from "../../../utils/State"
import { useSelector } from "react-redux"

const Tab = styled.div<{ type: string; x: number; y: number; id: string }>`
  padding: 5px;
  position: absolute;
  top: calc(${props => props.y}px - 45px);
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${props => `var(--${props.type}-color)`};
  color: var(--tab-text-color);
  padding: 5px 10px;

  @media (min-width: 480px) {
    border-radius: 4px 0px 0px 4px;
    -webkit-box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.27);
    box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.27);
  }
  cursor: pointer;
  text-align: center;
  width: calc(100% - 10px);
  box-sizing: border-box;
  border: 2px solid ${props => `var(--${props.type}-color)`};
  :hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 2px solid var(--themed-font-color);
  }

  @media (max-width: 480px) {
    width: 30px;
    padding-left: 5px;
  }
`

const MultiTab = styled.div<{ type: string; x: number; y: number }>`
  border-radius: 4px 0px 0px 4px;
  position: absolute;
  top: calc(${props => props.y}px - 45px);
  text-transform: uppercase;
  font-weight: bold;
  background-color: var(--themed-focus-color);
  color: var(--tab-text-color);
  cursor: pointer;
  text-align: center;
  width: calc(100% - 10px);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;

  @media (max-width: 480px) {
    width: 20px;
  }
`

const MultiTabComponent = styled.div<{ counter: number; type: string }>`
  background-color: ${props => `var(--${props.type}-color)`};
  color: var(--tab-text-color);
  width: ${props => `${Math.floor(100 * (1 / props.counter))}%`};
  border-radius: 4px 0px 0px 4px;
  overflow: hidden;
  padding: 5px;
  -webkit-box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.27);
  box-shadow: -4px 0px 6px 0px rgba(0, 0, 0, 0.27);

  :hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 75%;
    & + & div {
      width: 5px;
    }
  }
`

const NoteTab = ({
  items,
  showItemInfo
}: {
  items: Array<NoteItemLocationInterface>
  showItemInfo: Function
}) => {
  const project = useSelector((state: StateInterface) => currentProject(state))

  if (items.length === 1) {
    return (
      <Tab
        type={items[0].type as string}
        x={0}
        y={items[0].y}
        id={items[0].id}
        onClick={() => showItemInfo(items[0].id)}
      >
        <FontAwesomeIcon icon={getIconForAssetType(items[0].type as string)} />
      </Tab>
    )
  } else {
    return (
      <MultiTab type="multi" x={0} y={items[0].y}>
        {items.map((item: NoteItemLocationInterface) => {
          return (
            <MultiTabComponent
              key={`multitab-${Math.random() * 100000}`}
              type={item.type as string}
              counter={items.length}
              onClick={() => showItemInfo(item.id)}
            >
              <FontAwesomeIcon
                icon={getIconForAssetType(item.type as string)}
              />
            </MultiTabComponent>
          )
        })}
      </MultiTab>
    )
  }
}

export default NoteTab
