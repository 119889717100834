import React, { useState, MouseEventHandler } from "react";
import styled from "styled-components";
import ModalBase from "../../common/Modal/ModalBase/ModalBase";
import { NoteMetaInterface } from "../../utils/Interfaces";
import ModalActions from "../../common/Modal/ModalActions/ModalActions";
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton";
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faFolderPlus,
  faPlus,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";

const Supplemental = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  overflow-y: hidden;
`;

const StorySectionTitle = styled.input`
  font-size: 16px;
  padding: 10px;
`;

const AddStorySectionModal = ({
  closeModal,
  createStorySection,
}: {
  closeModal: MouseEventHandler;
  createStorySection: Function;
}) => {
  const [storySectionTitle, setStorySectionTitle] = useState<string>("");
  const [noteMeta, setNoteMeta] = useState({});

  const updateNoteMeta = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNoteMeta: NoteMetaInterface = {
      supplemental: e.currentTarget.value,
    };

    setNoteMeta(newNoteMeta);
  };

  const updateStorySectionTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStorySectionTitle(e.currentTarget.value);
  };

  const createStorySectionHandler = () => {
    createStorySection(storySectionTitle, noteMeta, false);
  };

  const createStorySectionAndSceneHandler = () => {
    createStorySection(storySectionTitle, noteMeta, true);
  };

  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Add Section</ModalTitle>
        <StorySectionTitle
          placeholder="Title"
          onChange={updateStorySectionTitle}
          autoFocus
        />
        <Supplemental placeholder="Section Summary" onChange={updateNoteMeta} />
        <ModalActions>
          <ModalActionButton type="cancel" title="Cancel" onClick={closeModal}>
            <FontAwesomeIcon icon={faBan} /> Cancel
          </ModalActionButton>
          <ModalActionButton
            title="Create Section"
            type="okay"
            onClick={createStorySectionHandler}
          >
            <FontAwesomeIcon icon={faFolderPlus} /> Create Section
          </ModalActionButton>
          <ModalActionButton
            type="okay"
            title="Create Section and Scene"
            onClick={createStorySectionAndSceneHandler}
          >
            <FontAwesomeIcon icon={faFolderPlus} /> Section 
            {" "}
            <FontAwesomeIcon icon={faPlus} />
            {" "}
            <FontAwesomeIcon icon={faStickyNote} /> Scene
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  );
};

export default AddStorySectionModal;
