import React from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import {
  StateInterface,
  ProjectInterface,
  UserInterface,
  UserProjectInterface,
} from "../../../utils/Interfaces"
import ProjectOverview from "../../../common/ProjectOverview/ProjectOverview"
import { getUser } from "../../../utils/State"
import NonLocalProjectOverview from "../../../common/NonLocalProjectOverview/NonLocalProjectOverview"
import ProjectCreate from "../../../common/ProjectCreate/ProjectCreate"
import { Link } from "@reach/router"

const Wrapper = styled.div`
  margin: 0px 10px;
`

const ProjectListingSubHead = styled.div`
  background-color: var(--themed-subhead-color);
  border-radius: 4px;
  margin: 10px 0px;

  span {
    font-size: 18px;
    color: var(--themed-font-color);
    font-weight: bold;
    text-transform: uppercase;
    margin: 0px 5px;
    padding: 0px 5px;
    background-color: var(--themed-bg-color);
  }
`

const ProjectListingInProjectView = ({
  createProject,
}: {
  createProject: boolean
}) => {
  const baseProjects = useSelector((state: StateInterface) => state).projects
  let projects: Array<ProjectInterface> = []
  baseProjects.forEach((project: ProjectInterface) => {
    projects.unshift(project)
  })

  projects.sort((a: ProjectInterface, b: ProjectInterface) => {
    const aDate: any = new Date(a.updated_at)
    const bDate: any = new Date(b.updated_at)
    return bDate - aDate
  })

  let userProjects: Array<UserProjectInterface> = useSelector(
    (state: StateInterface) => state.userProjects
  )

  const user: UserInterface | boolean = useSelector((state: StateInterface) =>
    getUser(state)
  )

  const dispatch = useDispatch()

  const startSyncingProject = (projectId: string) => {
    const toDispatch = {
      type: "start_syncing_project",
      value: {
        projectId: projectId,
      },
    }
    dispatch(toDispatch)
  }

  const projectNotLocal = (projectId: string) => {
    if (
      projects.filter((project: ProjectInterface) => project.id === projectId)
        .length === 0
    )
      return true
    return false
  }

  return (
    <Wrapper>
      <ProjectListingSubHead>
        <span>My Projects</span>
      </ProjectListingSubHead>

      <ProjectCreate />
      {projects.map((project: ProjectInterface) => {
        return (
          <ProjectOverview
            key={`project-overview-${project.id}`}
            project={project}
          />
        )
      })}
      <div>
        <ProjectListingSubHead>
          <span>Available Projects</span>
        </ProjectListingSubHead>

        {user && (
          <React.Fragment>
            {userProjects.filter((userProject: UserProjectInterface) =>
              projectNotLocal(userProject.projectId)
            ).length === 0 && (
              <p>
                <em>No additional projects found.</em>
              </p>
            )}
            {userProjects
              .filter((userProject: UserProjectInterface) =>
                projectNotLocal(userProject.projectId)
              )
              .map((userProject: UserProjectInterface) => {
                return (
                  <NonLocalProjectOverview
                    key={`project-on-server-${userProject.projectId}`}
                    project={userProject}
                    startSyncingProject={startSyncingProject}
                  />
                )
              })}
          </React.Fragment>
        )}

        {!user && (
          <p>
            If you were logged in, you'd see any other projects you have access
            to. <Link to={"/user"}>Go to your user page to log in.</Link>
          </p>
        )}
      </div>
    </Wrapper>
  )
}

export default ProjectListingInProjectView
