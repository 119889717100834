import React from "react"
import styled from "styled-components"
import Header from "../../common/Header/Header"
import ProjectListingInProjectView from "../../components/Projects/ProjectListingInProjectView/ProjectListingInProjectView"
import ProjectsFooter from "../../components/Projects/ProjectsFooter/ProjectsFooter"
import Spacer from "../../common/Spacer/Spacer"

const Wrapper = styled.div``

interface ProjectsProps {
  path?: string
}

const ProjectsIndex = (props: ProjectsProps) => {
  return (
    <Wrapper>
      <Header />
      <ProjectListingInProjectView
        createProject={props.path!.indexOf("/new", 0) > -1 ? true : false}
      />
      <Spacer width={"40px"} height={"40px"} />
      <ProjectsFooter />
    </Wrapper>
  )
}

export default ProjectsIndex
