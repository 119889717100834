import React, { useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { UserInterface, StateInterface } from "../../../utils/Interfaces"
import { LoginInterface } from "../../../utils/Interfaces"

const UserButton = styled.button`
  cursor: pointer;
  background-color: var(--brand-primary);
  border: 1px solid var(--brand-primary);
  color: var(--white-color);
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  padding: 5px 5px;
  font-weight: bold;

  :hover {
    background-color: var(--white-color);
    color: var(--brand-primary);
  }
`
const LoginError = styled.div`
  color: var(--error-background-color);
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
`

const LoginSuccess = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
`

const UserType = styled.span`
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: var(--brand-primary);
  }
`

const Wrapper = styled.div``

const UsernameLogin = () => {
  const [mode, setMode] = useState("login")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const user: UserInterface | undefined | null = useSelector(
    (state: StateInterface) => state.user
  )

  const login: LoginInterface = useSelector(
    (state: StateInterface) => state.login
  )
  const dispatch = useDispatch()

  const submitLogin = () => {
    const toDispatch = {
      type: "email_login",
      value: { email, password },
    }

    dispatch(toDispatch)
  }

  const submitRegister = () => {
    const toDispatch = {
      type: "email_register",
      value: { email, password },
    }

    dispatch(toDispatch)
  }

  const submitForgotPassword = () => {
    const toDispatch = {
      type: "email_forgot_password",
      value: { email },
    }

    dispatch(toDispatch)
  }

  const changeMode = (mode: string) => {
    setEmail("")
    setPassword("")
    setMode(mode)
    const toDispatch = {
      type: "clear_login_error",
      value: null,
    }

    dispatch(toDispatch)
  }

  return (
    <Wrapper>
      {mode === "login" && (
        <div>
          <div>
            Login:
            <br />
            {login.error && <LoginError>{login.error}</LoginError>}
            <input
              type="email"
              value={email}
              onChange={(e: any) => setEmail(e.currentTarget.value)}
              placeholder="your@email.com"
            />
            <br />
            <input
              type="password"
              value={password}
              onChange={(e: any) => setPassword(e.currentTarget.value)}
              placeholder="Password"
            />
            <br />
            {login.submitting === false && (
              <UserButton type="button" onClick={submitLogin}>
                Login
              </UserButton>
            )}
            {login.submitting === true && <p>Submitting...</p>}
          </div>
          <div>
            <UserType onClick={() => changeMode("register")}>
              Don't have an account?
            </UserType>
            &nbsp;|&nbsp;
            <UserType onClick={() => changeMode("forgot")}>
              Forgot your password?
            </UserType>
          </div>
        </div>
      )}
      {mode == "register" && (
        <div>
          <p>
            Register
            <br />
            {login.error && <LoginError>{login.error}</LoginError>}
            <input
              type="email"
              value={email}
              onChange={(e: any) => setEmail(e.currentTarget.value)}
              placeholder="your@email.com"
            />
            <br />
            <input
              type="password"
              value={password}
              onChange={(e: any) => setPassword(e.currentTarget.value)}
              placeholder="Password"
            />
            <br />
            {login.submitting === false && (
              <UserButton type="button" onClick={submitRegister}>
                Register
              </UserButton>
            )}
            {login.submitting === true && <p>Submitting...</p>}
          </p>

          <div>
            <UserType onClick={() => changeMode("login")}>
              I already have an account!
            </UserType>
          </div>
        </div>
      )}
      {mode == "forgot" && login.success === false && (
        <div>
          <p>
            Reset Password:
            <br />
            {login.error && <LoginError>{login.error}</LoginError>}
            {login.submitting === false && (
              <React.Fragment>
                <input
                  type="email"
                  value={email}
                  onChange={(e: any) => setEmail(e.currentTarget.value)}
                  placeholder="your@email.com"
                />
                <br />
                <UserButton type="button" onClick={submitForgotPassword}>
                  Reset Password
                </UserButton>
              </React.Fragment>
            )}
            {login.submitting === true && <p>Submitting...</p>}
          </p>
          <div>
            <UserType onClick={() => changeMode("login")}>Go Back</UserType>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default UsernameLogin
