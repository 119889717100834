import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { currentProject, getFullItem } from "../../utils/State"
import Header from "../../common/Header/Header"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt, faCaretLeft } from "@fortawesome/pro-regular-svg-icons"
import {
  StateInterface,
  ProjectInterface,
  NoteMetaInterface,
} from "../../utils/Interfaces"
import { faCheckCircle, faBan } from "@fortawesome/free-solid-svg-icons"
import AssetColorPicker from "../../common/AssetColorPicker/AssetColorPicker"
import { navigate, Link } from "@reach/router"
import UsesInProject from "../../components/UsesInProject/UsesInProject"
import { customAssetFields, projectNotesOfType } from "../../utils/Data"
import AssetsFooter from "../../components/Assets/AssetsFooter/AssetsFooter"
import Spacer from "../../common/Spacer/Spacer"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import Factoids from "./../../components/Assets/Factoids/Factoids"
import { scaleImageByURL } from "../../utils/Images"
import RelatedAssetSelector from "../../components/Assets/RelatedAssetSelector/RelatedAssetSelector"
import { UserInterface } from "../../utils/Interfaces"

const Wrapper = styled.div`
  padding: 5px;
  box-sizing: border-box;
`

const Supplemental = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  overflow-y: hidden;
`

const AssetTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
`

const AssetFieldTitle = styled.input`
  font-size: 16px;
  padding: 10px;
  width: 100%;
  margin-bottom: 5px;
`

const AssetSelection = styled.div`
  font-style: italic;
  font-size: 15px;
  padding: 10px;
  margin-bottom: 10px;
  max-height: 100px;
  background-color: var(--themed-selected-text-bg-color);
  overflow-y: scroll;
`

const AssetColourPickerToggle = styled.span`
  cursor: pointer;
  font-weight: bold;
  :hover {
    background-color: var(--themed-input-focus-color);
  }
`

const AssetColourExample = styled.div<{ color: string }>`
  width: 15px;
  height: 15px;
  border: 1px solid var(--themed-plot-header-bg-color);
  margin-right: 10px;
  display: inline-block;
  background-color: ${(props) => props.color};
`

const SpecificField = styled.div`
  margin-bottom: 10px;
`

const SpecificField_Title = styled.span`
  text-transform: capitalize;
  font-weight: bold;
`

const SpecificField_TextArea = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
`

const SpecificField_TextInput = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 10px;
`

const LinkToAssetPage = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  a {
    font-size: 20px;
    line-height: 2.6em;
    color: var(--themed-font-color);
  }
`

const Content = styled.div`
  margin: 10px;
`

const Thumbnail = styled.div`
  img {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
  }
`

const ModalWrapper = styled.div`
  position: fixed;
  bottom: 30px;
  left: 0px;
  background-color: var(--themed-bg-color);
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`

interface PlotProps {
  path?: string
  assetType?: string
  assetId?: string
  projectId?: string
}

const Assets = (props: PlotProps) => {
  // TODO: Fix this. project returns null or undefined, not sure
  // how that should go with the project destructuring
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state, props.projectId)
  )

  const dispatch = useDispatch()

  const fullItem = getFullItem(
    project,
    props.assetType as string,
    props.assetId as string
  )

  // TODO: not quite so many "any" types, they're creeping up.
  const getFieldSpecificValuesFromItem = (item: any) => {
    if (!fullItem) return {}

    let objectToReturn: any = {}
    const theseCustomAssetFields = customAssetFields(fullItem.type)
    Object.keys(fullItem).forEach((item: any) => {
      theseCustomAssetFields.forEach((field: any) => {
        if (field.id === item) {
          objectToReturn[item] = fullItem[item]
        }
      })
    })

    return objectToReturn
  }

  const [noteTitle, setNoteTitle] = useState(fullItem ? fullItem.title : "")
  const [noteImage, setNoteImage] = useState(fullItem ? fullItem.thumbnail : "")
  const [noteTags, setNoteTags] = useState(fullItem ? fullItem.tags : "")
  const [showColourPicker, setShowColourPicker] = useState(false)
  const typeSpecificFields: Array<any> = customAssetFields(props.assetType!)

  const [fieldSpecificValues, setFieldSpecificValues] = useState<any>(
    getFieldSpecificValuesFromItem(fullItem)
  )

  const user: UserInterface | undefined | null = useSelector(
    (state: StateInterface) => state.user
  )

  const forceRerender: number = useSelector(
    (state: StateInterface) => state.forceRerender
  )
  const [noteMeta, setNoteMeta] = useState<NoteMetaInterface>(
    fullItem ? fullItem.noteMeta : { supplemental: "" }
  )

  React.useEffect(() => {
    setNoteTitle(fullItem.title)
    setNoteImage(fullItem.thumbnail)
    setNoteTags(fullItem.tags)
    setFieldSpecificValues(getFieldSpecificValuesFromItem(fullItem))
    setNoteMeta(fullItem.noteMeta)
  }, [forceRerender])

  if (!fullItem) return <div />
  const updateFieldSpecificValue = (e: any, n: string) => {
    let newValue = Object.assign({}, fieldSpecificValues)
    if (e.currentTarget) {
      newValue[n] = e.currentTarget.value
    } else {
      newValue[n] = e
    }
    setFieldSpecificValues(newValue)
  }

  const updateNoteMeta = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNoteMeta: NoteMetaInterface = {
      supplemental: e.currentTarget.value,
    }

    setNoteMeta(newNoteMeta)
  }

  const updateNoteTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteTitle(e.currentTarget.value)
  }

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const updateImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileElement: any = document.getElementById(
      "asset-thumbnail"
    ) as HTMLInputElement
    const file: any = fileElement!.files[0]

    const result = await toBase64(file).catch((e) => e)
    if (result instanceof Error) {
      console.error("Error: ", result.message)
      return
    }

    await scaleImageByURL(result as string, 300, 300).then((scaledSrc: any) => {
      // console
      setNoteImage(scaledSrc)
    })
  }

  const clearImage = () => {
    setNoteImage("")
  }

  const deleteFromProject = () => {
    const toDispatch = {
      type: "delete_item_from_project",
      value: { id: fullItem.id, type: fullItem.type },
    }
    navigate(`/projects/${project!.id}/assets/${fullItem.type}`)
    dispatch(toDispatch)
  }

  const submitUpdatedNote = () => {
    let noteItemValues: any = {
      id: fullItem.id,
      noteMeta: noteMeta,
      title: noteTitle,
      type: fullItem.type,
      thumbnail: noteImage,
      tags: noteTags,
    }

    Object.keys(fieldSpecificValues).forEach((item: any) => {
      noteItemValues[item] = fieldSpecificValues[item]
    })

    const toDispatch = {
      type: "update_note_item",
      value: noteItemValues,
    }

    // add type-specific field values here.
    dispatch(toDispatch)
    navigate(`/projects/${project!.id}/assets/${fullItem.type}?updated=true`)
  }

  return (
    <Wrapper>
      <Header />
      <Content>
        <LinkToAssetPage>
          <Link to={`/projects/${project!.id}/assets/${fullItem.type}`}>
            <FontAwesomeIcon icon={faCaretLeft} />
          </Link>
          <AssetTitle>Update {fullItem.type}</AssetTitle>
        </LinkToAssetPage>
        <AssetFieldTitle
          type="text"
          value={noteTitle}
          onChange={updateNoteTitle}
          placeholder="Title"
        />
        {fullItem.selectedText > "" && (
          <AssetSelection>{fullItem.selectedText}</AssetSelection>
        )}
        <Supplemental
          placeholder="Elaborate Here"
          onChange={updateNoteMeta}
          value={noteMeta.supplemental}
        />

        <Thumbnail>
          {noteImage > "" && <img src={noteImage} />}
          {user && user.uid && (
            <input
              type="file"
              id="asset-thumbnail"
              accept=".jpg,.jpeg,.png,.gif"
              onChange={updateImage}
            />
          )}
          {noteImage > "" && (
            <span onClick={clearImage}>
              <FontAwesomeIcon icon={faBan} /> Clear Image
            </span>
          )}
        </Thumbnail>

        {showColourPicker === false && (
          <AssetColourPickerToggle onClick={() => setShowColourPicker(true)}>
            Custom Colour: <AssetColourExample color={fullItem.color} />
          </AssetColourPickerToggle>
        )}
        {showColourPicker === true && (
          <AssetColorPicker
            asset={fullItem}
            onUpdateColour={() => setShowColourPicker(false)}
          />
        )}
        <hr />
        <input
          type="text"
          value={noteTags}
          placeholder="Tags (comma-separated)"
          onChange={(e) => setNoteTags(e.currentTarget.value)}
        />
        <hr />
        <div>
          {typeSpecificFields.map((field) => {
            return (
              <SpecificField key={`type-specific-field-${field.id}`}>
                <SpecificField_Title>
                  {field.id.replace(/_/g, " ")}:
                </SpecificField_Title>
                {field.type === "string" && (
                  <SpecificField_TextInput
                    type="text"
                    onChange={(e) => updateFieldSpecificValue(e, field.id)}
                    defaultValue={fieldSpecificValues[field.id]}
                  />
                )}
                {field.type === "textarea" && (
                  <SpecificField_TextArea
                    onChange={(e) => updateFieldSpecificValue(e, field.id)}
                    defaultValue={fieldSpecificValues[field.id]}
                  ></SpecificField_TextArea>
                )}
                {field.type === "select" && field.options !== undefined && (
                  <select
                    onChange={(e: any) => {
                      if (e.currentTarget.value !== "") {
                        updateFieldSpecificValue(e, field.id)
                      }
                    }}
                    value={fieldSpecificValues[field.id]}
                  >
                    <option value="">Select {field.id}</option>
                    {field.options &&
                      field.options
                        .toUpperCase()
                        .split(",")
                        .map((item: string) => {
                          return (
                            <option
                              key={`select-thing-${Math.random() * 1000}`}
                              value={item}
                            >
                              {item}
                            </option>
                          )
                        })}
                    {!field.options &&
                      projectNotesOfType(project!, field.id).map(
                        (item: any) => {
                          return (
                            <option
                              key={`select-thing-${item.id}`}
                              value={item.id}
                            >
                              {item.title || item.name}
                            </option>
                          )
                        }
                      )}
                  </select>
                )}
                {field.type === "select" && field.id !== "role" && (
                  <RelatedAssetSelector
                    multiple={field.multiple || false}
                    assetType={field.id}
                    inModal={false}
                    selected={fieldSpecificValues[field.id]}
                    updateSelectedAssets={updateFieldSpecificValue}
                  />
                )}
              </SpecificField>
            )
          })}
        </div>

        <Factoids fullItem={fullItem} />

        <hr />
        <UsesInProject asset={fullItem} />

        <ModalWrapper>
          <ModalActions>
            <ModalActionButton
              type="cancel"
              title="Cancel"
              onClick={() =>
                navigate(`/projects/${project!.id}/assets/${fullItem.type}`)
              }
            >
              <FontAwesomeIcon icon={faBan} /> Cancel
            </ModalActionButton>
            <ModalActionButton
              type="delete"
              title="Delete from Project"
              onClick={deleteFromProject}
            >
              <FontAwesomeIcon icon={faTrashAlt} /> Delete
            </ModalActionButton>
            <ModalActionButton
              type="okay"
              title="Save"
              onClick={submitUpdatedNote}
            >
              <FontAwesomeIcon icon={faCheckCircle} /> Update
            </ModalActionButton>
          </ModalActions>
        </ModalWrapper>
        <Spacer width={"100%"} height={"80px"} />
      </Content>
      <AssetsFooter />
    </Wrapper>
  )
}

export default Assets
