import React from "react"
import Header from "../../common/Header/Header"

interface NotFoundProps {
  default?: boolean
}

const NotFound = (props: NotFoundProps) => {
  return (
    <div>
      <Header />
      <div>Route not found.</div>
    </div>
  )
}

export default NotFound
