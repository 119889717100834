import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--title-font);
  margin-bottom: 5px;
`

const ModalTitle = (props: any) => {
  return <Wrapper>{props.children}</Wrapper>
}

export default ModalTitle
