import React, { useState, SyntheticEvent } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DraggableProvided,
  Draggable,
} from "react-beautiful-dnd"

import { currentProject, getConfig } from "../../../utils/State"
import AddSceneModal from "../../../modals/AddSceneModal/AddSceneModal"
import AddStorySectionModal from "../../../modals/AddStorySectionModal/AddStorySectionModal"

import {
  StateInterface,
  ProjectInterface,
  NoteMetaInterface,
  StorySectionInterface,
  ShallowSceneInterface,
  MobileOptionsInterface,
} from "../../../utils/Interfaces"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFolderOpen,
  faFolder,
  faFolderPlus,
  faHome,
} from "@fortawesome/free-solid-svg-icons"
import SectionScenes from "../SectionScenes/SectionScenes"
import { faFilePlus } from "@fortawesome/pro-regular-svg-icons"
import EditStorySectionModal from "../../../modals/EditStorySectionModal/EditStorySectionModal"
import { navigate } from "@reach/router"

const Wrapper = styled.div<{ focusMode: boolean }>`
  @media (max-width: 768px) {
  }
  @media print {
    visibility: hidden;
  }
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);
  ${(props) =>
    props.focusMode === true
      ? "visibility: hidden; opacity: 0;"
      : "visibility: visible; opacity: 1;"}
`

const ExpandStorySections = styled.span`
  margin-left: 5px;
  color: var(--themed-add-scene-color);
  cursor: pointer;
  padding: 5px;
  :hover {
    color: var(--themed-font-color);
  }
`

const AddSceneLink = styled.div`
  margin-left: 20px;
  color: var(--themed-add-scene-color);
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
  /* display: inline-flex; */
  :hover {
    color: var(--themed-font-color);
  }

  svg {
    margin-top: 1px;
  }
`

const Section = styled.div`
  margin-bottom: 5px;
`

const HomeSection = styled.div<{ current: boolean }>`
  display: grid;
  grid-template-columns: 24px 1fr;
  border-radius: 4px;
  padding: 5px;
  margin: 0px 0px 5px 5px;
  font-size: 16px;
  cursor: pointer;
  font-weight: ${(props) => (props.current === true ? "bold" : "normal")};

  :hover {
    background-color: var(--themed-input-focus-color);
  }

  svg {
    padding-top: 4px;
    color: ${(props) =>
      props.current === true
        ? "var(--brand-primary)"
        : "var(--themed-font-color)"};
  }

  svg:hover {
    color: var(--themed-font-color);
  }
`

const SectionTitle = styled.div`
  font-weight: bold;
  font-family: var(--title-font);
`

const AddStorySectionLink = styled.div`
  cursor: pointer;
  color: var(--themed-add-scene-color);
  font-family: var(--title-font);
  font-weight: bold;
  font-size: 16px;
  padding-left: 10px;
  :hover {
    color: var(--themed-font-color);
  }
`

const DesktopContent = styled.div<{ active: boolean }>`
  @media (max-width: 768px) {
    position: absolute;
    left: ${(props) => (props.active === true ? "0px" : "-100%")};
    transition: left var(--transition--fast);
    -webkit-transition: left var(--transition--fast);
    background-color: var(--themed-bg-color);

    z-index: 10000;
    height: 100%;
    width: 100%;
    border-top: 1px solid var(--themed-subhead-color);
  }
`

const WriteSections = ({ selectScene }: { selectScene: Function }) => {
  // TODO: Can I even fix that one?
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const config = useSelector((state: StateInterface) => getConfig(state))
  const mobileMenu: MobileOptionsInterface = project!.mobileMenu

  const [storySections, setStorySections] = useState<
    Array<StorySectionInterface>
  >([])
  const [showAddScenePrompt, setShowAddScenePrompt] = useState<boolean>(false)
  const [storySectionToAddSceneTo, setStorySectionToAddSceneTo] = useState("")

  const currentSceneId: string = project!.currentScene
  const dispatch = useDispatch()
  const [showAddStorySectionPrompt, setShowAddStorySectionPrompt] = useState<
    boolean
  >(false)

  const [showEditStorySectionPrompt, setShowEditStorySectionModal] = useState(
    false
  )
  const [storySectionToEdit, setStorySectionToEdit] = useState("")

  React.useEffect(() => {
    project!.storySections.forEach((storySection: StorySectionInterface) => {
      if (
        storySection.scenes.find(
          (scene: ShallowSceneInterface) => scene.id === currentSceneId
        )
      ) {
        if (storySection.open === false) {
          const toDispatch = {
            type: "toggle_show_story_section_scenes",
            value: { id: storySection.id },
          }

          dispatch(toDispatch)
        }
      }
    })
  }, [])

  React.useEffect(() => {
    setStorySections(project!.storySections)
  }, [project])

  // TODO: What should this any be?
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId, type } = result

    if (!destination) return

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    if (type === "DEFAULT") {
      let newStorySections = [...storySections]
      const highlightedStorySection:
        | StorySectionInterface
        | undefined = storySections.find(
        (storySection: StorySectionInterface) => storySection.id === draggableId
      )

      newStorySections.splice(source.index, 1)
      newStorySections.splice(
        destination.index,
        0,
        highlightedStorySection as never
      )

      updateStorySectionsSort(newStorySections)
    } else if (type === "droppableScene") {
      // scene logic
      const sectionId: string = destination.droppableId.replace(
        "story-section-",
        ""
      )
      let storySection: StorySectionInterface | undefined = storySections.find(
        (storySection: StorySectionInterface) => storySection.id === sectionId
      )

      if (destination.droppableId === source.droppableId) {
        // move within same section
        let newScenes = [...storySection!.scenes]
        const highlightedScene:
          | ShallowSceneInterface
          | undefined = storySection!.scenes.find(
          (scene: ShallowSceneInterface) => scene.id === draggableId
        )

        newScenes.splice(source.index, 1)
        newScenes.splice(destination.index, 0, highlightedScene as never)

        updateSceneSort(
          newScenes,
          destination.droppableId.replace("story-section-", "")
        )
      } else {
        // move to another section
        const sourceSectionId: string = source.droppableId.replace(
          "story-section-",
          ""
        )
        const destinationSectionId: string = destination.droppableId.replace(
          "story-section-",
          ""
        )

        // remove from source array
        const sourceSection:
          | StorySectionInterface
          | undefined = storySections.find(
          (storySection: StorySectionInterface) =>
            storySection.id === sourceSectionId
        )
        const destinationSection:
          | StorySectionInterface
          | undefined = storySections.find(
          (storySection: StorySectionInterface) =>
            storySection.id === destinationSectionId
        )

        let sourceScenes = [...sourceSection!.scenes]
        let destinationScenes = [...destinationSection!.scenes]

        const sceneToMoveToNewSection:
          | ShallowSceneInterface
          | undefined = sourceScenes.find(
          (scene: ShallowSceneInterface) => scene.id === draggableId
        )
        sourceScenes = sourceScenes.filter(
          (scene: ShallowSceneInterface) => scene.id !== draggableId
        )

        destinationScenes.splice(
          destination.index,
          0,
          sceneToMoveToNewSection as ShallowSceneInterface
        )

        updateSceneSort(sourceScenes, sourceSectionId)
        updateSceneSort(destinationScenes, destinationSectionId)
      }
    }
  }
  const updateSceneSort = (
    newScenes: Array<ShallowSceneInterface>,
    sectionId: string
  ) => {
    const toDispatch = {
      type: "update_scene_sort",
      value: { scenes: newScenes, storySectionId: sectionId },
    }
    dispatch(toDispatch)
  }

  const updateStorySectionsSort = (
    newStorySections: Array<StorySectionInterface>
  ) => {
    setStorySections(newStorySections)

    const toDispatch = {
      type: "update_story_section_sort",
      value: { storySections: newStorySections },
    }
    dispatch(toDispatch)
  }

  const createScene = ({
    sceneTitle,
    noteMeta,
    createNewScene,
    existingSceneId,
  }: {
    sceneTitle: string
    noteMeta: NoteMetaInterface
    createNewScene?: boolean
    existingSceneId?: string
  }) => {
    const toDispatch = {
      type: "create_scene",
      value: {
        scene: {
          title: sceneTitle,
          noteMeta: noteMeta,
          sectionId: storySectionToAddSceneTo,
          createNewScene,
          existingSceneId,
        },
        redirectToScene: true,
      },
    }
    dispatch(toDispatch)
    setShowAddScenePrompt(false)
  }

  const createStorySection = (
    title: string,
    noteMeta: NoteMetaInterface,
    createScene: boolean
  ) => {
    const toDispatch = {
      type: "create_story_section",
      value: {
        title: title,
        description: noteMeta.supplemental,
        createScene: createScene,
      },
    }
    dispatch(toDispatch)
    setShowAddStorySectionPrompt(false)
  }

  const doShowAddScene = (id: string) => {
    setStorySectionToAddSceneTo(id)
    setShowAddScenePrompt(true)
  }

  const toggleShowStorySectionScenes = (e: SyntheticEvent, id: string) => {
    e.stopPropagation()
    const toDispatch = {
      type: "toggle_show_story_section_scenes",
      value: {
        id: id,
      },
    }

    dispatch(toDispatch)
  }

  const toggleMobileActive = () => {
    if (mobileMenu.write.sections === false) return
    const toDispatch = {
      type: "toggle_mobile_menu",
      value: { location: "write", type: "sections" },
    }

    dispatch(toDispatch)
  }
  return (
    <Wrapper focusMode={config.focusMode}>
      <DesktopContent
        active={mobileMenu.write.sections === true}
        onClick={() => toggleMobileActive()}
      >
        <HomeSection
          current={currentSceneId === "home"}
          onClick={() => navigate(`/projects/${project!.id}/write`)}
        >
          <FontAwesomeIcon icon={faHome} />
          <SectionTitle>Home</SectionTitle>
        </HomeSection>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"1"}>
            {(provided: DroppableProvided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {storySections.map(
                  (storySection: StorySectionInterface, index: number) => {
                    return (
                      <Draggable
                        draggableId={storySection.id}
                        key={`section-draggable-${storySection.id}`}
                        index={index}
                      >
                        {(provided: DraggableProvided) => (
                          <Section
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            key={`section-to-drag-${storySection.id}`}
                          >
                            <SectionTitle
                              title={`Edit ${storySection.title}`}
                              onClick={() => {
                                setStorySectionToEdit(storySection.id)
                                setShowEditStorySectionModal(true)
                              }}
                            >
                              <ExpandStorySections
                                title={`Expand ${storySection.title}`}
                                onClick={(e: SyntheticEvent) =>
                                  toggleShowStorySectionScenes(
                                    e,
                                    storySection.id
                                  )
                                }
                              >
                                {storySection.open === true && (
                                  <FontAwesomeIcon icon={faFolderOpen} />
                                )}
                                {storySection.open === false && (
                                  <FontAwesomeIcon icon={faFolder} />
                                )}
                              </ExpandStorySections>
                              <span {...provided.dragHandleProps}>
                                {storySection.title}{" "}
                              </span>
                              {storySection.open === true && (
                                <AddSceneLink
                                  title={`Add Scene to ${storySection.title}`}
                                  onClick={(e: SyntheticEvent) => {
                                    e.stopPropagation()
                                    doShowAddScene(storySection.id)
                                  }}
                                >
                                  <FontAwesomeIcon icon={faFilePlus} /> Add
                                  Scene
                                </AddSceneLink>
                              )}
                            </SectionTitle>
                            {storySection.open === true && (
                              <SectionScenes
                                storySectionId={storySection.id}
                                selectScene={selectScene}
                                currentSceneId={currentSceneId}
                              />
                            )}
                          </Section>
                        )}
                      </Draggable>
                    )
                  }
                )}
                {provided.placeholder}
                <AddStorySectionLink
                  title="Create New Section"
                  onClick={(e: SyntheticEvent) => {
                    e.stopPropagation()
                    setShowAddStorySectionPrompt(true)
                  }}
                >
                  <FontAwesomeIcon icon={faFolderPlus} /> Add Section
                </AddStorySectionLink>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div></div>
        {showAddScenePrompt === true && (
          <AddSceneModal
            closeModal={() => setShowAddScenePrompt(false)}
            createScene={createScene}
          />
        )}
        {showAddStorySectionPrompt === true && (
          <AddStorySectionModal
            closeModal={() => setShowAddStorySectionPrompt(false)}
            createStorySection={createStorySection}
          />
        )}
        {showEditStorySectionPrompt === true && (
          <EditStorySectionModal
            closeModal={() => setShowEditStorySectionModal(false)}
            storySectionId={storySectionToEdit}
          />
        )}
      </DesktopContent>
    </Wrapper>
  )
}

export default WriteSections
