import {
  NoteInterface,
  ConfigObjectInterface,
  StateInterface,
  ProjectConfigObjectInterface,
  ProjectInterface,
  SceneInterface,
  StorySectionInterface,
  AssetInterface,
  ProjectTemplateInterface,
  ProjectTemplateNote,
  ProjectTemplateAsset,
  NoteLinkInterface,
} from "./Interfaces"
import { generateRandomId } from "./Strings"

/* --- --- --- DATA --- --- -- */
export const defaultConfig: ConfigObjectInterface = {
  showDescription: false,
  plotScale: 1,
  plotScaleSnap: false,
  plotMode: "edit",
  focusMode: false,
  inspectHighlights: [],
  userName: "",
  email: "",
  bio: "",
  hideNonHighlighted: false,
  darkMode: "off",
  contextMenuMode: "system",
  showHighlight: 0,
  typewriterMode: false,
}

export const defaultState: StateInterface = {
  userLoadComplete: false,
  projectCountForUserLoad: 0,
  forceRerender: 0,
  projects: [],
  projectSyncs: [],
  userProjects: [],
  currentProject: "",
  config: defaultConfig,
  savePending: false,
  dateOfLastSave: new Date().toString(),
  isSaving: false,
  syncing: false,
  syncError: null,
  login: { submitting: false, error: false, success: false },
  dataSentToFirebase: false,
}

export const defaultNoteLink: NoteLinkInterface = {
  type: "link",
  url: "",
  description: "",
  metadata: {},
}

export const emptyNote: NoteInterface = {
  id: "",
  title: "",
  type: "idea",
  created_at: "",
  text: "",
  items: [],
  itemLocations: [],
  updated_at: "",
  factoids: [],
  tags: "",
  links: [],
}

export const defaultProjectConfig: ProjectConfigObjectInterface = {
  type: "manuscript",
  lineSpacing: 1.5,
  render: "html",
  printNotes: "",
  pageBreakBetweenScenes: false,
  pageBreakBetweenSections: true,
  printTitlePage: true,
}

export const emptyProject: ProjectInterface = {
  id: generateRandomId(),
  name: "New Project",
  created_at: null,
  updated_at: null,
  description: "",
  notes: [],
  currentNote: "",
  storySections: [],
  scenes: [],
  currentScene: "home",
  lastViewedScene: "",
  updateSceneView: 0,
  assets: [],
  config: {},
  activities: [],
  goals: [],
  currentScreenplayElementType: "screenplay-scene",
  mobileMenu: {
    write: { sections: false, assets: false },
    notes: { assets: false },
  },
  canBeSyncedToFirebase: true,
  wordCount: 0,
  showGoalSuccess: false,
}

export const emptyScene: SceneInterface = {
  id: generateRandomId(),
  title: "",
  description: "",
  config: defaultProjectConfig,
  noteMeta: { supplemental: "" },
  items: [],
  text: "",
  wordCount: 0,
}

export const emptyStorySection: StorySectionInterface = {
  id: "",
  title: "New Section",
  description: "",
  scenes: [],
  open: true,
  noteMeta: { supplemental: "" },
}

const defaultNotesForTemplate: Array<ProjectTemplateNote> = [
  {
    title: "Random Thoughts about this Project",
    type: "idea",
    text: `Use this note to capture any thoughts or ideas you have about your project, and how it needs to progress.
      
      
      Once you've written down an interesting thought or a great idea you want to use in your project -- like, say, that the hero of your piece needs a cape:

      my hero should have a really sweet cape

      You can turn that idea into a usable asset within Hilker. To do that, select the text, and select the appropriate asset type from the context menu that appears. Select 'New' to create a new asset with that text, type a name and optional description, and you're good! This will create an asset that you can expand on in the Assets section of Hilker, as well as use in Plot to help structure your story, and in Write to see what goes in which scenes.

      You can create new Story Sections, Scenes, Arcs, Storylines, Storybeats, Characters, Locations, Dialogue and MacGuffins!


      Next, say you realize that your hero's cape should be blue, like so:

      the cape should totally be blue, because read is pretty obvious.

      Just select that text, select the Character icon from the context menu, and click 'Add to Existing.' In the modal that appears, select the character you want to add this fact to, elaborate on it if you like, and click the Okay button to save it as a Factoid about your character. This is super helpful to track specific aspects and the continuity of your story assets.
      
      `,
  },
  {
    title: "Research I Need to do",
    type: "research",
    text: "Use this note to collect research you're doing for the project.",
  },
  {
    title: "Hero Motivations",
    type: "idea",
    text: "Work out what the motivations of your hero are.",
  },
  {
    title: "Villain Motivations",
    type: "idea",
    text: "Work out what the motivations of your villain are.",
  },
]

const defaultAssetsForTemplate: Array<ProjectTemplateAsset> = [
  {
    name: "The Hero",
    role: "PRIMARY",
    description: "Every story needs a hero.",
    type: "character",
  },
  {
    name: "The Plucky Sidekick",
    role: "SECONDARY",
    description: "No hero is an island.",
    type: "character",
  },
  {
    name: "The Villain",
    role: "PRIMARY",
    description: "Every story needs a villain.",
    type: "character",
  },
  {
    name: "The Underling",
    role: "SECONDARY",
    description: "Villains need help.",
    type: "character",
  },
  {
    name: "The Hero's Journey",
    description: "This is the arc the hero goes on in this story.",
    type: "arc",
  },
  {
    name: "The Villain's Journey",
    description:
      "You may not want them to win, but the villain also has an arc.",
    type: "arc",
  },
  {
    name: "The Hero saves the world from the villain.",
    description:
      "This is a storyline for the hero, which feeds into the hero's arc.",
    type: "storyline",
  },
  {
    name: "The Villain tries to flood the world!",
    description:
      "This storyline involves the machinations of the villain, which form a pillar of the villain's arc.",
    type: "storyline",
  },
  {
    name: "The Hero Pulls the Plug!",
    description:
      "This is a specific beat in the story, where the Hero causes the Villain's floodwater to pour down the drain. It relates to the 'The Hero Saves the world from the villain' storyline.",
    type: "storybeat",
  },
  {
    name: "The Hoover Dam",
    description: "This is a location used in the story.",
    type: "location",
  },
  {
    name: "Time's Up, Villain!",
    description:
      "This is some cool dialogue the hero should say, probably in the climax.",
    type: "dialogue",
  },
  {
    name: "You'll never stop me!",
    description:
      "Something cool the villain says defiantly right before losing.",
    type: "dialogue",
  },
  {
    name: "The Mega-Flood 2000",
    description:
      "This is the machine that the villain will use to flood the world.",
    type: "macguffin",
  },
]

export const projectTemplates: Array<ProjectTemplateInterface> = [
  {
    id: "1act",
    numberOfSections: 1,
    notes: defaultNotesForTemplate,
    assets: defaultAssetsForTemplate,
  },
  {
    id: "3act",
    numberOfSections: 3,
    notes: defaultNotesForTemplate,
    assets: defaultAssetsForTemplate,
  },
  {
    id: "4act",
    numberOfSections: 4,
    notes: defaultNotesForTemplate,
    assets: defaultAssetsForTemplate,
  },
  {
    id: "5act",
    numberOfSections: 5,
    notes: defaultNotesForTemplate,
    assets: defaultAssetsForTemplate,
  },
]

export const projectNotesOfType = (
  project: ProjectInterface,
  noteType: string
) => {
  return project.assets.filter(
    (asset: AssetInterface) => asset.type === noteType
  )
}

export const emptyAsset: AssetInterface = {
  id: "",
  sceneId: "",
  thumbnail: "",
  noteMeta: { supplemental: "" },
  description: "",
  type: "",
  title: "",
  tags: "",
}

export const customAssetFields = (assetType: string) => {
  switch (assetType) {
    case "character":
      return [
        { id: "role", type: "select", options: "primary,secondary" },
        { id: "age", type: "string" },
        { id: "sex", type: "string" },
        { id: "height", type: "string" },
        { id: "weight", type: "string" },
        { id: "purpose_in_story", type: "textarea" },
        { id: "goals", type: "textarea" },
        { id: "strengths", type: "textarea" },
        { id: "weaknesses", type: "textarea" },
      ]
    case "storybeat":
      return [
        { id: "purpose_in_story", type: "textarea" },
        { id: "character", type: "select", multiple: true },
        { id: "location", type: "select", multiple: true },
        { id: "macguffin", type: "select", multiple: true },
        { id: "dialogue", type: "select", multiple: true },
        { id: "arc", type: "select", multiple: true },
      ]
    case "dialogue":
      return [{ id: "character", type: "select" }]
    case "arc":
      return []
    case "storyline":
      return [
        { id: "storybeat", type: "select", multiple: true },
        { id: "character", type: "select", multiple: true },
        { id: "location", type: "select", multiple: true },
        { id: "macguffin", type: "select", multiple: true },
        { id: "dialogue", type: "select", multiple: true },
        { id: "arc", type: "select", multiple: true },
      ]

    case "location":
      return [
        { id: "city", type: "string" },
        { id: "state", type: "string" },
        { id: "country", type: "string" },
        { id: "planet", type: "textarea" },
        { id: "purpose_in_story", type: "textarea" },
        { id: "residents", type: "textarea" },
      ]
    case "macguffin":
      return [{ id: "purpose_in_story", type: "textarea" }]
  }
  return []
}
