import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave, faSync } from "@fortawesome/pro-regular-svg-icons"
import { StateInterface } from "../../utils/Interfaces"
import { getConfig } from "../../utils/State"
import moment from "moment"
import { faAsterisk } from "@fortawesome/free-solid-svg-icons"
import {
  Wrapper,
  SaveWrapper,
  SaveIndicator,
  SavePending,
  NoSavePending
} from "./SaveState.styles"

const SaveState = () => {
  const config = useSelector((state: StateInterface) => getConfig(state))
  const state: StateInterface = useSelector((state: StateInterface) => state)

  const savePending: boolean = state.savePending
  const isSaving: boolean = state.isSaving
  const dateOfLastSave: string = state.dateOfLastSave
  const [randomValue, setRandomValue] = useState(0)
  let refreshInterval: any

  useEffect(() => {
    refreshInterval = setInterval(() => {
      setRandomValue(Math.random())
    }, 60000)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [])

  const dispatch = useDispatch()

  const showSaveTitle = () => {
    if (savePending === true) {
      return "Save Needed"
    } else {
      return "No Save Needed"
    }
  }

  const saveProjectNow = () => {
    const toDispatch = { type: "save_project_now", value: {} }
    dispatch(toDispatch)
  }

  return (
    <Wrapper
      focusMode={config.focusMode}
      title={showSaveTitle()}
      onClick={saveProjectNow}
    >
      <SaveWrapper savePending={savePending}>
        {isSaving === false && (
          <React.Fragment>
            <FontAwesomeIcon icon={faSave} />
            {savePending === true && (
              <SaveIndicator>
                <FontAwesomeIcon icon={faAsterisk} />
              </SaveIndicator>
            )}
          </React.Fragment>
        )}
        {isSaving === true && (
          <React.Fragment>
            <FontAwesomeIcon icon={faSync} className="spinning" />
          </React.Fragment>
        )}
      </SaveWrapper>
      {isSaving === true && <SavePending>Saving...</SavePending>}
      {isSaving === false && (
        <NoSavePending key={`random-${randomValue}`}>
          Saved {`${moment(dateOfLastSave).fromNow()}`}
        </NoSavePending>
      )}
    </Wrapper>
  )
}

export default SaveState
