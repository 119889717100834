import React from "react"
import SubHeader from "../../../common/SubHeader/SubHeader"

interface SubHeaderPlotProps {
  children?: any
}
const SubHeaderPlot = (props: SubHeaderPlotProps) => {
  // return <SubHeader>Plot Submenu here.</SubHeader>
  // TODO: Move stuff from bottom menu to top?
  return <div></div>
}

export default SubHeaderPlot
