import * as React from "react"
import { useSelector, useDispatch } from "react-redux"
import Showdown from "showdown"
import TurndownService from "turndown"

import { generateRandomId, sanitizeHTMLPaste } from "../../../utils/Strings"
import {
  StateInterface,
  NoteItemInterface,
  ProjectInterface,
  doAddItemProps,
} from "../../../utils/Interfaces"

import AddNoteModal from "../../../modals/AddNoteModal/AddNoteModal"
import { currentNote, currentProject } from "../../../utils/State"
import EditableTextContextMenu from "../EditableTextContextMenu/EditableTextContextMenu"
import { navigate } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCaretLeft,
  faLightbulbOn,
  faTelescope,
} from "@fortawesome/pro-regular-svg-icons"
import { saveSelection, restoreSelection } from "../../../utils/Selection"
import {
  NoteTitleStrip,
  NotesIndexLink,
  NoteTitle,
  NoteTypeOption,
  NoteTypeOptions,
  EditableTextField,
  Wrapper,
} from "./EditableText.styles"
import { scrollWritingAreaIntoViewIfNecessary } from "../../../utils/Cursor"
import NoteLinks from "../NoteLinks/NoteLinks"

const EditableText = React.memo(
  ({
    addItem,
    showItemInfo,
  }: {
    addItem: Function
    showItemInfo: Function
  }) => {
    const project: ProjectInterface | null = useSelector(
      (state: StateInterface) => currentProject(state)
    )
    const converter = new Showdown.Converter()
    const editableRef = React.useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()

    const note = useSelector((state: StateInterface) => currentNote(state))

    const [selectedText, setSelectedText] = React.useState("")
    const [showAddItemPrompt, setShowAddItemPrompt] = React.useState<boolean>(
      false
    )
    const [selectionRange, setSelectionRange] = React.useState<Range | null>()
    const [showContextMenu, setShowContextMenu] = React.useState<number>(0)
    const [noteTitle, setNoteTitle] = React.useState<string>("")
    const [noteLinks, setNoteLinks] = React.useState<Array<any>>([])
    const [noteType, setNoteType] = React.useState<string>("")
    const [newNoteType, setNewNoteType] = React.useState<string>("")
    const [newNoteAction, setNewNoteAction] = React.useState<string>("")

    const overrideHTMLPaste = (e: React.ClipboardEvent) => {
      e.preventDefault()
      const selection = window.getSelection()

      if (e.clipboardData) {
        let sanitizedPasteContent: Array<any> = sanitizeHTMLPaste(
          e.clipboardData.getData("text/plain"),
          false
        )

        let currentElement: any = selection!.anchorNode!.parentElement
        let currentClass = currentElement!.className
        let insertType: InsertPosition = "afterend"

        if (currentElement!.localName !== "p") {
          currentElement = selection!.anchorNode!.firstChild
          currentClass = currentElement!.className
        }

        if (currentElement!.textContent!.length >= 1) {
          const textToMoveToNextNode =
            currentElement!.textContent!.substr(selection!.anchorOffset) ||
            "&#8288;"
          let posToShiftCursorTo: number = 0
          if (textToMoveToNextNode === "&#8288;") {
            posToShiftCursorTo = 1
          }
          currentElement!.textContent = currentElement!.textContent!.substr(
            0,
            selection!.anchorOffset
          )

          const secondHalfDiv = document.createElement("p")
          secondHalfDiv.className = currentClass
          secondHalfDiv.innerHTML = textToMoveToNextNode

          currentElement!.insertAdjacentElement(insertType, secondHalfDiv)
          for (let i = sanitizedPasteContent.length - 1; i >= 0; i--) {
            currentElement!.insertAdjacentElement(
              insertType,
              sanitizedPasteContent[i]
            )
          }

          selection!.collapse(secondHalfDiv.firstChild, posToShiftCursorTo)
        }
      }

      updateNoteText()
      updateItemLocations()
    }

    React.useEffect(() => {
      if (editableRef) {
        editableRef!.current!.innerHTML = converter.makeHtml(note!.text)
        setNoteTitle(note!.title)
        setNoteType(note!.type)
        if (note!.links) setNoteLinks(note!.links)
      }

      const textFieldElement: any = document.getElementById(
        "editable-text-field"
      )
      if (textFieldElement) textFieldElement.focus()

      updateItemLocations()

      window.addEventListener("resize", resizeHandler)

      return () => {
        window.removeEventListener("resize", resizeHandler)
      }
    }, [])

    const resizeHandler = (e: any) => {
      updateItemLocations()

      scrollWritingAreaIntoViewIfNecessary(false, 0)
    }

    const forceRerender: number = useSelector(
      (state: StateInterface) => state.forceRerender
    )

    React.useEffect(() => {
      if (editableRef) {
        editableRef!.current!.innerHTML = converter.makeHtml(note!.text)
        setNoteTitle(note!.title)
        setNoteType(note!.type)
        if (note!.links) setNoteLinks(note!.links)
      }

      updateItemLocations()
    }, [forceRerender])

    React.useEffect(() => {
      updateItemLocations()
    }, [note!.items])

    React.useEffect(() => {
      updateNoteText()
    }, [noteTitle])

    React.useEffect(() => {
      updateNoteText()
    }, [noteType])

    React.useEffect(() => {
      updateNoteText()
    }, [noteLinks])

    const addFactoidToItem = ({
      referenceId,
      type,
      selectedText,
      description,
    }: {
      referenceId: string
      type: string
      selectedText: string
      description: string
    }) => {
      const toDispatch = {
        type: "add_factoid_to_asset",
        value: {
          referenceId,
          type,
          selectedText,
          description: description,
          noteId: note!.id,
        },
      }

      dispatch(toDispatch)
    }
    const doAddItem = (props: doAddItemProps) => {
      const { type, noteMeta, title, tags, newNoteAction, referenceId } = props

      hidePromptToAddItem()
      if (selectionRange !== undefined && selectionRange !== null) {
        restoreSelection(selectionRange)
      }

      const selectedText: Selection | null = document.getSelection()
      const id: string = generateRandomId()

      // add item to shared array
      const newItem: NoteItemInterface = {
        selectedText: selectedText!.toString(),
        id,
        type,
        noteMeta,
        title,
        tags,
      }

      var span = document.createElement("span")
      if (newNoteAction === "existing") {
        if (referenceId) {
          addFactoidToItem({
            referenceId,
            type,
            selectedText: selectedText!.toString(),
            description: noteMeta.supplemental || "",
          })

          span.id = "selection_" + referenceId
          span.className = "note_selection_" + type
        }
      } else {
        addItem(newItem)

        span.id = "selection_" + id
        span.className = "note_selection_" + type
      }
      const selection: DocumentFragment = document
        .getSelection()!
        .getRangeAt(0)
        .cloneContents()

      document.getSelection()!.getRangeAt(0).deleteContents()
      const range: Range = document.getSelection()!.getRangeAt(0)
      range.collapse(true)
      span.appendChild(selection)

      // TODO: Add &nbsp; conditionally, if on last character
      range.insertNode(span)
      range.setStartAfter(span)
      range.collapse(true)
      document.getSelection()!.removeAllRanges()
      document.getSelection()!.addRange(range)

      updateNoteText()
      updateItemLocations()
    }

    const showPromptToAddItem = (assetType: string, newAction: string) => {
      const sel: Selection | null = document.getSelection()
      setSelectedText(sel!.toString())
      setSelectionRange(saveSelection())
      setNewNoteType(assetType)
      setNewNoteAction(newAction)
      setShowAddItemPrompt(true)
      setShowContextMenu(0)
    }

    const hidePromptToAddItem = () => {
      setShowAddItemPrompt(false)
    }

    const updateItemLocations = () => {
      const newItems: Array<NoteItemInterface> = []
      note!.items.forEach((item: NoteItemInterface) => {
        const newItem = { ...item }
        const itemRef: HTMLElement | null = document.getElementById(
          "selection_" + item.id
        )

        if (itemRef) {
          if (window.innerWidth <= 768) {
            newItem.y = itemRef.offsetTop - 54
          } else {
            newItem.y = itemRef.offsetTop - 25
          }
        } else {
          newItem.y = -1
        }

        newItems.push(newItem)
      })

      if (note!.items.length > 0) {
        const toDispatch = {
          type: "update_note_item_locations",
          value: newItems,
        }
        dispatch(toDispatch)
      }
    }

    const selectHandlerToShowContextMenu = () => {
      var sel = window.getSelection()
      if (sel!.getRangeAt && sel!.rangeCount) {
        const range: Range = sel!.getRangeAt(0)
        const locationOfSelectedText: ClientRect = range.getBoundingClientRect()

        if (locationOfSelectedText.width > 0) {
          setShowContextMenu(1 + Math.floor(Math.random() * 1000))
        } else {
          setShowContextMenu(0)
        }
      }
    }

    const updateNoteText = () => {
      const turndownService = new TurndownService()
      turndownService.keep("span")
      const innerHTML = document.getElementById("editable-text-field")
      const toDispatch = {
        type: "update_note",
        value: {
          text: turndownService.turndown(innerHTML!.innerHTML),
          title: noteTitle,
          type: noteType,
          links: noteLinks,
        },
      }

      dispatch(toDispatch)
      updateItemLocations()
    }

    const updateNoteTitle = (e: any) => {
      setNoteTitle(e.currentTarget.value)
    }

    const updateNoteLinks = (links: Array<any>) => {
      setNoteLinks(links)
    }

    const checkForSelectionClick = (target: any) => {
      if (target.id && target.id.indexOf("selection_") > -1) {
        const id: string = target.id.replace("selection_", "")
        showItemInfo(id)
      }
    }

    const clickHandler = (e: any) => {
      scrollWritingAreaIntoViewIfNecessary(false, 0)
      checkForSelectionClick(e.target)
    }

    return (
      <React.Fragment>
        <Wrapper>
          <NoteTitleStrip>
            <NotesIndexLink
              title="Back to List of Notes"
              onClick={() => navigate(`/projects/${project!.id}/note`)}
            >
              <FontAwesomeIcon icon={faCaretLeft} />
            </NotesIndexLink>
            <NoteTypeOptions>
              {noteType !== "research" && (
                <NoteTypeOption
                  onClick={(e) => setNoteType("research")}
                  active={noteType === "" || noteType === "idea"}
                >
                  <FontAwesomeIcon icon={faLightbulbOn} />
                </NoteTypeOption>
              )}
              {noteType === "research" && (
                <NoteTypeOption
                  onClick={(e) => setNoteType("idea")}
                  active={noteType === "research"}
                >
                  <FontAwesomeIcon icon={faTelescope} />
                </NoteTypeOption>
              )}
            </NoteTypeOptions>
            <NoteTitle
              id={`editable-title`}
              placeholder={"Give your note a title"}
              value={noteTitle}
              tabIndex={0}
              onChange={updateNoteTitle}
            />
          </NoteTitleStrip>
          {noteType === "research" && (
            <React.Fragment>
              <NoteLinks links={noteLinks} updateNoteLinks={updateNoteLinks} />
            </React.Fragment>
          )}

          <EditableTextField
            id={`editable-text-field`}
            ref={editableRef}
            onInput={updateNoteText}
            onPaste={overrideHTMLPaste}
            tabIndex={1}
            onKeyUp={() => scrollWritingAreaIntoViewIfNecessary(false, 0)}
            placeholder={"Enter Your Notes Here..."}
            contentEditable={true}
            onSelect={selectHandlerToShowContextMenu}
            onClick={clickHandler}
          />
        </Wrapper>

        {showContextMenu > 0 && (
          <EditableTextContextMenu clickHandler={showPromptToAddItem} />
        )}

        {showAddItemPrompt === true && (
          <AddNoteModal
            selectedText={selectedText}
            closeModal={hidePromptToAddItem}
            addItem={doAddItem}
            newNoteType={newNoteType}
            newNoteAction={newNoteAction}
          />
        )}
      </React.Fragment>
    )
  }
)

export default EditableText
