import React, { useState } from "react"
import styled from "styled-components"
import Header from "../../common/Header/Header"
import { useSelector, useDispatch } from "react-redux"
import {
  StateInterface,
  ProjectInterface,
  ActivityInterface
} from "../../utils/Interfaces"
import { projectById } from "../../utils/State"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "@reach/router"
import ActivityInfo from "../../components/Projects/ActivityInfo/ActivityInfo"
import ProjectsFooter from "../../components/Projects/ProjectsFooter/ProjectsFooter"
import StandardButton from "../../common/StandardButton/StandardButton"
import Spacer from "../../common/Spacer/Spacer"

const Wrapper = styled.div``

const ProjectMeta = styled.h2`
  margin: 0px;
`

const Meta = styled.div`
  display: grid;
  grid-template-columns: 15px 1fr;
`

const Paging = styled.div`
  max-width: 500px;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  grid-gap: 10px;
`

const PageInfo = styled.div`
  text-align: center;
  line-height: 2.4em;
`

const Content = styled.div`
  margin: 0px 10px;
`

const BackLink = styled.div`
  line-height: 2.2em;
  a {
    color: var(--brand-primary);
  }
`

const ProjectActivities = ({
  path,
  projectId
}: {
  path: string
  projectId?: string | undefined | null
}) => {
  const dispatch = useDispatch()

  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => projectById(state, projectId!)
  )

  const currentProject: ProjectInterface | null = useSelector(
    (state: StateInterface) => projectById(state, projectId!)
  )

  const listOfActivities: Array<ActivityInterface> = [
    ...currentProject!.activities
  ].reverse()

  const activitiesPerPage: number = 25
  const numberOfPages: number = Math.floor(
    listOfActivities.length / activitiesPerPage + 0.99
  )
  const [currentPage, setCurrentPage] = useState(0)

  const updateCurrentPage = (dir: number) => {
    let page: number = currentPage + dir
    if (page <= 0) page = 0
    if (page >= numberOfPages - 1) page = numberOfPages - 1
    setCurrentPage(page)
  }

  if (!project) return <div />
  return (
    <Wrapper>
      <Header />
      <Content>
        <Meta>
          <BackLink>
            {window.location.search.indexOf("fromWrite=true") === -1 && (
              <Link to={`/projects/${project!.id}`}>
                <FontAwesomeIcon icon={faCaretLeft} />
              </Link>
            )}
            {window.location.search.indexOf("fromWrite=true") > -1 && (
              <Link to={`/projects/${project!.id}/write/home`}>
                <FontAwesomeIcon icon={faCaretLeft} /> Back to Writing
              </Link>
            )}
          </BackLink>

          <ProjectMeta>Activities for {currentProject!.name}</ProjectMeta>
        </Meta>

        {listOfActivities.length > activitiesPerPage && (
          <Paging>
            {currentPage > 0 && (
              <StandardButton onClick={() => updateCurrentPage(-1)}>
                <FontAwesomeIcon icon={faCaretLeft} />
              </StandardButton>
            )}
            {currentPage === 0 && <span></span>}
            <PageInfo>
              Page {currentPage + 1} of {numberOfPages}
            </PageInfo>
            {currentPage < numberOfPages - 1 && (
              <StandardButton onClick={() => updateCurrentPage(1)}>
                <FontAwesomeIcon icon={faCaretRight} />
              </StandardButton>
            )}
            {currentPage === numberOfPages && <span></span>}
          </Paging>
        )}
        <hr />
        <div>
          {listOfActivities
            .splice(currentPage * activitiesPerPage, activitiesPerPage)
            .map((activity: ActivityInterface) => {
              return (
                <ActivityInfo
                  project={project!}
                  activity={activity}
                  key={`activity-list-${Math.floor(Math.random() * 1000000)}`}
                />
              )
            })}
          {listOfActivities.length > activitiesPerPage && (
            <Paging>
              {currentPage > 0 && (
                <StandardButton onClick={() => updateCurrentPage(-1)}>
                  <FontAwesomeIcon icon={faCaretLeft} />
                </StandardButton>
              )}
              {currentPage === 0 && <span></span>}
              <PageInfo>
                Page {currentPage + 1} of {numberOfPages}
              </PageInfo>
              {currentPage < numberOfPages - 1 && (
                <StandardButton onClick={() => updateCurrentPage(1)}>
                  <FontAwesomeIcon icon={faCaretRight} />
                </StandardButton>
              )}
              {currentPage === numberOfPages && <span></span>}
            </Paging>
          )}
        </div>
      </Content>
      <Spacer width="100%" height="40px" />
      <ProjectsFooter />
    </Wrapper>
  )
}

export default ProjectActivities
