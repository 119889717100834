import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { projectById } from "../../utils/State"
import {
  ProjectInterface,
  StateInterface,
  SceneInterface,
  AssetInterface
} from "../../utils/Interfaces"
import { WordCount, formatWordCount } from "../../utils/Numbers"
import moment from "moment"
import { navigate } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIconForAssetType } from "../../utils/Icons"
import { assetTypesFull } from "../../utils/Assets"
import { faTally, faCalendar } from "@fortawesome/pro-regular-svg-icons"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 14px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

const StatWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-gap: 10px;
  margin: 0px 5px 10px 0px;

  svg {
    font-size: 20px;
  }
`

const StatIcon = styled.div`
  text-align: center;
`

const ProjectMeta = ({ projectId }: { projectId: string }) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => projectById(state, projectId)
  )

  const assetTypes = assetTypesFull(true)

  const AssetList = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    @media (max-width: 900px) {
      margin-top: 10px;
      border-top: 1px solid var(--themed-asset-header-color);
      padding-top: 20px;
    }

    margin-bottom: 20px;
  `

  const AssetType = styled.div<{ assetType: string }>`
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--white-color);
    background-color: ${(props) => `var(--${props.assetType}-color)`};
    padding: 5px;
    border-radius: 4px;
    svg {
      color: var(--white-color);
      font-size: 20px;
      margin-right: 5px;
    }
  `

  const getNumberOfAssetType = (assetType: string) => {
    switch (assetType) {
      case "storySection":
        return project!.storySections.length
      case "scene":
        return project!.scenes.length
      default:
        return project!.assets.filter((asset: AssetInterface) => asset.type === assetType).length
    }
  }

  const getFormattedAssetType = (type: string) => {
    const result: string = type.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  if (!project) {
    return <div />
  }
  return (
    <Wrapper>
      <div>
        <StatWrapper>
          <StatIcon>
            <FontAwesomeIcon icon={faTally} />
          </StatIcon>
          <span>
            You've written{" "}
            <strong>{formatWordCount(project!.wordCount, true)}</strong> since{" "}
            <strong>
              {moment(project!.created_at).format("MMMM Do YYYY")}
            </strong>
            !
          </span>
        </StatWrapper>

        <StatWrapper>
          <StatIcon>
            <FontAwesomeIcon icon={faCalendar} />
          </StatIcon>
          <span>
            You last worked on this book{" "}
            <strong>{moment(project!.updated_at).fromNow()}</strong>. Good job!
          </span>
        </StatWrapper>
      </div>
      <div>
        <AssetList>
          {assetTypes.map((assetType: string) => {
            return (
              <AssetType
                key={`asset-type-${assetType}`}
                onClick={(e: any) => {
                  e.stopPropagation()
                  navigate(`/projects/${project!.id}/assets/${assetType}`)
                }}
                assetType={assetType}
                title={`Your Project has ${getNumberOfAssetType(
                  assetType
                )} ${assetType}s`}
              >
                <FontAwesomeIcon icon={getIconForAssetType(assetType)} />{" "}
                <span>
                  {getNumberOfAssetType(assetType)}{" "}
                  {getFormattedAssetType(assetType)}
                  {getNumberOfAssetType(assetType) === 1 ? "" : "s"}
                </span>
              </AssetType>
            )
          })}
        </AssetList>
      </div>
    </Wrapper>
  )
}

export default ProjectMeta
