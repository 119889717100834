import React, { useState } from "react"
import styled from "styled-components"
import { getFullItem, currentProject } from "../../../utils/State"
import { ProjectInterface, StateInterface } from "../../../utils/Interfaces"
import { useSelector } from "react-redux"
import Thumbnail from "../../../common/Thumbnail/Thumbnail"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { filterForSearchTerms } from "../../../utils/Strings"
import {
  Wrapper,
  NoAssetSelected,
  SelectAssetButton,
  SelectAssetButtonDiv,
  SelectedAsset,
  SelectableWrapper,
  SelectableScrollWrapper,
  SelectableAsset
} from "./RelatedAssetSelector.styles"
import { getAssetsByType } from "../../../utils/Assets"

interface RelatedAssetSelectorProps {
  multiple: boolean
  assetType: string
  selected: string
  inModal: boolean
  filteredOptions?: Array<any>
  updateSelectedAssets: Function
}

const RelatedAssetSelector = (props: RelatedAssetSelectorProps) => {
  const [showSelectPrompt, setShowSelectPrompt] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const {
    inModal,
    selected,
    updateSelectedAssets,
    assetType,
    multiple,
    filteredOptions
  } = props

  const toggleAsset = (asset: string) => {
    let assets: Array<string> = []
    if (selected) {
      assets = selected.split(",")
    }

    if (multiple === true) {
      if (assets.includes(asset)) {
        assets = assets.filter((a: string) => a !== asset)
      } else {
        assets.push(asset)
      }
    } else {
      if (assets.includes(asset)) {
        assets = []
      } else {
        assets = [asset]
      }
    }
    updateSelectedAssets(assets.join(","), assetType)
  }

  const getRelatedAssets = () => {
    if (filteredOptions) return filteredOptions
    return getAssetsByType(project!, assetType)
  }

  return (
    <Wrapper>
      {showSelectPrompt === false && (selected === "" || !selected) && (
        <NoAssetSelected>
          No {assetType}
          {multiple === true ? "s have " : " has "}been selected.{" "}
          <SelectAssetButton onClick={() => setShowSelectPrompt(true)}>
            Select
          </SelectAssetButton>
        </NoAssetSelected>
      )}
      {showSelectPrompt === false && selected && selected > "" && (
        <div>
          {selected.split(",").map((asset: string) => {
            const fullItem: any = getFullItem(project, assetType, asset)

            if (!fullItem) return <div key={`selected-asset-${asset}`} />
            return (
              <SelectedAsset
                key={`selected-asset-${asset}`}
                customColor={fullItem!.color}
              >
                {fullItem!.thumbnail && fullItem!.thumbnail > "" && (
                  <Thumbnail src={fullItem!.thumbnail} size={"30px"} />
                )}
                {fullItem!.role && fullItem!.role === "PRIMARY" && (
                  <React.Fragment>
                    <FontAwesomeIcon icon={faStar} />
                  </React.Fragment>
                )}
                {fullItem.title}
              </SelectedAsset>
            )
          })}
          <div>
            <SelectAssetButtonDiv onClick={() => setShowSelectPrompt(true)}>
              CHANGE
            </SelectAssetButtonDiv>
          </div>
        </div>
      )}
      {showSelectPrompt === true && (
        <SelectableWrapper>
          <div>
            <input
              type="text"
              placeholder={`Search for ${assetType}`}
              value={searchValue}
              onChange={e => setSearchValue(e.currentTarget.value)}
            />
          </div>
          <SelectableScrollWrapper inModal={inModal}>
            {getRelatedAssets()
              .filter((asset: any) => filterForSearchTerms(asset, searchValue))
              .map((asset: any) => {
                return (
                  <SelectableAsset
                    key={`selectable-asset-${asset.id}`}
                    selected={
                      selected ? selected.indexOf(asset.id) > -1 : false
                    }
                    onClick={() => toggleAsset(asset.id)}
                  >
                    {asset.thumbnail && asset.thumbnail > "" && (
                      <Thumbnail src={asset.thumbnail} size={"30px"} />
                    )}
                    {asset.role && asset.role === "PRIMARY" && (
                      <React.Fragment>
                        <FontAwesomeIcon icon={faStar} />
                      </React.Fragment>
                    )}
                    {asset.title}
                  </SelectableAsset>
                )
              })}
          </SelectableScrollWrapper>
          <div>
            <SelectAssetButtonDiv
              onClick={() => {
                setShowSelectPrompt(false)
                setSearchValue("")
              }}
            >
              CONFIRM
            </SelectAssetButtonDiv>
          </div>
        </SelectableWrapper>
      )}
    </Wrapper>
  )
}

export default RelatedAssetSelector
