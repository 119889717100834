import React, { MouseEventHandler } from "react"
import ModalBase from "../../common/Modal/ModalBase/ModalBase"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle"
import { faBan, faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const DeleteLocalProjectModal = ({
  closeModal,
  deleteLocalProject,
}: {
  closeModal: MouseEventHandler
  deleteLocalProject: Function
}) => {
  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Delete Local Copy of Project?</ModalTitle>
        <p>
          Are you sure you want to delete your local copy of this project? If you have the project synced, you'll be 
          able to download it again, but if you don't have the project synced, it will be gone forever.
        </p>
        <ModalActions>
          <ModalActionButton type="cancel" onClick={closeModal}>
            <FontAwesomeIcon icon={faBan} /> No
          </ModalActionButton>
          <ModalActionButton type="okay" onClick={deleteLocalProject}>
            <FontAwesomeIcon icon={faCheckCircle} /> Yes
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  )
}

export default DeleteLocalProjectModal
