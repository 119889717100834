import styled from "styled-components"

export const Wrapper = styled.div`
  margin: 0px 0px 25px 0px;
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 10px;
  border-radius: 8px 0px 0px 8px;
  box-sizing: border-box;
  padding: 5px;

  :hover {
    background-color: var(--themed-input-focus-color);
  }
`

export const TopRow = styled.div``

export const ProjectDescription = styled.div`
  font-family: var(--body-font);
  font-size: 14px;
  color: var(--themed-asset-header-color);
  padding: 0px 5px 5px 0px;
  box-sizing: border-box;
`
export const ProjectName = styled.div<{ current: boolean }>`
  font-weight: bold;
  font-family: var(--title-font);
  font-size: 24px;
  border-bottom: 1px solid var(--themed-plot-header-bg-color);
  cursor: pointer;
  ${(props) => (props.current === true ? "color: var(--brand-primary);" : "")}
  svg {
    margin-right: 5px;
  }
`

export const LeftRow = styled.div<{ current: boolean }>`
  font-size: 60px;
  cursor: pointer;
  line-height: 1.25em;
  padding-left: 10px;
  box-sizing: border-box;
  ${(props) => (props.current === true ? "color: var(--brand-primary);" : "")}
  svg {
    margin-right: 5px;
  }
`
