import {
  ConfigObjectInterface,
  AssetInterface,
  WriteMetaItemInterface,
  ProjectInterface
} from "./Interfaces"
import { getFullItem } from "./State"

export const sortCharacters = (
  characters: Array<AssetInterface>,
  config: ConfigObjectInterface
) => {
  if (config.plotMode === "edit") {
    return characters
  }
  let newCharacters: Array<AssetInterface> = []

  characters.forEach((character: AssetInterface) => {
    if (character.role && character.role === "PRIMARY") {
      newCharacters.push(character)
    }
  })

  characters.forEach((character: AssetInterface) => {
    if (character.role && character.role === "SECONDARY") {
      newCharacters.push(character)
    }
  })

  characters.forEach((character: AssetInterface) => {
    if (
      !character.role ||
      (character.role &&
        character.role !== "PRIMARY" &&
        character.role !== "SECONDARY")
    ) {
      newCharacters.push(character)
    }
  })

  return newCharacters
}

export const sortCharactersByRole = (
  project: ProjectInterface,
  items: Array<WriteMetaItemInterface>
) => {
  let primaryItems: Array<WriteMetaItemInterface> = []
  let secondaryItems: Array<WriteMetaItemInterface> = []

  items.forEach((item: WriteMetaItemInterface) => {
    if (item.type === "character") {
      let fullItem = getFullItem(project, "character", item.id)

      if (fullItem && fullItem.role && fullItem.role === "PRIMARY") {
        primaryItems.push(item)
      } else {
        secondaryItems.push(item)
      }
    } else {
      secondaryItems.push(item)
    }
  })

  return [...primaryItems, ...secondaryItems]
}
