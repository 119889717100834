import React, { useLayoutEffect } from "react"
import styled from "styled-components"

import ModalBg from "./../ModalBg/ModalBg"
import ModalFrame from "./../ModalFrame/ModalFrame"

const Wrapper = styled.div<{ y: number }>`
  position: fixed;
  left: 0;
  top: ${props => props.y}px;
  width: 100%;
  height: 100%;
  z-index: 100004;
`

// TODO: closeModal was giving me trouble with a specific type
const ModalBase = ({
  children,
  closeModal
}: {
  children: any
  closeModal: any
}) => {
  useLockBodyScroll()

  const onKeyDown = (e: any) => {
    let keynum: number = 0
    if (window.event) {
      // IE
      keynum = e.keyCode
    } else if (e.which) {
      // Netscape/Firefox/Opera
      keynum = e.which
    }

    if (keynum === 27) {
      closeModal()
    }
  }

  React.useEffect(() => {
    window.addEventListener("keydown", onKeyDown)

    return () => {
      window.removeEventListener("keydown", onKeyDown)
    }
  }, [])
  return (
    <Wrapper y={window.scrollY}>
      <ModalBg closeModal={closeModal} />
      <ModalFrame closeModal={closeModal}>{children}</ModalFrame>
    </Wrapper>
  )
}

const useLockBodyScroll = () => {
  useLayoutEffect((): any => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden"
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle)
  }, []) // Empty array ensures effect is only run on mount and unmount
}

export default ModalBase
