import React, { useState } from "react";
import styled from "styled-components";
import ModalBase from "../../common/Modal/ModalBase/ModalBase";
import {
  ProjectInterface,
  StateInterface,
  StorySectionInterface,
} from "../../utils/Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { currentProject } from "../../utils/State";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import ModalActions from "../../common/Modal/ModalActions/ModalActions";
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton";
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle";

const Supplemental = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  overflow-y: hidden;
`;

const StorySectionTitle = styled.input`
  font-size: 16px;
  padding: 10px;
`;

const EditStorySectionModal = ({
  closeModal,
  storySectionId,
}: {
  closeModal: Function;
  storySectionId: string;
}) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state),
  );
  const storySection = project!.storySections.find(
    (storySection: StorySectionInterface) => storySection.id === storySectionId,
  );

  const [storySectionTitle, setStorySectionTitle] = useState(
    storySection!.title || "",
  );
  const [description, setDescription] = useState(
    storySection!.description || "",
  );
  const dispatch = useDispatch();

  const updateDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.currentTarget.value);
  };

  const updateStorySectionTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStorySectionTitle(e.currentTarget.value);
  };

  const editStorySection = () => {
    const toDispatch = {
      type: "update_story_section",
      value: {
        id: storySectionId,
        title: storySectionTitle,
        description: description,
      },
    };
    dispatch(toDispatch);
    closeModal();
  };

  const deleteStorySection = () => {
    const toDispatch = {
      type: "delete_story_section",
      value: { id: storySectionId },
    };
    dispatch(toDispatch);
    closeModal();
  };

  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Edit Section</ModalTitle>
        <StorySectionTitle
          placeholder="Title"
          autoFocus
          value={storySectionTitle}
          onChange={updateStorySectionTitle}
        />
        <Supplemental
          placeholder="Section Summary"
          onChange={updateDescription}
          defaultValue={description}
        />
        <ModalActions>
          <ModalActionButton
            type="cancel"
            title="Cancel"
            onClick={() => closeModal()}
          >
            <FontAwesomeIcon icon={faBan} /> Cancel
          </ModalActionButton>
          <ModalActionButton
            type="delete"
            title="Delete from Project"
            onClick={deleteStorySection}
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Delete
          </ModalActionButton>
          <ModalActionButton
            type="okay"
            title="Save"
            onClick={editStorySection}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Update
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  );
};

export default EditStorySectionModal;
