import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import AddNoteToSceneModal from "../../../modals/AddNoteToSceneModal/AddNoteToSceneModal"

import EditNoteModal from "../../../modals/EditNoteModal/EditNoteModal"
import {
  currentProject,
  currentSceneObject,
  getConfig
} from "../../../utils/State"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faBoxFull } from "@fortawesome/pro-regular-svg-icons"
import {
  StateInterface,
  Dispatch_UpdateNoteItemInterface,
  NoteMetaInterface,
  MobileOptionsInterface
} from "../../../utils/Interfaces"
import WriteMetaItems from "../WriteMetaItems/WriteMetaItems"
import AssetProgressBar from "../../../common/AssetProgressBar/AssetProgressBar"

const Wrapper = styled.div<{ focusMode: boolean }>`
  @media print {
    visibility: hidden;
  }
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);
  ${props =>
    props.focusMode === true
      ? "visibility: hidden; opacity: 0;"
      : "visibility: visible; opacity: 1;"}
`

const DesktopContent = styled.div<{ active: boolean }>`
  @media (max-width: 768px) {
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    left: ${props => (props.active === true ? "0px" : "-100%")};
    transition: left var(--transition--fast);
    -webkit-transition: left var(--transition--fast);
    background-color: var(--themed-bg-color);

    z-index: 1000;
    min-height: 100%;
    width: 100%;
    border-top: 1px solid var(--themed-subhead-color);
  }
`

const ShowMetaModalPrompt = styled.div`
  color: var(--themed-font-color);
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 4px;

  :hover {
    background-color: var(--themed-light-bg);
  }
`

const AssetMetaWrapper = styled.div<{ oneColumn: boolean }>`
  display: grid;
  grid-template-columns: ${props =>
    props.oneColumn === true ? "1fr" : "45px 1fr"};
  margin-bottom: 10px;
  border-bottom: 1px solid var(--themed-border-color);
  width: calc(100% - 20px);
`

const WriteMeta = () => {
  const [showMetaModal, setShowMetaModal] = useState(false)
  const [showEditNoteModal, setShowEditNoteModal] = useState(false)
  const [editNoteId, setEditNoteId] = useState("")
  const project = useSelector((state: StateInterface) => currentProject(state))
  const config = useSelector((state: StateInterface) => getConfig(state))
  const mobileMenu: MobileOptionsInterface = project!.mobileMenu

  const items = useSelector((state: StateInterface) =>
    currentSceneObject(project)
  ).items

  const dispatch = useDispatch()

  const addItem = ({
    type,
    noteMeta,
    title,
    createNewUser,
    existingUserId
  }: {
    type: string
    noteMeta: NoteMetaInterface
    title: string
    createNewUser: boolean
    existingUserId: string
  }) => {
    const toDispatch = {
      type: "add_item_to_scene",
      value: { type, noteMeta, title, createNewUser, existingUserId }
    }
    dispatch(toDispatch)
    setShowMetaModal(false)
  }

  const updateItem = (updatedNoteItem: Dispatch_UpdateNoteItemInterface) => {
    const toDispatch = {
      type: "update_note_item",
      value: updatedNoteItem
    }
    dispatch(toDispatch)
    setShowEditNoteModal(false)
  }

  const showItemInfo = (id: string) => {
    setEditNoteId(id)
    setShowEditNoteModal(true)
  }

  // TODO: Figure this "any" out.
  const itemToEdit = (id: string) => {
    return items.find((item: any) => item.id === id)
  }

  if (project && project.scenes.length === 0) {
    return <div />
  }

  if (project!.currentScene === "home") {
    return <div />
  }
  return (
    <Wrapper focusMode={config.focusMode}>
      <DesktopContent active={mobileMenu.write.assets === true}>
        <AssetMetaWrapper oneColumn={items.length === 0}>
          <ShowMetaModalPrompt
            title="Add Asset to Scene"
            onClick={() => setShowMetaModal(true)}
          >
            <FontAwesomeIcon icon={faBoxFull} />{" "}
            <FontAwesomeIcon icon={faPlus} />
            {items.length === 0 && <span> ADD ASSET</span>}
          </ShowMetaModalPrompt>
          {items.length > 0 && <AssetProgressBar items={items} sticky={true} />}
        </AssetMetaWrapper>
        <WriteMetaItems items={items} showItemInfo={showItemInfo} />

        {showEditNoteModal === true && (
          <EditNoteModal
            closeModal={() => setShowEditNoteModal(false)}
            doCloseModal={() => setShowEditNoteModal(false)}
            item={itemToEdit(editNoteId)}
            updateItem={updateItem}
            mode={"write"}
            sceneId={project!.currentScene}
          />
        )}

        {showMetaModal === true && (
          <AddNoteToSceneModal
            closeModal={() => setShowMetaModal(false)}
            addItem={addItem}
          />
        )}
      </DesktopContent>
    </Wrapper>
  )
}

export default WriteMeta
