import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 60px;
  margin: 5px 0px 5px 0px;
`
export const AssetTitle = styled.div<{ customColor: string }>`
  position: relative;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  ${props => (props.customColor > "" ? `color: ${props.customColor}; ` : "")}
    :hover {
    background-color: var(--themed-input-focus-color);
  }
`

export const AssetDescription = styled.div`
  font-size: 14px;
  font-style: italic;
`

export const NotUsedInProject = styled.div`
  text-align: right;
  padding: 0px 5px;
  box-sizing: border-box;
  font-size: 20px;
  color: red;
  opacity: 0.3;
`

export const UsesInProject = styled.div`
  font-size: 12px;
  text-align: right;
  span {
    font-weight: bold;
  }
`

export const AssetTitleText = styled.div<{ thumbnail: boolean }>`
  display: inline-block;
  ${props => props.thumbnail === true && `position: absolute; top: 10px;`}
`

export const AssetMeta = styled.div``
