import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { projectById } from "../../utils/State"
import {
  ProjectInterface,
  StateInterface,
  SceneInterface,
} from "../../utils/Interfaces"
import { WordCount, formatWordCount } from "../../utils/Numbers"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIconForAssetType } from "../../utils/Icons"

const Wrapper = styled.div`
  display: grid;
  font-size: 14px;
  grid-template-columns: 1fr 1fr 2fr;
  @media (max-width: 480px) {
    grid-template-columns: 1fr 1fr;
  }
`

const WordCountInfo = styled.div`
  padding: 0px 5px 5px 0px;
  font-size: 13px;
  font-style: italic;
`
const SectionInfo = styled.div`
  font-size: 13px;
  padding: 0px 5px 5px 0px;
`
const LastWorkedOn = styled.div`
  font-size: 13px;
  font-style: italic;
  text-align: right;
  padding: 0px 5px 5px 0px;
  @media (max-width: 480px) {
    font-size: 10px;
    grid-column: 3 / 1;
  }
`

const StorySection = styled.span`
  svg {
    color: var(--storySection-color);
    margin-right: 5px;
  }

  margin-right: 10px;
`

const Scene = styled.span`
  svg {
    color: var(--scene-color);
    margin-right: 5px;
  }
`

const ProjectOverviewMeta = ({ projectId }: { projectId: string }) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => projectById(state, projectId)
  )

  if (!project) {
    return <div />
  }
  return (
    <Wrapper>
      <WordCountInfo>
        {formatWordCount(project!.wordCount, true)}
      </WordCountInfo>
      <SectionInfo>
        <StorySection>
          <FontAwesomeIcon icon={getIconForAssetType("storySection")} />{" "}
          {project!.storySections.length}{" "}
        </StorySection>
        <Scene>
          <FontAwesomeIcon icon={getIconForAssetType("scene")} />{" "}
          {project!.scenes.length}
        </Scene>
      </SectionInfo>
      <LastWorkedOn>
        Last worked on {moment(project!.updated_at).fromNow()}.
      </LastWorkedOn>
    </Wrapper>
  )
}

export default ProjectOverviewMeta
