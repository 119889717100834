import styled from "styled-components"

export const MenuWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-gap: 0px;
  border-bottom: 1px solid var(--themed-subhead-color);
  margin-bottom: 10px;
  grid-template-columns: 50px 100px 1fr 30px 30px 30px;

  @media (max-width: 700px) {
    grid-template-columns: 30px 50px calc(100% - 170px) 30px 30px 30px;
  }

  a {
    font-size: 20px;
    padding: 0px 20px 10px 5px;
    @media (max-width: 700px) {
      padding: 13px 0px 10px 3px;
    }
    text-decoration: none;
    text-align: center;
    transition: var(--rollover-transition--fast);
    -webkit-transition: var(--rollover-transition--fast);
    cursor: pointer;
    font-family: var(--title-font);
    font-weight: 900;
  }

  a:hover {
    color: var(--brand-primary);
    font-weight: 900;
  }

  @media (max-width: 700px) {
    a {
      span {
        display: none;
      }
    }
  }
`

export const Wrapper = styled.div<{ focusMode: boolean }>`
  @media print {
    visibility: hidden;
  }
  width: 100%;
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);
  /* font-family: "EB Garamond", serif; */
  ${(props) =>
    props.focusMode === true
      ? "visibility: hidden; opacity: 0;"
      : "visibility: visible; opacity: 1;"}
`

export const ProjectName = styled.div`
  padding: 9px 0px 24px 0px;
  color: var(--white-color);
  background-color: var(--themed-asset-header-color);
  height: 21px;
  white-space: nowrap;
  margin-right: 7px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  :hover {
    background-color: var(--brand-primary);
    color: var(--themed-font-color);
  }
`

export const ProjectDropDown = styled.div`
  position: absolute;
  padding: 5px;
  box-sizing: border-box;
  background-color: var(--themed-bg-color);
  color: var(--themed-font-color);
  border: 1px solid var(--brand-primary);
  width: calc(100% - 60px);
  z-index: 10000;
`

export const ProjectNameLink = styled.span``

export const HeaderLink = styled.a<{ current: boolean }>`
  ${(props) =>
    props.current === true
      ? "color: var(--brand-primary); font-weight: bold;"
      : "color: var(--themed-header-color) !important;"}
`

export const MenuLinks = styled.div`
  a:first-of-type {
    padding-left: 0px;
  }
`

export const ProjectLink = styled.div``

export const DropDownOptions = styled.div`
  border-top: 1px solid var(--themed-border-color);
  height: 25px;
  line-height: 1em;
  padding-top: 3px;
`

export const DropDownProjectsWrapper = styled.div`
  max-height: 100px;
  max-height: 50vh;
  overflow-y: scroll;
`

export const DropDownProject = styled.div`
  height: 50px;
  font-size: 14px;
  cursor: pointer;
  line-height: 2.7em;
  font-family: var(--title-font);
  font-weight: bold;
  padding: 5px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid var(--themed-border-color);
  :hover {
    color: var(--white-color);
    background-color: var(--brand-primary);
    svg {
      color: var(--white-color);
    }
  }
`

export const AllProjectsLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: 5px;
  text-align: center;
  box-sizing: border-box;
  :hover {
    color: var(--white-color);
    background-color: var(--new-color);
  }
`

export const UserSettings = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 2.65em;
  cursor: pointer;

  :hover {
    color: var(--brand-primary);
  }
`

export const ProjectTitle = styled.div`
  color: var(--themed-asset-header-color);
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 0px;
  margin-bottom: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 402px;
  cursor: pointer;
  @media (max-width: 480px) {
    width: 184px;
  }

  :hover {
    color: var(--brand-primary);
  }
`

export const FeedbackButton = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 2.65em;
  color: var(--brand-primary);
  cursor: pointer;

  :hover {
    color: var(--black-color);
  }
`

export const HelpButton = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 2.65em;
  cursor: pointer;

  :hover {
    color: var(--brand-primary);
  }
`

export const DropDownProjectName = styled.div`
  display: grid;
  grid-template-columns: 1fr 15px;
`

export const IsProjectSelected = styled.span``

export const Logo = styled.div`
  background-image: var(--logo);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  margin: 7px 0px -10px 4.5px;
  border-radius: 4px;

  @media (max-width: 700px) {
    margin: -11px 0px -10px 2px;
    width: 25px;
  }
`
