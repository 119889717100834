import React from "react"
import { ProjectInterface, StateInterface } from "../../utils/Interfaces"
import { navigate } from "@reach/router"
import { projectById } from "../../utils/State"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBook } from "@fortawesome/free-solid-svg-icons"
import ProjectOverviewMeta from "../ProjectOverviewMeta/ProjectOverviewMeta"
import { useSelector } from "react-redux"
import { faCameraMovie } from "@fortawesome/pro-regular-svg-icons"
import {
  Wrapper,
  TopRow,
  ProjectName,
  LeftRow,
  ProjectDescription,
} from "./ProjectOverview.styles"

const ProjectOverview = ({ project }: { project: ProjectInterface }) => {
  const currentProject: ProjectInterface | null = useSelector(
    (state: StateInterface) => projectById(state, state.currentProject)
  )

  return (
    <Wrapper onClick={() => navigate(`/projects/${project.id}`)}>
      <LeftRow current={currentProject! && project.id === currentProject!.id}>
        <FontAwesomeIcon
          icon={project.config.type === "screenplay" ? faCameraMovie : faBook}
        />
      </LeftRow>
      <TopRow>
        <ProjectName
          current={currentProject! && project.id === currentProject!.id}
        >
          {project.name}
        </ProjectName>
        <ProjectOverviewMeta projectId={project.id} />
        <ProjectDescription>{project.description}</ProjectDescription>
      </TopRow>
    </Wrapper>
  )
}

export default ProjectOverview
