import React from "react";
import styled from "styled-components";

const Wrapper = styled.div<{ type: string }>`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  /* color: var(--themed-font-color); */
  color: var(--white-color);
  ${(props) => props.type !== "disabled" && "cursor: pointer;"}
  text-align: center;
  padding: 10px;
  transition: var(--rollover-transition--fast);
  -webkit-transition: var(--rollover-transition--fast);
  color: ${(props) => `var(--${props.type}-button-color)`};
  :hover {
    color: ${(props) => `var(--${props.type}-button-color--hover)`};
  }
`;
const ModalActionButton = ({
  type,
  children,
  onClick,
  title,
}: {
  type: string;
  children: any;
  onClick: any;
  title?: string;
}) => {
  return (
    <Wrapper type={type} onClick={onClick} title={title}>
      {children}
    </Wrapper>
  );
};

export default ModalActionButton;
