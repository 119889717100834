import React, { useState } from "react"
import styled from "styled-components"
import {
  Draggable,
  DraggableProvided,
  DroppableProvided,
  Droppable,
} from "react-beautiful-dnd"
import {
  StorySectionInterface,
  ShallowSceneInterface,
  NoteMetaInterface,
  StateInterface,
  ConfigObjectInterface,
} from "../../../utils/Interfaces"
import SceneInStorySection from "./../SceneInStorySection/SceneInStorySection"
import { useDispatch, useSelector } from "react-redux"
import AddSceneModal from "./../../../modals/AddSceneModal/AddSceneModal"
import { getConfig } from "../../../utils/State"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { faEdit, faPlusCircle } from "@fortawesome/pro-regular-svg-icons"

const Container = styled.div`
  border: 1px solid var(--themed-light-bg);
  border-radius: 8px;
  padding: 5px;
  margin: 5px 5px;
  background-color: var(--themed-bg-color);
  color: var(--themed-font-color);
  min-width: 50px;
`

const NoScenesInSection = styled.div`
  font-size: 14px;
  text-align: center;
  color: var(--themed-no-content-color);
  min-height: 291px;
  padding: 26px 10px 10px 10px;
  box-sizing: border-box;
  cursor: pointer;

  :hover {
    color: var(--themed-font-color);
  }
`

const NoScenesTitle = styled.div`
  color: var(--themed-no-content-color);
  height: 34px;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  line-height: 2.2em;
`

const Scenes = styled.div<{ plotScale: number; snap: boolean }>`
  display: flex;
  margin: 10px 0px 0px 0px;
  ${(props) =>
    props.snap !== true &&
    `width: calc(100% * ${props.plotScale});
  `}
`

const Footer = styled.div`
  text-align: right;
  margin-top: -6px;
`

const AddSceneLink = styled.div<{ plotScale: number }>`
  ${(props) => (props.plotScale < 0.4 ? "span { display: none; }" : "")}
  cursor: pointer;
  text-align: right;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--themed-bg-color);
  background-color: var(--themed-font-color);
  border-radius: 0px 0px 8px 8px;
  padding: 2px 5px;
  font-weight: bold;

  :hover {
    background-color: var(--brand-primary);
  }
`

const StorySectionDescription = styled.div`
  height: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
  font-style: italic;
  background-color: var(--themed-bg-color);
  color: var(--themed-font-color);
`

const Flexer = styled.div`
  display: flex;
`

const StorySectionTitle = styled.span`
  font-weight: bold;
  display: grid;
  color: var(--themed-font-color);
  grid-template-columns: 22px 1fr 22px;
`

const StorySectionEdit = styled.span`
  cursor: pointer;
  color: var(--themed-active-scene-color);
  :hover {
    color: var(--themed-font-color);
  }
`

const StorySectionTitleText = styled.div<{ plotScale: number }>`
  /* width: ${(props) => `calc(166px * ${props.plotScale})`}; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ShowDescriptionToggle = styled.span`
  cursor: pointer;
  color: var(--themed-active-scene-color);
  padding: 0px 5px;

  :hover {
    color: var(--themed-font-color);
  }
`

const StorySection = ({
  index,
  id,
  storySection,
  showEditModal,
  filters,
}: {
  index: number
  id: string
  title: string
  storySection: StorySectionInterface
  showEditModal: Function
  filters: Array<string>
}) => {
  const dispatch = useDispatch()
  const addSceneToStorySection = ({
    sceneTitle,
    noteMeta,
    createNewScene,
    existingSceneId,
  }: {
    sceneTitle: string
    noteMeta: NoteMetaInterface
    createNewScene: boolean
    existingSceneId: string
  }) => {
    const toDispatch = {
      type: "create_scene",
      value: {
        scene: {
          title: sceneTitle,
          noteMeta: noteMeta,
          sectionId: id,
          createNewScene,
          existingSceneId,
        },
        redirectToScene: false,
      },
    }

    dispatch(toDispatch)
    setShowScenePrompt(false)
  }

  const [scenes, setScenes] = useState<Array<ShallowSceneInterface>>([])
  React.useEffect(() => {
    setScenes(storySection!.scenes)
  }, [storySection])

  const [showAddScenePrompt, setShowScenePrompt] = useState(false)

  const showDescription = useSelector(
    (state: StateInterface) => getConfig(state) as ConfigObjectInterface
  ).showDescription

  const toggleShowDescription = () => {
    const toDispatch = {
      type: "toggle_show_description",
      value: { showDescription: showDescription },
    }

    dispatch(toDispatch)
  }

  const config = useSelector((state: StateInterface) => getConfig(state))

  return (
    <Draggable draggableId={id} index={index}>
      {(provided: DraggableProvided) => (
        <Container {...provided.draggableProps} ref={provided.innerRef}>
          <StorySectionTitle title={storySection.title}>
            <StorySectionEdit onClick={() => showEditModal()}>
              <FontAwesomeIcon icon={faEdit} />
            </StorySectionEdit>
            <StorySectionTitleText
              plotScale={config.plotScale}
              {...provided.dragHandleProps}
            >
              {storySection.title}
            </StorySectionTitleText>
            <ShowDescriptionToggle onClick={toggleShowDescription}>
              {showDescription === true && (
                <FontAwesomeIcon icon={faCaretDown} />
              )}
              {showDescription === false && (
                <FontAwesomeIcon icon={faCaretRight} />
              )}
            </ShowDescriptionToggle>
          </StorySectionTitle>

          {showDescription === true && (
            <StorySectionDescription>
              {storySection.description}
            </StorySectionDescription>
          )}
          <Scenes
            plotScale={config.plotScale}
            snap={config.plotScaleSnap || true}
          >
            {scenes.length > 0 && (
              <Droppable
                droppableId={`story-section-in-plot-${storySection!.id}`}
                direction="horizontal"
                type="droppableScene"
              >
                {(provided: DroppableProvided) => (
                  <Flexer
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: "100%", minHeight: "50px" }}
                  >
                    {scenes.map((scene: ShallowSceneInterface, index) => {
                      if (!scene) {
                        return <div key={Math.random() * 100000000} />
                      }
                      return (
                        <Draggable
                          draggableId={`${scene.id}`}
                          key={`section-${storySection!.id}-scene-${
                            scene.id
                          }-draggable`}
                          index={index}
                        >
                          {(provided: DraggableProvided) => (
                            <div
                              style={
                                config.plotScaleSnap === true
                                  ? { width: `${(1 / scenes.length) * 100}%` }
                                  : {}
                              }
                            >
                              <div
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <SceneInStorySection
                                  key={`character-in-story-section${scene.id}`}
                                  sceneId={scene.id}
                                  handle={provided.dragHandleProps}
                                  filters={filters}
                                  storySection={storySection!}
                                  numberOfScenes={scenes.length}
                                />
                                {provided.placeholder}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </Flexer>
                )}
              </Droppable>
            )}
            {scenes.length === 0 && (
              <div>
                <NoScenesTitle>Empty Section</NoScenesTitle>
                <NoScenesInSection onClick={() => setShowScenePrompt(true)}>
                  Click here to add the first scene
                  <br />
                  to this section.
                  <br />
                  <br /> Click the "Add Scene{" "}
                  <FontAwesomeIcon icon={faPlusCircle} />" button
                  <br />
                  below to create more.
                </NoScenesInSection>
              </div>
            )}
          </Scenes>
          {config.plotMode === "edit" && (
            <Footer>
              <AddSceneLink
                plotScale={config.plotScale}
                onClick={() => setShowScenePrompt(true)}
                title="Add Scene"
              >
                Add Scene <FontAwesomeIcon icon={faPlusCircle} />
              </AddSceneLink>
            </Footer>
          )}

          {showAddScenePrompt === true && (
            <AddSceneModal
              closeModal={() => setShowScenePrompt(false)}
              createScene={addSceneToStorySection}
            />
          )}
        </Container>
      )}
    </Draggable>
  )
}

export default StorySection
