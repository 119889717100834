import React, { useState } from "react"
import { Router } from "@reach/router"
import Home from "../views/Home/Home"
import Assets from "../views/Assets/Assets"
import AssetsIndex from "../views/AssetsIndex/AssetsIndex"
import Note from "../views/Note/Note"
import NoteIndex from "../views/NoteIndex/NoteIndex"
import NotFound from "../views/NotFound/NotFound"
import About from "../views/About/About"
import Plot from "../views/Plot/Plot"
import Write from "../views/Write/Write"
import { useSelector } from "react-redux"
import { StateInterface } from "../utils/Interfaces"
import Projects from "../views/Projects/Projects"
import ProjectsIndex from "../views/ProjectsIndex/ProjectsIndex"
import ProjectActivities from "../views/ProjectActivities/ProjectActivities"
import User from "../views/User/User"
import Help from "../views/Help/Help"
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy"
import TermsOfService from "../views/TermsOfService/TermsOfService"
import ImportFile from "../views/ImportFile/ImportFile"

// ScrollToTop.js
export const ScrollToTop = ({
  children,
  path,
  location,
}: {
  children?: any
  path?: any
  location?: any
}) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname])
  return children
}

const App: React.FC = () => {
  const projects = useSelector((state: StateInterface) => state.projects)

  return (
    <Router primary={false}>
      <ScrollToTop path="/">
        <Home path="/" />
        {projects.length > 0 && <NoteIndex path="note" />}
        {projects.length > 0 && <Note path="note/:noteId" />}
        {projects.length > 0 && <Plot path="plot" />}
        {projects.length > 0 && <AssetsIndex path="/assets" />}
        {projects.length > 0 && <AssetsIndex path="/assets/:assetType" />}
        {projects.length > 0 && <Assets path="/assets/:assetType/:assetId" />}
        {projects.length > 0 && <Write path="write" />}
        {projects.length > 0 && <Write path="write/:sceneId" />}
        <ImportFile path="import" />
        <ProjectsIndex path="projects/" />
        <ProjectsIndex path="projects/new" />
        {projects.length > 0 && <Projects path="projects/:projectId" />}
        {projects.length > 0 && <Write path="projects/:projectId/write" />}
        {projects.length > 0 && (
          <Write path="projects/:projectId/write/:sceneId" />
        )}
        {projects.length > 0 && <NoteIndex path="projects/:projectId/note" />}
        {projects.length > 0 && (
          <Note path="projects/:projectId/note/:noteId" />
        )}
        {projects.length > 0 && <Plot path="projects/:projectId/plot" />}
        {projects.length > 0 && (
          <AssetsIndex path="projects/:projectId/assets" />
        )}
        {projects.length > 0 && (
          <AssetsIndex path="projects/:projectId/assets/:assetType" />
        )}
        {projects.length > 0 && (
          <Assets path="projects/:projectId/assets/:assetType/:assetId" />
        )}
        {projects.length > 0 && (
          <ProjectActivities path="projects/:projectId/activities" />
        )}
        <Help path="/help" />
        <TermsOfService path="/termsofservice" />
        <PrivacyPolicy path="/privacypolicy" />
        <About path="/about" />
        <About path="/beta" />
        <User path="user" />
        <NotFound default />
      </ScrollToTop>
    </Router>
  )
}

export default App
