import React, { useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBook,
  faPlus,
  faBan,
  faCheck,
} from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import { faCameraMovie, faFileImport } from "@fortawesome/pro-regular-svg-icons"
import {
  Wrapper,
  TopRow,
  LeftRow,
  NewProjectWrapper,
  NewProjectFile,
  NewProjectTitle,
  NewProjectTypeOption,
  NewProjectTemplateSelect,
  SupportedTypes,
  ImportError,
  ImportLinkWrapper,
  JSONError,
  NewProjectOption,
  ProjectTypeOptions,
  ProjectTypeOption,
} from "./ProjectCreate.styles"
import { sanitizeFileName, isValidFileForImport } from "../../utils/Strings"
import { ProjectConfigObjectInterface } from "../../utils/Interfaces"
const mammoth = require("mammoth")

const CreateProject = () => {
  const [projectType, setProjectType] = useState<
    "" | "manuscript" | "screenplay"
  >("")
  const [showJSONError, setShowJSONError] = useState(false)
  const [projectName, setProjectName] = useState("")
  const [showImport, setShowImport] = useState(false)
  const [newProjectTemplate, setNewProjectTemplate] = useState("")
  const [filenameForImport, setFilenameForImport] = useState("")

  const [showCreateOptions, setShowCreateOptions] = useState(false)
  const dispatch = useDispatch()

  const createNewProject = () => {
    const rightNow: Date = new Date()
    const blankProjectName: string = `New Project (${rightNow.getMonth()}-${rightNow.getDay()}-${rightNow.getFullYear()})`

    let newProjectConfig: ProjectConfigObjectInterface = {
      type: projectType === "screenplay" ? "screenplay" : "manuscript",
      lineSpacing: 1.5,
      render: "html",
      pageBreakBetweenScenes: false,
      pageBreakBetweenSections: true,
      printNotes: "",
      printTitlePage: true,
    }

    if (projectType === "screenplay") {
      newProjectConfig.type = "screenplay"
      newProjectConfig.lineSpacing = 1
    }

    const toDispatch = {
      type: "create_project",
      value: {
        name: projectName > "" ? projectName : blankProjectName,
        config: newProjectConfig,
        template: newProjectTemplate,
      },
    }

    dispatch(toDispatch)
    setProjectName("")
    setNewProjectTemplate("")
    setShowCreateOptions(false)
  }

  const importHandler = (e: any) => {
    e.preventDefault()

    const files: HTMLElement | null = document.getElementById("csvImport")
    let filesToUpload = (files as HTMLInputElement | null)!.files
    if (filesToUpload!.length <= 0) {
      return false
    }

    const fReader: FileReader = new FileReader()
    const fileToLoad: File = (filesToUpload as FileList)[0]

    fReader.onload = (e: any) => {
      if (fileToLoad.name.indexOf(".fdx") > -1) {
        const toDispatch = {
          type: "save_file_for_import",
          value: {
            fileName: sanitizeFileName(fileToLoad.name),
            contents: e.target.result,
            type: "fdx",
          },
        }

        dispatch(toDispatch)
      } else if (fileToLoad.name.indexOf(".fountain") > -1) {
        const toDispatch = {
          type: "save_file_for_import",
          value: {
            fileName: sanitizeFileName(fileToLoad.name),
            contents: e.target.result,
            type: "fountain",
          },
        }

        dispatch(toDispatch)
      } else if (fileToLoad.name.indexOf(".txt") > -1) {
        const toDispatch = {
          type: "import_txt_file",
          value: {
            fileName: sanitizeFileName(fileToLoad.name),
            content: e.target.result,
          },
        }

        setShowCreateOptions(false)
        dispatch(toDispatch)
      } else if (fileToLoad.name.indexOf(".docx") > -1) {
        mammoth
          .convertToMarkdown({ arrayBuffer: e.target.result })
          .then((result: any) => {
            var text = result.value // The generated HTML
            var messages = result.messages // Any messages, such as warnings during conversion

            const toDispatch = {
              type: "import_docx_file",
              value: {
                fileName: sanitizeFileName(fileToLoad.name),
                content: result.value,
              },
            }
            setShowCreateOptions(false)
            dispatch(toDispatch)
          })
          .done()
      } else if (fileToLoad.name.indexOf(".json") > -1) {
        try {
          var result = JSON.parse(e.target.result)

          if (result.name && result.storySections) {
            const toDispatch = {
              type: "import_project",
              value: result,
            }

            setShowCreateOptions(false)
            dispatch(toDispatch)
            setShowJSONError(false)
          } else {
            setShowJSONError(true)
          }
        } catch (e) {
          console.error("Error parsing provided JSON file")
          console.error(e)
          setShowJSONError(true)
        }
      }
    }

    if (fileToLoad.name.indexOf(".docx") > -1) {
      fReader.readAsArrayBuffer(fileToLoad)
    } else {
      fReader.readAsText(fileToLoad)
    }
  }

  const toggleCreateOptions = () => {
    setProjectName("")
    setNewProjectTemplate("")
    setProjectType("")
    setShowImport(false)
    if (showCreateOptions === false) {
      setShowCreateOptions(true)
      return
    }

    setShowCreateOptions(false)
  }

  const enterShowImportMode = () => {
    setShowImport(true)
    setProjectName("")
    setProjectType("")
    setNewProjectTemplate("")
  }

  const newProjectTemplates: Array<any> = [
    { value: "", manuscript: "No Template", screenplay: "No Template" },
    {
      value: "1act",
      manuscript: "Short Story - One Act",
      screenplay: "Short Film - One Act",
    },
    {
      value: "3act",
      manuscript: "Novel - Three Acts",
      screenplay: "Full-Length Film - Three Acts",
    },
    { value: "3act", screenplay: "30-Minute Episodic - Three Acts" },
    { value: "4act", screenplay: "Hour-Long - Four Acts" },
    { value: "5act", screenplay: "Hour-Long - Five Acts" },
  ]

  return (
    <Wrapper>
      <LeftRow onClick={toggleCreateOptions}>
        {showCreateOptions === false && <FontAwesomeIcon icon={faPlus} />}
        {showCreateOptions === true && <FontAwesomeIcon icon={faBan} />}
      </LeftRow>
      <TopRow>
        {showCreateOptions === false && (
          <NewProjectOption onClick={() => setShowCreateOptions(true)}>
            Create New Project
          </NewProjectOption>
        )}
        {showCreateOptions === true &&
          projectType === "" &&
          showImport === false && (
            <ProjectTypeOptions>
              <ProjectTypeOption onClick={() => setProjectType("manuscript")}>
                <FontAwesomeIcon icon={faBook} />
                <br />
                Manuscript
              </ProjectTypeOption>
              <ProjectTypeOption onClick={() => setProjectType("screenplay")}>
                <FontAwesomeIcon icon={faCameraMovie} />
                <br />
                Screenplay
              </ProjectTypeOption>
              <ProjectTypeOption onClick={enterShowImportMode}>
                <FontAwesomeIcon icon={faFileImport} />
                <br />
                Import
              </ProjectTypeOption>
            </ProjectTypeOptions>
          )}
        {showCreateOptions === true &&
          projectType !== "" &&
          showImport === false && (
            <NewProjectWrapper>
              <NewProjectTitle
                type="text"
                placeholder="Enter New Project Name"
                onChange={(e: any) => setProjectName(e.currentTarget.value)}
              />
              <NewProjectTypeOption
                title="Create Project"
                onClick={() => createNewProject()}
              >
                <FontAwesomeIcon icon={faCheck} />
              </NewProjectTypeOption>
              <NewProjectTemplateSelect
                value={newProjectTemplate}
                onChange={(e) => setNewProjectTemplate(e.currentTarget.value)}
              >
                {newProjectTemplates
                  .filter((t: any) => t[projectType])
                  .map((option: any, index: number) => {
                    return (
                      <option
                        key={`project-template-key-${option.value}-${index}`}
                        value={option.value}
                      >
                        {option[projectType]}
                      </option>
                    )
                  })}
              </NewProjectTemplateSelect>
            </NewProjectWrapper>
          )}
        {showCreateOptions === true && showImport === true && (
          <NewProjectWrapper>
            <div>
              <NewProjectFile
                type="file"
                id="jsonImport"
                onChange={(e: any) => setFilenameForImport(e.target.value)}
              />
              <SupportedTypes>
                Import Text (.txt) files, Office DocX Files (.docx), Final Draft
                (.fdx) and Fountain (.fountain) projects, as well as Hilker
                exports (.json).
              </SupportedTypes>

              {isValidFileForImport(filenameForImport) === false &&
                filenameForImport > "" && (
                  <ImportError>
                    In order to import a project, you must select a valid file.
                  </ImportError>
                )}
            </div>
            <ImportLinkWrapper>
              {isValidFileForImport(filenameForImport) && (
                <NewProjectTypeOption
                  id="jsonImportButton"
                  onClick={(e: any) => {
                    if (isValidFileForImport(filenameForImport) === true)
                      importHandler(e)
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} />{" "}
                </NewProjectTypeOption>
              )}
            </ImportLinkWrapper>
          </NewProjectWrapper>
        )}
        {showJSONError === true && (
          <JSONError>
            There was an error with the file you provided; unable to import.
          </JSONError>
        )}
      </TopRow>
    </Wrapper>
  )
}

export default CreateProject
