import React, { MouseEventHandler } from "react"
import styled from "styled-components"
import ModalBase from "../../common/Modal/ModalBase/ModalBase"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons"
import ProjectGoals from "../../components/Projects/ProjectGoals/ProjectGoals"

const Screenshot = styled.img`
  width: 100%;
`

const ProjectGoalsModal = ({
  closeModal,
}: {
  closeModal: MouseEventHandler
}) => {
  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <h2>Project Goals: </h2>
        <ProjectGoals modal={true} />

        <ModalActions>
          <ModalActionButton type="okay" title="Okay" onClick={closeModal}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  )
}

export default ProjectGoalsModal
