import React, { useState } from "react"
import styled from "styled-components"
import Header from "../../common/Header/Header"
import { useSelector, useDispatch } from "react-redux"
import { StateInterface } from "../../utils/Interfaces"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFile,
  faFolder,
  faFolderPlus,
  faTrashAlt,
  faCheckCircle,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons"
import { faFilePlus } from "@fortawesome/pro-regular-svg-icons"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import Spacer from "../../common/Spacer/Spacer"
import { Link } from "@reach/router"
const fountain = require("../../utils/Fountain")
const Wrapper = styled.div``

interface ImportFileProps {
  path?: string
}

const ImportLogic = styled.div<{
  sceneActive: boolean
  sectionActive: boolean
}>`
  padding: 0px 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 50px 50px 1fr;
  ${(props) =>
    (props.sectionActive === true || props.sceneActive === true) &&
    "margin-top: 20px;"}
`

const Content = styled.div`
  padding: 10px;
  a {
    color: var(--brand-primary) !important;
  }
`

const SectionHere = styled.div<{ sectionActive: boolean }>`
  ${(props) =>
    props.sectionActive === true &&
    `grid-column: 1 / -1; border-bottom: 1px solid var(--themed-asset-header-color);  display: grid; grid-template-columns: 50px 1fr;`}
  cursor: pointer;
`

const SceneHere = styled.div<{ sceneActive: boolean }>`
  ${(props) =>
    props.sceneActive === true
      ? `border-bottom: 1px solid var(--themed-subhead-color); margin-bottom: 5px; grid-column: 2 / -1; display: grid; grid-template-columns: 50px 1fr;`
      : `grid-column: 2;`}
  cursor: pointer;
`

const NoItem = styled.span`
  color: var(--themed-no-content-color);
`

const Shot = styled.div`
  font-family: "Courier Prime", monospace;
  text-transform: uppercase;
  grid-column: 3;
  min-height: 24px;
`

const ImportName = styled.input`
  margin: 20px 0px;
  font-size: 20px;
`

const Snippet = styled.div`
  color: var(--themed-snippet-color);
  text-transform: none;
  max-width: 900px;
`

const Ellipsis = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: var(--themed-snippet-color);
`

const SectionTitle = styled.input``
const SceneTitle = styled.input``

const ImportFile = (props: ImportFileProps) => {
  const fileData = useSelector((state: StateInterface) => state.fileForImport)

  const parser = new DOMParser()
  let xmlDoc: Document
  let initialFileTitle: string = ""

  if (fileData && fileData!.contents) {
    if (fileData.type === "fdx") {
      xmlDoc = parser.parseFromString(fileData!.contents as string, "text/xml")
    }
    initialFileTitle = fileData!.fileName
  }

  const [fileTitle, setFileTitle] = useState(initialFileTitle)
  const [fileElements, setFileElements] = useState([])

  const [sceneAt, setSceneAt] = useState<Array<any>>([
    { id: 0, title: "Scene 1" },
  ])
  const [sectionAt, setSectionAt] = useState<Array<any>>([
    { id: 0, title: "Act 1" },
  ])

  const dispatch = useDispatch()

  const toggleSceneAt = (index: number) => {
    if (index === 1) return
    if (isSectionAt(index)) return
    let newScenes: Array<any>
    if (sceneAt.find((scene: any) => scene.id === index)) {
      newScenes = [...sceneAt.filter((scene: any) => scene.id !== index)]
    } else {
      newScenes = [...sceneAt, { id: index, title: "" }]
    }
    setSceneAt(newScenes)
  }

  const generateSnippet = (index: number) => {
    let snippet: Array<any> = []
    const potentialElements: Array<any> = (fileElements as Array<any>).slice(
      index,
      index + 4
    )
    potentialElements.forEach((element: any) => {
      if (element.type !== "screenplay-scene") {
        snippet.push(element)
      }
    })

    return snippet
  }

  const toggleSectionAt = (index: number) => {
    if (index === 1) return
    let newSections: Array<any>
    if (sectionAt.find((section: any) => section.id === index)) {
      newSections = [
        ...sectionAt.filter((section: any) => section.id !== index),
      ]
    } else {
      newSections = [...sectionAt, { id: index, title: "" }]
      if (!isSceneAt(index)) {
        toggleSceneAt(index)
      }
    }

    setSectionAt(newSections)
  }

  const updateSectionTitle = (index: number, newTitle: string) => {
    let newSections: Array<any> = [
      ...sectionAt.filter((section: any) => section.id !== index),
      { id: index, title: newTitle },
    ]
    setSectionAt(newSections)
  }
  const updateSceneTitle = (index: number, newTitle: string) => {
    let newScenes: Array<any> = [
      ...sceneAt.filter((scene: any) => scene.id !== index),
      { id: index, title: newTitle },
    ]
    setSceneAt(newScenes)
  }

  const isSectionAt = (index: number) => {
    if (sectionAt.find((section: any) => section.id === index)) {
      return true
    } else {
      return false
    }
  }

  const isSceneAt = (index: number) => {
    if (sceneAt.find((scene: any) => scene.id === index)) {
      return true
    } else {
      return false
    }
  }

  const screenplayElements: any = []

  const newElementType = (paragraphType: string) => {
    switch (paragraphType.toLowerCase()) {
      case "general":
        return "screenplay-action"
      case "scene heading":
        return "screenplay-scene"
      case "scene_heading":
        return "screenplay-scene"
      case "action":
        return "screenplay-action"
      case "character":
        return "screenplay-character"
      case "transition":
        return "screenplay-transition"
      case "dialogue":
        return "screenplay-dialogue"
      case "parenthetical":
        return "screenplay-parenthetical"
    }

    return "screenplay-action"
  }

  const newElementValue = (paragraph: any) => {
    const elementValue: Array<string> = []

    const textElements = paragraph.getElementsByTagName("Text")

    for (var i = 0; i < textElements.length; i++) {
      elementValue.push(textElements[i].innerHTML)
    }

    return elementValue.join("")
  }

  const sanitizeElementValue = (type: string, paragraph: any) => {
    if (type !== "parenthetical") return paragraph
    return paragraph.replace("(", "").replace(")", "")
  }

  const cancelImport = () => {
    const toDispatch = {
      type: "cancel_file_import",
    }

    dispatch(toDispatch)
  }

  const confirmImport = () => {
    const toDispatch = {
      type: "import_file_project",
      value: {
        sections: sectionAt,
        title: fileTitle,
        scenes: sceneAt,
        elements: fileElements,
      },
    }

    dispatch(toDispatch)
  }

  React.useEffect(() => {
    if (fileData === undefined || !fileData.contents) return
    if (fileData.type === "fdx") {
      const paragraphs: HTMLCollectionOf<Element> = xmlDoc.getElementsByTagName(
        "Content"
      )[0].children
      for (var i = 0; i < paragraphs.length; i++) {
        let paragraphHasType: boolean = false
        let paragraphType: string = ""
        for (var j = 0; j < paragraphs[i].attributes.length; j++) {
          if (paragraphs[i].attributes[j].name === "Type") {
            paragraphHasType = true
            paragraphType = paragraphs[i].attributes[j].value

            const newScreenplayElement: any = {
              type: newElementType(paragraphType),
              value: newElementValue(paragraphs[i]),
            }
            screenplayElements.push(newScreenplayElement)
          }
        }
      }
    } else if (fileData.type === "fountain") {
      fountain.parse(fileData.contents, true, (output: any) => {
        output.tokens.forEach((token: any) => {
          if (
            token.type !== "dialogue_begin" &&
            token.type !== "dialogue_end"
          ) {
            const newScreenplayElement: any = {
              type: newElementType(token.type),
              value: sanitizeElementValue(token.type, token.text),
            }

            screenplayElements.push(newScreenplayElement)
          }
        })
      })
    }

    setFileElements(screenplayElements)
  }, [])

  if (!fileData || fileData === undefined || !fileData.contents) {
    return (
      <Wrapper>
        <Header />
        <Content>
          <div>
            No Import File Data found.{" "}
            <Link to={`/projects`}>
              Return to the Projects Page to re-import.
            </Link>
          </div>
        </Content>
      </Wrapper>
    )
  }
  return (
    <Wrapper>
      <Header />
      <Content>
        <div>
          Click the Section (<FontAwesomeIcon icon={faFolderPlus} />) and Scene
          (
          <FontAwesomeIcon icon={faFilePlus} />) buttons to determine where new
          Sections and Scenes begin. You can change the names to whatever you
          like.
        </div>

        <div>
          <ImportName
            type="text"
            placeholder="Enter Script Title"
            value={fileTitle}
            onChange={(e) => setFileTitle(e.currentTarget.value)}
          />
        </div>

        <div>
          {fileElements.map((element: any, index: number) => {
            if (
              index === 0 ||
              (index > 0 &&
                element.type === "screenplay-scene" &&
                element.value.indexOf("INTERCUT") != 0 &&
                (element.value.indexOf("INT") === 0 ||
                  element.value.indexOf("EXT") === 0))
            ) {
              return (
                <div key={`import-logic-${index}`}>
                  <ImportLogic
                    sceneActive={isSceneAt(index)}
                    sectionActive={isSectionAt(index)}
                  >
                    <SectionHere
                      sectionActive={isSectionAt(index)}
                      title="Click to determine where sections start"
                    >
                      <div onClick={() => toggleSectionAt(index)}>
                        {isSectionAt(index) ? (
                          <FontAwesomeIcon icon={faFolder} />
                        ) : (
                          <NoItem>
                            <FontAwesomeIcon icon={faFolderPlus} />
                          </NoItem>
                        )}
                      </div>
                      {isSectionAt(index) && (
                        <div>
                          <SectionTitle
                            defaultValue={`Act ${index + 1}`}
                            onChange={(e) =>
                              updateSectionTitle(index, e.currentTarget.value)
                            }
                          />
                        </div>
                      )}
                    </SectionHere>
                    <SceneHere
                      sceneActive={isSceneAt(index)}
                      title="Click to determine where scenes go"
                    >
                      <div onClick={() => toggleSceneAt(index)}>
                        {isSceneAt(index) ? (
                          <FontAwesomeIcon icon={faFile} />
                        ) : (
                          <NoItem>
                            <FontAwesomeIcon icon={faFilePlus} />
                          </NoItem>
                        )}
                      </div>
                      <div>
                        {isSceneAt(index) && (
                          <SceneTitle
                            defaultValue={`Scene ${index + 1}`}
                            onChange={(e) =>
                              updateSceneTitle(index, e.currentTarget.value)
                            }
                          />
                        )}
                      </div>
                    </SceneHere>
                    <Shot>
                      {element.value}

                      <Snippet>
                        {generateSnippet(index).map(
                          (item: any, index: number) => {
                            return (
                              <p className={item.type} key={`snippet-${index}`}>
                                {item.value}
                              </p>
                            )
                          }
                        )}
                      </Snippet>
                      <Ellipsis>
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </Ellipsis>
                    </Shot>
                  </ImportLogic>
                </div>
              )
            }
          })}
        </div>
        <Spacer width="100%" height="40px" />
        <ModalActions>
          <ModalActionButton
            type="delete"
            title="Cancel Import"
            onClick={cancelImport}
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Cancel
          </ModalActionButton>
          <ModalActionButton type="okay" title="Import" onClick={confirmImport}>
            <FontAwesomeIcon icon={faCheckCircle} /> Complete Import
          </ModalActionButton>
        </ModalActions>
        <Spacer width={"100%"} height={"80px"} />
      </Content>
    </Wrapper>
  )
}

export default ImportFile
