import React from "react"
import SubHeader from "../../../common/SubHeader/SubHeader"
import { navigate } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIconForAssetType } from "../../../utils/Icons"
import {
  Wrapper,
  AssetTypeHeader,
  AssetTypeHeaderIcon,
  AssetTypeHeaderTitle
} from "./SubHeaderAssets.styles"
import { ProjectInterface, StateInterface } from "../../../utils/Interfaces"
import { useSelector } from "react-redux"
import { currentProject } from "../../../utils/State"
import { assetTypes } from "../../../utils/Assets"
import { faBoxFull } from "@fortawesome/pro-regular-svg-icons"

interface SubHeaderAssetsProps {
  children?: any
}

const SubHeaderAssets = (props: SubHeaderAssetsProps) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )

  const navigateTo = async (type: string) => {
    if (type === "") {
      await navigate(`/projects/${project!.id}/assets`)
    } else {
      await navigate(`/projects/${project!.id}/assets/${type}`)
    }
  }

  return (
    <SubHeader>
      <Wrapper>
        <AssetTypeHeader
          type={""}
          onClick={() => navigateTo("")}
          active={window.location.pathname.indexOf("/assets/") === -1}
          key={`asset-type-header-home`}
          title={`Click to see all assets`}
        >
          <AssetTypeHeaderIcon
            type={""}
            active={false}
          >
            <FontAwesomeIcon icon={faBoxFull} />
          </AssetTypeHeaderIcon>
          <AssetTypeHeaderTitle>All</AssetTypeHeaderTitle>
        </AssetTypeHeader>
        {assetTypes.map((assetType: string) => {
          return (
            <AssetTypeHeader
              type={assetType}
              onClick={() => navigateTo(assetType)}
              // active={assetType === selectedAssetType}
              active={window.location.pathname.indexOf(assetType) > -1}
              key={`asset-type-header-${assetType}`}
              title={`Click to see ${assetType.substr(0, 1).toUpperCase() +
                assetType.substr(1)}s`}
            >
              <AssetTypeHeaderIcon
                type={assetType}
                active={false}
                // active={assetType === selectedAssetType}
              >
                <FontAwesomeIcon icon={getIconForAssetType(assetType)} />
              </AssetTypeHeaderIcon>
              <AssetTypeHeaderTitle>{assetType}s</AssetTypeHeaderTitle>
            </AssetTypeHeader>
          )
        })}
      </Wrapper>
    </SubHeader>
  )
}

export default SubHeaderAssets
