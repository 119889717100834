import React, { useState } from "react"
import styled from "styled-components"
import {
  ProjectInterface,
  StateInterface,
  ActivityInterface,
  SceneInterface
} from "../../../utils/Interfaces"
import { useSelector } from "react-redux"
import {
  currentProject,
  currentScene,
  currentSceneObject
} from "../../../utils/State"
import ActivityInfo from "../../Projects/ActivityInfo/ActivityInfo"
import { Link } from "@reach/router"
import Spacer from "../../../common/Spacer/Spacer"
import ProjectMeta from "../../../common/ProjectMeta/ProjectMeta"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPrint } from "@fortawesome/free-solid-svg-icons"
import PrintModal from "../../../modals/PrintModal/PrintModal"

const Wrapper = styled.div``

const ViewAllLink = styled.div`
  a {
    color: var(--brand-primary);
  }
`

const ProjectName = styled.div`
  font-size: 18px;
  padding: 0p 0px 5px 0px;
  font-family: var(--title-font);
  color: var(--themed-font-color);
  font-weight: bold;
`

const PrintOption = styled.span`
  font-size: 16px;
  color: var(--themed-font-color);
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 5px;

  :hover {
    color: var(--option-active-color);
  }
`

const ProjectNameWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 25px;
  grid-gap: 5px;
`

const WriteHomeScreen = () => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )

  const [showPrintModal, setShowPrintModal] = useState(false)

  const filterActivitiesForWrite = (activities: Array<ActivityInterface>) => {
    const approvedActivities: Array<string> = [
      "UPDATED_SCENE_CONFIG",
      "UPDATED_SCENE",
      "CREATED_SCENE",
      "DELETED_SCENE",
      "CREATED_STORY_SECTION",
      "UPDATED_STORY_SECTION",
      "UPDATED_STORY_SECTION_SORT",
      "DELETED_STORY_SECTION",
      "UPDATE_SCENE_SORT",
      "ADDED_ITEM_TO_SCENE",
      "REMOVED_ITEM_FROM_SCENE",
      "CREATED_ASSET"
    ]
    return activities.filter((activity: ActivityInterface) =>
      approvedActivities.includes(activity.type)
    )
  }

  const listOfActivities: Array<ActivityInterface> = [
    ...filterActivitiesForWrite(project!.activities)
  ].reverse()

  return (
    <Wrapper>
      <ProjectNameWrapper>
        <ProjectName>{project!.name}</ProjectName>
        <PrintOption
          title="Click to Print"
          onClick={() => setShowPrintModal(true)}
          onMouseDown={e => e.preventDefault()}
        >
          <FontAwesomeIcon icon={faPrint} />
        </PrintOption>
      </ProjectNameWrapper>
      <hr />

      <ProjectMeta projectId={project!.id} />

      {/* <h3>Your Recent Changes:</h3>
      <hr />
      <em>Note: I'm still working on making this display useful.</em>
      {listOfActivities.length === 0 && (
        <div>
          <em>You haven't done anything yet!</em>
        </div>
      )}
      <div>
        {listOfActivities.slice(0, 5).map((activity: ActivityInterface) => {
          return (
            <ActivityInfo
              project={project!}
              activity={activity}
              key={`activity-list-${Math.floor(Math.random() * 1000000)}`}
            />
          )
        })}

        {project!.activities.length > 10 && (
          <ViewAllLink>
            <Link to={`/projects/${project!.id}/activities?fromWrite=true`}>
              View all
            </Link>
          </ViewAllLink>
        )}
      </div> */}
      <Spacer width={"100%"} height={"30px"} />
      {showPrintModal === true && (
        <PrintModal
          printType="text"
          projectId={project!.id}
          closeModal={() => setShowPrintModal(false)}
        />
      )}
    </Wrapper>
  )
}

export default WriteHomeScreen
