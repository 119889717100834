import React, { useState, MouseEventHandler, createContext } from "react"
import styled from "styled-components"
import ModalBase from "../../common/Modal/ModalBase/ModalBase"
import { useSelector } from "react-redux"
import { currentSceneObject, currentProject } from "../../utils/State"
import {
  StateInterface,
  ProjectInterface,
  SceneInterface,
  NoteItemShallowReferenceInterface,
  NoteMetaInterface,
} from "../../utils/Interfaces"
import { projectNotesOfType } from "../../utils/Data"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIconForAssetType } from "../../utils/Icons"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle"
import RelatedAssetSelector from "../../components/Assets/RelatedAssetSelector/RelatedAssetSelector"
import { assetTypes } from "../../utils/Assets"
import { faBan, faCheckCircle } from "@fortawesome/pro-solid-svg-icons"

const Supplemental = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  overflow-y: hidden;
`

const NoteTypeOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-bottom: 15px;

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
`

const NoteTypeOption = styled.div`
  font-size: 16px;
  text-transform: capitalize;
  font-family: var(--title-font);
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    background-color: var(--themed-selected-text-bg-color);
  }
`

const NoteTypeOptionIcon = styled.div<{ type: string }>`
  font-size: 40px;
  color: ${(props) => `var(--${props.type}-color)`};

  @media (max-width: 600px) {
    font-size: 30px;
  }
`

const AddNoteToSceneModal = ({
  closeModal,
  addItem,
  sceneId,
  type,
}: {
  closeModal: MouseEventHandler
  addItem: Function
  sceneId?: string
  type?: string
}) => {
  const [viewMode, setViewMode] = useState(sceneId ? 1 : 0)
  const [noteType, setNoteType] = useState(type ? type : "")
  const [noteTitle, setNoteTitle] = useState("")
  const [noteMeta, setNoteMeta] = useState({})
  const [createNewUser, setCreateNewUser] = useState(true)
  const [existingUserIds, setExistingUserIds] = useState("")

  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  let scene: SceneInterface | undefined = useSelector((state: StateInterface) =>
    currentSceneObject(project)
  )

  if (sceneId) {
    scene = project!.scenes.find(
      (scene: SceneInterface) => scene.id === sceneId
    )
  }

  const selectNoteType = (type: string) => {
    setNoteType(type)
    setViewMode(1)
  }

  const existingNotes = () => {
    return projectNotesOfType(project!, noteType).filter(
      (note: NoteItemShallowReferenceInterface) => {
        const inScene: boolean = isNoteInScene(note.id, noteType)
        return inScene
      }
    )
  }

  const isNoteInScene = (id: string, noteType: string) => {
    const inScene = scene!.items.find(
      (item: NoteItemShallowReferenceInterface) => {
        return item.id === id && item.type === noteType
      }
    )

    if (inScene) return false
    return true
  }

  const updateNoteMeta = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNoteMeta: NoteMetaInterface = {
      supplemental: e.currentTarget.value,
    }

    setNoteMeta(newNoteMeta)
  }

  const updateNoteTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteTitle(e.currentTarget.value)
  }

  const submitNewNote = () => {
    existingUserIds.split(",").forEach((existingUserId: string) => {
      addItem({
        type: noteType,
        noteMeta,
        title: noteTitle,
        createNewUser,
        existingUserId,
        sceneId,
      })
    })
  }

  const updateSelectedAssets = (e: any, n: string) => {
    setExistingUserIds(e)
    if (e > "") {
      setCreateNewUser(false)
    } else {
      setCreateNewUser(true)
    }
  }

  return (
    <ModalBase closeModal={closeModal}>
      {viewMode === 0 && (
        <React.Fragment>
          <NoteTypeOptions>
            {assetTypes.map((type: string) => {
              return (
                <NoteTypeOption
                  key={`add-note-option-${type}`}
                  onClick={() => selectNoteType(type)}
                >
                  <NoteTypeOptionIcon type={type}>
                    <FontAwesomeIcon icon={getIconForAssetType(type)} />
                  </NoteTypeOptionIcon>

                  {` ${type}`}
                </NoteTypeOption>
              )
            })}
          </NoteTypeOptions>
          <ModalActions>
            <ModalActionButton type="cancel" onClick={closeModal}>
              <FontAwesomeIcon icon={faBan} /> Cancel
            </ModalActionButton>
          </ModalActions>
        </React.Fragment>
      )}
      {viewMode === 1 && (
        <React.Fragment>
          <ModalTitle>Add {noteType}</ModalTitle>
          <hr />

          <RelatedAssetSelector
            multiple={true}
            assetType={noteType}
            filteredOptions={existingNotes()}
            inModal={false}
            selected={existingUserIds}
            updateSelectedAssets={updateSelectedAssets}
          />
          <br />
          {createNewUser === true && (
            <React.Fragment>
              <input
                type="title"
                onChange={updateNoteTitle}
                placeholder="Title"
                autoFocus
              />
              <Supplemental
                placeholder="Elaborate Here"
                onChange={updateNoteMeta}
              />
            </React.Fragment>
          )}
          <ModalActions>
            <ModalActionButton type="cancel" onClick={closeModal}>
              <FontAwesomeIcon icon={faBan} /> Cancel
            </ModalActionButton>
            <ModalActionButton type="okay" onClick={submitNewNote}>
              <FontAwesomeIcon icon={faCheckCircle} /> Okay
            </ModalActionButton>
          </ModalActions>
        </React.Fragment>
      )}
    </ModalBase>
  )
}

export default AddNoteToSceneModal
