import {
  faBan,
  faChevronDown,
  faChevronRight,
  faExternalLink,
  faFilePdf,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useRef } from "react"
import { useState } from "react"
import styled from "styled-components"
import { defaultNoteLink } from "../../../utils/Data"
import { NoteLinkInterface } from "../../../utils/Interfaces"
import {
  validateUrl,
  validateImageUrl,
  validateVideoUrl,
  extractVideoIdFromYoutubeLink,
  extractVideoIdFromVimeoLink,
} from "../../../utils/Strings"

const Wrapper = styled.div`
  padding-bottom: 10px;

  margin-bottom: 10px;
`

interface NoteLinkProps {
  links: Array<NoteLinkInterface>
  updateNoteLinks: Function
}

const LinkItems = styled.div`
  box-sizing: border-box;
  padding-right: 20px;
`

const AddLinkWrapper = styled.div`
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 25px 25px;
`
const LinkCount = styled.div`
  padding: 5px;
  box-sizing: border-box;
  line-height: 1.7em;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
`
const LinkItem = styled.div``

const LinkItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-gap: 5px;
`

const LinkItemType = styled.select`
  font-size: 16px;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`

const LinkButton = styled.span`
  font-weight: bold;
  margin-left: 5px;
  font-size: 14px;
  padding: 5px;
  box-sizing: border-box;
  cursor: pointer;
  color: var(--themed-header-color);

  :hover {
    color: var(--themed-font-color);
  }
`

const LinkItemUrl = styled.input`
  font-size: 16px;
  padding: 5px;
  box-sizing: border-box;
`

const LinkRemoveItem = styled.div`
  cursor: pointer;
  color: var(--remove-button-color);
  text-align: center;
  line-height: 2.25em;
`

const LinkItemPreview = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 5px;
  margin: 0px 0px 10px 0px;
  width: calc(100% - 30px);
  box-sizing: border-box;
  padding: 5px;
`

const LinkItemImagePreview = styled.div`
  display: grid;
  grid-template-columns: minmax(10px, 300px) 1fr;
  grid-gap: 5px;
  margin: 0px 0px 10px 0px;
  width: calc(100% - 30px);
  box-sizing: border-box;
  padding: 5px;

  img {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
    object-position: 0 0;
  }
`

const LinkItemVideoPreview = styled.div`
  width: calc(100% - 300px);
  margin: 0px 0px 10px 0px;
  padding: 5px;
  box-sizing: border-box;
`

const LinkItemImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  object-position: 0 0;
`

const LinksWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 500px));
  grid-gap: 10px;
`

const LinkItemPreviewData = styled.div<{ hasImage: boolean }>`
  grid-column-start: 2;
  ${(props) => props.hasImage === false && "grid-column: -1 /1; "}
`

const LinkItemPreviewDataTitle = styled.div`
  font-weight: bold;
  font-size: 16px;

  a {
    text-decoration: none;
    color: var(--themed-header-link-color);
  }
`

const NoBlobsAllowed = styled.span`
  font-size: 12px;
  font-style: italic;
  color: var(--themed-no-content-color);
`

const LinkItemPreviewDataDescription = styled.div`
  font-size: 14px;
`

const NoteLinks = (props: NoteLinkProps) => {
  const { updateNoteLinks } = props
  const [links, setNoteLinks] = useState<Array<NoteLinkInterface>>([])
  const [showLinks, setShowLinks] = useState(true)
  const [updatedMeta, setUpdatedMeta] = useState<any>(null)

  const timeoutRef = useRef<any>(null)
  const urlRef = useRef<any>({})

  const addLink = () => {
    const newLinks = [...links]
    newLinks.unshift(Object.assign({}, defaultNoteLink))
    setNoteLinks(newLinks)
    updateNoteLinks(newLinks)
    setShowLinks(true)
  }

  React.useEffect(() => {
    setNoteLinks(props.links)
  }, [])

  React.useEffect(() => {
    if (updatedMeta !== null)
      updateLinkItemField(updatedMeta.index, updatedMeta.data, "metadata")
    setUpdatedMeta(null)
    timeoutRef.current = null
    urlRef.current = {}
  }, [updatedMeta])

  const removeLink = (index: number) => {
    let newLinks = [...links]
    newLinks = newLinks.filter((item: NoteLinkInterface, ind: number) => {
      if (index === ind) {
        return false
      } else {
        return true
      }
    })
    setNoteLinks(newLinks)
    updateNoteLinks(newLinks)
  }

  const updateLinkItemField = (index: number, val: any, field: string) => {
    let newLinks = [...links]

    const linkToUpdate: any = Object.assign({}, newLinks[index])

    linkToUpdate[`${field}`] = val
    if (field === "url") {
      if (validateImageUrl(val) === true) {
        linkToUpdate.type = "image"
      } else if (validateUrl(val) && val.indexOf(".pdf") > -1) {
        linkToUpdate.type = "pdf"
      } else if (validateVideoUrl(val)) {
        linkToUpdate.type = "video"
      } else if (validateUrl(val)) {
        linkToUpdate.type = "link"
      }
    }

    newLinks[index] = linkToUpdate
    clearTimeout(timeoutRef.current)
    timeoutRef.current = null

    setNoteLinks(newLinks)
    updateNoteLinks(newLinks)
    if (field === "url" && validateUrl(val) && linkToUpdate.type === "link") {
      urlRef.current = { val: val, index: index }
      console.log("timeoutt")
      timeoutRef.current = setTimeout(() => scrapeMetaData(index), 1000)
    }
  }

  // TODO: Write perturbed blog about this, when I have it fixed.

  const scrapeMetaData = (index: number) => {
    const endpoint: string =
      "https://us-central1-hilker-prod.cloudfunctions.net/scraper"

    const urlToScrape: any = {
      text: urlRef.current.val,
    }

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(urlToScrape),
    })
      .then((response) => response.json())
      .then((data) => {
        setUpdatedMeta({ index: index, data: data[0] })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <Wrapper>
      <LinkItems>
        <AddLinkWrapper>
          <LinkCount>Links ({links.length}) </LinkCount>
          <LinkButton onClick={addLink}>
            <FontAwesomeIcon icon={faPlus} />
          </LinkButton>
          <LinkButton onClick={(e: any) => setShowLinks(!showLinks)}>
            {showLinks === true ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </LinkButton>
        </AddLinkWrapper>
        {showLinks === true && (
          <LinksWrapper>
            {links.map((link: NoteLinkInterface, index: number) => {
              return (
                <LinkItem key={`note-link-${index}`}>
                  <LinkItemRow>
                    <span>
                      <LinkItemUrl
                        type="text"
                        value={link.url}
                        maxLength={256}
                        placeholder={"Enter URL"}
                        onChange={(e: any) => {
                          updateLinkItemField(
                            index,
                            e.currentTarget.value,
                            "url"
                          )
                        }}
                      />
                      {timeoutRef.current !== null &&
                        urlRef.current.index === index && (
                          <div className="loading-text" />
                        )}
                      {link.url.indexOf("data:image", 0) > -1 && (
                        <NoBlobsAllowed>
                          You can't paste image data here; URLs only, please.
                        </NoBlobsAllowed>
                      )}
                    </span>
                    <LinkRemoveItem onClick={(e: any) => removeLink(index)}>
                      <FontAwesomeIcon icon={faBan} />
                    </LinkRemoveItem>
                  </LinkItemRow>
                  {link.type === "link" &&
                    link.metadata &&
                    validateUrl(link.url.trim()) &&
                    link.metadata.title && (
                      <LinkItemPreview>
                        {link.metadata.image && (
                          <LinkItemImage src={link.metadata.image} />
                        )}
                        <LinkItemPreviewData
                          hasImage={link.metadata.image !== undefined}
                        >
                          <LinkItemPreviewDataTitle>
                            {link.metadata.title}{" "}
                            <a href={link.url.trim()} target="_blank">
                              <FontAwesomeIcon icon={faExternalLink} />
                            </a>
                          </LinkItemPreviewDataTitle>
                          <LinkItemPreviewDataDescription>
                            {link.metadata.description}
                          </LinkItemPreviewDataDescription>
                        </LinkItemPreviewData>
                      </LinkItemPreview>
                    )}
                  {link.type === "pdf" && validateUrl(link.url.trim()) && (
                    <LinkItemPreview>
                      <LinkItemPreviewDataTitle>
                        View PDF{" "}
                        <a href={link.url} target="_blank">
                          <FontAwesomeIcon icon={faFilePdf} />
                        </a>
                      </LinkItemPreviewDataTitle>
                    </LinkItemPreview>
                  )}
                  {link.type === "image" && validateImageUrl(link.url.trim()) && (
                    <LinkItemImagePreview
                      onClick={(e: any) =>
                        window.open(link.url.trim(), "_blank")
                      }
                    >
                      <img src={link.url} />
                    </LinkItemImagePreview>
                  )}
                  {link.type === "video" && validateVideoUrl(link.url.trim()) && (
                    <LinkItemVideoPreview>
                      {link.url.indexOf("youtube", 0) > -1 && (
                        <iframe
                          height="200px"
                          src={`https://www.youtube.com/embed/${extractVideoIdFromYoutubeLink(
                            link.url
                          )}`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          frameBorder="0"
                        ></iframe>
                      )}
                      {link.url.indexOf("vimeo", 0) > -1 && (
                        <iframe
                          title="vimeo-player"
                          src={`https://player.vimeo.com/video/${extractVideoIdFromVimeoLink(
                            link.url
                          )}`}
                          height="200px"
                          frameBorder="0"
                          allowFullScreen
                        ></iframe>
                      )}
                    </LinkItemVideoPreview>
                  )}
                </LinkItem>
              )
            })}
          </LinksWrapper>
        )}
      </LinkItems>
    </Wrapper>
  )
}

export default NoteLinks
