import React from "react"
import styled from "styled-components"
import moment from "moment"
import {
  ActivityInterface,
  ActivitiesListInterface,
  ProjectInterface
} from "../../../utils/Interfaces"
import { activityItem } from "../../../utils/Activities"
import { Link } from "@reach/router"

const Wrapper = styled.div`
  font-size: 12px;
  color: var(--themed-asset-header-color);
  margin-bottom: 10px;
  strong {
    color: var(--themed-font-color);
  }
  a {
    color: var(--brand-primary);
  }
`

const UpdatedExcerpt = styled.div`
  padding: 10px 10px 0px 10px;
  font-size: 14px;
  color: var(--themed-font-color);
`

const ActivityInfo = ({
  activity,
  project
}: {
  activity: ActivityInterface
  project: ProjectInterface
}) => {
  const activityRef: false | ActivitiesListInterface = activityItem(
    activity.type
  )

  if (!activityRef)
    return <div key={`activity-list-${Math.floor(Math.random() * 1000000)}`} />

  let linkPath: string = ""

  if (activityRef.showLink === true) {
    linkPath = ("/projects/" +
      project!.id +
      "/" +
      activityRef.linkPath) as string
    linkPath = linkPath.replace(":referenceId", activity.referenceId as string)
    linkPath = linkPath.replace(
      ":referenceType",
      activity.referenceType as string
    )
  }

  return (
    <Wrapper>
      {activity.type !== "UPDATED_SCENE" && (
        <React.Fragment>
          <em>
            <strong>{moment(activity.created_at).fromNow()}</strong> you{" "}
            {activityRef.informalLabel}{" "}
          </em>
          {activityRef.showLink === true && (
            <Link to={linkPath}>
              {activity.description || activity.referenceId}
            </Link>
          )}
        </React.Fragment>
      )}
      {activity.type === "UPDATED_SCENE" && (
        <React.Fragment>
          <em>
            <strong>{moment(activity.created_at).fromNow()}</strong> in{" "}
          </em>
          <Link to={linkPath}>
            {activity.description || activity.referenceId}
          </Link>
          <em>, you updated the scene.</em>

        </React.Fragment>
      )}
    </Wrapper>
  )
}

export default ActivityInfo
