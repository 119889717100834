import React, { useState, SyntheticEvent } from "react"
import styled from "styled-components"
import {
  ProjectInterface,
  StateInterface,
  SceneInterface,
  AssetInterface,
  SceneItemInterface,
  NoteMetaInterface,
  Dispatch_UpdateNoteItemInterface,
  StorySectionInterface,
} from "../../../utils/Interfaces"
import { useSelector, useDispatch } from "react-redux"
import {
  currentProject,
  getFullItem,
  getConfig,
  isUsedInProject,
} from "../../../utils/State"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlusCircle,
  faTimesCircle,
  faEdit,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons"
import AddNoteToSceneModal from "../../../modals/AddNoteToSceneModal/AddNoteToSceneModal"
import { navigate } from "@reach/router"
import EditNoteModal from "../../../modals/EditNoteModal/EditNoteModal"
import AssetProgressBar from "../../../common/AssetProgressBar/AssetProgressBar"
import AssetsTypeHeader from "../../../common/AssetsTypeHeader/AssetsTypeHeader"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { sortCharacters, sortCharactersByRole } from "../../../utils/Characters"
import { assetTypes } from "../../../utils/Assets"

const Wrapper = styled.div<{
  plotScale: number
  snap: boolean
  numberOfScenes: number
}>`
  ${(props) =>
    props.snap !== true &&
    `width: calc(13px + (200px * ${props.plotScale})); margin: 0px 1px;`}
`

const Header = styled.div`
  background-color: var(--themed-plot-header-bg);
  text-align: center;
  text-decoration: uppercase;
  font-weight: bold;
  color: var(--themed-font-color);
  box-sizing: border-box;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  span:nth-child(2) {
    color: var(--black-color);
    display: none;
    position: absolute;
    left: 3px;
    top: 0px;
    cursor: pointer;
  }
  span:nth-child(3) {
    color: var(--black-color);
    display: none;
    position: absolute;
    right: 3px;
    top: 0px;
    cursor: pointer;
  }

  :hover {
    span:nth-child(2) {
      display: block;
      color: var(--really-black-color);
    }

    span:nth-child(3) {
      display: block;
      color: var(--really-black-color);
    }
  }
`

const Summary = styled.div`
  margin: 5px;
  font-size: 12px;
  height: 75px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const NoSummary = styled.em`
  color: var(--themed-no-content-color);
  height: 75px;
  overflow-y: scroll;
  overflow-x: hidden;
`

const AssetsWrapper = styled.div<{ type: string }>`
  color: ${(props) => `var(--${props.type}-color)`};
  margin-bottom: 5px;
`

const Assets = styled.div<{ type: string; plotMode: string }>`
  background-color: ${(props) => `var(--${props.type}-color--plot-bg)`};
  padding: ${(props) =>
    props.plotMode === "edit" ? "5px 5px 1px 5px" : "5px 0px 5px 0px"};
  ${(props) => props.plotMode === "edit" && "min-height: 33px;"}
`

const PlotItem = styled.div<{
  type: string
  highlighted: boolean
  customColor: string
  plotMode: string
  thumbnail: boolean
}>`
  background-color: ${(props) => `var(--${props.type}-color)`};
  ${(props) =>
    props.highlighted === false && props.customColor === ""
      ? `background-color: var(--${props.type}-color--plot-bg);`
      : ""}
  ${(props) =>
    props.customColor !== "" &&
    `background-color: ${props.customColor}`}
  font-size: 14px;
  color: ${(props) =>
    props.highlighted === true ? "var(--white-color);" : "transparent"};
  ${(props) => props.customColor === "#ffffff" && "color: var(--black-color)"};
  ${(props) => (props.plotMode === "edit" ? "border-radius: 4px" : "")};
  text-transform: uppercase;
  font-weight: bold;
  padding: 4px 5px 5px 5px;
  box-sizing: border-box;
  margin: ${(props) =>
    props.plotMode === "edit" ? "0px 0px 4px 0px" : "0px 0px 5px 0px"};
  height: 29px;
  ${(props) =>
    props.plotMode === "inspect" &&
    `margin: 0px 0px 1px 0px;
    height: 20px;
    padding: 10px 0px;`}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: grid;
  grid-template-columns: ${(props) =>
    props.thumbnail === true ? "25px 1fr 15px" : "1fr 15px"};

  position: relative;
`

const PlotItemInspect = styled.div<{
  type: string
  highlighted: boolean
  customColor: string
  plotMode: string
}>`
  background-color: ${(props) => `var(--${props.type}-color)`};
  ${(props) =>
    props.highlighted === false && props.customColor === ""
      ? `background-color: var(--${props.type}-color--plot-bg);`
      : ""}
  ${(props) =>
    props.customColor !== ""
      ? `background-color: ${props.customColor}`
      : ""}
  font-size: 14px;
  color: ${(props) =>
    props.highlighted === true ? "var(--white-color);" : "transparent"};
  ${(props) => props.customColor === "#ffffff" && "color: var(--black-color)"};
  ${(props) => (props.plotMode === "edit" ? "border-radius: 4px" : "")};
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
  margin: 0px 0px 5px 0px;
  border-radius: 4px;
  ${(props) =>
    props.plotMode === "inspect" &&
    `margin: 0px 0px 1px 0px;
    height: 8px;`}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  ${(props) =>
    props.highlighted === false &&
    props.plotMode === "inspect" &&
    props.customColor !== "" &&
    "opacity: 0.1"};

  position: relative;
  span {
    display: none;
    cursor: pointer;
  }
`

const PlotItemBlank = styled.div<{
  plotScale: number
  isUsedInProject: boolean
  plotMode: string
}>`
  padding: 5px;
  height: 29px;
  box-sizing: border-box;
  ${(props) =>
    props.isUsedInProject === false &&
    "background-color: var(--not-used-in-project-bg); color: var(--black-color); opacity: 0.1;"}
  ${(props) =>
    props.plotMode === "inspect"
      ? `height: 20px; margin: 0px 0px 1px 0px;`
      : "margin: 0px 0px 4px 0px;"}
`

const SummarySpacer = styled.div`
  width: 100%;
  height: 5px;
`

const AddAssetToScene = styled.div`
  cursor: pointer;
  text-align: right;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--themed-bg-color);
  background-color: var(--themed-font-color);
  border-radius: 8px 8px 0px 0px;
  padding: 2px 5px;
  font-weight: bold;

  :hover {
    background-color: var(--brand-primary);
  }
`

const TabCompleted = styled.div`
  font-size: 14px;
  color: var(--white-color);
`

const TabName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  svg {
    margin-right: 5px;
  }
`

const TabThumbnail = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  margin: -5px 0px -5px -5px;
  width: 25px;
  height: 31px;
`

const SceneInStorySection = ({
  sceneId,
  handle,
  filters,
  numberOfScenes,
  storySection,
}: {
  sceneId: string
  handle: any
  filters: Array<string>
  numberOfScenes: number
  storySection: StorySectionInterface
}) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const scene: SceneInterface | undefined = project!.scenes.find(
    (scene: SceneInterface) => scene.id === sceneId
  )

  const showAddAssetToScene = (sceneId: string) => {
    setAddNoteScene(sceneId)
    setAddNoteType("")
    setShowMetaModal(true)
  }

  const [showMetaModal, setShowMetaModal] = useState(false)
  const [addNoteScene, setAddNoteScene] = useState("")
  const [addNoteType, setAddNoteType] = useState("")

  // use it to draw the right number of blanks
  let assetHeights: any = {
    arc: 0,
    character: 0,
    dialogue: 0,
    storybeat: 0,
    location: 0,
    macguffin: 0,
    storyline: 0
  }

  const calculateHeights = () => {
    project!.scenes.forEach((scene: SceneInterface) => {
      let arcs: number = 0
      let characters: number = 0
      let dialogues: number = 0
      let storybeats: number = 0
      let locations: number = 0
      let macguffins: number = 0
      let storylines: number = 0

      scene.items.forEach((item: SceneItemInterface) => {
        switch (item.type) {
          case "arc":
            arcs++
            break
          case "character":
            characters++
            break
          case "dialogue":
            dialogues++
            break
          case "storybeat":
            storybeats++
            break
          case "location":
            locations++
            break
          case "macguffin":
            macguffins++
            break
          case "storyline":
            storylines++
            break
        }
      })

      if (arcs > assetHeights.arc) assetHeights.arc = arcs
      if (characters > assetHeights.character)
        assetHeights.character = characters
      if (dialogues > assetHeights.dialogue) assetHeights.dialogue = dialogues
      if (storybeats > assetHeights.storybeat)
        assetHeights.storybeat = storybeats
      if (locations > assetHeights.location) assetHeights.location = locations
      if (macguffins > assetHeights.macguffin)
        assetHeights.macguffin = macguffins
      if (storylines > assetHeights.storyline)
        assetHeights.storyline = storylines
    })
  }

  calculateHeights()

  const dispatch = useDispatch()
  const addItem = ({
    type,
    noteMeta,
    title,
    createNewUser,
    existingUserId,
    sceneId,
  }: {
    type: string
    noteMeta: NoteMetaInterface
    title: string
    createNewUser: boolean
    existingUserId: string
    sceneId?: string
  }) => {
    const toDispatch = {
      type: "add_item_to_scene",
      value: {
        type,
        noteMeta,
        title,
        createNewUser,
        existingUserId,
        sceneId: addNoteScene,
      },
    }

    dispatch(toDispatch)
    setShowMetaModal(false)
  }

  const [sceneToEdit, setSceneToEdit] = useState("")
  const [sceneItemToEdit, setSceneItemToEdit] = useState("")
  const [showEditNoteModal, setShowEditNoteModal] = useState(false)

  const config = useSelector((state: StateInterface) => getConfig(state))

  const { showDescription, plotScale } = config
  const updateItem = (updatedNoteItem: Dispatch_UpdateNoteItemInterface) => {
    const toDispatch = {
      type: "update_note_item",
      value: updatedNoteItem,
    }
    dispatch(toDispatch)
    setShowEditNoteModal(false)
  }

  const itemToEdit = () => {
    const scene: SceneInterface | undefined = project!.scenes.find(
      (scene: SceneInterface) => scene.id === sceneToEdit
    )

    return scene!.items.find(
      (item: SceneItemInterface) => item.id === sceneItemToEdit
    )
  }

  const deleteScene = (id: string) => {
    const toDispatch = {
      type: "delete_scene",
      value: { id },
    }

    dispatch(toDispatch)
  }

  const showEditModal = (id: string, sceneId: string) => {
    setShowEditNoteModal(true)
    setSceneItemToEdit(id)
    setSceneToEdit(sceneId)
  }

  const getProjectAssetsByType = (
    project: ProjectInterface | null,
    assetType: string
  ) => {
    switch (assetType) {
      case "character":
        return sortCharacters(project!.assets.filter((asset: AssetInterface) => asset.type === "character"), config)

      default:
        return project!.assets.filter((asset: AssetInterface) => asset.type === assetType)
    }
  }

  if (!scene) return <div />

  const noneOfTypeAreHighlighted = (assetType: string) => {
    return (
      config.inspectHighlights.filter(
        (highlight: any) => highlight.type === assetType
      ).length === 0
    )
  }

  const isHighlighted = (item: any) => {
    if (config.inspectHighlights.length === 0) return true

    if (
      config.inspectHighlights.find(
        (highlight: any) => highlight.id === item.id
      )
    ) {
      return true
    } else {
      return false
    }
  }

  const getCustomColor = (item: any) => {
    if (item.type === "storybeat" || item.type === "scene") return ""
    if (!item.color) return ""
    return item.color
  }

  const anyAreHighlighted = (project: ProjectInterface, assetType: string) => {
    if (config.plotMode !== "inspect") return true
    let numOfTypeFound: number = 0

    getProjectAssetsByType(project, assetType)!.map((item: any) => {
      if (isHighlighted(item)) {
        numOfTypeFound++
      }
    })

    return numOfTypeFound > 0
  }

  let nextIndex: number = 0

  return (
    <Wrapper
      numberOfScenes={numberOfScenes}
      plotScale={plotScale}
      snap={config.plotScaleSnap}
    >
      <Header {...handle} title={scene!.title || ""}>
        <strong>{scene!.title || ""}</strong>
        {config.plotScaleSnap === false && config.plotMode !== "inspect" && (
          <span
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation()
              deleteScene(scene!.id)
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
        )}
        {config.plotScaleSnap === false && config.plotMode !== "inspect" && (
          <span
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation()
              navigate(`/projects/${project!.id}/write/${scene!.id}`)
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </span>
        )}
      </Header>
      <AssetProgressBar sticky={false} items={scene!.items} />
      {showDescription === true && (
        <Summary>
          {scene!.noteMeta.supplemental || (
            <NoSummary>This scene has no summary.</NoSummary>
          )}
        </Summary>
      )}
      {showDescription === false && <SummarySpacer />}
      {config.plotMode === "edit" && (
        <AddAssetToScene onClick={() => showAddAssetToScene(scene!.id)}>
          Add Asset <FontAwesomeIcon icon={faPlusCircle} />
        </AddAssetToScene>
      )}
      {assetTypes.map((assetType: string, assetTypeIndex: number) => {
        let numberOfThisTypeOfAssetInScene: number = 0

        return (
          <AssetsWrapper type={assetType} key={`asset-wrapper-${assetType}`}>
            {config.plotMode === "inspect" && filters.includes(assetType) && (
              <AssetsTypeHeader type={assetType} grid={false} />
            )}
            {config.plotMode === "edit" && (
              <AssetsTypeHeader type={assetType} grid={false}>
                {storySection!.scenes[0].id === scene!.id && (
                  <span>{assetType + "s"}</span>
                )}
              </AssetsTypeHeader>
            )}
            {config.plotMode === "edit" && (
              <Assets type={assetType} plotMode={config.plotMode}>
                {sortCharactersByRole(project!, scene!.items)
                  .filter((item: SceneItemInterface) => item.type === assetType)
                  .map((asset: any) => {
                    const fullItem = getFullItem(project, asset.type, asset.id)

                    numberOfThisTypeOfAssetInScene++
                    if (!fullItem)
                      return (
                        <div
                          key={`inspect-asset-type-${Math.random() * 1000}`}
                        />
                      )
                    return (
                      <PlotItem
                        key={`inspect-asset-type-${Math.random() * 1000}`}
                        highlighted={true}
                        plotMode={config.plotMode}
                        type={assetType}
                        title={fullItem!.title || ""}
                        customColor={getCustomColor(fullItem)}
                        thumbnail={
                          fullItem!.thumbnail && fullItem!.thumbnail > ""
                        }
                      >
                        {fullItem!.thumbnail && fullItem!.thumbnail > "" && (
                          <TabThumbnail src={fullItem!.thumbnail} />
                        )}
                        <TabName
                          onClick={() => showEditModal(fullItem!.id, scene!.id)}
                        >
                          {fullItem!.role && fullItem!.role === "PRIMARY" && (
                            <React.Fragment>
                              <FontAwesomeIcon icon={faStar} />
                            </React.Fragment>
                          )}
                          {fullItem!.title}
                        </TabName>
                        <TabCompleted>
                          {scene!.items.find(
                            (item: any) => item.id === fullItem!.id
                          )!.completed === true && (
                              <FontAwesomeIcon icon={faCheck} />
                            )}
                        </TabCompleted>
                      </PlotItem>
                    )
                  })}
                {[
                  ...Array(
                    assetHeights[assetType] - numberOfThisTypeOfAssetInScene
                  ),
                ].map((e, i) => (
                  <PlotItemBlank
                    plotMode={config.plotMode}
                    plotScale={config.plotScale}
                    key={`blank-1${Math.floor(Math.random() * 10000000)}`}
                    isUsedInProject={true}
                  />
                ))}
              </Assets>
            )}

            {config.plotMode === "inspect" && filters.includes(assetType) && (
              // get list of all assets in project of current type
              // iterate through that, find if one in current scene matchs
              // if so, draw.
              // if not, draw blank

              <Assets type={assetType} plotMode={config.plotMode}>
                {getProjectAssetsByType(project, assetType)!.map(
                  (
                    assetOfType: AssetInterface,
                    index: number
                  ) => {
                    const assetIsInThisScene: boolean =
                      scene!.items.filter(
                        (item: SceneItemInterface) =>
                          item.type === assetOfType.type &&
                          item.id === assetOfType.id
                      ).length > 0

                    if (
                      isHighlighted(assetOfType) === false &&
                      noneOfTypeAreHighlighted(assetOfType.type) === false &&
                      config.hideNonHighlighted === true
                    ) {
                      return (
                        <div key={`inspect-asset-type-${assetOfType.id}`} />
                      )
                    }
                    return (
                      <div key={`inspect-asset-type-${assetOfType.id}`}>
                        {assetIsInThisScene === true && (
                          <PlotItemBlank
                            plotMode={config.plotMode}
                            plotScale={config.plotScale}
                            key={`blank-2${Math.floor(
                              Math.random() * 1000000
                            )}`}
                            isUsedInProject={true}
                          >
                            <PlotItemInspect
                              highlighted={
                                isHighlighted(assetOfType) ||
                                noneOfTypeAreHighlighted(assetOfType.type)
                              }
                              type={assetType}
                              plotMode={config.plotMode}
                              title={assetOfType!.title || ""}
                              key={`scene-item-${Math.random() * 1000000}`}
                              onClick={() =>
                                showEditModal(assetOfType!.id, scene!.id)
                              }
                              customColor={getCustomColor(assetOfType)}
                            ></PlotItemInspect>
                          </PlotItemBlank>
                        )}
                        {assetIsInThisScene === false && (
                          <PlotItemBlank
                            plotMode={config.plotMode}
                            plotScale={config.plotScale}
                            key={`blank-3${Math.floor(
                              Math.random() * 1000000
                            )}`}
                            isUsedInProject={isUsedInProject(
                              project!,
                              assetOfType
                            )}
                          ></PlotItemBlank>
                        )}
                      </div>
                    )
                  }
                )}

                {getProjectAssetsByType(project, assetType)!.length === 0 &&
                  anyAreHighlighted(project as ProjectInterface, assetType) ===
                  false && (
                    <PlotItemBlank
                      plotMode={config.plotMode}
                      plotScale={config.plotScale}
                      key={`blank-4${Math.floor(Math.random() * 1000000)}`}
                      isUsedInProject={true}
                    ></PlotItemBlank>
                  )}
              </Assets>
            )}
          </AssetsWrapper>
        )
      })}
      {showMetaModal === true && addNoteType !== "" && (
        <AddNoteToSceneModal
          closeModal={() => setShowMetaModal(false)}
          type={addNoteType}
          sceneId={addNoteScene}
          addItem={addItem}
        />
      )}
      {showMetaModal === true && addNoteType === "" && (
        <AddNoteToSceneModal
          closeModal={() => setShowMetaModal(false)}
          addItem={addItem}
        />
      )}
      {showEditNoteModal === true && (
        <EditNoteModal
          closeModal={() => setShowEditNoteModal(false)}
          doCloseModal={() => setShowEditNoteModal(false)}
          item={itemToEdit()}
          updateItem={updateItem}
          mode={"write"}
          sceneId={scene.id}
        />
      )}
    </Wrapper>
  )
}

export default SceneInStorySection
