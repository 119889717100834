import React from "react"
import styled from "styled-components"

const Thumb = styled.img<{ size: string; src: string }>`
  display: inline-block;
  overflow: hidden;
  border-radius: 30px;
  width: ${props => props.size};
  height: ${props => props.size};
  margin-right: 5px;
  object-fit: cover;
`
const ThumbWrapper = styled.div`
  display: inline-block;

  img {
    margin-top: 3px;
    margin-bottom: -3px;
  }
`
const Thumbnail = ({
  src,
  size
}: {
  src: string
  size: string
  assetName?: string
}) => {
  return (
    <ThumbWrapper>
      {src && src > "" && <Thumb size={size} src={src}></Thumb>}
      {!src || (src === "" && <Thumb size={size} src={src}></Thumb>)}
    </ThumbWrapper>
  )
}

export default Thumbnail
