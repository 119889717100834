import React, { useState, MouseEventHandler } from "react"
import styled from "styled-components"
import ModalBase from "../../common/Modal/ModalBase/ModalBase"
import {
  NoteMetaInterface,
  AssetInterface,
  ProjectInterface,
  StateInterface,
} from "../../utils/Interfaces"
import { projectNotesOfType } from "../../utils/Data"
import { useSelector } from "react-redux"
import { currentProject } from "../../utils/State"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle"
import { faBan, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { faCircle } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Supplemental = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  overflow-y: hidden;
`

const AddNoteModalSelection = styled.div`
  font-style: italic;
  font-size: 15px;
  background-color: var(--themed-input-bg-color);
  padding: 10px;
  margin-bottom: 10px;
  max-height: 100px;
  overflow-y: scroll;
`

const AddToCurrentScene = styled.div<{ active: boolean }>`
  cursor: pointer;
  background-color: ${(props) =>
    props.active ? "var(--themed-focus-color)" : "var(--themed-bg-color)"};

  :hover {
    background-color: var(--themed-focus-color);
  }
`

const AddNoteModal = ({
  selectedText,
  closeModal,
  addItem,
  newNoteType,
  newNoteAction,
  showAddToSceneOption,
}: {
  selectedText: string
  closeModal: MouseEventHandler
  addItem: Function
  newNoteType: string
  newNoteAction?: string
  showAddToSceneOption?: boolean
}) => {
  const [noteMeta, setNoteMeta] = useState<NoteMetaInterface>({
    supplemental: "",
  })
  const [addToSceneOption, setAddToSceneOption] = useState(true)
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const [noteTitle, setNoteTitle] = useState<string>("")
  const [noteTags, setNoteTags] = useState<string>("")
  const [referenceId, setReferenceId] = useState<string>("")
  if (!newNoteAction) newNoteAction = "new"

  const updateNoteMeta = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNoteMeta: NoteMetaInterface = {
      supplemental: e.currentTarget.value,
    }

    setNoteMeta(newNoteMeta)
  }

  const updateNoteTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteTitle(e.currentTarget.value)
  }

  const submitNewNote = () => {
    // TODO: Refactor so addItem logic is in the note modal
    addItem({
      type: newNoteType,
      noteMeta,
      title: noteTitle,
      tags: noteTags,
      addToSceneOption,
      newNoteAction,
      referenceId,
    })
  }

  const chooseExistingAssetToAddFactoidTo = (e: any) => {
    setReferenceId(e.target.value)
  }

  const existingNotes = () => {
    return projectNotesOfType(project!, newNoteType)
  }

  const toggleAddToSceneOption = () => {
    setAddToSceneOption(!addToSceneOption)
  }

  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Add {newNoteType}</ModalTitle>
        {newNoteAction === "existing" && (
          <React.Fragment>
            <div>Thing here to specify existing {newNoteType} to add to.</div>
            <select onChange={chooseExistingAssetToAddFactoidTo}>
              <option value="">
                Select existing {newNoteType} to add Fact to.
              </option>
              {existingNotes().map((note: AssetInterface) => {
                return (
                  <option key={`existing-note-id-${note.id}`} value={note.id}>
                    {note.title}
                  </option>
                )
              })}
            </select>
          </React.Fragment>
        )}
        {newNoteAction !== "existing" && (
          <input type="title" onChange={updateNoteTitle} autoFocus />
        )}
        {showAddToSceneOption === true &&
          newNoteType !== "storySection" &&
          newNoteType !== "scene" && (
            <AddToCurrentScene active={false} onClick={toggleAddToSceneOption}>
              <FontAwesomeIcon
                icon={addToSceneOption === true ? faCheckCircle : faCircle}
              />{" "}
              Add to Current Scene
            </AddToCurrentScene>
          )}
        <AddNoteModalSelection>{selectedText}</AddNoteModalSelection>
        <Supplemental
          placeholder="Elaborate Here"
          defaultValue={noteMeta.supplemental as string}
          onChange={updateNoteMeta}
        />
        <input
          type="text"
          placeholder="Tags (comma-separated)"
          defaultValue={noteTags}
          onChange={(e) => setNoteTags(e.currentTarget.value)}
        />
        <ModalActions>
          <ModalActionButton title="Cancel" type="cancel" onClick={closeModal}>
            <FontAwesomeIcon icon={faBan} /> Cancel
          </ModalActionButton>
          {newNoteAction === "new" && (
            <ModalActionButton
              title="Add Note"
              type="okay"
              onClick={submitNewNote}
            >
              <FontAwesomeIcon icon={faCheckCircle} /> Okay
            </ModalActionButton>
          )}
          {newNoteAction === "existing" && referenceId > "" && (
            <ModalActionButton
              title="Update Note"
              type="okay"
              onClick={submitNewNote}
            >
              <FontAwesomeIcon icon={faCheckCircle} /> Okay
            </ModalActionButton>
          )}
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  )
}

export default AddNoteModal
