import React, { useState } from "react"
import styled from "styled-components"
import Header from "../../common/Header/Header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

import HomeFooter from "../../components/Home/HomeFooter/HomeFooter"
import {
  questionTypes,
  HelpQuestionInterface,
  HelpQuestionTypeInterface,
} from "./HelpQuestions"

interface HelpProps {
  path?: string
}

const Wrapper = styled.div`
  a {
    color: var(--brand-primary);
    font-weight: bold;
  }
`

const HelpQuestions = styled.div``

const HelpQuestion = styled.div`
  margin-bottom: 30px;
`

const Par = styled.div`
  margin: 15px 0px;
`

const QuestionTypeHeader = styled.div``

const QuestionTypeLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 400px) {
    grid-template-columns: 1fr;
  }

  div {
    margin-bottom: 10px;
    font-size: 14px;
  }
`

const NoHelpYet = styled.span`
  font-style: italic;
  color: var(--themed-font-color);
  font-size: 14px;
`

const QuestionTitle = styled.div`
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: bold;
  color: var(--themed-font-color);

  a {
    cursor: pointer;
  }
`

const QuestionAnswer = styled.div`
  font-family: var(--body-font);
  margin: 5px 10px 5px 10px;
  font-size: 16px;
  color: var(--themed-header-color);
`

const Content = styled.div`
  margin: 10px;
`

const Intro = styled.p`
  font-size: 14px;
`

const QuestionType = styled.h2``

const Help = (props: HelpProps) => {
  let hash: string = new URL(document.URL).hash
  if (hash) {
    hash = hash.slice(1)
  } else {
    hash = ""
  }

  const [currentSection, setCurrentSection] = useState(hash)

  const showHelpList = () => {
    window.scrollTo(0, 0)
    setCurrentSection("")
    showHelpItem("")
  }

  const showHelpItem = (hash: string) => {
    window.scrollTo(0, 0)
    setCurrentSection(hash)
    window.history.pushState(
      {},
      "Hilker",
      "#" + hash
    )
  }

  const goBack = () => {
    showHelpList()
  }

  const convertQuestionToId = (question: string) => {
    return question.toLowerCase().replace(/\W/g, "")
  }

  const popStateListener = (e: any) => {
    let hash: string = new URL(document.URL).hash
    if (hash) {
      hash = hash.slice(1)
    } else {
      hash = ""
    }

    if (hash > "") {
      showHelpItem(hash)
    } else {
      showHelpList()
    }
  }

  React.useEffect(() => {
    // if (genre > "" || subjectMatter > "" || creators > "") submitSearch(true);
    window.addEventListener("popstate", popStateListener);

    return () => {
      window.removeEventListener("popstate", popStateListener);
    };
  }, []);

  return (
    <Wrapper>
      <Header />
      <Content>
        <h1>Help</h1>

        <Intro>
          Welcome! If you've got questions, this is the place to be. Below is an
          expanding list of questions you might have about Hilker, along with
          some pretty good answers.
        </Intro>
        <Intro>But!</Intro>

        <Intro>
          We might not have <em>all</em> the answers yet, so if you have a
          question that's not on this page, email us at{" "}
          <a href="mailto:brian@hoggworks.com">brian@hoggworks.com</a>. Cheers!
        </Intro>

        {currentSection === "" &&
          questionTypes.map((questionType: HelpQuestionTypeInterface) => {
            return (
              <QuestionTypeHeader key={`question-header-${Math.random()}`}>
                <QuestionType>{questionType.name}</QuestionType>
                <QuestionTypeLinks>
                  {questionType.questions.map(
                    (question: HelpQuestionInterface) => {
                      return (
                        <QuestionTitle key={`question-title-${Math.random()}`}>
                          <a
                            onClick={(e) => {
                              e.preventDefault()
                              showHelpItem(
                                convertQuestionToId(question.question)
                              )
                            }}
                          >
                            {question.question}
                          </a>
                        </QuestionTitle>
                      )
                    }
                  )}
                </QuestionTypeLinks>
              </QuestionTypeHeader>
            )
          })}

        {questionTypes.map((questionType: HelpQuestionTypeInterface) => {
          return (
            <div key={`question-type-header-2-${Math.random()}`}>
              <HelpQuestions>
                {questionType.questions
                  .filter(
                    (question: HelpQuestionInterface) =>
                      convertQuestionToId(question.question) === currentSection
                  )
                  .map((question: HelpQuestionInterface) => {
                    return (
                      <HelpQuestion key={`help-question-${Math.random()}`}>
                        <QuestionType>{question.question}</QuestionType>
                        <QuestionTitle>
                          <a
                            onClick={(e) => {
                              e.preventDefault()
                              goBack()
                            }}
                          >
                            <FontAwesomeIcon icon={faChevronLeft} /> Back to List
                          </a>
                        </QuestionTitle>
                        <QuestionAnswer>{question.answer}</QuestionAnswer>
                      </HelpQuestion>
                    )
                  })}
              </HelpQuestions>
            </div>
          )
        })}

        <hr />
        <Intro>
          If you have additional questions, email me at{" "}
          <a href="mailto:brian@hoggworks.com">brian@hoggworks.com</a>. Cheers!
        </Intro>
      </Content>
      <HomeFooter />
    </Wrapper>
  )
}

export default Help
