import { ProjectInterface, GoalInterface, GoalDateInterface } from "./Interfaces";
import moment from "moment";


export const updateGoals = (project: ProjectInterface, initialWordCount: number) => {
  const today = moment().format("YYYY-MM-DD")
  let toReturn: boolean = false
  project.goals.filter((goal: GoalInterface) => goal.active === true).forEach((goal: GoalInterface) => {
    switch (goal.type) {
      case 'project':
        goal.wordsWritten = project.wordCount
        if (project.wordCount >= goal.wordCountTarget) {
          goal.completed = true
          goal.active = false
          toReturn = true
        }
        break;
      case 'date':
        goal.days.map((date: GoalDateInterface) => {
          if (date.day === today) {
            if (date.wordCountStart === 0) {
              date.wordCountStart = initialWordCount
            }
            date.wordsWritten = project.wordCount - date.wordCountStart
          }  

          return date
        })

        let totalWordsWritten = 0
        goal.days.forEach((date: GoalDateInterface) => totalWordsWritten += date.wordsWritten)

        goal.wordsWritten = totalWordsWritten

        if (totalWordsWritten >= goal.wordCountTarget) {
          goal.completed = true
          goal.active = false
          toReturn = true
        }
        break;
      case 'today':
        if (goal.dateStart < today) {
          goal.active = false
        } else if (goal.dateStart === today) {
          if (goal.wordCountStart === 0) {
            goal.wordCountStart = initialWordCount
          }

          goal.wordsWritten = project.wordCount - goal.wordCountStart
          if (goal.wordsWritten >= goal.wordCountTarget) {
            goal.completed = true
            goal.active = false
            toReturn = true
          }

        }

        break;

    }

  })

  return toReturn
}