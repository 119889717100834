import React from "react"
import styled from "styled-components"

const Wrapper = styled.span`
  font-weight: bold;
  color: var(--error-color);
  background-color: var(--error-background-color);
  padding: 10px;
  border-radius: 28px;
  box-sizing: border-box;
  margin: 0px 5px;
  font-style: italic;
`

const NoteNotFound = () => {
  if (window.location.search.indexOf("error=not_found") > -1) {
    return (
      <div>
        <Wrapper>Unable to find specified note.</Wrapper>
      </div>
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}

export default NoteNotFound
