import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
  grid-gap: 10px;
  overflow: hidden;
  border-radius: 0px 0px 8px 8px;
`

const ModalActions = (props: any) => {
  return <Wrapper>{props.children}</Wrapper>
}

export default ModalActions
