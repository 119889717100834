import sanitizeHTML from "sanitize-html"

export const generateRandomId = () => {
  for (var c = ""; c.length < 32; ) c += Math.random().toString(36).substr(2, 1)
  return c
}

export const sanitizeText = (dirtyText: string) => {
  let textToBeCleaned: string = dirtyText.replace(/↵/gi, "")

  const div = document.createElement("div")
  div.innerHTML = textToBeCleaned
  return div.textContent || div.innerText || ""
}

export const sanitizeLocationName = (locationName: string) => {
  let sanitized: string = locationName
    .replace(/^([^,*.]+[.*,])/, "")
    .replace(/&#8288;/g, "")
    .trim()

  if (sanitized[0] === "." || sanitized[0] === ",") {
    sanitized = sanitized.substr(1).trim()
  }

  return sanitized
}

export const sanitizeCharacterName = (characterName: string) => {
  if (!characterName) return ""
  if (characterName.substr(0, 1) === "@") {
    characterName = characterName.substr(1)
  }

  return characterName
    .replace("CONT'D", "")
    .replace("cont'd", "")
    .replace("Cont'd", "")
    .replace("CON'T", "")
    .replace("con't", "")
    .replace("Con't", "")
    .replace("CONTINUED", "")
    .replace("Continued", "")
    .replace("continued", "")
    .split("(")
    .filter((asset: string) => asset.indexOf(")") === -1)[0]
    .trim()
}

export const sanitizeFileName = (filename: string) => {
  if (!filename) return ""
  return filename
    .replace(/\.[^/.]+$/, "")
    .replace(/_/g, " ")
    .replace(/-/g, " ")
}

const convertLineBreaksInPastedData = (
  pasteData: string,
  screenplay: boolean
) => {
  let split: Array<any> = pasteData
    .split(/\r?\n/g)
    .filter((pd: string) => pd.trim() !== "")

  return split.map((pd: string) => {
    let pd2: any = document.createElement("p")
    if (screenplay === true) {
      pd2.className = "screenplay-action"
    }
    pd2.innerHTML = pd

    return pd2
  })
}

export const sanitizeHTMLPaste = (pasteData: string, screenplay: boolean) => {
  // const options: any = {
  //   allowedTags: ["b", "em", "i", "u", "strong", "p"],
  //   allowedAttributes: {
  //     p: ["class"],
  //     span: ["class", "id"],
  //   },
  // }

  // const sanitized: string = sanitizeHTML(pasteData, options)

  // console.log(convertLineBreaksInPastedData(sanitized))
  return convertLineBreaksInPastedData(pasteData, screenplay)
}

export const htmlToElements = (html: string) => {
  var template = document.createElement("template")
  template.innerHTML = html
  return template.content.childNodes
}

export const filterForSearchTerms = (asset: any, searchTerms: string) => {
  let match: boolean = false
  let numberOfMatches: number = 0

  const searchTermsArray = searchTerms
    .toUpperCase()
    .replace(/,/g, "")
    .split(" ")
    .filter((term: string) => term > "")

  searchTermsArray.forEach((searchTerm: string) => {
    if (
      searchTerm > "" &&
      ((asset.title &&
        asset.title.toUpperCase().includes(searchTerm.toUpperCase().trim())) ||
        (asset.tags &&
          asset.tags.toUpperCase().includes(searchTerm.toUpperCase())))
    ) {
      match = true
      numberOfMatches++
    }
  })

  if (searchTerms.trim() === "") {
    match = true
  } else if (
    searchTermsArray.length > 0 &&
    numberOfMatches < searchTermsArray.length
  ) {
    match = false
  }

  return match
}

export const getFileExtension = (filename: string) => {
  const re: RegExp = /(?:\.([^.]+))?$/
  const results: RegExpExecArray | null = re.exec(filename)
  if (!results || results.length === 0 || !results[1]) return false
  return results[1].split("?")[0]
}

export const isValidFileForImport = (filename: string) => {
  const validFileFormats: Array<string> = [
    "txt",
    "docx",
    "fountain",
    "fdx",
    "json",
  ]
  const ext: string = getFileExtension(filename) || ""

  if (
    validFileFormats.filter((format: string) => format === ext.toLowerCase())
      .length > 0
  ) {
    return true
  }
  return false
}

export const validateUrl = (value: string) => {
  try {
    const url: string | URL | undefined = new URL(value)
    return true
  } catch (e) {
    return false
  }
}

export const validateImageUrl = (value: string) => {
  const imageTypes: Array<string> = ["jpg", "jpeg", "png", "gif"]

  if (
    validateUrl(value) &&
    imageTypes.includes(getFileExtension(value) as string)
  )
    return true
  return false
}

export const validateVideoUrl = (value: string) => {
  if (validateUrl(value)) {
    const url: string | URL | undefined = new URL(value)

    if (
      url.host.indexOf("youtube", 0) > -1 ||
      url.host.indexOf("vimeo", 0) > -1
    ) {
      return true
    } else {
      return false
    }
  }
  return false
}

export const extractVideoIdFromYoutubeLink = (link: string) => {
  const searchParams = new URL(link).search.substr(1).split("&")
  let videoId: string = ""
  searchParams.forEach((param: string) => {
    const parsedParam = param.split("=")
    if (parsedParam[0] === "v") videoId = parsedParam[1]
  })

  return videoId
}

export const extractVideoIdFromVimeoLink = (link: string) => {
  return new URL(link).pathname.substr(1)
}
