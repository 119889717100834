import React from "react"
import styled from "styled-components"

import SubHeader from "../../../common/SubHeader/SubHeader"
import {
  NoteInterface,
  ProjectInterface,
  StateInterface,
} from "../../../utils/Interfaces"
import { useSelector, } from "react-redux"
import { currentProject } from "../../../utils/State"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePlus, faLightbulbOn, faTelescope } from "@fortawesome/pro-regular-svg-icons"

interface SubHeaderNoteProps {
  children?: any
  setNoteFilterType: Function
  notes: Array<NoteInterface>
  noteFilterType: string
  createNoteAndRedirect: Function
}

const Content = styled.div`
  display: inline-flex;
  color: var(--themed-font-color);
  font-size: 12px;
  text-transform: uppercase;
  width: 100%;

  :hover {
    color: var(--themed-bg-color);
  }

  @media (min-width: 768px) {
    display: none;
  }
`

const NoteTypeOption = styled.div<{ current: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  padding: 6px 5px 5px 5px;
  color: var(--themed-font-color);
  
  ${props => props.current === true && 'font-weight: bold; color: var(--white-color); background-color: var(--brand-primary);'}
  margin-right: 5px;

  svg {
    padding-top: 4px;
    padding-right: 8px;
    color: var(--themed-font-color);
  }
`

const SubHeaderNoteIndex = (props: SubHeaderNoteProps) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )

  const {
    setNoteFilterType,
    noteFilterType,
    notes,
    createNoteAndRedirect
  } = props

  return (
    <SubHeader>
      <Content>
        <NoteTypeOption onClick={() => setNoteFilterType("idea")} current={noteFilterType === "idea"}>
          <FontAwesomeIcon icon={faLightbulbOn} /><span>Ideas</span>
        </NoteTypeOption>
        <NoteTypeOption onClick={() => setNoteFilterType("research")} current={noteFilterType === "research"}>
          <FontAwesomeIcon icon={faTelescope} /><span>Research</span>
        </NoteTypeOption>
        <NoteTypeOption onClick={() => createNoteAndRedirect()} current={false}>
          <FontAwesomeIcon icon={faFilePlus} />
            <span>New Note</span>
          </NoteTypeOption>
      </Content>
    </SubHeader>
  )
}

export default SubHeaderNoteIndex
