import React from "react";
import styled from "styled-components";
import { ConfigObjectInterface, StateInterface } from "../../utils/Interfaces";
import { useSelector } from "react-redux";
import { getConfig } from "../../utils/State";

const Wrapper = styled.div<{ focusMode: boolean }>`
  margin: -10px 0px 10px 0px;
  box-sizing: border-box;

  padding-left: 46px;

  border-bottom: 1px solid var(--themed-subhead-color);
  background-color: var(--themed-light-bg);
  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 700px) {
    padding-left: 25px;
  }

  @media print {
    visibility: hidden;
  }
  width: 100%;
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);
  /* font-family: "EB Garamond", serif; */
  ${(props) =>
  props.focusMode === true
    ? "visibility: hidden; opacity: 0;"
    : "visibility: visible; opacity: 1;"}
`;

interface SubHeaderProps {
  children?: any;
}

const SubHeader = (props: SubHeaderProps) => {
  const config: ConfigObjectInterface = useSelector((state: StateInterface) =>
    getConfig(state)
  );
  let focusMode: boolean = config.focusMode;
  return <Wrapper focusMode={focusMode}>{props.children}</Wrapper>;
};

export default SubHeader;
