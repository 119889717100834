import React, { useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"

import { currentNote, currentProject } from "../../utils/State"

import EditableText from "../../components/Notes/EditableText/EditableText"
import NoteTabs from "../../components/Notes/NoteTabs/NoteTabs"
import NoteItems from "../../components/Notes/NoteItems/NoteItems"

import EditNoteModal from "../../modals/EditNoteModal/EditNoteModal"
import Header from "../../common/Header/Header"
import { navigate } from "@reach/router"
import {
  NoteItemInterface,
  DispatchObjectInterface,
  StateInterface,
  Dispatch_UpdateNoteItemInterface,
  ProjectInterface,
} from "../../utils/Interfaces"
import NotesFooter from "../../components/Notes/NotesFooter/NotesFooter"
import SubHeaderNote from "../../components/Notes/SubHeaderNote/SubHeaderNote"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr 180px;
  height: calc(100vh - 97px);

  @media (max-width: 480px) {
    grid-template-columns: 38px 1fr;
    height: calc(100vh - 127px);
  }
`

interface NoteProps {
  noteId?: string
  path?: string
  projectId?: string
}

const Note = (props: NoteProps) => {
  let { noteId } = props
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state, props.projectId)
  )

  if (noteId === "") navigate(`/projects/${project!.id}/note`)
  if (noteId === "latest") noteId = project!.currentNote

  const dispatch = useDispatch()
  if (noteId !== project!.currentNote) {
    let toDispatch = { type: "select_note", value: noteId }
    dispatch(toDispatch)
  }

  const note = useSelector((state: StateInterface) => currentNote(state))

  // TODO: Abstract this into redux
  const [showEditNoteModal, setShowEditNoteModal] = useState(false)
  const [editNoteId, setEditNoteId] = useState("")

  if (!note) {
    navigate(`/projects/${project!.id}/note?error=not_found`)
    return (
      <div>
        <Header />
      </div>
    )
  }

  let items = note.items

  let itemLocations = note.itemLocations

  const addItem = (itemValues: NoteItemInterface) => {
    const toDispatch: DispatchObjectInterface = {
      type: "add_note_item",
      value: { ...itemValues, noteId: noteId },
    }
    dispatch(toDispatch)
  }

  const updateItem = (props: Dispatch_UpdateNoteItemInterface) => {
    const toDispatch = {
      type: "update_note_item",
      value: props,
    }
    dispatch(toDispatch)
    setShowEditNoteModal(false)
  }

  const showItemInfo = (id: string) => {
    setEditNoteId(id)
    setShowEditNoteModal(true)
  }

  const itemToEdit = (id: string) => {
    return items.find((item: NoteItemInterface) => item.id === id)
  }

  return (
    <div>
      <Header />
      <SubHeaderNote />
      <Wrapper>
        <NoteTabs
          items={items}
          showItemInfo={showItemInfo}
          itemLocations={itemLocations}
        />
        <EditableText addItem={addItem} showItemInfo={showItemInfo} />
        <NoteItems items={items} showItemInfo={showItemInfo} />
        {showEditNoteModal === true && (
          <EditNoteModal
            closeModal={() => setShowEditNoteModal(false)}
            item={itemToEdit(editNoteId)}
            updateItem={updateItem}
            mode={"note"}
          />
        )}
      </Wrapper>
      <NotesFooter />
    </div>
  )
}

export default Note
