import React from "react"

import styled from "styled-components"
import {
  AssetInterface
} from "../../utils/Interfaces"

import { CompactPicker } from "react-color"
import { useDispatch } from "react-redux"

const Wrapper = styled.div``

const AssetColorPicker = ({
  asset,
  onUpdateColour
}: {
  asset:
    | AssetInterface
  onUpdateColour?: Function
}) => {
  const dispatch = useDispatch()

  const updateAssetColor = (event: any) => {
    const toDispatch = {
      type: "update_note_item",
      value: {
        id: asset.id,
        color: event.hex,
        title: asset.title,
        type: asset.type,
        noteMeta: asset.noteMeta
      }
    }
    dispatch(toDispatch)
    if (onUpdateColour) onUpdateColour()
  }
  return (
    <Wrapper>
      <div>
        <CompactPicker color={asset.color || ""} onChange={updateAssetColor} />
      </div>
    </Wrapper>
  )
}

export default AssetColorPicker
