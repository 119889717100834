import React, { MouseEventHandler, useState } from "react"
// import styled from "styled-components"
import ModalBase from "../../common/Modal/ModalBase/ModalBase"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle"
import { db } from "../../_config/firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBan,
  faEnvelope,
  faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons"
import LoadingIcon from "../../common/LoadingIcon/LoadingIcon"

const SendFeedbackModal = ({
  closeModal,
}: {
  closeModal: MouseEventHandler
}) => {
  const sendFeedback = (e: any) => {
    if (submitting === true) return
    setSubmitting(true)
    db.collection("feedback")
      .add({ email: userEmail, name: userName, feedback: feedback })
      .then((results: any) => {
        setShowSuccess(true)
        setSubmitting(false)
        setShowError(false)
      })
      .catch((results: any) => {
        setShowSuccess(false)
        setSubmitting(false)
        setShowError(true)
      })
  }

  const [submitting, setSubmitting] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [userName, setUserName] = useState("")
  const [userEmail, setUserEmail] = useState("")
  const [feedback, setFeedback] = useState("")

  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Send Feedback!</ModalTitle>
        {showError === true && <p>There was an error: please try again.</p>}
        {showSuccess === false && (
          <React.Fragment>
            <p>
              Do you have feedback for Hilker? Problems, suggestions, feature
              requests? Enter your feedback below, hit the SEND button, and
              we'll get it!{" "}
            </p>
            <input
              type="text"
              value={userName}
              autoFocus
              onChange={(e: any) => setUserName(e.currentTarget.value)}
              placeholder="Your Name (optional)"
            />
            <input
              type="email"
              value={userEmail}
              onChange={(e: any) => setUserEmail(e.currentTarget.value)}
              placeholder="your@email.com (optional)"
            />
            <textarea
              placeholder="Give us your feedback!"
              value={feedback}
              onChange={(e: any) => setFeedback(e.currentTarget.value)}
            ></textarea>
          </React.Fragment>
        )}

        {showSuccess === false && submitting === false && (
          <ModalActions>
            <ModalActionButton type="cancel" onClick={closeModal}>
              <FontAwesomeIcon icon={faBan} /> CANCEL
            </ModalActionButton>
            <ModalActionButton type="okay" onClick={sendFeedback}>
              <FontAwesomeIcon icon={faEnvelope} /> SEND
            </ModalActionButton>
          </ModalActions>
        )}

        {submitting === true && (
          <React.Fragment>
            <ModalActions>
              <ModalActionButton type="okay" onClick={() => {}}>
                <LoadingIcon />
              </ModalActionButton>
            </ModalActions>
          </React.Fragment>
        )}

        {showSuccess === true && (
          <React.Fragment>
            <p>Thanks for the feedback!</p>
            <ModalActions>
              <ModalActionButton
                type="okay"
                onClick={(e: any) => closeModal(e)}
              >
                <FontAwesomeIcon icon={faCheckCircle} /> Okay
              </ModalActionButton>
            </ModalActions>
          </React.Fragment>
        )}
      </React.Fragment>
    </ModalBase>
  )
}

export default SendFeedbackModal
