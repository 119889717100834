import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStickyNote } from "@fortawesome/pro-regular-svg-icons"
import { navigate } from "@reach/router"
import { ProjectInterface } from "../../../utils/Interfaces"

const Container = styled.div<{ current: boolean }>`
  border-radius: 4px;
  padding: 5px;
  margin: 5px 0px 5px 20px;
  font-size: 13px;
  cursor: pointer;
  font-weight: ${props => (props.current === true ? "bold" : "normal")};

  :hover {
    background-color: var(--themed-input-focus-color);
  }

  svg {
    color: ${props =>
      props.current === true
        ? "var(--brand-primary)"
        : "var(--themed-font-color)"};
  }

  svg:hover {
    color: var(--themed-font-color);
  }
`

const WriteAreaScene = ({
  id,
  selectScene,
  currentSceneId,
  index,
  title,
  project
}: {
  id: string
  selectScene: Function
  currentSceneId: string
  index: number
  title: string | null | undefined
  project: ProjectInterface
}) => {
  const doSelectScene = () => {
    navigate(`/projects/${project.id}/write/${id}`)
  }
  return (
    <Container onClick={doSelectScene} current={currentSceneId === id}>
      <FontAwesomeIcon icon={faStickyNote} />
      {` ${title || id}`}
    </Container>
  )
}

export default WriteAreaScene
