import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import Header from "../../common/Header/Header"
import WriteSections from "../../components/Write/WriteSections/WriteSections"
import WriteTextArea from "../../components/Write/WriteTextArea/WriteTextArea"
import WriteMeta from "../../components/Write/WriteMeta/WriteMeta"
import WriteFooter from "../../components/Write/WriteFooter/WriteFooter"
import EditNoteModal from "../../modals/EditNoteModal/EditNoteModal"
import {
  NoteItemInterface,
  SceneInterface,
  StateInterface,
  ProjectInterface,
  Dispatch_UpdateNoteItemInterface,
  StorySectionInterface,
  AssetInterface,
} from "../../utils/Interfaces"
import { currentSceneObject, currentProject } from "../../utils/State"
import SubHeaderWrite from "../../components/Write/SubHeaderWrite/SubHeaderWrite"

const Wrapper = styled.div``

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  grid-gap: 10px;
  height: calc(100vh - 100px);

  @media (max-width: 768px) {
    grid-template-columns: 1px 1fr 1px;
    grid-gap: 0px;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1px calc(100% - 2px) 1px;
    grid-gap: 0px;
  }
`

const InvalidSceneId = styled.div`
  font-style: italic;
  color: var(--themed-no-content-color);
  font-size: 16px;
`

const Padding = styled.div`
  width: 100%;
  height: 10px;
`

interface WriteProps {
  sceneId?: string
  path?: string
  projectId?: string
}

const Write = ({ sceneId, projectId }: WriteProps) => {
  const dispatch = useDispatch()
  const [showEditNoteModal, setShowEditNoteModal] = useState(false)
  const [editNoteId, setEditNoteId] = useState("")
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state, projectId)
  )

  const selectScene = (id: string) => {
    const toDispatch = { type: "select_scene", value: { id: id } }
    dispatch(toDispatch)
  }

  const scene: SceneInterface = useSelector((state: StateInterface) =>
    currentSceneObject(project!)
  )
  if (project!.currentScene !== "home" && (sceneId === "" || !sceneId)) {
    selectScene("home")
  } else if (project!.currentScene !== sceneId && sceneId! > "") {
    selectScene(sceneId!)
  }

  useEffect(() => {
    if (sceneId) {
      selectScene(sceneId)
    } else {
      selectScene("home")
    }
  }, [])

  const showItemInfo = (id: string) => {
    setEditNoteId(id)
    setShowEditNoteModal(true)
  }

  const itemToEdit = (id: string) => {
    let item = scene.items.find((item: NoteItemInterface) => item.id === id)

    if (item) return item

    item = project!.storySections.find(
      (item: StorySectionInterface) => item.id === id
    )

    if (item) return item

    
    return project!.assets.find((asset: AssetInterface) => asset.id === id)
  }

  const updateItem = (props: Dispatch_UpdateNoteItemInterface) => {
    const toDispatch = {
      type: "update_note_item",
      value: props,
    }
    dispatch(toDispatch)
    setShowEditNoteModal(false)
  }

  let sceneExists: boolean =
    project!.scenes.find(
      (scene: SceneInterface | undefined) => scene!.id === sceneId
    ) !== undefined
  if (!sceneId || sceneId === "") sceneExists = true

  if (!sceneExists) {
  }

  return (
    <Wrapper>
      <Header />
      <SubHeaderWrite />
      <Padding />
      <Container>
        <WriteSections selectScene={selectScene} />
        {sceneExists === true && scene!.config.type !== "screenplay" && (
          <WriteTextArea showItemInfo={showItemInfo} />
        )}
        {sceneExists === true && scene!.config.type === "screenplay" && (
          <WriteTextArea showItemInfo={showItemInfo} />
        )}
        {sceneExists === true && <WriteMeta />}
        {sceneExists === false && (
          <InvalidSceneId>
            We can't find the scene you specified; please select one from the
            left.
          </InvalidSceneId>
        )}
      </Container>

      {showEditNoteModal === true && (
        <EditNoteModal
          closeModal={() => setShowEditNoteModal(false)}
          item={itemToEdit(editNoteId)}
          updateItem={updateItem}
          mode={"write"}
        />
      )}
      <WriteFooter />
    </Wrapper>
  )
}

export default Write
