import React from "react"
import { navigate } from "@reach/router"
import styled from "styled-components"
import { getAssetsByType } from "../../../utils/Assets"
import { ProjectInterface, StateInterface } from "../../../utils/Interfaces"
import { useSelector } from "react-redux"
import { currentProject, countUsesInProject } from "../../../utils/State"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIconForAssetType } from "../../../utils/Icons"
import Asset from "../Asset/Asset"

const AssetType = styled.div<{ assetType: string }>`
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--themed-font-color);
  padding: 5px;
  border-radius: 4px;

  /* border-bottom: 1px solid ${(props) => `var(--${props.assetType}-color)`}; */
  border-bottom: 1px solid var(--themed-font-color);
  svg {
    color: ${(props) => `var(--${props.assetType}-color)`};
    font-size: 20px;
    margin-right: 5px;
  }
`

const RecentAssetWrapper = styled.div`
  margin-bottom: 20px;
`

const RecentAssets = ({ assetType }: { assetType: string }) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )

  const getFormattedAssetType = (type: string) => {
    const result: string = type.replace(/([A-Z])/g, " $1")
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  const assetsOfType: Array<any> = getAssetsByType(project!, assetType)

  if (assetsOfType.length === 0) {
    return <div />
  }
  return (
    <RecentAssetWrapper>
      <AssetType
        assetType={assetType}
        onClick={() =>
          navigate(`/projects/${project!.id}/assets/${assetType}/`)
        }
      >
        <FontAwesomeIcon icon={getIconForAssetType(assetType)} />{" "}
        <span>Recent {getFormattedAssetType(assetType)}s</span>
      </AssetType>
      <div>
        {assetsOfType
          .slice()
          .sort((a: any, b: any) => {
            const a_updated: string = a.updated || "0"
            const b_updated: string = b.updated || "0"
            if (a_updated > b_updated) {
              return -1
            } else {
              return 1
            }
          })
          .slice(0, 3)
          .map((asset: any) => {
            const numberOfUsesInProject: number = countUsesInProject(
              project!,
              asset
            )
            return (
              <Asset
                key={`recent-asset-${assetType}-${asset.id}`}
                asset={asset}
                selectedAssetType={assetType}
                numberOfUsesInProject={numberOfUsesInProject}
              />
            )
          })}
      </div>
    </RecentAssetWrapper>
  )
}

export default RecentAssets
