import styled from "styled-components"

export const Wrapper = styled.div``

export const Factoid = styled.div`
  margin-bottom: 10px;
  border-top: 1px solid #f1f1f1;
  padding: 10px;
  box-sizing: border-box;
`

export const Supplemental = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  overflow-y: hidden;
`

export const SelectedText = styled.span`
  background-color: var(--themed-selected-text-bg-color);
  color: var(--black-color);
  font-style: italic;
  font-size: 12px;
  box-sizing: border-box;
  padding: 5px;
`

export const FactoidMeta = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: 1fr 40px;
`

export const DeleteFactoidLink = styled.div`
  color: var(--themed-font-color);
  background-color: var(--cancel-button-color);
  border-radius: 0px 4px 4px 0px;
  cursor: pointer;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  :hover {
    background-color: var(--cancel-button-color--hover);
  }
`

export const FactoidLink = styled.div`
  font-size: 12px;
  border-radius: 4px 0px 0px 4px;
  border-left: 1px solid var(--themed-subhead-color);
  border-top: 1px solid var(--themed-subhead-color);
  border-bottom: 1px solid var(--themed-subhead-color);
  line-height: 2em;
  padding: 5px 10px;
  box-sizing: border-box;
  font-style: italic;

  a {
    color: var(--brand-primary);
  }
`

// TODO: Make global "none of this" message
export const NoFactoids = styled.div`
  font-size: 12px;
  font-style: italic;
  color: var(--themed-no-content-color);
  margin-bottom: 20px;
`

export const NewFactoid = styled.div`
  display: grid;
  grid-template-columns: 1fr 55px;
  grid-gap: 5px;
`

export const NewFactoidButton = styled.button`
  font-size: 26px;
  padding: 10px;
  background-color: var(--themed-bg-color);
  border: none;
  line-height: 0em;
  cursor: pointer;

  color: var(--brand-primary);
  :hover {
    color: var(--themed-font-color);
  }
`
