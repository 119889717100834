import React, { useState, SyntheticEvent } from "react"
import styled from "styled-components"
import Header from "../../common/Header/Header"
import { useSelector, useDispatch } from "react-redux"
import {
  StateInterface,
  ProjectInterface,
  ProjectConfigObjectInterface,
  ActivityInterface,
  ProjectSyncInterface,
  UserInterface,
  NoteInterface,
  SceneInterface,
} from "../../utils/Interfaces"
import { projectById } from "../../utils/State"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCaretLeft,
  faPrint,
  faFileDownload,
  faCaretDown,
  faTrashAlt,
  faBook,
  faHistory,
  faChevronLeft,
  faFile,
} from "@fortawesome/free-solid-svg-icons"
import {
  faClipboard,
  faPen,
  faBoxFull,
  faCameraMovie,
} from "@fortawesome/pro-regular-svg-icons"
import { Link, navigate } from "@reach/router"
import { DownloadProject } from "../../utils/Download"
import PrintModal from "../../modals/PrintModal/PrintModal"
import ActivityInfo from "../../components/Projects/ActivityInfo/ActivityInfo"
import ProjectsFooter from "../../components/Projects/ProjectsFooter/ProjectsFooter"
import Spacer from "../../common/Spacer/Spacer"
import AlertBox from "../../common/AlertBox/AlertBox"
import ProjectMeta from "../../common/ProjectMeta/ProjectMeta"
import ModalActions from "../../common/Modal/ModalActions/ModalActions"
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton"
import StandardButton from "../../common/StandardButton/StandardButton"
import { saveSelection, restoreSelection } from "../../utils/Selection"
import {
  faCloudRainbow,
  faCloud,
  faLineHeight,
  faFileAlt,
} from "@fortawesome/pro-solid-svg-icons"
import { faMarkdown } from "@fortawesome/free-brands-svg-icons"
import ProjectGoals from "../../components/Projects/ProjectGoals/ProjectGoals"
import DeleteLocalProjectModal from "../../modals/DeleteLocalProjectModal/DeleteLocalProjectModal"

const Wrapper = styled.div``

const ProjectName = styled.input`
  box-sizing: border-box;
  font-size: 16px;
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
`

const ProjectDescription = styled.textarea`
  font-size: 16px;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;
`

const LoginPrompt = styled.div`
  text-align: center;
  line-height: 2.9em;
`

const ProjectInfo = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 10px;

  @media (max-width: 480px) {
    grid-template-columns: 60px 1fr;
  }
`

const ProjectListingSubHead = styled.div`
  background-color: var(--themed-subhead-color);
  border-radius: 4px;
  margin: 10px 0px;

  span {
    font-size: 18px;
    color: var(--themed-font-color);
    font-weight: bold;
    text-transform: uppercase;
    margin: 0px 5px;
    padding: 0px 5px;
    background-color: var(--themed-bg-color);

    svg {
      padding-right: 5px;
      color: var(--brand-primary);
    }
  }
`

// TODO: Make this a separate component
const PrintOption = styled.span`
  font-size: 18px;
  color: var(--black-color);
  text-align: center;
  cursor: pointer;
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  margin-left: 5px;

  :hover {
    color: green;
  }
`

const WriteOptions = styled.div`
  font-size: 12px;
  padding: 10px;
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
`

const ActivitiesTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  cursor: pointer;

  strong {
    line-height: 2em;
  }

  span {
    svg {
      font-size: 24px;
    }
    cursor: pointer;
    padding: 0px 10px;

    :hover {
      color: var(--brand-primary);
    }
  }

  :hover {
    background-color: var(--themed-input-focus-color);
    span {
      color: var(--brand-primary);
    }
  }
`

const ActivitiesList = styled.div`
  margin: 0px auto 20px auto;
  padding: 0px 10px;
  box-sizing: border-box;
`

const NoActivities = styled.div`
  font-size: 14px;
  font-style: italic;
  color: var(--themed-no-content-color);
`

const Content = styled.div`
  margin: 0px 10px;
`

const ProjectTypeIcon = styled.span`
  font-size: 60px;
  line-height: 1.6em;
  svg {
    margin-right: 5px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
    line-height: 2em;
  }
`

const RecentlyWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  grid-gap: 10px;
  margin: 0px 5px 20px 0px;

  svg {
    font-size: 20px;
  }

  a {
    color: var(--themed-font-color) !important;
  }
`

const Projects = ({
  path,
  projectId,
}: {
  path: string
  projectId?: string | undefined | null
}) => {
  const dispatch = useDispatch()

  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => projectById(state, projectId!)
  )

  const projectSyncs: Array<ProjectSyncInterface> = useSelector(
    (state: StateInterface) => state.projectSyncs
  )

  const currentProject: ProjectInterface | null = useSelector(
    (state: StateInterface) => projectById(state, state.currentProject)
  )

  const user: UserInterface | undefined | null = useSelector(
    (state: StateInterface) => state.user
  )

  const updateProjectName = (e: any) => {
    setProjectName(e.currentTarget.value)
    const toDispatch = {
      type: "update_project",
      value: { name: e.currentTarget.value, description: projectDescription },
    }

    dispatch(toDispatch)
  }

  const updateProjectDescription = (e: any) => {
    setProjectDescription(e.currentTarget.value)
    const toDispatch = {
      type: "update_project",
      value: { name: projectName, description: e.currentTarget.value },
    }

    dispatch(toDispatch)
  }

  const deleteLocalProject = () => {
    const toDispatch = {
      type: "delete_project",
      value: project!.id,
    }

    navigate("/projects")
    dispatch(toDispatch)
  }


  const selectProject = (id: string) => {
    const toDispatch = {
      type: "select_project",
      value: id,
    }

    dispatch(toDispatch)
  }

  if (currentProject! && project! && currentProject!.id !== project!.id)
    selectProject(project!.id)

  const toggleOptionsLineSpacing = (e: SyntheticEvent) => {
    let newLineSpacing: number = project!.config.lineSpacing

    switch (project!.config.lineSpacing) {
      case 1:
        newLineSpacing = 1.5
        break
      case 1.5:
        newLineSpacing = 2
        break
      case 2:
        newLineSpacing = 3
        break
      case 3:
        newLineSpacing = 1
        break
    }
    const toDispatch = {
      type: "update_project_config",
      value: {
        projectId: project!.id,
        config: { ...project!.config, lineSpacing: newLineSpacing },
      },
    }
    dispatch(toDispatch)
  }

  const addToProjectSync = (orojectId: string) => {
    const toDispatch = {
      type: "add_to_project_sync",
      value: {
        projectId: project!.id,
      },
    }

    dispatch(toDispatch)
  }

  const removeFromProjectSync = (orojectId: string) => {
    const toDispatch = {
      type: "remove_from_project_sync",
      value: {
        projectId: project!.id,
      },
    }

    dispatch(toDispatch)
  }

  const toggleOptionsRender = (e: SyntheticEvent) => {
    const newRender: string =
      project!.config.render === "html" ? "markdown" : "html"
    const toDispatch = {
      type: "update_project_config",
      value: {
        projectId: project!.id,
        config: { ...project!.config, render: newRender },
      },
    }
    dispatch(toDispatch)
  }
  let newProjectName = ""
  let newProjectDescription = ""

  if (project) {
    newProjectName = project!.name
    newProjectDescription = project!.description
  }

  const sceneConfig: ProjectConfigObjectInterface = currentProject!.config
  const [showActivities, setShowActivities] = useState(false)
  const [projectName, setProjectName] = useState(newProjectName)
  const [projectDescription, setProjectDescription] = useState(
    newProjectDescription
  )

  const forceRerender: number = useSelector(
    (state: StateInterface) => state.forceRerender
  )
  React.useEffect(() => {
    const sel: any = saveSelection()
    setProjectName(project!.name)
    setProjectDescription(project!.description)
    restoreSelection(sel)
  }, [forceRerender])

  React.useEffect(() => {
    setProjectName(currentProject!.name)
    setProjectDescription(currentProject!.description)
  }, [currentProject!.id])
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false)

  const listOfActivities: Array<ActivityInterface> = [
    ...currentProject!.activities,
  ].reverse()

  const toggleShowActivities = () => {
    setShowActivities(!showActivities)
  }

  const recentNote: NoteInterface | undefined = currentProject!.notes.find(
    (note: NoteInterface) => currentProject!.currentNote === note.id
  )

  let recentAsset: any = null

  const recentScene: SceneInterface | undefined = currentProject!.scenes.find(
    (scene: SceneInterface) => currentProject!.lastViewedScene === scene.id
  )

  if (!project) return <div />

  return (
    <Wrapper>
      <Header />
      <Content>
        {window.location.search.indexOf("created=true") > -1 && (
          <AlertBox allowHide={true}>Success! Your project has been created.</AlertBox>
        )}

        <ProjectListingSubHead>
          <span>
            <Link to="/projects" title="Back to Project Listing">
              <FontAwesomeIcon icon={faChevronLeft} />
            </Link>
            Project Info
          </span>
        </ProjectListingSubHead>

        <ProjectInfo>
          <ProjectTypeIcon>
            <FontAwesomeIcon
              icon={
                project.config.type === "manuscript" ? faBook : faCameraMovie
              }
            />
          </ProjectTypeIcon>
          <div>
            <ProjectName
              type="text"
              value={projectName}
              onChange={updateProjectName}
              placeholder="Project Name"
            />
            <ProjectDescription
              placeholder="Project Description"
              value={projectDescription}
              onChange={updateProjectDescription}
            ></ProjectDescription>
          </div>
        </ProjectInfo>
        <ProjectListingSubHead>
          <span>Recently Edited</span>
        </ProjectListingSubHead>

        <RecentlyWrapper>
          <FontAwesomeIcon icon={faClipboard} />{" "}
          {recentNote !== undefined && (
            <Link to={`/projects/${currentProject!.id}/note/${recentNote.id}`}>
              {recentNote.title > "" ? recentNote.title : recentNote.id}
            </Link>
          )}
          {recentNote === undefined && (
            <Link to={`/projects/${currentProject!.id}/note`}>
              Create your first Note
            </Link>
          )}
          <FontAwesomeIcon icon={faPen} />{" "}
          {recentScene !== undefined && (
            <Link
              to={`/projects/${currentProject!.id}/write/${recentScene.id}`}
            >
              {recentScene.title > "" ? recentScene.title : recentScene.id}
            </Link>
          )}
          {recentScene === undefined && currentProject!.scenes.length === 0 && (
            <Link to={`/projects/${currentProject!.id}/write`}>
              Create your first Scene
            </Link>
          )}
          {recentScene === undefined && currentProject!.scenes.length > 0 && (
            <Link to={`/projects/${currentProject!.id}/write`}>
              Continue Writing
            </Link>
          )}
        </RecentlyWrapper>
        <ProjectListingSubHead>
          <span>Options</span>
        </ProjectListingSubHead>
        <WriteOptions>
          <StandardButton onClick={toggleOptionsRender} mode="inverse">
            {sceneConfig.render === "html" ? (
              <FontAwesomeIcon icon={faFileAlt} />
            ) : (
                <FontAwesomeIcon icon={faMarkdown} />
              )}{" "}
            Render as:{" "}
            {sceneConfig.render === "html" ? "Rich Text" : "Markdown"}
          </StandardButton>
          <StandardButton onClick={toggleOptionsLineSpacing} mode="inverse">
            <FontAwesomeIcon icon={faLineHeight} /> Line Spacing:{" "}
            {sceneConfig.lineSpacing}
          </StandardButton>
          {user && user.uid && (
            <StandardButton
              mode="inverse"
              onClick={() => {
                if (
                  projectSyncs.filter(
                    (project: ProjectSyncInterface) =>
                      project.id === currentProject!.id
                  ).length > 0
                ) {
                  removeFromProjectSync(currentProject!.id)
                } else {
                  addToProjectSync(currentProject!.id)
                }
              }}
            >
              {projectSyncs.filter(
                (project: ProjectSyncInterface) =>
                  project.id === currentProject!.id
              ).length > 0 && (
                  <span>
                    <FontAwesomeIcon icon={faCloudRainbow} /> Sync: Yes
                  </span>
                )}
              {projectSyncs.filter(
                (project: ProjectSyncInterface) =>
                  project.id === currentProject!.id
              ).length === 0 && (
                  <span>
                    <FontAwesomeIcon icon={faCloud} /> Sync: No
                  </span>
                )}
            </StandardButton>
          )}
          {(!user || !user.uid) && (
            <LoginPrompt>
              <FontAwesomeIcon icon={faCloud} /> Login to sync projects between
              devices!
            </LoginPrompt>
          )}
        </WriteOptions>
        <ProjectListingSubHead>
          <span>Stats</span>
        </ProjectListingSubHead>
        <ProjectMeta projectId={currentProject!.id} />
        <ProjectListingSubHead><span>Goals</span></ProjectListingSubHead>
        <ProjectGoals modal={false} />
        <div>
          <ProjectListingSubHead>
            <span>Activities</span>
          </ProjectListingSubHead>
          <ActivitiesTitle onClick={toggleShowActivities}>
            {showActivities === false && (
              <NoActivities>
                Click to show Project Activities.{" "}
                <FontAwesomeIcon icon={faCaretLeft} />
              </NoActivities>
            )}

            {showActivities === true && listOfActivities.length === 0 && (
              <NoActivities>
                There are no activities to show.{" "}
                <FontAwesomeIcon icon={faCaretDown} />
              </NoActivities>
            )}

            {showActivities === true && listOfActivities.length > 0 && (
              <NoActivities>
                Click to hide Project Activities.{" "}
                <FontAwesomeIcon icon={faCaretDown} />
              </NoActivities>
            )}
          </ActivitiesTitle>

          {showActivities === true && (
            <ActivitiesList>
              {listOfActivities
                .slice(0, 10)
                .map((activity: ActivityInterface) => {
                  return (
                    <ActivityInfo
                      project={project!}
                      activity={activity}
                      key={`activity-list-${Math.floor(
                        Math.random() * 1000000
                      )}`}
                    />
                  )
                })}
              {project!.activities.length > 10 && (
                <StandardButton
                  onClick={() => navigate(`/projects/${projectId}/activities`)}
                >
                  View all
                </StandardButton>
              )}
            </ActivitiesList>
          )}
        </div>

        <Spacer width={"100%"} height={"40px"} />

        <ModalActions>
          <ModalActionButton
            type="delete"
            title="Delete Project"
            onClick={(e: any) => {
              e.stopPropagation()
              setShowDeleteProjectModal(true)}
            }
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Delete Project
          </ModalActionButton>
          <ModalActionButton
            type="okay"
            title="Export Project"
            onClick={() => {
              DownloadProject(project)
            }}
          >
            <FontAwesomeIcon icon={faFileDownload} /> Download Project Files
          </ModalActionButton>
          <ModalActionButton
            type="okay"
            title="Print"
            onClick={() => {
              setShowPrintModal(true)
            }}
          >
            <FontAwesomeIcon icon={faPrint} /> Print Project
          </ModalActionButton>
        </ModalActions>
        <Spacer width={"100%"} height={"80px"} />

        {showPrintModal === true && (
          <PrintModal
            printType="text"
            projectId={project!.id}
            closeModal={() => setShowPrintModal(false)}
          />
        )}
        {showDeleteProjectModal === true && (
          <DeleteLocalProjectModal
            closeModal={() => setShowDeleteProjectModal(false)}
            deleteLocalProject={deleteLocalProject}
          />
        )}
        <Spacer width={"100%"} height={"80px"} />
      </Content>
      <ProjectsFooter />
    </Wrapper>
  )
}

export default Projects
