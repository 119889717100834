import React from 'react'
import WriteMetaItem from "../WriteMetaItem/WriteMetaItem"

import { WriteMetaItemInterface, AssetInterface, ProjectInterface, StateInterface } from '../../../utils/Interfaces'
import { getFullItem, currentProject } from '../../../utils/State'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

const Section = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: var(--themed-subhead-color);
  text-transform: uppercase;
  margin-bottom: 5px;
`

const ItemsWrapper = styled.div`
  margin-bottom: 30px;
`

type WriteMetaItemsByTypeProps = {
  items: Array<WriteMetaItemInterface>
  assetType: string
  showItemInfo: Function
}

const WriteMetaItemsByType = ({ items, showItemInfo, assetType }: WriteMetaItemsByTypeProps) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  
  return (
  <React.Fragment>
    {items.filter((item: WriteMetaItemInterface) => item.type === assetType)
          .length > 0 &&
          <React.Fragment>
            <Section>
              {assetType} (
              {
                items.filter(
                  (item: WriteMetaItemInterface) => item.type === assetType
                ).length
              }
              )
            </Section>
            <ItemsWrapper>
              {items
                .filter(
                  (item: WriteMetaItemInterface) => item.type === assetType
                )
                .map((item, index) => {
                  const fullItem: any = getFullItem(project, item.type, item.id)
                  return (
                    <WriteMetaItem
                      key={`noteitem_${item.id}`}
                      item={fullItem}
                      showItemInfo={showItemInfo}
                      shallowItem={item}
                    >
                      {fullItem.selectedText}
                      {index}
                    </WriteMetaItem>
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
}</React.Fragment>)
}

export default WriteMetaItemsByType