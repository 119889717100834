import React from "react"
import styled from "styled-components"
import Header from "../../common/Header/Header"
import HomeFooter from "../../components/Home/HomeFooter/HomeFooter"

const Wrapper = styled.div``

const Content = styled.div`
  padding: 10px;
`

interface TermsOfServiceProps {
  path?: string
}

const TermsOfService = (props: TermsOfServiceProps) => {
  return (
    <Wrapper>
      <Header />
      <Content>
        <h1>Terms of Service</h1>
        <p>Terms of service will go here.</p>
      </Content>
      <HomeFooter />
    </Wrapper>
  )
}

export default TermsOfService
