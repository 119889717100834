import React from "react"
import HelpImage from "../../common/HelpImage/HelpImage"
import { Link } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faEraser,
  faEye,
  faEllipsisH,
  faFileDownload,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons"
import {
  faEdit,
  faPlus,
  faPlusCircle,
  faBan,
  faChevronDoubleRight,
  faTrash,
  faTimesCircle,
  faExpandArrows,
  faFolderPlus,
  faFilePlus,
  faPrint,
  faBooks,
  faCommentExclamation,
  faPageBreak,
} from "@fortawesome/pro-regular-svg-icons"
import { getIconForAssetType } from "../../utils/Icons"
import Help from "./Help"

export interface HelpQuestionInterface {
  question: string
  answer: any
}

export interface HelpQuestionTypeInterface {
  name: string
  questions: Array<HelpQuestionInterface>
}

const goToItem = (e: any, hash: string) => {
  e.preventDefault()
  window.history.pushState(
    {},
    "Hilker",
    hash
  )

  const popStateEvent = new PopStateEvent('popstate', { state: {} });
  dispatchEvent(popStateEvent);
}

export const questionTypes: Array<HelpQuestionTypeInterface> = [
  {
    name: "General",
    questions: [
      {
        question: "What is Hilker?",
        answer:
          "Hilker is a writing app I'm developing, focused on helping create awesome long-form works, like novels, short stories, and screenplays. It's a website.",
      },
      {
        question: "Is it done?",
        answer:
          "No! Hilker is very much in development, so I'm adding new features frequently, and changing things around. That can be both a plus and a minus, though it's far enough along at this point that nothing I introduce will massively change the interface or user experience.",
      },
      { question: "So Hilker is a Beta?", answer: "Yep!" },
      {
        question: "Why is this a website, instead of an app?",
        answer:
          "I wanted to create a writing environment that I could use on a device that didn't have much else on it, like a Chromebook, where I wouldn't have the distraction of all of the apps I love to use but which eat up way more of my time than I'd like to admit.",
      },
      {
        question: "What does it cost?",
        answer:
          "Right now, it's free! In future, if there's enough user interest, I'll have a paid tier, which will likely involve some extra online synching options, and/or some the ability to use X number of projects at the same time. Even if I add a premium tier at some point, however, there will always be a free version.",
      },
      {
        question: "Where is my writing saved?",
        answer: (
          <span>
            By default, your writing is saved on your own computer, in a special
            long-term browser cache. As such, you can only access what you've
            written in the web browser you've written it in. <br />
            <br />
            If you create a user account by signing up on the{" "}
            <Link to={`/user`}>User</Link> page, you can enable automated
            syncing on a per-project basis. You can enable syncing by going to
            the project page, and clicking "Click to add to sync list" to enable
            syncing, and "Click to remove" to stop syncing.
          </span>
        ),
      },
      {
        question: "When is my writing saved?",
        answer: (
          <span>
            Local saving happens 1 minute after you make a change. You'll see an
            indicator on the bottom left of the screen that shows the date of
            your last save, and whether a save is pending. (The save icon turns
            orange to denote a save is going to happen)
            <br />
            <br />
            <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fhelp_save_indicator.PNG?alt=media&token=2c8bb5bc-d5ef-4903-b53c-48c2370ef64e" />
            <br />
            <br />
            When local saving occurs (either automatically or manually), any
            projects set to sync to server will be synced.
          </span>
        ),
      },
      {
        question: "Syncing Projects on the Server",
        answer: (
          <span>
            To save a project on the server, go to your{" "}
            <Link to={`/projects`}>projects listing</Link>, and click on the
            name of project you wish to back up. Next, set your project to sync
            by clicking the link that says "Click to Add to Sync List". If you
            see "Project is set to sync to cloud." on the page, then the project
            is already being set to sync. (When you create a project, the
            default setting is for it <em>not</em> to sync to the server)
            <p>
              If you wish to stop your project from being synced, click the
              "Click to Remove" link on the page.
            </p>
          </span>
        ),
      },
      {
        question: "Manually Saving a Project",
        answer: (
          <span>
            Though Hilker automatically saves your project updates as you work,
            you can manually trigger a save. To do this, click the save icon on
            the very bottom left of the page: This will force an immediate local
            save.
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fhelp_save_indicator.PNG?alt=media&token=2c8bb5bc-d5ef-4903-b53c-48c2370ef64e" />
            </p>
            <p>
              If your project is set to sync, triggering a save manually will
              also trigger a sync.
            </p>
          </span>
        ),
      },
      {
        question: "Will this work on my mobile device?",
        answer: (
          <span>
            <em>Technically, yes.</em> At present, the interface isn't optimized
            for phone-sized devices, but it's definitely usable on a tablet. As
            I said above, it's being designed primarily for use on a
            laptop/chromebook.
          </span>
        ),
      },
      {
        question: "How do I provide feedback?",
        answer: (
          <span>
            If you've used Hilker and you have feedback -- of any kind, from
            bugs, feature requests, to endless praise -- I'd love to read it!
            You can email me at{" "}
            <a href="mailto:brian@hoggworks.com">brian@hoggworks.com</a>. I'm
            also on Twitter infrequently at{" "}
            <a href="https://twitter.com/dotboom" target="link">
              @dotboom
            </a>
            .{" "}

            <p>Also, you can click the feedback button in the top menu (<FontAwesomeIcon icon={faCommentExclamation} />), which opens a feedback modal that you can use:</p>

            <p><HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Ffeedback.PNG?alt=media&token=9a2d1617-7f79-42d1-adfb-bc86db0911f1" /></p>
          </span>
        ),
      },
    ],
  },
  {
    name: "Projects",
    questions: [
      {
        question: "How do I create a new Project?",
        answer: (
          <span>
            To create a new project, get to the{" "}
            <Link to={`/projects`}>Projects Page</Link>. You can find it by
            clicking the library icon in the top menu, then clicking "See All":
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fmenu_projects_dropdown.PNG?alt=media&token=8b995424-e906-4763-8b59-659a688373e3" />
            </p>
            Then click the Create New Project link:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fnew_project_link.PNG?alt=media&token=822094f4-59dd-4eb9-8f5f-7f1c40f3fbf0" />
            </p>
            Next, click the type of project you wish to create: Manuscript,
            Screenplay, or <a href="#" onClick={(e) => goToItem(e, "#importingprojects")}>Import</a>:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fnew_project_type.PNG?alt=media&token=97706646-dddb-48e5-9610-4713bc32a96a" />
            </p>
            Then enter the name of your new project, and click the checkmark to
            create your new project:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fnew_project_name_option.PNG?alt=media&token=139cf0cb-0102-4f86-b110-5db1ff774936" />
            </p>
            <em>
              Note: Once you select your project type, you will be unable to
              change it, so choose wisely.
            </em>
          </span>
        ),
      },
      {
        question: "Editing Project options",
        answer: (
          <span>
            To change project options, go to the Project Details Page for the
            desired Project. Click the Projects link on the top menu:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fhelp_projects_button.PNG?alt=media&token=5922ebc3-6f59-4e21-bc53-fc77f6494e3e" />
            </p>
            then select the appropriate project by clicking on the name:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fmenu_projects_dropdown.PNG?alt=media&token=8b995424-e906-4763-8b59-659a688373e3" />
            </p>
            Alternatively, click the "See All" link in the menu dropdown, and
            select the appropriate project from the Projects Listing view:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fprojects_listing.PNG?alt=media&token=77c90dd0-f333-40d6-bca2-edd523009510" />
            </p>
            Once you select the project you want to edit, you'll go to the
            project detail page, which will let you edit the project{" "}
            <strong>Name</strong>, <strong>Description</strong> and{" "}
            <strong>Project Defaults</strong>.
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fproject_details.PNG?alt=media&token=3ef2ce09-4355-4ea8-ae41-e02434318e42" />
            </p>
            <p>
              To edit the Project Name and defaults, simply update the values in
              the appropriate text fields; the values are updated as you type,
              so there's no save button to click.
            </p>
            <p>
              The Project Defaults allow you to define project-wide default
              values, which get copied to individual scenes as you create them.
              These defaults can then be over-ridden on a scene-by-scene basis.
              To update the Project Defaults, simply click one of the three blue
              buttons:
            </p>
            <ol>
              <li>
                <strong>Render As</strong>
                <br />
                This determines if you render in the default <em>
                  Rich Text
                </em>{" "}
                mode, or in <em>Markdown</em>. (Your documents are saved in
                Markdown format, so this simply allows you to remain in this
                mode)
              </li>
              <li>
                <strong>Line Spacing</strong>
                <br />
                This determines the line spacing value. Click the button to
                cycle through the available values of <em>1</em>, <em>1.5</em>,{" "}
                <em>2</em>, and <em>3</em>.
              </li>
              <li>
                <strong>Sync</strong>
                <br />
                If you've created a Hilker login, you'll be given the option to
                enable server-side sync for your project. The text will either
                say "Click to add to sync list" or "Click to Remove". These are
                links to toggle.
                <p>
                  <em>
                    The interface for this isn't great yet, we realize: we'll be
                    updating it soon.
                  </em>
                </p>
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "Switching between Projects",
        answer: (
          <span>
            To switch between multiple projects, simply click the blue Projects
            Listing Button in the top menu:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fhelp_projects_button.PNG?alt=media&token=5922ebc3-6f59-4e21-bc53-fc77f6494e3e" />
            </p>
            and click the name of the project you want to select.
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fmenu_projects_dropdown.PNG?alt=media&token=8b995424-e906-4763-8b59-659a688373e3" />
            </p>
            Alternatively, you can click on the "
            <FontAwesomeIcon icon={faBooks} /> See All" button, which takes you
            to the <Link to={`/projects`}>Projects Page</Link>.
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fprojects_listing.PNG?alt=media&token=77c90dd0-f333-40d6-bca2-edd523009510" />
            </p>
            Here you can see which of your projects is active (the title of the
            active project is blue. In addition, the title of the active project
            is always visible in the top menu). Clicking on the title of a
            different project will select it as the active one.
          </span>
        ),
      },
      {
        question: "Exporting Projects",
        answer: (
          <span>
            You can export your project -- which is a good idea during the beta
            -- by selecting the going to the details page of the project you
            want to export, and click the Download Project Files button (
            <FontAwesomeIcon icon={faFileDownload} />) in the footer menu:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fproject_details.PNG?alt=media&token=3ef2ce09-4355-4ea8-ae41-e02434318e42" />
            </p>
            After clicking this button, you'll be prompted to download a zip of
            your entire project. This will contain text files relating to notes,
            assets and writing you've generated. It will also contain a weirdly
            named file like 2312l3kj123l1k2j3lkj.json; this can be used to
            import your project later on.
          </span>
        ),
      },
      {
        question: "Importing Projects",
        answer: (
          <span>
            Hilker allows you to import from a variety of sources, including
            Final Draft (.fdx), Office DocX (.docx) and with the Fountain file format
            (.fountain). You can also import Hilker projects (.json files) that
            have been previously exported. Importing a project requires that
            you've first downloaded a project export file.
            <p>
              <em>
                (See the <a onClick={(e) => goToItem(e, "#exportingprojects")} href="#">previous question</a> for infomation on how to export a
                Hilker project)
              </em>
            </p>
            Once you've got your file, go the the{" "}
            <Link to={`/projects`}>Projects Page</Link>. Click the Create New
            Project button: 
            
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fnew_project_link.PNG?alt=media&token=822094f4-59dd-4eb9-8f5f-7f1c40f3fbf0" />
            </p>
            Then click the Import button:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fnew_project_type.PNG?alt=media&token=97706646-dddb-48e5-9610-4713bc32a96a" />
            </p> And select the file
            you want to import: 

            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fnew_project_import.PNG?alt=media&token=1a1b6432-db0a-45d7-8dcc-2add0f44a741" />
            </p>
            
                       If you're importing a Hilker project you've
            previously exported, you'll need to use the .json file in the root
            directory of the zip file you downloaded as part of the Hilker
            export process. Click the "Choose File" button: And the import will
            occur. If you're importing a Hilker project file, a new, completed
            file will be created, which will be visible in your list of
            projects.
            <p>
            <b>Importing a Screenplay:</b></p>
            
            If you're importing a Final Draft (.fdx) or Fountain project, you'll
            be shown the screenplay import interface: 
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fimport_screenplay_interface_1.PNG?alt=media&token=e5d36b28-8b45-4e12-b7c4-9f71062edc31" />
            </p>
            
            This view shows excerpts of every scene in your project, so you can quickly orient yourself and decide where breakpoints need to be added. You can do that by clicking on the folder and document icons beside the text, to define new sections and scenes.
            
            <br /><br/>
            Hilker automatically creates one section and one scene to start. 

            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fimport_screenplay_interface_2.PNG?alt=media&token=f0238548-06ba-45e9-8f7b-16bc7cfd4d24" />
            </p>

            Once you've broken the script up in the way you prefer, click the green checkmark icon at the bottom of the interface (or hit the red trashcan to cancel the import):

            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fimport_screenplay_interface_3.PNG?alt=media&token=7128b863-e8ec-473a-956d-ba048c690ba5" />
            </p>

            After you click the checkmark, the project will be created and you'll be taken to the Project Detail page.

            <p><b>Importing a Manuscript:</b></p>
            
            If you're importing a Word Doc or text file, once you click the blue checkmark, you'll be taken to the Project detail page for your imported project. The import will create
            a single folder, with one section containing the entire document.
            You can break this up by using the{" "}
            <a
              onClick={(e) => goToItem(e, "#splittingupscenes")}
              href="#"
            >
              Split Up Scene
            </a>{" "}
            functionality, which is pretty neat.
          </span>
        ),
      },
      {
        question: "Deleting Projects",
        answer: (
          <span>
            To delete a project, simply click the "Delete Project" button at the
            button of the Project Detail page for the project you wish to
            delete:
            
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fproject_details.PNG?alt=media&token=3ef2ce09-4355-4ea8-ae41-e02434318e42" />
            </p>
            You can get to the Project Detail page by clicking the blue
            box in the menu, then clicking on the appropriate project name.{" "}
            <b>
              <em>
                WARNING: Deleting a project is permanent, and at present there's
                no double-confirmation, so clicking it once will make the
                project go away.{" "}
              </em>
            </b>
          </span>
        ),
      },
      {
        question: "Printing",
        answer: (
          <span>
            You can print your project from one of two locations:
            <ol>
              <li>
                <strong>The Project Detail Page:</strong>
                <br />
                Get to the project detail page, then scroll to the bottom of the
                page. Click the Print Project button (
                <FontAwesomeIcon icon={faPrint} />
                 Print Project). This will open up the Print Dialog, which will allow you to
                determine which scenes are printed. Opening this from the
                Project Detail Page will select all scenes for printing by
                default; click the scene names to toggle what gets printed.
              </li>
              <li>
                <strong>The Writing Page:</strong>
                <br />
                If you're in the Writing interface, you'll see the Print Scene
                Button (<FontAwesomeIcon icon={faPrint} />) near the top of the
                screen, to the right of the scene title. Click this, and you'll
                launch the Print Options modal. When you open this from the
                Scene view, only the scene you're writing will be selected by
                default; click any other scenes that you also want to print.
              </li>
            </ol>
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fprint_1.PNG?alt=media&token=3b7f2c35-cd12-416f-acb0-4e87b84095e9" />
            </p>
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fprint_2.PNG?alt=media&token=2bf9d328-0e37-49ef-9565-caa18e3c8669" />
            </p>
          </span>
        ),
      },
    ],
  },
  {
    name: "User Accounts",
    questions: [
      {
        question: "User Preferences",
        answer: (
          <span>
            Go to the <Link to={`/user`}>User Page</Link>. There you can set
            some options, such as your <strong>name</strong>, <strong>email</strong>, and a brief <strong>bio</strong>; this
            information gets added to the title pages when you print out your
            projects.
            <p>Additionally you can set your <a href="#" onClick={(e) => goToItem(e, "#darkmode")}>dark mode</a> preferences, and log in to Hilker to enable <a href="#" onClick={(e) => goToItem(e, "#syncingprojectsontheserver")}>project syncing</a>.</p>
          </span>
        ),
      },
      {
        question: "Dark Mode",
        answer: (
          <span>
            You have three options for Dark Mode, which you can adjust on the{" "}
            <Link to={`/user`}>User Page</Link>:
            <ol>
              <li>
                <strong>On:</strong>
                <br /> Dark Mode is always activated.
              </li>
              <li>
                <strong>Off:</strong>
                <br /> Dark Mode is never activated.
              </li>
              <li>
                <strong>Respect System Setting:</strong>
                <br /> Dark Mode is activated if the system you're using Hilker
                on has an activated Dark Mode.
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "Login and Sync",
        answer: (
          <span>
            On the <Link to={`/user`}>User Page</Link>, you have the ability
            create a user account to enable remote syncing of your projects. To
            do this, go to the bottom of the user page, to the Login section. There you'll be given the option to log in with an existing account, or create one using Facebook, Google, or a username/password account. 
            <br />
            <br />
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Flogin_options.PNG?alt=media&token=40696ee5-f677-494d-894f-8dc90e9df155" />
            </p>
            Once you're logged in, you'll be given the option to enable syncing on a <a href="#" onClick={(e) => goToItem(e, "#syncingprojectsontheserver")}>project-by-project basis</a>, in the Project Detail page of your project:

            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fproject_details.PNG?alt=media&token=3ef2ce09-4355-4ea8-ae41-e02434318e42" />
            </p> 
              <strong>
                WARNING: At present, there is no merging of data. This means
                that if you save an older version of your account to the server,
                or download one, it will overwrite whatever your current state
                is.{" "}
                <em>
                  Make sure you're syncing the appropriate version of your work.
                </em>
              </strong>
          </span>
        ),
      },
    ],
  },
  {
    name: "Notes",
    questions: [
      {
        question: "What's a Note?",
        answer: (
          <span>
            In Hilker, <Link to={`/note`}>Notes</Link> are places both to
            capture ideas for your writing project, and to convert them into
            Story Sections, Scenes, and story assets, which you can easily refer
            to throughout your project in the Plot and Write interfaces. You can
            create as many notes as you'd like!
          </span>
        ),
      },
      {
        question: "Creating a Note",
        answer: (
          <span>
            To create a Note, go to the <Link to={`/note`}>Notes</Link> page,
            and click the blue button that says "
            <FontAwesomeIcon icon={faPlus} /> New. " This will create a new
            note, and redirect you to it.
          </span>
        ),
      },
      {
        question: "Editing a Note",
        answer: (
          <span>
            To edit a note, go to the <Link to={`/note`}>Notes</Link> page, and
            click the edit button to the far right of the name of the note you
            want to edit (<FontAwesomeIcon icon={faEdit} />
            ). Changes you make in the note are saved automatically.
          </span>
        ),
      },
      {
        question: "Creating an Asset From a Note",
        answer: (
          <span>
            Hilker allows you to convert the ideas that you capture in your
            notes into usable story elements and assets. It's a pretty neat
            feature, I think. This is done by selecting text you want to convert
            into an asset. Once you do that, you'll be shown the Context Menu
            <p><HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fnote_context_menu.PNG?alt=media&token=6569d0d9-0157-447b-8969-a09341183058" /></p>
            <p>
              With the context menu up, you can select the type of asset you
              want to convert your text into. The available types are:
              <ul>
                <li>
                  <strong>
                    Story Section (
                    <FontAwesomeIcon
                      icon={getIconForAssetType("storySection")}
                    />
                    )
                  </strong>
                  <br />A Story Section is akin to an Act. They're the top-level
                  in terms of structure for your writing, and you can have
                  multiple Story Sections in your project.
                </li>
                <li>
                  <strong>
                    Scene (
                    <FontAwesomeIcon icon={getIconForAssetType("scene")} />)
                  </strong>
                  <br />A scene in Hilker is what you'd probably expect. It's a
                  scene. Multiple scenes
                </li>
                <li>
                  <strong>
                    Storybeat (
                    <FontAwesomeIcon icon={getIconForAssetType("storybeat")} />)
                  </strong>
                  <br />
                  This is a plot point, or an action that should happen in your
                  story.
                </li>

                <li>
                  <strong>
                    Dialogue (
                    <FontAwesomeIcon icon={getIconForAssetType("dialogue")} />)
                  </strong>
                  <br />
                  This is used to tag dialogue you want to use somewhere in your
                  story.
                </li>

                <li>
                  <strong>
                    Character (
                    <FontAwesomeIcon icon={getIconForAssetType("character")} />)
                  </strong>
                  <br />
                  This is used to tag notes or information about a character
                  that should be in your story.
                </li>

                <li>
                  <strong>
                    Arc (<FontAwesomeIcon icon={getIconForAssetType("arc")} />)
                  </strong>
                  <br />
                  This is used to tag notes related to an arc that should be in
                  your story.
                </li>

                <li>
                  <strong>
                    Location (
                    <FontAwesomeIcon icon={getIconForAssetType("location")} />)
                  </strong>
                  <br />
                  This is used to tag notes related to a location in your story.
                </li>
                <li>
                  <strong>
                    MacGuffin (
                    <FontAwesomeIcon icon={getIconForAssetType("macguffin")} />)
                  </strong>
                  <br />
                  This is used to tag notes related to an item or MacGuffin used
                  in your story. An example of this would be the Maltese Falcon,
                  or some other object relevant to the plot.
                </li>
              </ul>
              <p>
                When you select either <em>Story Section</em> or <em>Scene</em>,
                you will be prompted to define a title and optional description.
                Once you provide this and click Okay, the Story Section or Scene
                will be added to the project, highlighted in the note, and
                available in the Plot and Write views.
              </p>
              <p>
                If you select any of the other types, you'll be presented with
                two options: <em>New</em> and <em>Add to Existing</em>.
                Selecting New will cause a modal to appear, in order to create a
                new assets. Selecting Add to Existing will allow you to add
                additional arbitrary information (referred to as Factoids in
                Hilker) for an already existing asset.{" "}
              </p>
              <p>
                As an example of the "Add to Existing" function, if you have a
                note regarding a new character, you might select some text and
                create a Character with it. Later in the note you might write
                something about their personality; selecting that text and
                clicking the "Add to Existing" option will allow you to easily
                augment the information you're storing about that character.
              </p>
              <p><HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fnote_add_asset.PNG?alt=media&token=54db38f2-fbdf-4ab6-8363-54d45aee8170" /></p>
              <p>
                <em>
                  This functionality is available both in the Note and Write
                  sections of Hilker.
                </em>
              </p>
            </p>
          </span>
        ),
      },
      {
        question: "Deleting a Note",
        answer: (
          <span>
            At present, you can't actually delete a note from Hilker. The
            thinking is that Notes are meant to be idea capture that you don't
            need to delete, so you can refer to them later on, if need be.
          </span>
        ),
      },
    ],
  },

  {
    name: "Assets",
    questions: [
      {
        question: "What's an asset?",
        answer: (
          <span>
            In Hilker, Assets are items relevant to and used in your story.
            There are six types of assets:
            <ol>
              <li>
                <strong>
                  Storybeat (
                  <FontAwesomeIcon icon={getIconForAssetType("storybeat")} />)
                </strong>
                <br />
                This is a plot point, or an action that should happen in your
                story, like <em>The Nakatomi Tower rooftop explodes.</em>
              </li>

              <li>
                <strong>
                  Dialogue (
                  <FontAwesomeIcon icon={getIconForAssetType("dialogue")} />)
                </strong>
                <br />
                These are snippets of dialogue that should go in your story,
                like <em>"Yippie-Kayay!"</em>
              </li>

              <li>
                <strong>
                  Character (
                  <FontAwesomeIcon icon={getIconForAssetType("character")} />)
                </strong>
                <br />
                These are characters used in your story, like{" "}
                <em>Hans Gruber.</em>
              </li>

              <li>
                <strong>
                  Arc (<FontAwesomeIcon icon={getIconForAssetType("arc")} />)
                </strong>
                <br />
                This is an arc to be used in your story, like{" "}
                <em>John McClane and Holly repair their relationship.</em>
              </li>

              <li>
                <strong>
                  Location (
                  <FontAwesomeIcon icon={getIconForAssetType("location")} />)
                </strong>
                <br />
                This is a location used in your story, like{" "}
                <em>The Nakatomi Tower.</em>
              </li>
              <li>
                <strong>
                  MacGuffin (
                  <FontAwesomeIcon icon={getIconForAssetType("macguffin")} />)
                </strong>
                <br />
                This is an object that's important to your story, like{" "}
                <em>Holly's Gold Watch</em>.
              </li>
            </ol>

            <em>Technically story sections and scenes are also assets, but they're not of the same kind as the ones above, and arent editable in the Assets section of Hilker.</em>
            <p>
              Assets help you plan out and keep track of your story. They can be
              created throughout the app, and accessed at any time via the{" "}
              <Link to={`/assets`}>Assets</Link> page, as well as inside the
              Notes, Plot and Write views.{" "}
            </p>
          </span>
        ),
      },
      {
        question: "Creating an Asset",
        answer: (
          <span>
            Assets can be created in four ways:
            <ol>
              <li>
                <strong>In Assets</strong>
                <br />
                In the <Link to={`/assets`}>Assets Page</Link>, click the Plus
                Symbol (<FontAwesomeIcon icon={faPlus} />) to the right of the
                Asset type you want to create. This will open up a modal that
                allows you to enter basic information about your Asset. Click
                Okay and it will be created.
              </li>
              <li>
                <strong>In Notes</strong>
                <br />
                To create an asset in the <Link to={`/notes`}>
                  Notes Page,
                </Link>{" "}
                see the instructions on{" "}
                <a href="#Creating%20an%20Asset%20From%20a%20Note">
                  Creating Assets From a Note
                </a>
                .
              </li>
              <li>
                <strong>In Plot</strong>
                <br />
                To create an asset in the <Link to={`/plot`}>Plot View</Link>,
                click the "Asset <FontAwesomeIcon icon={faPlusCircle} />" button
                to the top of the scene you want to add the asset to. You'll get
                a choice of the asset type you wish to create, then will be
                prompted to enter basic information about the asset you wish to
                create. (You can use this interface to select an existing asset
                to add to a scene) This will add the asset to the scene.
              </li>
              <li>
                <strong>In Write</strong>
                <br />
                In the <Link to={`/write`}>Write</Link> view, you can add assets
                to any scenes. To do this, click the "
                <FontAwesomeIcon icon={faPlus} /> ADD ASSET" button on the right
                side of the writing view. This will prompt you to select an
                existing asset to add to the scene, or to enter details for a
                new asset. Do so and click Okay to create a new asset, which
                will also get added to the scene.
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "Editing an Asset",
        answer: (
          <span>
            You can edit your assets in two main ways:
            <ol>
              <li>
                <strong>In the Assets View</strong>
                <br />
                To edit an Asset, select the asset to be edited from the{" "}
                <Link to={`/assets`}>Assets View</Link> by selecting the type,
                and then selecting the asset from the list that appears. Click
                the checkmark icon at the bottom of the page to save your
                updates, or the Trash can icon to to delete the asset from your
                project.
                <p>
                  Once you've selected the asset to edit, you'll be able to
                  update basic name/description information at the top of the
                  page, as well as:
                </p>
                <ul>
                  <li>
                    <strong>Custom Colour</strong>
                    <br />
                    This is used in the plot view to help visualize the flow of
                    your story. Click and select from the grid of available
                    colours.
                  </li>
                  <li>
                    <strong>Type-specific information</strong>
                    <br />
                    Each asset type has its own specific fields that you can
                    fill with information; these are below the Custom Colour
                    field, and can be filled out however you like.
                  </li>
                  <li>
                    <strong>Factoids</strong>
                    <br />
                    Factoids are any facts or information about your assets that
                    don't fit anywhere else. These can be whatever you like,
                    like their pet's name, favourite colour, or anything at all!
                    <br />
                    <br />
                    Create a factoid by entering text into the text field in the
                    Factoids section, then clicking the add Factoid button (
                    <FontAwesomeIcon icon={faPlusCircle} />
                    ). The new factoid will appear in the list of factoids for
                    this asset.
                    <p>
                      To delete a factoid, click the Delete Factoid (
                      <FontAwesomeIcon icon={faBan} />) button to the right of a
                      given factoid.
                    </p>
                    <p>
                      <em>
                        You can't edit a factoid currently, because I haven't
                        built that feature yet. I will soon.
                      </em>
                    </p>
                  </li>
                </ul>
              </li>
              <li>
                <strong>In Notes, Plot, or Write</strong>
                <br />
                You can also edit assets in Notes, Plot or Write by clicking the
                button for the asset (on the right side of the screen in Notes
                and Write, or in the listing for each scene in Plot). This will
                open up a modal you can use to update the Asset, including
                Factoids. Once you've made whatever updates you want, you can
                click the Save button (
                <FontAwesomeIcon icon={faCheckCircle} />
                ). You can also remove the asset from the current scene by
                clicking the Remove From Scene button (
                <FontAwesomeIcon icon={faEraser} />
                ), or delete it from the project entirely by clicking the Delete
                Asset button (<FontAwesomeIcon icon={faTrash} />
                ).
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "See Where Assets are Used",
        answer: (
          <span>
            You can see where an asset is used in your story three ways:
            <ol>
              <li>
                <strong>In the Assets View</strong>
                <br />
                In the Asset Detail page (accessible through the Assets View, or
                by clicking the "Asset's Page" link in the Asset info modal
                accessible through Notes, Plot or Write), scroll down to the
                Uses In Project section.
                <br />
                <br />
                Here you'll find a section-and-scene breakdown of where the
                Asset is attached in your project. If it's not used anywhere,
                there will be a red bar detailing this. (The number of uses is
                also visible in the Assets listing)
              </li>
              <li>
                <strong>In the Plot View</strong>
                <br />
                In Hilker's <Link to={`/plot`}>Plot View</Link>, you can scroll
                through the sections and scenes of your project while in{" "}
                <em>Edit</em> mode and see where an asset is used.
                <p>
                  In Plot's <em>Inspect</em> mode, you will see a list of every
                  asset added to your project, as well as where they're used. If
                  they're not used, the row for the given asset will be bright
                  red, to alert you that said asset is not used. It's a nice
                  visual feedback to let you know if you forgot to use the asset
                  you created, or if you don't actually need it.
                </p>
              </li>
              <li>
                <strong>In the Write View</strong>
                <br />
                You can jump around from scene to scene and see which assets are
                attached, but this doesn't offer the same kind of birds-eye view
                that the Assets and Plot views provide.
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "Adding an Asset to a Scene",
        answer: (
          <span>
            You can add an asset to a scene in two ways:
            <ol>
              <li>
                <strong>The Plot View</strong>
                <br />
                You can add an asset to a scene in the{" "}
                <Link to={`/plot`}>Plot</Link> view by clicking the "Asset{" "}
                <FontAwesomeIcon icon={faPlusCircle} />" button at the top of
                the scene item in the interface. You can add a newly created
                asset to the selected scene, or select an existing asset from
                the drop-down list that will appear.
              </li>
              <li>
                <strong>The Write View</strong>
                <br />
                In the <Link to={`/write`}>Write</Link> view, click the "
                <FontAwesomeIcon icon={faPlus} /> ADD ASSET" button. You can add
                a newly created asset to the selected scene, or select an
                existing asset from the drop-down list that will appear.
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "Adding a Factoid to an Asset",
        answer: (
          <span>
            Factoids are any facts or information about your assets that don't
            fit anywhere else. These can be whatever you like, like their pet's
            name, favourite colour, or anything at all! They can be added to an
            asset in one of two ways:
            <ol>
              <li>
                <strong>In the Assets view</strong>
                <br />
                From the <Link to={`/assets`}>Assets View</Link>, select an
                asset you want to add a factoid to by selecting the type, then
                the specific factoid. On the page, you'll see a list of existing
                Factoids, if any, along with a text field to add new Factoids.
                Create a factoid by entering text into the text field in the
                Factoids section, then clicking the Add Factoid button (
                <FontAwesomeIcon icon={faPlusCircle} />
                ). The new factoid will appear in the list of factoids for this
                asset.
                <p>
                  To delete a factoid, click the Delete Factoid (
                  <FontAwesomeIcon icon={faBan} />) button to the right of a
                  given factoid.
                </p>
                <p>
                  <em>
                    You can't edit a factoid currently, because I haven't built
                    that feature yet, but I will soon.
                  </em>
                </p>
              </li>
              <li>
                <strong>In Note or Write</strong>
                <br />
                In the Note and Write views, you can add a Factoid to an Asset
                by selecting text that describes the fact, like{" "}
                <em>Bob calls his wife Mary</em>. Select the asset type, then
                click "<FontAwesomeIcon icon={faChevronDoubleRight} /> Add to
                Existing". Select the asset to add the fact to, enter the
                appropriate description, and click Okay (this button only
                becomes visible once you select an asset from the list).
                <br />
                <br />
                Doing this from the Write view is a good way to help keep a
                sense of your story's continuity, by adding relevant details as
                you write them, so you never forget.
              </li>
            </ol>
          </span>
        ),
      },
    ],
  },

  {
    name: "Plotting",
    questions: [
      {
        question: "What's the Plot View?",
        answer: (
          <span>
            The <Link to={`/plot`}>Plot View</Link> is a special mode of Hilker
            that lets you visualize and organize the plot and progression of
            your story, in a way that's somewhat similar to a video editor's
            timline view.
            <p>Plot has two modes, which are:</p>
            <ol>
              <li>
                <strong>
                  Edit (<FontAwesomeIcon icon={faEdit} />)
                </strong>
                <br />
                This mode lets you add, reorder and remove scenes, sections and
                assets from your story, and get a nice birds-eye view of your
                project.
              </li>
              <li>
                <strong>
                  Inspect (<FontAwesomeIcon icon={faEye} />)
                </strong>
                <br />
                Inspect mode lets you see from a higher perspective from Edit,
                and is designed to let you see not just which assets are used in
                your project, but which assets you <em>aren't</em> using in your
                project. As well, you can get a good view of the progression of
                things like the arcs in your project, and how they relate to the
                other types of assets, like characters.
                <br />
                <br />
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "Plot View Modes",
        answer: (
          <span>
            {" "}
            <p>
              The <Link to={`/plot`}>Plot View</Link> has two modes, which are:
            </p>
            <ol>
              <li>
                <strong>
                  Edit (<FontAwesomeIcon icon={faEdit} />)
                </strong>
                <br />
                This mode lets you add, reorder and remove scenes, sections and
                assets from your story, and get a nice birds-eye view of your
                project.
              </li>
              <li>
                <strong>
                  Inspect (<FontAwesomeIcon icon={faEye} />)
                </strong>
                <br />
                Inspect mode lets you see from a higher perspective from Edit,
                and is designed to let you see not just which assets are used in
                your project, but which assets you <em>aren't</em> using in your
                project. As well, you can get a good view of the progression of
                things like the arcs in your project, and how they relate to the
                other types of assets, like characters.
                <br />
                <br />
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "Adding Sections",
        answer: (
          <span>
            To add a section via the <Link to={`/plot`}>Plot</Link> view, click
            the blue bar that reads "Section{" "}
            <FontAwesomeIcon icon={faPlusCircle} />
            ". You will be prompted to enter the name and optional summary of
            the new section. Click okay, and the section will be created and
            added at the end of the project.
            <p>
              Sections you add to the project via the Note or Write interfaces
              will also show up in the Plot view.
            </p>
          </span>
        ),
      },
      {
        question: "Adding Scenes",
        answer: (
          <span>
            In the Plot view, you add a scene to a specific section. To do so,
            find the "Scene <FontAwesomeIcon icon={faPlusCircle} />" button at
            the bottom of the Scene listing in the{" "}
            <Link to={`/plot`}>Plot</Link> view. You'll be prompted to either
            select a scene that isn't in a section (scenes created in the Note
            view don't automatically get assigned to a section) or to enter
            information for a new one. Clicking Okay will create a new scene in
            the current{" "}
            <section className="">
              <p>
                Scenes you add to the project via the Note or Write interfaces
                will also show up in the Plot view.
              </p>
            </section>
          </span>
        ),
      },
      {
        question: "Adding Assets",
        answer: (
          <span>
            Assets can be added to scenes directly in the{" "}
            <Link to={`/plot`}>Plot</Link> view. To do so, click the "Asset{" "}
            <FontAwesomeIcon icon={faPlusCircle} />" button under the name of
            the Scene you wish to add your asset to. You'll be given a prompt
            first to select the type of asset you wish to add, then to either
            select an existing Asset, or to enter information to create a new
            one.
            <p>
              Any assets you create in the Plot view will be accessible in the{" "}
              <Link to={`/assets`}>Assets</Link> view.
            </p>
          </span>
        ),
      },
      {
        question: "Reorganizing Sections and Scenes",
        answer: (
          <span>
            Scenes and Sections can be reorganized in the{" "}
            <Link to={`/plot`}>Plot</Link> view. You can do this by clicking and
            dragging the Scene title, or the Section title. If you drag a scene,
            you can drag it within a single Story section, as well as from one
            Section to another. If you are using Hilker on a device with a
            mouse, the cursor will change to a grabbing hand to signify you're
            able to grab to drag.
            <p>
              You can also reorganize scenes and sections in the{" "}
              <Link to={`/write`}>Write</Link> view, by dragging them around by
              the names on the left side of the writing interface.
            </p>
          </span>
        ),
      },
      {
        question: "Reorganizing Assets",
        answer: (
          <span>
            Dragging for Assets in the <Link to={`/plot`}>Plot</Link> view
            currently isn't enabled. To change which scene an asset is in,
            you'll need to remove it from the scene you don't want it in, and
            add it to the scene that you do.
            <ul>
              <li>
                Remove the asset from the scene by hovering your mouse over the
                asset you need to remove; A Remove Asset icon (
                <FontAwesomeIcon icon={faTimesCircle} />) will appear. Click
                that, and the asset will be removed from the section.
              </li>
              <li>
                If you're using Hilker on a device without a mouse, click the
                asset. Doing so will bring up the Update Asset modal. At the
                bottom of that modal is a Remove From Scene button (
                <FontAwesomeIcon icon={faEraser} />
                ). Clicking this will remove the asset from the current scene.
              </li>
            </ul>
            <p>
              Once you've deleted the asset, you can click the "Asset{" "}
              <FontAwesomeIcon icon={faPlusCircle} />" button to specify an
              asset to add to the appropriate scene.
            </p>
          </span>
        ),
      },
      {
        question: "Zooming in and out",
        answer: (
          <span>
            You can zoom in and out of the <Link to={`/plot`}>Plot</Link> view,
            in order to better help you visualize your project. To do so, drag
            the scaling slider on the very bottom of the Plot View screen. The
            slider is under the text "Zoom."
            <p>
              Additionally, you can fit the enter project into the visible
              screen by toggling the Fit To Screen button (
              <FontAwesomeIcon icon={faExpandArrows} />
              ). When Fit to Screen is enabled, the icon will be green; when it
              isn't, the icon will be light gray.
            </p>
          </span>
        ),
      },
      {
        question: "Inspect Mode - Filtering Asset Types",
        answer: (
          <span>
            In the <Link to={`/plot`}>Plot</Link> view's inspect mode (
            <FontAwesomeIcon icon={faEye} />
            ), you're able to use the "FILTER BY TYPE OF ASSET" menu in the
            Footer at the bottom of the screen to hide specific asset types from
            view temporarily. Just click the asset type icons to toggle
            visibility of assets of that type; if an icon is green, that type of
            asset is shown. If it's gray, it's not.{" "}
          </span>
        ),
      },
      {
        question: "Inspect Mode - Showing Unused Assets",
        answer: (
          <span>
            In the <Link to={`/plot`}>Plot</Link> view's inspect mode (
            <FontAwesomeIcon icon={faEye} />
            ), all assets in your project are shown under the scene heading for
            the scene they're attached to. If an asset isn't currently attached
            to any scene, both the name of the asset in the legend, and the row
            in the scene listings are coloured bright red. This is to help make
            sure you don't lose track of things you meant to include in your
            project.
          </span>
        ),
      },
    ],
  },
  {
    name: "Writing",
    questions: [
      {
        question: "Creating a Section",
        answer: (
          <span>
            You can create a Story Section in the{" "}
            <Link to={`/write`}>Write</Link> view by clicking the "
            <FontAwesomeIcon icon={faFolderPlus} /> Add Section" button, on the
            left side of the writing interface. The button is at the bottom of
            the list of any sections you may have added to your project.
            Clicking this button will prompt you to add a name and optional
            description for your new Section.
          </span>
        ),
      },
      {
        question: "Creating a Scene",
        answer: (
          <span>
            To create a Scene in the <Link to={`/write`}>Write</Link> view,
            click the "<FontAwesomeIcon icon={faFilePlus} /> Add Scene" button.
            You'll be prompted to either select an available Scene (one created
            via Notes and not attached to a Section) or enter information to
            create a new Scene.
            <br />
            <br />
            Click Okay to create a new Scene.
          </span>
        ),
      },
      {
        question: "Reordering Sections and Scenes",
        answer: (
          <span>
            You can reorder scenes and sections in the{" "}
            <Link to={`/write`}>Write</Link> view by clicking and dragging on
            either the name of the Section, or the Scene. If you're using Hilker
            on a device that has a mouse, your cursor will turn into a grabbing
            hand when it's over the title, to indicate you can drag it.
          </span>
        ),
      },
      {
        question: "Splitting up Scenes",
        answer: (
          <span>
            Hilker lets you break up an existing scene into multiple, smaller
            scenes, in case you're importing a large Word Doc, or if you realize
            that your scene needs to be two, or three, or more. To enter the
            split up scene mode, click the Split up Scene (
            <FontAwesomeIcon icon={faPageBreak} />) icon in the top of the
            writing view:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fsplit_up_scene_menu.PNG?alt=media&token=dbc3db90-2258-4cf4-a3f5-6d5902a55697" />
            </p>
            You'll see a series of "Add Break" buttons between each line of
            text, which indicate where breakpoints can be placed:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fsplit_up_mode.PNG?alt=media&token=15041e9a-a62f-45b7-9265-c54702ae577a" />
            </p>
            In this mode, you can select the "Add Break" buttons to toggle where
            new scene breaks will occur. You can create as many breaks in your
            scene as you like:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fsplit_up_selections.PNG?alt=media&token=a943719d-f825-4013-b23f-c7f8f0318405" />
            </p>
            If you don't want to split up your scenes, click the red cancel
            button at the top of the writing area. If you still want to split up
            the scene, click the green check mark when you're satisfied with how
            the scene will be broken up. Hilker will create the new scenes,
            naming each one sequentially, based on the name of the original
            scene:
            <p>
              <HelpImage src="https://firebasestorage.googleapis.com/v0/b/hilker-prod.appspot.com/o/help%2Fsplit_up_new_scenes.PNG?alt=media&token=82d52876-405a-4929-a7c1-917e8384e1a4" />
            </p>
          </span>
        ),
      },
      {
        question: "Changing Per-Scene Options",
        answer: (
          <span>
            Project options are defined initially at the project level, and when
            you create new scenes, your current settings get copied onto them.
            You can change them on a per-scene basis as well, to have different
            scenes have different rich text/line-spacing and screenplay options.
            <br />
            <br />
            Click the Click to Change Scene Setting button (
            <FontAwesomeIcon icon={faEllipsisH} />
            ), located to the right of the Scene title in the editor, beside the
            Print button. You'll be presented the three options,{" "}
            <em>Render as</em>, <em>Line Spacing</em>, and <em>Mode</em>:
            <ol>
              <li>
                <strong>Render As</strong>
                <br />
                This determines if you render in the default <em>
                  Rich Text
                </em>{" "}
                mode, or in <em>Markdown</em>. (Your documents are saved in
                Markdown format, so this simply allows you to remain in this
                mode)
              </li>
              <li>
                <strong>Line Spacing</strong>
                <br />
                This determines the line spacing value. Click the button to
                cycle through the available values of <em>1</em>, <em>1.5</em>,{" "}
                <em>2</em>, and <em>3</em>.
              </li>
              <li>
                <strong>Mode</strong>
                <br />
                This allows you to set Manuscript or Screenplay as your mode.{" "}
                <em>Manuscript</em> is the default. In <em>Screenplay</em>, you
                have access to an element subnav at the top of the writing area,
                which allows you to select which type of screenplay element
                you're using:
                <ul>
                  <li>🎬 Scene</li>
                  <li>💥Action</li>
                  <li>👤 Character</li>
                  <li>(...) Parenthetical</li>
                  <li>💬 Dialogue</li>
                  <li>
                    <FontAwesomeIcon icon={faProjectDiagram} /> Transition
                  </li>
                </ul>
                <br />
                <br />
                In addition, you can hit the TAB key to cycle between modes, or
                click the icon immediately to the write of the word "SCREENPLAY"
                on the footer. As you navigate your document, the subnav and the
                footer update to show you what type of element your cursor is
                currently in.
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "Adding Assets to a Scene",
        answer: (
          <span>
            To add an asset to a Scene, click on the name of the scene on the
            left side of the <Link to={`/write`}>Write</Link> view to set it as
            the active scene.
            <br />
            <br />
            Once you've done that, you'll be shown a list of assets added to the
            scene on the right side of the screen. Click the "
            <FontAwesomeIcon icon={faPlus} /> ADD ASSET" button at the bottom of
            the list. You will be prompted to select the type of Asset to add,
            then given the option to either select an existing asset of that
            type, or create a new one.
          </span>
        ),
      },
      {
        question: "Removing Assets from a Scene",
        answer: (
          <span>
            To remove an asset from a scene via the{" "}
            <Link to={`/write`}>Write</Link> view, click on the asset to be
            removed. You'll be presented with the Updte Assert modal. Click the
            Remove From Scene button (<FontAwesomeIcon icon={faEraser} />) to
            remove it from the scene.
            <br />
            <br />
            You can delete the asset from the project entirely by clicking the
            Delete From Project button (<FontAwesomeIcon icon={faTrash} />
            ).
          </span>
        ),
      },
      {
        question: "Focus Mode",
        answer: (
          <span>
            In the <Link to={`/write`}>Write</Link> view, on the bottom right of
            the screen, there's a "Focus." button. Clicking this will activate
            Focus Mode, which hides Hilker's primary interface, leaving only the
            text that you're currently working on, to reduce visual complexity.
            <p>
              Once you've activated Focus Mode, the link will change to say
              "Chaos!" Clicking this will deactivate Focus Mode, and bring the
              interface elements into view.
            </p>
          </span>
        ),
      },
      {
        question: "Screenplay Mode",
        answer: (
          <span>
            Hilker has a screenplay mode, which you can set in the project
            settings view, or at the top of a given scene by clicking the Click
            to Change Scene Settings button (
            <FontAwesomeIcon icon={faEllipsisH} />
            ). <br />
            <br />
            Screenplay mode lets you write documents in a screenplay format.
            <br />
            <br />
            When writing scenes that are set to Screenplay mode, you have access
            to an element subnav at the top of the writing area, which allows
            you to select which type of screenplay element you're using:
            <ul>
              <li>🎬 Scene</li>
              <li>💥Action</li>
              <li>👤 Character</li>
              <li>(...) Parenthetical</li>
              <li>💬 Dialogue</li>
              <li>
                <FontAwesomeIcon icon={faProjectDiagram} /> Transition
              </li>
            </ul>
            <br />
            <br />
            In addition, you can hit the TAB key to cycle between modes, or
            click the icon immediately to the write of the word "SCREENPLAY" on
            the footer. As you navigate your document, the subnav and the footer
            update to show you what type of element your cursor is currently in.
          </span>
        ),
      },
      {
        question: "Adding a Factoid to an Asset",
        answer: (
          <span>
            In the <Link to={`/write`}>Write</Link> view, you can add a Factoid
            in two ways:
            <ol>
              <li>
                <strong>Clicking the Asset</strong>
                <br />
                You can click on an asset in the list to the right side of the
                Writing area. On the modal that appears, you'll be able to view
                all existing Factoids, and enter a new one by typing the
                relevant info in the text field, and clicking the Add Factoid
                button (<FontAwesomeIcon icon={faPlusCircle} />
                ).
              </li>
              <li>
                <strong>Selecting Text in the Writing Area</strong>
                <br />
                When writing, if something you write is worth ercording in an
                asset (if you name a character's pet, or they reveal their home
                town), you can select the text, and in the context menu that
                appears, first click the type of asset you want to add a
                Factoid, then click the "
                <FontAwesomeIcon icon={faChevronDoubleRight} /> Add to Existing"
                button. This will bring up the Add Asset modal.
                <br />
                <br />
                Select the asset to add the Factoid to, as well as any
                elaboration you feel appropriate; the text you select will be
                saved into the Factoid, to give you context.
                <br />
                <br />
                This feature is intended as something of an adhoc continuity
                helper, to keep all the little things straight as you're writing
                large projects.
              </li>
            </ol>
          </span>
        ),
      },
      {
        question: "Rich Text and Markdown modes",
        answer: (
          <span>
            The default Writing Render as mode is Rich Text, which lets you use
            things like bolded, and italicized text. There's no interface for
            this currently in Hilker, but you can do things like CTRL+B and
            CTRL+I currently.
            <br />
            <br />
            Hilker saves the text you write down to Markdown format. Markdown is
            a plaintext format, that does things like let you define text in
            ways like <code>**Bold Text**</code>. This text can be translated to{" "}
            <code>
              <strong>Bold Text</strong>
            </code>
            . Since Hliker saves to Markdwon, this setting lets you keep
            everything plaintext, if you like.
            <br />
            <br />
            Here's a handy{" "}
            <a href="https://www.markdownguide.org/basic-syntax/" target="link">
              syntax reference for Markdown
            </a>{" "}
            to check out.
          </span>
        ),
      },
      {
        question: "Removing Sections",
        answer: (
          <span>
            To remove a section via the <Link to={`/write`}>Write</Link> view,
            first click on the name of the section in the left-side navigation.
            When you do, you'll see the Edit Section modal. Click the Delete
            From Project button (<FontAwesomeIcon icon={faTrash} />
            ) to delete the section.
            <br />
            <br />
            <strong>
              NOTE: Deleting a section will delete all of the scenes within it.
              If you want to delete the section but <em>not</em> delete the
              scenes, drag them into new sections first; Hilker doesn't
              currently support scenes that aren't inside sections.
            </strong>
          </span>
        ),
      },
      {
        question: "Removing Scenes",
        answer: (
          <span>
            To remove a scene from your project in the{" "}
            <Link to={`/write`}>Write</Link> view, click on the name of the
            scene you want to remove, then in the writing area, to the right of
            the Scene title, you'll see a row of buttons.
            <br />
            <br />
            The leftmost button is the Delete Scene (
            <FontAwesomeIcon icon={faBan} />) button, beside the Print button.
            Click the Delete Scene button to remove your scene from your
            project.
          </span>
        ),
      },
      
    ],
  },
]
