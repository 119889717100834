import React, { useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import {
  StateInterface,
  ProjectInterface,
  UserInterface,
  ProjectSyncInterface,
} from "../../../utils/Interfaces"
import { navigate } from "@reach/router"
import StandardButton from "../../../common/StandardButton/StandardButton"
import LoadingIcon from "../../../common/LoadingIcon/LoadingIcon"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCameraMovie,
  faBooks,
  faUserCog,
  faBook,
  faPlus,
  faCog,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/pro-regular-svg-icons"
import { faCloud, faLineHeight } from "@fortawesome/pro-solid-svg-icons"
import LoginModal from "../../../modals/LoginModal/LoginModal"

const Wrapper = styled.div`
  border-bottom: 2px solid var(--themed-input-bg-color);
  background-color: var(--themed-header-color);
`

const ProjectMessageWrapper = styled.div`
  font-weight: normal;
  font-size: 18px;
  line-height: 2.1em;
  grid-column: 1 /3;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 1.5em;
    grid-template-columns: 1fr;
  }
`

const ProjectHeader = styled.div`
  border-bottom: 1px solid var(--themed-bg-color);
  color: var(--themed-bg-color);
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  @media (max-width: 480px) {
    padding-bottom: 5px;
  }
`

const LoadingWrapper = styled.div`
  color: var(--themed-bg-color);
`

const ListingSection = styled.div`
  @media (max-width: 480px) {
    :first-of-type {
      margin-bottom: 20px;
    }
  }

  svg {
    width: 25px !important;
  }
`

const ProjectHR = styled.hr`
  border-top: 1px solid #999999;
  border-bottom: 0px;
`

const CurrentProjectMessage = styled.div`
  display: grid;
  max-width: 800px;
  margin: 0px auto;
  grid-template-columns: 1fr 1fr;
  padding: 10px;

  span {
    display: none;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    span {
      display: inline-block;
    }
  }
  a {
    color: var(--brand-primary);
  }
`

// TODO: Refactor the heck out of this page
const ProjectListing = () => {
  const allProjects = useSelector((state: StateInterface) => state).projects
  const projects = JSON.parse(JSON.stringify(allProjects))
  const user: UserInterface | undefined | null = useSelector(
    (state: StateInterface) => state.user
  )
  const projectSyncs: Array<ProjectSyncInterface> = useSelector(
    (state: StateInterface) => state.projectSyncs
  )
  const dispatch = useDispatch()

  projects.sort((a: ProjectInterface, b: ProjectInterface) => {
    const aDate: any = new Date(a.updated_at)
    const bDate: any = new Date(b.updated_at)
    return bDate - aDate
  })
  const [showLoginModal, setShowLoginModal] = useState(false)
  const logoutUser = () => {
    const toDispatch = { type: "log_user_out" }
    dispatch(toDispatch)
  }

  let mostRecentProject: ProjectInterface | null = null

  if (projects.length > 0) mostRecentProject = projects[0]

  const userLoadComplete: boolean = useSelector(
    (state: StateInterface) => state.userLoadComplete
  )

  if (userLoadComplete === false) {
    return (
      <Wrapper>
        <LoadingWrapper>
          <LoadingIcon />
        </LoadingWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <CurrentProjectMessage>
        <ProjectMessageWrapper>
          <ListingSection>
            <ProjectHeader>
              <FontAwesomeIcon icon={faBooks} /> Projects
            </ProjectHeader>
            {projects.slice(0, 5).map((project: ProjectInterface) => {
              return (
                <StandardButton
                  key={`project-listing-${project.id}`}
                  mode="transparent"
                  onClick={() => navigate(`/projects/${project.id}`)}
                >
                  <FontAwesomeIcon
                    icon={
                      project!.config.type === "screenplay"
                        ? faCameraMovie
                        : faBook
                    }
                  />{" "}
                  <strong>{project.name}</strong>
                </StandardButton>
              )
            })}
            {(projects.length > 0 || projectSyncs.length > 0) && <ProjectHR />}
            {projects.length > 2 && (
              <StandardButton
                mode="transparent"
                onClick={() => navigate(`/projects/`)}
              >
                <FontAwesomeIcon icon={faBooks} /> See All Projects
              </StandardButton>
            )}
            {projectSyncs.length > 0 && (
              <StandardButton
                mode="transparent"
                onClick={() => navigate(`/projects/`)}
              >
                <FontAwesomeIcon icon={faCloud} /> See Available Projects
              </StandardButton>
            )}
            <StandardButton
              mode="transparent"
              onClick={() => navigate(`/projects/new`)}
            >
              <FontAwesomeIcon icon={faPlus} />{" "}
              <strong>
                {projects.length === 0
                  ? "Create Your First Project!"
                  : "New Project"}
              </strong>
            </StandardButton>
          </ListingSection>
          <ListingSection>
            <ProjectHeader>
              <FontAwesomeIcon icon={faCog} /> Settings
            </ProjectHeader>
            <StandardButton
              mode="transparent"
              onClick={() => navigate(`/user`)}
            >
              <FontAwesomeIcon icon={faUserCog} /> User Settings
            </StandardButton>
            {(user === null || !user || !user.uid) && (
              <StandardButton
                mode="transparent"
                onClick={() => setShowLoginModal(true)}
              >
                <FontAwesomeIcon icon={faSignInAlt} /> Login
              </StandardButton>
            )}
            {user && user.uid && (
              <StandardButton mode="transparent" onClick={() => logoutUser()}>
                <FontAwesomeIcon icon={faSignOutAlt} /> Logout
              </StandardButton>
            )}
          </ListingSection>
        </ProjectMessageWrapper>
      </CurrentProjectMessage>
      {showLoginModal === true && (
        <LoginModal closeModal={(e: any) => setShowLoginModal(false)} />
      )}
    </Wrapper>
  )
}

export default ProjectListing
