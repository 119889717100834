import styled from "styled-components";

export const NoteHeaderContent = styled.div`
  display: grid;
  color: var(--themed-font-color);
  svg {
    padding: 15px 5px;
  }
`;

export const Hr = styled.hr`
  color: var(--themed-font-color);
  margin-right: 10px;
`

export const Wrapper = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  overflow: hidden;

  h2 {
    margin: 0px;
    border-bottom: 1px solid var(--themed-header-color);
  }

  a {
    font-size: 24px;
    color: var(--brand-primary);

    :hover {
      color: var(--header-font-color);
    }
  }
`;
export const NoteHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
`;
export const NoteDetails = styled.div`
  font-size: 12px;
  padding-top: 3px;
  color: var(--themed-subhead-color);
  text-transform: uppercase;
  text-align: right;
  span {
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const NoteTitle = styled.div`
  cursor: pointer;
  color: var(--brand-primary);
  padding: 0p 0px 5px 0px;

  :hover {
    color: var(--header-font-color);
  }
  font-size: 18px;
  font-family: var(--title-font);
  font-weight: bold;
`;

export const NoteExcerpt = styled.div`
  font-size: 14px;
  padding: 10px 0px;
  font-style: italic;
  color: var(--themed-header-color);
`;

export const NoNoteContent = styled.div`
  color: var(--themed-no-content-color);
`