export const saveSelection = () => {
  if (window.getSelection) {
    var sel = window.getSelection()
    if (sel!.getRangeAt && sel!.rangeCount) {
      return sel!.getRangeAt(0)
    }
  }
  return null
}

export const restoreSelection = (range: Range | undefined | null) => {
  if (range) {
    if (window.getSelection) {
      var sel = window.getSelection()
      sel!.removeAllRanges()
      sel!.addRange(range)
    }
  }
}
