import React, { MouseEventHandler } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  background-color: var(--themed-bg-color);
  max-width: 768px;
  width: calc(100% - 20px);
  border-radius: 8px;
  padding: 10px;
  height: 0px;
  min-height: 100px;
  min-width: 280px;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.27);
  margin: 20px auto;
  display: table;
  overflow-y: scroll;

  @media (max-width: 768px) {
    max-height: 400px;
    max-width: 100%;
    width: calc(100% - 40px);
    border-radius: 0px;
    top: 0px;
    position: fixed;
    margin: 20px;
    box-sizing: border-box;
  }

  @media (max-width: 480px) {
    margin-top: 0px;
    max-height: 100%;
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    margin: 0px;
  }
`

const ModalFrame = ({
  closeModal,
  children
}: {
  closeModal: MouseEventHandler
  children: any
}) => {
  const interceptClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
  }

  return <Wrapper onClick={interceptClick}>{children}</Wrapper>
}

export default ModalFrame
