import React from "react"
import styled from "styled-components"
import { string } from "prop-types"

const Wrapper = styled.div<{ width: string; height: string }>`
  width: ${props => props.width};
  height: ${props => props.height};
`

const Spacer = (props: any) => {
  return <Wrapper width={props.width} height={props.height}></Wrapper>
}

export default Spacer
