import React, { useState } from "react";
import styled from "styled-components";
import ModalBase from "../../common/Modal/ModalBase/ModalBase";
import { useDispatch } from "react-redux";
import { NoteMetaInterface } from "../../utils/Interfaces";
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton";
import ModalActions from "../../common/Modal/ModalActions/ModalActions";
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faBan } from "@fortawesome/pro-regular-svg-icons";
import { getIconForAssetType } from "../../utils/Icons";
import { assetTypes } from "../../utils/Assets"

const Supplemental = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  overflow-y: hidden;
`;

const NoteTypeOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-bottom: 15px;

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
`

const NoteTypeOption = styled.div`
  font-size: 16px;
  text-transform: capitalize;
  font-family: var(--title-font);
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    background-color: var(--themed-selected-text-bg-color);
  }
`

const NoteTypeOptionIcon = styled.div<{ type: string }>`
  font-size: 40px;
  color: ${(props) => `var(--${props.type}-color)`};

  @media (max-width: 600px) {
    font-size: 30px;
  }
`

// TODO: How to fix this?
const AddItemToProjectModal = ({
  type,
  closeModal,
}: {
  type: string;
  closeModal: any;
}) => {
  const [noteTitle, setNoteTitle] = useState("");
  const [assetType, setAssetType] = useState(type);
  const [noteMeta, setNoteMeta] = useState({});
  const dispatch = useDispatch();

  const updateNoteMeta = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNoteMeta: NoteMetaInterface = {
      supplemental: e.currentTarget.value,
    };

    setNoteMeta(newNoteMeta);
  };

  const updateNoteTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteTitle(e.currentTarget.value);
  };

  const submitNewNote = () => {
    const toDispatch = {
      type: "create_project_asset",
      value: { type: assetType, noteMeta, title: noteTitle },
    };
    dispatch(toDispatch);
    closeModal();
  };

  // TODO: Enable ability to select existing item to scene.
  if (assetType === "") {
      return (<ModalBase closeModal={closeModal}>
        <React.Fragment>
        <NoteTypeOptions>
          {assetTypes.map((type: string) => {
            return (
              <NoteTypeOption
                key={`add-note-option-${type}`}
                onClick={() => setAssetType(type)}
              >
                <NoteTypeOptionIcon type={type}>
                  <FontAwesomeIcon icon={getIconForAssetType(type)} />
                </NoteTypeOptionIcon>

                {` ${type}`}
              </NoteTypeOption>
            )
          })}
        </NoteTypeOptions>
        <ModalActions>
          <ModalActionButton type="cancel" onClick={closeModal}>
            <FontAwesomeIcon icon={faBan} /> Cancel
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
      </ModalBase>
    )
  } else {

  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Add {assetType}</ModalTitle>
        <input
          type="title"
          onChange={updateNoteTitle}
          placeholder="Title"
          autoFocus
        />
        <Supplemental placeholder="Elaborate Here" onChange={updateNoteMeta} />
        <ModalActions>
          <ModalActionButton title="Cancel" type="cancel" onClick={closeModal}>
            <FontAwesomeIcon icon={faBan} /> Cancel
          </ModalActionButton>
          <ModalActionButton title="Okay" type="okay" onClick={submitNewNote}>
            <FontAwesomeIcon icon={faCheckCircle} /> Okay
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  )
  };
};

export default AddItemToProjectModal;
