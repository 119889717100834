import React from "react"
import styled from "styled-components"
import NoteItem from "../../Notes/NoteItem/NoteItem"
import { getFullItem, currentProject } from "../../../utils/State"
import { useSelector } from "react-redux"
import {
  StateInterface,
  ProjectInterface,
  NoteItemInterface,
  SceneItemInterface,
  MobileOptionsInterface,
} from "../../../utils/Interfaces"

const Wrapper = styled.div`
  padding: 20px 20px 0px 0px;
`

const Section = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: var(--themed-subhead-color);
  text-transform: uppercase;
  margin-bottom: 5px;
`

const DesktopContent = styled.div<{ active: boolean }>`
  @media (max-width: 768px) {
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    left: ${(props) => (props.active === true ? "0px" : "-100%")};
    top: 84px;
    height: 100vh;
    transition: left var(--transition--fast);
    -webkit-transition: left var(--transition--fast);
    background-color: var(--themed-bg-color);

    z-index: 1000;
    min-height: 100%;
    width: 100%;
    border-top: 1px solid var(--themed-subhead-color);
  }
`

const ItemsWrapper = styled.div`
  margin-bottom: 30px;
`

type NoteItemsProps = {
  items: Array<NoteItemInterface | SceneItemInterface>
  showItemInfo: Function
}

const NoteItems = ({ items, showItemInfo }: NoteItemsProps) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const mobileMenu: MobileOptionsInterface = project!.mobileMenu

  return (
    <Wrapper>
      <DesktopContent active={mobileMenu.notes.assets === true}>
        {items.filter(
          (item: NoteItemInterface | SceneItemInterface) =>
            item.type === "storyline"
        ).length > 0 && (
          <React.Fragment>
            <Section>Storylines</Section>
            <ItemsWrapper>
              {items
                .filter(
                  (item: NoteItemInterface | SceneItemInterface) =>
                    item.type === "storyline"
                )
                .map((item: NoteItemInterface | SceneItemInterface) => {
                  if (!item) return
                  return (
                    <NoteItem
                      key={`noteitem_${item.id}`}
                      item={item}
                      showItemInfo={showItemInfo}
                    />
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
        )}
        {items.filter(
          (item: NoteItemInterface | SceneItemInterface) =>
            item.type === "storySection"
        ).length > 0 && (
          <React.Fragment>
            <Section>Sections</Section>
            <ItemsWrapper>
              {items
                .filter(
                  (item: NoteItemInterface | SceneItemInterface) =>
                    item.type === "storySection"
                )
                .map((item: NoteItemInterface | SceneItemInterface) => {
                  if (!item) return
                  return (
                    <NoteItem
                      key={`noteitem_${item.id}`}
                      item={item}
                      showItemInfo={showItemInfo}
                    />
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
        )}
        {items.filter(
          (item: NoteItemInterface | SceneItemInterface) =>
            item.type === "scene"
        ).length > 0 && (
          <React.Fragment>
            <Section>Scenes</Section>
            <ItemsWrapper>
              {items
                .filter(
                  (item: NoteItemInterface | SceneItemInterface) =>
                    item.type === "scene"
                )
                .map((item) => {
                  if (!item) return
                  return (
                    <NoteItem
                      key={`noteitem_${item.id}`}
                      item={item}
                      showItemInfo={showItemInfo}
                    />
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
        )}
        {items.filter(
          (item: NoteItemInterface | SceneItemInterface) =>
            item.type === "storybeat"
        ).length > 0 && (
          <React.Fragment>
            <Section>Story Beats</Section>
            <ItemsWrapper>
              {items
                .filter(
                  (item: NoteItemInterface | SceneItemInterface) =>
                    item.type === "storybeat"
                )
                .map((item) => {
                  const fullItem: any = getFullItem(project, item.type, item.id)
                  if (!fullItem) return
                  return (
                    <NoteItem
                      key={`noteitem_${item.id}`}
                      item={fullItem}
                      showItemInfo={showItemInfo}
                    >
                      {fullItem.selectedText}
                    </NoteItem>
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
        )}
        {items.filter(
          (item: NoteItemInterface | SceneItemInterface) =>
            item.type === "dialogue"
        ).length > 0 && (
          <React.Fragment>
            <ItemsWrapper>
              <Section>Dialogue</Section>
              {items
                .filter(
                  (item: NoteItemInterface | SceneItemInterface) =>
                    item.type === "dialogue"
                )
                .map((item) => {
                  const fullItem: any = getFullItem(project, item.type, item.id)
                  if (!fullItem) return
                  return (
                    <NoteItem
                      key={`noteitem_${item.id}`}
                      item={fullItem}
                      showItemInfo={showItemInfo}
                    >
                      {fullItem.selectedText}
                    </NoteItem>
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
        )}
        {items.filter(
          (item: NoteItemInterface | SceneItemInterface) =>
            item.type === "character"
        ).length > 0 && (
          <React.Fragment>
            <ItemsWrapper>
              <Section>Characters</Section>
              {items
                .filter(
                  (item: NoteItemInterface | SceneItemInterface) =>
                    item.type === "character"
                )
                .map((item) => {
                  const fullItem: any = getFullItem(project, item.type, item.id)
                  if (!fullItem) return
                  return (
                    <NoteItem
                      key={`noteitem_${item.id}`}
                      item={fullItem}
                      showItemInfo={showItemInfo}
                    >
                      {fullItem.selectedText}
                    </NoteItem>
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
        )}
        {items.filter(
          (item: NoteItemInterface | SceneItemInterface) => item.type === "arc"
        ).length > 0 && (
          <React.Fragment>
            <ItemsWrapper>
              <Section>Arcs</Section>
              {items
                .filter(
                  (item: NoteItemInterface | SceneItemInterface) =>
                    item.type === "arc"
                )
                .map((item) => {
                  const fullItem: any = getFullItem(project, item.type, item.id)
                  if (!fullItem) return
                  return (
                    <NoteItem
                      key={`noteitem_${item.id}`}
                      item={fullItem}
                      showItemInfo={showItemInfo}
                    >
                      {fullItem.selectedText}
                    </NoteItem>
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
        )}
        {items.filter(
          (item: NoteItemInterface | SceneItemInterface) =>
            item.type === "location"
        ).length > 0 && (
          <React.Fragment>
            <ItemsWrapper>
              <Section>Locations</Section>
              {items
                .filter(
                  (item: NoteItemInterface | SceneItemInterface) =>
                    item.type === "location"
                )
                .map((item) => {
                  const fullItem: any = getFullItem(project, item.type, item.id)
                  if (!fullItem) return
                  return (
                    <NoteItem
                      key={`noteitem_${item.id}`}
                      item={fullItem}
                      showItemInfo={showItemInfo}
                    >
                      {fullItem.selectedText}
                    </NoteItem>
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
        )}
        {items.filter(
          (item: NoteItemInterface | SceneItemInterface) =>
            item.type === "macguffin"
        ).length > 0 && (
          <React.Fragment>
            <Section>MacGuffins</Section>
            <ItemsWrapper>
              {items
                .filter(
                  (item: NoteItemInterface | SceneItemInterface) =>
                    item.type === "macguffin"
                )
                .map((item) => {
                  if (!item) return
                  return (
                    <NoteItem
                      key={`noteitem_${item.id}`}
                      item={item}
                      showItemInfo={showItemInfo}
                    />
                  )
                })}
            </ItemsWrapper>
          </React.Fragment>
        )}
      </DesktopContent>
    </Wrapper>
  )
}

export default NoteItems
