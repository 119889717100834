import React from "react"
import styled from "styled-components"
import WriteMetaItemsByType from "../WriteMetaItemsByType/WriteMetaItemsByType"
import {
  WriteMetaItemInterface,
} from "../../../utils/Interfaces"


import { assetTypes } from "../../../utils/Assets"

const Wrapper = styled.div`
  padding: 0px 20px 0px 0px;
`

type WriteMetaItemsProps = {
  items: Array<WriteMetaItemInterface>
  showItemInfo: Function
}

const WriteMetaItems = ({ items, showItemInfo }: WriteMetaItemsProps) => {
  return (
    <Wrapper>
      {assetTypes.map((assetType: string) => <WriteMetaItemsByType key={`write-meta-items-${assetType}`} items={items} assetType={assetType} showItemInfo={showItemInfo} />)}
    </Wrapper>
  )
}

export default WriteMetaItems
