import { ConfigObjectInterface } from "./Interfaces"

export const isMobile = (config: ConfigObjectInterface): boolean => {
  let mobile: boolean = false
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    mobile = true
  }

  if (CSS.supports('hover: none') === true) {
    mobile = true
  }

  if (/Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 0) {
    mobile = true
  }

  if (config.contextMenuMode === "desktop") { mobile = false }
  if (config.contextMenuMode === "mobile") { mobile = true }

  return mobile
  
}