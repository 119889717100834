import styled from "styled-components"

export const Wrapper = styled.div``

export const AssetTypeHeader = styled.div<{ type: string; active: boolean }>`
  padding: 5px;
  display: inline-block;
  font-size: 15px;
  font-family: var(--title-font);
  color: var(--themed-font-color);

  ${props =>
    props.active === true &&
    `color: var(--white-color) !important; background-color: var(--${props.type}-color)`};
  cursor: pointer;
  svg {
    color: var(--themed-asset-header-color);
  }
  svg:first-of-type {
    color: ${props => `var(--${props.type}-color)`};
    ${props => props.active === true && `color: var(--white-color);`}
  }

  :hover {
    color: var(--themed-bg-color);
    background-color: ${props => `var(--${props.type}-color)`};

    ${props => (props.type === "") && 'background-color: var(--black-color); '}
  }

  :hover svg {
    color: var(--white-color);
  }

  ${props => (props.active === true && props.type === "") && 'background-color: var(--black-color); '}
`

export const AssetTypeHeaderIcon = styled.div<{
  type: string
  active: boolean
}>`
  font-size: 16px;
  display: inline-block;
  margin-right: 5px;

  @media (max-width: 600px) {
    margin-right: 0px;
  }
`

export const AssetTypeHeaderTitle = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 2em;
  display: inline-block;

  @media (max-width: 600px) {
    display: none;
  }
`
