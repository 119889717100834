import styled from "styled-components"

export const EditableTextField = styled.div`
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px 20px 13px;
  margin-bottom: 100px;
  -webkit-touch-callout: none;
  outline: 0;
  min-height: calc(100vh - 100px);

  border-top: 1px solid var(--themed-border-color);
`

export const Wrapper = styled.div``

export const NoteTypeOption = styled.span<{ active: boolean }>`
  ${(props) => props.active === true && "color: var(--themed-font-color);"}
  ${(props) =>
    props.active === false && "color: var(--themed-no-content-color);"}
  margin-left: 5px;

  cursor: pointer;
  :hover {
    color: var(--themed-font-color);
  }
  margin-bottom: 10px;
`

export const NoteTypeOptions = styled.div`
  line-height: 3.4em;
`

export const NoteTitle = styled.input`
  padding: 5px;
  width: calc(100% - 40px);
  font-size: 18px;
  font-weight: bold;
  border: none;
  outline: 0;
  font-family: var(--title-font);
  box-sizing: border-box;
  border: 0px;
  margin: 10px 20px 10px 0px;
  background-color: var(--themed-bg-color);
  color: var(--themed-font-color);
  :hover {
    background-color: var(--themed-input-focus-color);
  }

  :focus {
    background-color: var(--themed-input-focus-color);
  }
`

export const NoteTitleStrip = styled.div`
  display: grid;
  grid-template-columns: 30px 30px 1fr;
`

export const NotesIndexLink = styled.span`
  font-size: 18px;
  color: var(--themed-font-color);
  text-align: center;
  cursor: pointer;
  padding: 7px 2px 2px 2px;
  border-radius: 4px;
  margin-left: 5px;
  line-height: 2.5em;

  :hover {
    color: green;
  }
`
