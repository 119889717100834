import { createReducer, configureStore } from "redux-starter-kit"
import {
  generateRandomId,
  sanitizeLocationName,
  sanitizeCharacterName,
} from "../utils/Strings"
import {
  currentProject,
  currentNote,
  currentNoteFromProject,
  currentSceneObject,
  detectDarkMode,
} from "../utils/State"

import {
  auth,
  db,
  firestore,
  facebookAuthProvider,
  googleAuthProvider,
} from "./../_config/firebase"

import {
  StateInterface,
  NoteInterface,
  ProjectInterface,
  AssetInterface,
  SceneInterface,
  Dispatch_UpdateNote,
  Dispatch_AddNoteItem,
  Dispatch_UpdateSceneContent,
  Dispatch_AddItemToScene,
  NoteItemLocationInterface,
  Dispatch_CreateProjectAsset,
  SceneItemInterface,
  NoteItemInterface,
  InitialProjectStateInterface,
  StorySectionInterface,
  ShallowSceneInterface,
  Dispatch_UpdateStorySection,
  Dispatch_DeleteStorySection,
  Dispatch_UpdateProjectInterface,
  SceneConfigObjectInterface,
  ProjectConfigObjectInterface,
  ActivityInterface,
  ActivitiesListInterface,
  Dispatch_AddFactoidToAsset,
  FactsInterface,
  Dispatch_DeleteFactoid,
  Dispatch_AddItemFromScene,
  UserInterface,
  NoteMetaInterface,
  Dispatch_ImportFileProject,
  ImportFileInterface,
  MobileOptionsInterface,
  ProjectSyncInterface,
  UserProjectInterface,
  GoalDateInterface,
  ProjectTemplateInterface,
  ProjectTemplateNote,
  ProjectTemplateAsset,
} from "../utils/Interfaces"
import { navigate } from "@reach/router"
import {
  emptyProject,
  emptyStorySection,
  emptyNote,
  emptyScene,
  defaultState,
  defaultProjectConfig,
  projectNotesOfType,
  projectTemplates,
  emptyAsset,
} from "../utils/Data"
import { Activities } from "../utils/Activities"
import { uploadImageToStorage, deleteImageFromStorage } from "../utils/Images"
import { WordCount } from "../utils/Numbers"

// const Dexie = require('dexie')
import Dexie from "dexie"
import moment from "moment"
import { updateGoals } from "../utils/Goals"
const dexiedb = new Dexie("hilker")

declare global {
  interface Window {
    gtag: any
  }
}

/* --- --- --- CONVENIENCE METHOD --- --- --- */
const addLeaveSiteConfirmation = () => {
  window.onbeforeunload = function () {
    if (Note.getState()!.savePending === true) return true
  }
}

/* --- --- --- METHODS --- --- --- */
const createProject = (state: StateInterface, initialProjectState: any) => {
  const newProjectTemplateType: string = initialProjectState.template
  delete initialProjectState.template

  let newProject = Object.assign({}, JSON.parse(JSON.stringify(emptyProject)), {
    ...initialProjectState,
    id: generateRandomId(),
    config: { ...initialProjectState.config },
  })

  if (newProjectTemplateType > "") {
    newProject = applyTemplate(newProject, newProjectTemplateType)
  }

  state.projects.push(newProject)
  state.currentProject = newProject.id

  window.gtag("event", "Project - Created")

  saveProjectNow(state)

  navigate(`/projects/${newProject.id}?created=true`)
}

const applyTemplate = (
  newProject: ProjectInterface,
  newProjectTemplateType: string
) => {
  const templateToUse:
    | ProjectTemplateInterface
    | undefined = projectTemplates.find(
    (template: ProjectTemplateInterface) =>
      template.id === newProjectTemplateType
  )

  if (!templateToUse) return newProject

  // add acts and scenes
  for (let i = 0; i < templateToUse.numberOfSections; i++) {
    let sectionTitle: string = "Act " + (i + 1)
    let sectionDescription: string = "Description for Act " + (i + 1)

    const newScene: SceneInterface = Object.assign({}, emptyScene, {
      id: generateRandomId(),
      title: sectionTitle + " - Scene 1",
      noteMeta: {
        supplemental: "Description for " + sectionTitle + " - Scene 1",
      },
      items: [],
    })
    const newSection: StorySectionInterface = Object.assign(
      {},
      emptyStorySection,
      {
        id: generateRandomId(),
        title: sectionTitle,
        description: sectionDescription,
        scenes: [{ id: newScene.id }],
      }
    )

    newProject.storySections.push(newSection)
    newProject.scenes.push(newScene)
  }

  // add notes
  templateToUse.notes.forEach((note: ProjectTemplateNote) => {
    const newNote: NoteInterface = Object.assign({}, emptyNote, {
      id: generateRandomId(),
      ...note,
    })
    newProject.notes.push(newNote)
  })

  // add assets
  templateToUse.assets.forEach((asset: ProjectTemplateAsset) => {
    const newAsset: AssetInterface = Object.assign({}, emptyAsset, {
      id: generateRandomId(),
      title: asset.name,
      type: asset.type,
      noteMeta: { supplemental: asset.description },
    })

    if (asset.type === "character") {
      newAsset.role = asset.role?.toUpperCase()
    }

    newProject.assets.push(newAsset)
  })

  newProject.scenes[0].items.push({
    id: newProject.assets[0].id,
    type: "character",
  })

  newProject.templateUsed = newProjectTemplateType

  return newProject
}

const updateProjectConfig = (
  state: StateInterface,
  {
    projectId,
    config,
  }: { projectId: string; config: ProjectConfigObjectInterface }
) => {
  const project = state.projects.find(
    (project: ProjectInterface) => project.id === projectId
  )
  project!.config = config
  project!.canBeSyncedToFirebase = true
  saveActivity(project!, "UPDATED_PROJECT_CONFIG", "")
}

const importProject = (
  state: StateInterface,
  initialProjectState: InitialProjectStateInterface
) => {
  const newProject = Object.assign({}, emptyProject, {
    ...initialProjectState,
    id: generateRandomId(),
    created_at: new Date().toString(),
    updated_at: new Date().toString(),
  })

  state.projects.push(newProject)
  state.currentProject = newProject.id

  window.gtag("event", "Project - Imported")

  navigate(`/projects/${newProject.id}`)
}

const importDocxFile = (state: StateInterface, data: any) => {
  importTxtFile(state, data)
  window.gtag("event", "Project - DocxFile Imported")
}

const importTxtFile = (state: StateInterface, data: any) => {
  let newProject = Object.assign({}, emptyProject, {
    id: generateRandomId(),
    created_at: new Date().toString(),
    updated_at: new Date().toString(),
    name: data.fileName,
  })

  const storySectionId: string = generateRandomId()
  const sceneId: string = generateRandomId()

  let newSection = Object.assign({}, emptyStorySection, {
    id: storySectionId,
    title: "Act 1",
    scenes: [{ id: sceneId }],
  })
  let newScene = Object.assign({}, emptyScene, {
    title: "Scene 1",
    id: sceneId,
    text: data.content,
    parentId: storySectionId,
    sectionId: storySectionId,
  })
  newProject.scenes.push(newScene)
  newProject.storySections.push(newSection)
  newProject.currentScreenplayElementType = "screenplay-scene"
  state.projects.push(newProject)
  state.currentProject = newProject.id

  saveProjectNow(state)

  navigate(`/projects/${newProject.id}`)
}

const saveFileForImport = (
  state: StateInterface,
  fileData: ImportFileInterface
) => {
  state.fileForImport = fileData
  navigate(`/import`)
}

const cancelFileImport = (state: StateInterface) => {
  state.fileForImport = undefined
  navigate(`/projects/new`)
}

const importFileProject = (
  state: StateInterface,
  importData: Dispatch_ImportFileProject
) => {
  const initialProjectState = {
    name: importData.title,
  }

  window.gtag("event", "Project - Screenplay Imported")

  const project = Object.assign({}, emptyProject, {
    ...initialProjectState,
    id: generateRandomId(),
    created_at: new Date().toString(),
    updated_at: new Date().toString(),
  })

  project.config.render = "html"
  project.config.type = "screenplay"
  project.config.lineSpacing = 1
  state.projects.push(project)
  state.currentProject = project.id

  importData.elements.forEach((element: any, index: number) => {
    // create section, if necessary
    const currentStorySection: any = importData.sections.find(
      (section: any) => section.id === index
    )

    if (currentStorySection) {
      const newStorySection: StorySectionInterface = {
        id: generateRandomId(),
        title:
          currentStorySection.title === ""
            ? "Act " + (project.storySections.length + 1)
            : currentStorySection.title,
        open: false,
        noteMeta: { supplemental: " from FDX file" },
        description: "",
        scenes: [],
      }

      project.storySections.push(newStorySection)
    }

    const latestStorySection: StorySectionInterface =
      project.storySections[project.storySections.length - 1]

    const currentScene: any = importData.scenes.find(
      (scene: any) => scene.id === index
    )

    if (currentScene) {
      const newScene: SceneInterface = {
        id: generateRandomId(),
        title:
          currentScene.title === ""
            ? "Scene " + (project.scenes.length + 1)
            : currentScene.title,
        items: [],
        description: "",
        text: "",
        wordCount: 0,
        noteMeta: { supplemental: "" },
        config: Object.assign({}, project!.config),
      }

      latestStorySection.scenes.push({ id: newScene.id })
      project.scenes.push(newScene)
    }

    const latestScene: SceneInterface =
      project.scenes[project.scenes.length - 1]

    // add element to current scene
    latestScene.text +=
      '<p class="' + element.type + '">' + element.value + "</p>"

    // fire auto-add
    if (
      element.type === "screenplay-character" ||
      element.type === "screenplay-scene"
    ) {
      addAssetToSceneIfNecessary(
        element.type,
        element.value,
        project,
        latestScene,
        state
      )
    }
  })

  saveProjectNow(state)
  navigate(`/projects/${project.id}?created=true`)

  state.fileForImport = undefined
}

const addAssetToSceneIfNecessary = (
  assetType: string,
  assetName: string,
  project: ProjectInterface,
  scene: SceneInterface,
  state: StateInterface
) => {
  let newAssetName: string = assetName.toUpperCase().trim()
  let assetTypeToAdd: string = assetType
  if (assetType === "screenplay-character") {
    assetTypeToAdd = "character"
    newAssetName = sanitizeCharacterName(newAssetName)
  }
  if (assetType === "screenplay-scene") {
    // TODO: trim stuff for location, after deciding abotu int/ext stuff.
    assetTypeToAdd = "location"
    newAssetName = sanitizeLocationName(newAssetName)

    const splitNewAsset: Array<string> = newAssetName
      .split(" -")
      .filter((asset: string) => {
        return (
          asset > "" &&
          asset > " " &&
          asset.trim() > "" &&
          asset.replace(/[^a-zA-Z0-9+]/g, "").length > 0
        )
      })

    if (!splitNewAsset || splitNewAsset.length === 0) return
    newAssetName = splitNewAsset[0].toUpperCase().trim()
  }

  if (
    ((assetName.toUpperCase().indexOf("EXT") === -1 &&
      assetName.toUpperCase().indexOf("INT") === -1) ||
      assetName.toUpperCase().indexOf("INTERCUT") === 0) &&
    assetTypeToAdd === "location"
  ) {
  } else {
    stateAction(addItemToSceneAndCreate, state, {
      type: assetTypeToAdd,
      title: newAssetName,
      projectId: project.id,
      sceneId: scene.id,
      completed: true,
    })
  }
}

const selectProject = (state: StateInterface, id: string) => {
  // TODO: Add check to make sure the project is valid
  // Eventually used regarding sharing. Presently just making
  // the assumption
  state.currentProject = id
  const project: ProjectInterface | undefined = state.projects.find(
    (project: ProjectInterface) => project.id === id
  )
  project!.currentScene = "home"
}

const deleteImagesFromServerIfProjectIsOnlyLocal = (
  projectToBeDeleted: ProjectInterface
) => {
  const itemsToDelete: Array<any> = []
  projectToBeDeleted.assets.map((asset: AssetInterface) => {
    if (asset.thumbnail > "") {
      itemsToDelete.push({
        thumbnail: asset.thumbnail,
        type: asset.type,
        id: asset.id,
        projectId: projectToBeDeleted.id,
      })
    }
  })

  db.collection("projects")
    .where("id", "==", projectToBeDeleted.id)
    .get()
    .then((querySnapshot: any) => {
      if (querySnapshot.size === 0) {
        // project isn't on remote; delete all images
        processImageRemoval(itemsToDelete)
      }
    })
    .catch(function (error) {
      console.error(error)
    })
}

const processImageRemoval = (assets: Array<any>) => {
  assets.map((asset: any) => {
    deleteImageFromStorage({
      src: asset.thumbnail,
      assetType: asset.type,
      assetId: asset.id,
      projectId: asset.projectId,
    })
  })
}

const deleteProject = (state: StateInterface, id: string) => {
  const projectToBeDeleted: ProjectInterface | undefined = state.projects.find(
    (project: ProjectInterface) => project.id === id
  )
  if (projectToBeDeleted !== undefined) {
    deleteImagesFromServerIfProjectIsOnlyLocal(projectToBeDeleted!)
  }
  state.projects = state.projects.filter(
    (project: ProjectInterface) => project.id !== id
  )

  state.projectSyncs = state.projectSyncs.filter(
    (projectSync: ProjectSyncInterface) => projectSync.id !== id
  )

  if (state.projects.length > 0 && id === state.currentProject) {
    state.currentProject = state.projects[0].id
  }

  saveProjectNow(state)
  window.gtag("event", "Project - Deleted")
}

const updateProject = (
  state: StateInterface,
  values: Dispatch_UpdateProjectInterface
) => {
  let project: ProjectInterface | null = currentProject(state)

  project!.name = values.name!
  project!.description = values.description!
  project!.canBeSyncedToFirebase = true

  saveActivity(project!, "UPDATED_PROJECT_VALUES", "")
}

const createStorySection = (
  state: StateInterface,
  section: StorySectionInterface
) => {
  const project: ProjectInterface | null = currentProject(state)
  const id: string = generateRandomId()
  const newStorySection: StorySectionInterface = Object.assign(
    {},
    emptyStorySection,
    { id, title: section.title, description: section.description }
  )

  window.gtag("event", "Story Section - Created")

  saveActivity(
    project!,
    "CREATED_STORY_SECTION",
    newStorySection.title,
    newStorySection.id,
    "storySection"
  )
  project!.storySections.push(newStorySection)
  project!.canBeSyncedToFirebase = true

  if (section.createScene === true) {
    setTimeout(() => {
      Note.dispatch({
        type: "create_scene",
        value: {
          scene: {
            title: newStorySection.title + " - First Scene",
            noteMeta: { supplemental: "" },
            sectionId: newStorySection.id,
            createNewScene: true,
          },
        },
      })
    }, 10)
  } else {
    saveProjectNow(state)
  }
}

const toggleShowStorySectionScenes = (state: StateInterface, id: string) => {
  const project: ProjectInterface | null = currentProject(state)
  const storySection:
    | StorySectionInterface
    | undefined = project!.storySections.find(
    (section: StorySectionInterface) => section.id === id
  )
  storySection!.open = !storySection!.open
}

const createNote = (state: StateInterface, newNoteType: string) => {
  const project: ProjectInterface | null = currentProject(state)
  const newNoteId = generateRandomId()
  const newNoteDate = new Date()

  const newNote = {
    ...emptyNote,
    id: newNoteId,
    type: newNoteType,
    created_at: newNoteDate.toString(),
  }

  window.gtag("event", "Note - Created")

  saveActivity(project!, "CREATED_NOTE", newNote!.title, newNote!.id, "note")

  project!.notes.push(newNote)
  project!.currentNote = newNoteId
  project!.canBeSyncedToFirebase = true

  saveProjectNow(state)
}

const setCurrentNote = (state: StateInterface, noteId: string) => {
  const project: ProjectInterface | null = currentProject(state)
  project!.currentNote = noteId
}

const addNoteItem = (
  state: StateInterface,
  {
    id,
    noteId,
    type,
    selectedText,
    noteMeta,
    title,
    tags,
  }: Dispatch_AddNoteItem
) => {
  const project: ProjectInterface | null = currentProject(state)

  // TODO: Make customization per type more robust.
  const itemPayload: any = {
    id,
    noteId,
    selectedText,
    noteMeta,
    type,
    title,
    factoids: [],
    tags,
    updated: new Date().getTime().toString(),
  }

  window.gtag("event", "Note - Item Added to Note")

  saveActivity(project!, "ADDED_ITEM_TO_NOTE", type + " : " + title, id, type)

  // ADD TO MAIN PROJECT ARRAYS
  switch (type) {
    case "storySection":
      const newStorySection: StorySectionInterface = Object.assign(
        {},
        emptyStorySection,
        itemPayload
      )
      project!.storySections.push(newStorySection)
      break
    case "scene":
      const newScene: SceneInterface = Object.assign(
        {},
        emptyScene,
        itemPayload
      )
      project!.scenes.push(newScene)
      break
    default:
      project!.assets.push(itemPayload)
      break
  }

  project!.canBeSyncedToFirebase = true

  currentNote(state)!.items.push({
    id,
    type,
    title,
    tags,
    selectedText: "",
    noteMeta: {},
  })
  saveProjectNow(state)
}

const updateNoteItem = (state: StateInterface, props: any) => {
  // TODO: Only update main project arrays
  const project: ProjectInterface | null = currentProject(state)
  const { type, id } = props
  let itemToEdit: any
  switch (type) {
    case "storySection":
      itemToEdit = project!.storySections.find(
        (item: StorySectionInterface) => item.id === id
      )
      break
    case "scene":
      itemToEdit = project!.scenes.find(
        (item: SceneInterface) => item.id === id
      )
      break
    default:
      itemToEdit = project!.assets.find(
        (item: AssetInterface) => item.id === id
      )
      break
  }

  Object.keys(props).forEach((item: any) => {
    if (item !== "id" && item !== "type") {
      // check to see if image value is different;
      // if it is, either remove the image from server, or upload
      if (item === "thumbnail" && itemToEdit[item] !== props[item]) {
        //
        if (props[item] === "") {
          // delete on server
          deleteImageFromStorage({
            src: props[item],
            assetType: itemToEdit.type,
            assetId: itemToEdit.id,
            projectId: project!.id,
          })
        } else {
          uploadImageToStorage({
            src: props[item],
            assetType: itemToEdit.type,
            assetId: itemToEdit.id,
            projectId: project!.id,
          }).then((imageToStorageProps: any) => {
            const { url, assetType, assetId, projectId } = imageToStorageProps
            Note.dispatch({
              type: "set_asset_thumbnail",
              value: {
                assetId: assetId,
                assetType: assetType,
                projectId: projectId,
                url: url,
              },
            })
          })
        }
      }
      itemToEdit[item] = props[item]
    }
  })
  // itemToEdit.noteMeta = noteMeta
  // itemToEdit.title = title
  // itemToEdit.text = text
  // if (color) itemToEdit.color = color
  itemToEdit.updated = new Date().getTime().toString()
  project!.canBeSyncedToFirebase = true

  saveActivity(project!, "UPDATED_NOTE_ITEM", props.title, itemToEdit.id, type)
}

const setAssetThumbnail = (
  state: StateInterface,
  {
    assetId,
    assetType,
    projectId,
    url,
  }: { assetId: string; assetType: string; projectId: string; url: string }
) => {
  let itemToEdit: any
  const project: ProjectInterface | null = currentProject(state)

  switch (assetType) {
    case "storySection":
      itemToEdit = project!.storySections.find(
        (item: StorySectionInterface) => item.id === assetId
      )
      break
    case "scene":
      itemToEdit = project!.scenes.find(
        (item: SceneInterface) => item.id === assetId
      )
      break
    default:
      itemToEdit = project!.assets.find(
        (item: AssetInterface) => item.id === assetId
      )
      break
  }

  itemToEdit.thumbnail = url
  saveProjectNow(state)
}

const updateNote = (
  state: StateInterface,
  updatedNote: Dispatch_UpdateNote
) => {
  const project: ProjectInterface | null | undefined = currentProject(state)
  const note: NoteInterface | undefined = currentNoteFromProject(project!)

  note!.text = updatedNote.text
  if (note!.text === "") {
    note!.text = "<p>&#8288;</p>"
  }
  note!.title = updatedNote.title
  note!.type = updatedNote.type
  note!.links = updatedNote.links
  note!.updated_at = new Date().toString()
  project!.canBeSyncedToFirebase = true

  saveActivity(project!, "UPDATED_NOTE", updatedNote.title, note!.id, "note")
}

const removeItemFromScene = (
  state: StateInterface,
  { id, type }: { id: string; type: string }
) => {
  const project: ProjectInterface | null = currentProject(state)
  const scene: SceneInterface = currentSceneObject(project)

  const itemToBeRemoved: any = scene.items.find(
    (item: SceneItemInterface) => item.id === id && item.type === type
  )

  saveActivity(
    project!,
    "REMOVED_ITEM_FROM_SCENE",
    itemToBeRemoved.title + " from " + scene.title,
    id,
    "scene"
  )
  project!.canBeSyncedToFirebase = true
  scene.items = scene.items.filter(
    (item: SceneItemInterface) => !(item.id === id && item.type === type)
  )
}

const removeItemFromSpecificScene = (
  state: StateInterface,
  { id, type, sceneId }: { id: string; type: string; sceneId: string }
) => {
  const project: ProjectInterface | null = currentProject(state)
  const scene: SceneInterface | undefined = project!.scenes.find(
    (chap: SceneInterface) => chap.id === sceneId
  )
  const itemToBeRemoved: any = scene!.items.find(
    (item: SceneItemInterface) => item.id === id && item.type === type
  )
  saveActivity(
    project!,
    "REMOVED_ITEM_FROM_SCENE",
    itemToBeRemoved.title + " from " + scene!.title,
    id,
    "scene"
  )
  project!.canBeSyncedToFirebase = true

  scene!.items = scene!.items.filter(
    (item: SceneItemInterface) => !(item.id === id && item.type === type)
  )
}

// TODO: Need to add confirmation for the removals
const removeItemFromNote = (
  state: StateInterface,
  { id, type }: { id: string; type: string }
) => {
  const project: ProjectInterface | null = currentProject(state)
  const note: NoteInterface | null = currentNote(state)
  project!.canBeSyncedToFirebase = true

  if (note) {
    const itemToBeRemoved: any = note.items.find(
      (item: SceneItemInterface) => item.id === id && item.type === type
    )
    saveActivity(
      project!,
      "REMOVED_ITEM_FROM_NOTE",
      itemToBeRemoved.title + " from " + note.title,
      note.id,
      "note"
    )
    note.items = note.items.filter(
      (item: NoteItemInterface) => !(item.id === id && item.type === type)
    )
    // TODO: Fix this any
    note.itemLocations = note.itemLocations.filter(
      (item: any) => !(item.id === id && item.type === type)
    )
  }
}

const deleteItemFromProject = (
  state: StateInterface,
  { id, type }: { id: string; type: string }
) => {
  const project: ProjectInterface | null = currentProject(state)
  let itemToBeRemoved: any

  window.gtag("event", "Project - Deleted " + type + " from project")
  project!.canBeSyncedToFirebase = true
  itemToBeRemoved = project!.assets.find(
    (asset: AssetInterface) => asset.id === id
  )
  project!.assets = project!.assets.filter(
    (asset: AssetInterface) => asset.id !== id
  )

  if (itemToBeRemoved.thumbnail === "") {
    saveProjectNow(state)
  }
  deleteImageFromServer(itemToBeRemoved, project!)

  saveActivity(
    project!,
    "REMOVED_ITEM_FROM_PROJECT",
    itemToBeRemoved.title,
    itemToBeRemoved.id,
    type
  )
  // delete references in note
  project!.notes.forEach((note: NoteInterface) => {
    note.items = note.items.filter(
      (item: NoteItemInterface) => !(item.id === id && item.type === type)
    )
    // TODO: Fix this any
    note.itemLocations = note.itemLocations.filter(
      (item: any) => !(item.id === id && item.type === type)
    )
  })

  // TODO: also delete from inside the text, if in note

  // delete references in scene
  project!.scenes.forEach((scene: SceneInterface) => {
    scene.items = scene.items.filter(
      (item: SceneItemInterface) => !(item.id === id && item.type === type)
    )
  })
}

const deleteImageFromServer = (
  itemToBeRemoved: any,
  project: ProjectInterface
) => {
  // call db
  if (itemToBeRemoved.thumbnail > "") {
    deleteImageFromStorage({
      src: itemToBeRemoved.thumbnail,
      assetType: itemToBeRemoved.type,
      assetId: itemToBeRemoved.id,
      projectId: project.id,
    })
    saveProjectNow(Note.getState()!)
  }
}

const updateSceneSort = (
  state: StateInterface,
  {
    scenes,
    storySectionId,
  }: { scenes: Array<SceneInterface>; storySectionId: string }
) => {
  const project: ProjectInterface | null | undefined = currentProject(state)
  const storySection = project!.storySections.find(
    (storySection: StorySectionInterface) => storySection.id === storySectionId
  )

  storySection!.scenes = scenes
  project!.canBeSyncedToFirebase = true
  saveActivity(
    project!,
    "UPDATED_SCENE_SORT",
    storySection!.title,
    storySection!.id,
    "storySection"
  )
}

const updateSceneConfig = (
  state: StateInterface,
  { sceneId, config }: { sceneId: string; config: SceneConfigObjectInterface }
) => {
  const project: ProjectInterface | null | undefined = currentProject(state)
  const scene = project!.scenes.find(
    (scene: SceneInterface) => scene.id === sceneId
  )
  scene!.config = config
  scene!.config.updateConfigView = Math.random() * 1000
  project!.canBeSyncedToFirebase = true

  saveActivity(project!, "UPDATED_SCENE_CONFIG", "", scene!.id, "scene")
}

const updateStorySectionSort = (
  state: StateInterface,
  { storySections }: { storySections: Array<StorySectionInterface> }
) => {
  const project: ProjectInterface | null | undefined = currentProject(state)
  project!.storySections = storySections
  project!.canBeSyncedToFirebase = true

  saveActivity(project!, "UPDATED_STORY_SECTION_SORT", "")
}

const createScene = (state: StateInterface, data: any) => {
  const { scene, redirectToScene } = data
  const project: ProjectInterface | null = currentProject(state)
  let newSceneId: string | undefined = generateRandomId()

  window.gtag("event", "Scene - Created")

  if (scene.createNewScene === false) {
    newSceneId = scene.existingSceneId
  }

  let newScene: SceneInterface = Object.assign({}, emptyScene, {
    sectionId: scene.sectionId,
    id: newSceneId,
    title: scene.title,
    noteMeta: scene.noteMeta,
    config: Object.assign({}, project!.config),
    items: [],
  })

  if (scene.text) {
    newScene.text = scene.text
  } else {
    if (project!.config.type === "screenplay") {
      newScene.text = "<p class='screenplay-scene'>&#8288;</p>"
    } else {
      newScene.text = "<p>&#8288;</p>"
    }
  }
  project!.canBeSyncedToFirebase = true

  const section:
    | StorySectionInterface
    | undefined = project!.storySections.find(
    (section: StorySectionInterface) => section.id === scene.sectionId
  )
  const shallowScene: ShallowSceneInterface = { id: newSceneId as string }
  if (!scene.positionReference) {
    section!.scenes.push(shallowScene)
  } else {
    let newSceneIndex: number = section!.scenes.findIndex(
      (shallowScene: ShallowSceneInterface) =>
        shallowScene.id === scene!.positionReference
    )
    newSceneIndex! += scene!.positionOffset!
    section!.scenes.splice(newSceneIndex!, 0, shallowScene)
  }
  section!.open = true
  if (scene.createNewScene !== false) {
    project!.scenes.push(newScene)
  }
  project!.currentScene = newSceneId as string
  project!.lastViewedScene = newSceneId as string
  project!.updateSceneView = Math.random() * 1000

  if (scene!.text) {
    checkForAssetsToAddToScene(state, newScene)
  }

  if (redirectToScene === true) {
    navigate(`/write/${newSceneId}`)
  }

  saveProjectNow(state)
  saveActivity(project!, "CREATED_SCENE", newScene.title, newScene.id, "scene")
}

const checkForAssetsToAddToScene = (
  state: StateInterface,
  scene: SceneInterface
) => {
  if (!scene.text) return false

  scene.text.split("</p>").forEach((asset: string) => {
    if (asset > "") {
      let modifiedAsset: string = asset + "</p>"

      let assetType: string = ""
      let assetValue: string = ""
      if (modifiedAsset.indexOf('class="screenplay-character') > -1) {
        assetType = "character"
      }

      if (modifiedAsset.indexOf('class="screenplay-scene') > -1) {
        assetType = "location"
      }

      if (assetType != "") {
        const newP: any = document.createElement("p")
        newP.innerHTML = modifiedAsset
        assetValue = newP!.children[0]!.innerText

        if (assetType === "character") {
          assetValue = sanitizeCharacterName(assetValue)
        } else if (assetType === "location") {
          assetValue = sanitizeLocationName(assetValue)
          const splitNewAsset: Array<string> = assetValue
            .split(" -")
            .filter((asset: string) => {
              return (
                asset > "" &&
                asset > " " &&
                asset.trim() > "" &&
                asset.replace(/[^a-zA-Z0-9+]/g, "").length > 0
              )
            })

          if (!splitNewAsset || splitNewAsset.length === 0) return
          assetValue = splitNewAsset[0].toUpperCase().trim()
        }
        stateAction(addItemToSceneAndCreate, state, {
          type: assetType,
          title: assetValue,
          sceneId: scene.id,
        })
      }
    }
  })
}

const selectScene = (state: StateInterface, { id }: { id: string }) => {
  const project: ProjectInterface | null = currentProject(state)
  project!.currentScene = id
  project!.lastViewedScene = id
  project!.updateSceneView = Math.random() * 1000
  project!.canBeSyncedToFirebase = true

  project!.storySections.forEach((storySection: StorySectionInterface) => {
    if (
      storySection.scenes.find(
        (scene: ShallowSceneInterface) => scene.id === id
      )
    ) {
      storySection.open = true
    }
  })
}

const deleteScene = (state: StateInterface, { id }: { id: string }) => {
  const project: ProjectInterface | null = currentProject(state)

  const sceneToBeDeleted = project!.scenes.find(
    (scene: SceneInterface) => scene.id === id
  )
  const sceneTitle: string = sceneToBeDeleted!.title

  saveActivity(project!, "DELETED_SCENE", sceneTitle, id, "scene")

  project!.scenes = project!.scenes.filter(
    (scene: SceneInterface) => scene.id !== id
  )
  project!.canBeSyncedToFirebase = true

  window.gtag("event", "Scene - Deleted")

  navigate("/write")

  project!.storySections.forEach((storySection: StorySectionInterface) => {
    storySection.scenes = storySection.scenes.filter(
      (scene: ShallowSceneInterface) => scene.id !== id
    )
  })

  saveProjectNow(state)
}

const updateSceneContent = (
  state: StateInterface,
  sceneContent: Dispatch_UpdateSceneContent
) => {
  const project: ProjectInterface | null = currentProject(state)
  const scene: SceneInterface = currentSceneObject(project)

  let initialWordCount: number = project!.wordCount

  scene.wordCount = WordCount("scene", sceneContent.text)
  project!.wordCount = WordCount("project", project!)
  const completedGoals: boolean = updateGoals(project!, initialWordCount)

  if (completedGoals === true) {
    project!.showGoalSuccess = true
  }

  scene.title = sceneContent.title
  scene.text = sceneContent.text

  if (scene.text === "" && project!.config.type === "screenplay") {
    scene.text = "<p class='screenplay-scene'>&#8288;</p>"
  } else if (scene.text === "" && project!.config.type !== "screenplay") {
    scene.text = "<p>&#8288;</p>"
  }

  if (sceneContent) scene.noteMeta.supplemental = sceneContent.summary
  project!.canBeSyncedToFirebase = true

  // update goals

  saveActivity(project!, "UPDATED_SCENE", scene.title, scene.id, "scene")
}

const addItemToScene = (
  state: StateInterface,
  {
    type,
    noteMeta,
    title,
    createNewUser,
    existingUserId,
    description,
    sceneId,
    tags,
  }: Dispatch_AddItemToScene
) => {
  const project: ProjectInterface | null = currentProject(state)
  let scene: SceneInterface | undefined = currentSceneObject(project)

  if (sceneId) {
    scene = project!.scenes.find(
      (scene: SceneInterface) => scene.id === sceneId
    )
  }

  const newId: string = generateRandomId()
  let itemType: string = ""
  project!.canBeSyncedToFirebase = true

  const newProjectConfig = Object.assign({}, project!.config)

  if (createNewUser === true) {
    switch (type) {
      case "scene":
        project!.scenes.push({
          id: newId,
          title,
          description,
          items: [],
          config: newProjectConfig,
          noteMeta,
          wordCount: 0,
        })
        itemType = "scene"
        break
      default:
        project!.assets.push({
          id: newId,
          sceneId: scene!.id,
          title,
          description,
          items: [],
          type,
          noteMeta,
          thumbnail: "",
          factoids: [],
          tags,
          updated: new Date().getTime().toString(),
        })
        itemType = type
        break
    }
  }

  const idToAddToScene: string = createNewUser === true ? newId : existingUserId

  if (!scene!.items.includes(idToAddToScene)) {
    scene!.items.push({
      type: type,
      id: createNewUser === true ? newId : existingUserId,
      completed: false,
    })
  }
}

const toggleTabCompleted = (state: StateInterface, itemId: string) => {
  const project = currentProject(state)
  const scene = currentSceneObject(project)
  project!.canBeSyncedToFirebase = true

  const itemToToggle: any = scene.items.find((item: any) => item.id === itemId)
  if (!itemToToggle.completed) {
    itemToToggle.completed = true
  } else {
    itemToToggle.completed = !itemToToggle.completed
  }
}

const setTabCompleted = (state: StateInterface, itemId: string) => {
  const project = currentProject(state)
  const scene = currentSceneObject(project)
  project!.canBeSyncedToFirebase = true

  const itemToToggle: any = scene.items.find((item: any) => item.id === itemId)
  if (itemToToggle) {
    itemToToggle.completed = true
  }
}

const addItemToSceneAndCreate = (
  state: StateInterface,
  { type, title, projectId, sceneId, tags }: Dispatch_AddItemToScene
) => {
  let project: ProjectInterface | null | undefined
  if (!projectId) {
    project = currentProject(state)
  } else {
    project = state.projects.find(
      (project: ProjectInterface) => project.id === projectId
    )
  }
  project!.canBeSyncedToFirebase = true

  let scene: SceneInterface | undefined | null
  if (!sceneId) {
    scene = currentSceneObject(project as ProjectInterface)
  } else {
    scene = project!.scenes.find(
      (scene: SceneInterface) => scene.id === sceneId
    )
  }

  const newId: string = generateRandomId()
  let itemType: string = ""
  const noteMeta: NoteMetaInterface = { supplemental: "Added in-scene" }
  const description: string = ""

  const newProjectConfig = Object.assign({}, project!.config)
  let needToAdd: boolean = true
  let createNewAsset: boolean = true
  let existingUserId: string = ""
  if (type === "character" || type === "location") {
    project!.assets
      .filter((asset: AssetInterface) => asset.type === type)
      .forEach((asset: AssetInterface) => {
        if (asset.title.toUpperCase().trim() === title.toUpperCase().trim()) {
          createNewAsset = false
          existingUserId = asset.id
        }
        const assetInScene: any = scene!.items.find((item: any) => {
          return (
            item.id === existingUserId &&
            asset.title.toUpperCase().trim() === title.toUpperCase().trim()
          )
        })

        if (assetInScene !== undefined) {
          needToAdd = false
        }
      })

    if (needToAdd === true && createNewAsset === true) {
      switch (type) {
        case "character":
          project!.assets.push({
            id: newId,
            sceneId: scene!.id,
            title,
            description,
            items: [],
            type,
            noteMeta,
            thumbnail: "",
            factoids: [],
            tags,
            updated: new Date().getTime().toString(),
          })
          itemType = "character"
          break
        case "location":
          project!.assets.push({
            id: newId,
            sceneId: scene!.id,
            title,
            description,
            items: [],
            type,
            noteMeta,
            thumbnail: "",
            factoids: [],
            tags,
            updated: new Date().getTime().toString(),
          })
          itemType = "location"
          break
      }
    }

    saveActivity(
      project!,
      "ADDED_ITEM_TO_SCENE",
      title,
      createNewAsset === true ? newId : existingUserId,
      itemType
    )

    if (needToAdd === true) {
      scene!.items.push({
        type: type,
        id: createNewAsset === true ? newId : existingUserId,
        completed: true,
      })
    } else if (needToAdd === false) {
      stateAction(setTabCompleted, state, existingUserId)
    }
  }
}

const addNewGoal = (state: StateInterface, goal: any) => {
  const project: ProjectInterface | null = currentProject(state)

  // some extra logic here.
  // calculate number of days, if relevant
  const days: Array<GoalDateInterface> = []

  const { dateStart, dateEnd, type } = goal

  const a = moment(dateStart)
  const b = moment(dateEnd)

  let numDays: number = 0

  if (type === "date") {
    let wordCountRemaining = goal.wordCount
    numDays = b.diff(a, "days")
    for (let i = 0; i <= numDays; i++) {
      let baseWordTarget: number = Math.floor(goal.wordCount / (numDays + 1))

      if (i === numDays) {
        baseWordTarget = wordCountRemaining
      } else {
        wordCountRemaining -= baseWordTarget
      }
      days.push({
        day: moment(dateStart).add(i, "days").format("YYYY-MM-DD"),
        wordCountStart: 0, // this should be calculated during the first entry for that day.
        wordsWritten: 0,
        completed: false,
        baseWordTarget: baseWordTarget,
      })
    }
  }

  let wordsWritten: number = 0
  if (goal.type === "project") {
    wordsWritten = project!.wordCount
  }

  project!.goals.push({
    type: goal.type,
    dateStart: goal.dateStart,
    dateEnd: goal.dateEnd,
    wordCountStart: WordCount("project", project!), // this should be counted now
    numDays: numDays,
    wordCountTarget: goal.wordCount,
    completed: false,
    active: true,
    days: days,
    wordsWritten: wordsWritten,
  })
}

const removeGoal = (state: StateInterface, index: number) => {
  const project: ProjectInterface | null = currentProject(state)
  project!.goals.splice(index, 1)
}

const createProjectAsset = (
  state: StateInterface,
  { type, noteMeta, title }: Dispatch_CreateProjectAsset
) => {
  const project: ProjectInterface | null = currentProject(state)
  project!.canBeSyncedToFirebase = true
  const itemPayload: any = {
    id: generateRandomId(),
    noteMeta,
    type,
    title,
    factoids: [],
    updated: new Date().getTime().toString(),
  }

  window.gtag("event", "Asset - Created " + type)

  // ADD TO MAIN PROJECT ARRAYS
  let itemType: string = ""
  switch (type) {
    case "scene":
      project!.scenes.push(itemPayload)
      itemType = "scene"
      break
    default:
      project!.assets.push(itemPayload)
      itemType = type
      break
  }

  saveActivity(project!, "CREATED_ASSET", title, itemPayload.id, type)
  saveProjectNow(state)
}

const updateNoteItemLocations = (
  state: StateInterface,
  itemLocations: Array<NoteItemLocationInterface>
) => {
  const project: ProjectInterface | null = currentProject(state)
  const note: NoteInterface | undefined = currentNoteFromProject(project!)

  note!.itemLocations = itemLocations
  project!.canBeSyncedToFirebase = true
}

const toggleHighlight = (
  state: StateInterface,
  { id, type }: { id: string; type: string }
) => {
  let inspectHighlights = state.config.inspectHighlights
  if (inspectHighlights.find((highlight: any) => highlight.id === id)) {
    state.config.inspectHighlights = inspectHighlights.filter(
      (highlight: any) => highlight.id !== id
    )
  } else {
    inspectHighlights.push({ id: id, type: type })
  }

  // TODO: highlight?
}

const updateStorySection = (
  state: StateInterface,
  value: Dispatch_UpdateStorySection
) => {
  const project: ProjectInterface | null = currentProject(state)
  const storySection = project!.storySections.find(
    (storySection: StorySectionInterface) => storySection.id === value.id
  )
  project!.canBeSyncedToFirebase = true
  storySection!.title = value.title
  storySection!.description = value.description

  saveActivity(
    project!,
    "UPDATED_STORY_SECTION",
    storySection!.title,
    storySection!.id,
    "storySection"
  )
}

const deleteStorySection = (
  state: StateInterface,
  value: Dispatch_DeleteStorySection
) => {
  const project: ProjectInterface | null = currentProject(state)
  const storySection = project!.storySections.find(
    (storySection: StorySectionInterface) => storySection.id === value.id
  )
  project!.canBeSyncedToFirebase = true
  saveActivity(
    project!,
    "DELETED_STORY_SECTION",
    storySection!.title,
    storySection!.id,
    "storySection"
  )

  window.gtag("event", "Section - Deleted")

  // delete scenes
  storySection!.scenes.forEach((chp: ShallowSceneInterface) => {
    project!.scenes = project!.scenes.filter(
      (scene: SceneInterface) => scene.id !== chp.id
    )
  })

  // then delete section
  project!.storySections = project!.storySections.filter(
    (storySection: StorySectionInterface) => storySection.id !== value.id
  )

  saveProjectNow(state)
}

const toggleShowDescription = (state: StateInterface) => {
  state.config.showDescription = !state.config.showDescription
}

const setPlotScale = (state: StateInterface, value: number) => {
  state.config.plotScale = value
}

const togglePlotScaleSnap = (state: StateInterface) => {
  if (state.config.plotScaleSnap !== true) {
    state.config.plotScaleSnap = true
  } else {
    state.config.plotScaleSnap = false
  }
}

const setPlotMode = (state: StateInterface, plotMode: "edit" | "inspect") => {
  if (plotMode === "edit") state.config.inspectHighlights = []
  state.config.plotMode = plotMode
}

const setFocusMode = (state: StateInterface, value: boolean) => {
  state.config.focusMode = value
}

const addFactoidToAsset = (
  state: StateInterface,
  factoid: Dispatch_AddFactoidToAsset
) => {
  const project: ProjectInterface | null = currentProject(state)
  project!.canBeSyncedToFirebase = true
  const asset = projectNotesOfType(project!, factoid.type).filter(
    (asset: any) => asset.id === factoid.referenceId
  )[0]
  if (!asset.factoids) asset.factoids = []

  asset.factoids.push({
    selectedText: factoid.selectedText,
    description: factoid.description,
    created_at: new Date().toString(),
    noteId: factoid.noteId || "",
    sceneId: factoid.sceneId || "",
    id: generateRandomId(),
  })
}

const deleteFactoid = (
  state: StateInterface,
  factoidToDelete: Dispatch_DeleteFactoid
) => {
  const project: ProjectInterface | null = currentProject(state)
  project!.canBeSyncedToFirebase = true
  const asset = projectNotesOfType(project!, factoidToDelete.type).find(
    (asset: any) => asset.id === factoidToDelete.assetId
  )

  asset!.factoids = asset!.factoids!.filter(
    (factoid: FactsInterface) => factoid.id !== factoidToDelete.id
  )
}

const updateUserConfig = (state: StateInterface, configData: any) => {
  state.config = Object.assign({}, state.config, configData)

  detectDarkMode(state.config.darkMode)
}

const addItemFromScene = (
  state: StateInterface,
  itemInfo: Dispatch_AddItemFromScene
) => {
  const project: ProjectInterface | null = currentProject(state)
  const {
    id,
    type,
    noteMeta,
    selectedText,
    title,
    referenceId,
    addToSceneOption,
  } = itemInfo

  // TODO: Make customization per type more robust.
  const itemPayload: any = {
    id: id,
    selectedText,
    noteMeta,
    type,
    title,
    factoids: [],
    updated: new Date().getTime().toString(),
  }

  saveActivity(project!, "CREATED_ASSET", type + " : " + title, id, type)
  project!.canBeSyncedToFirebase = true

  // ADD TO MAIN PROJECT ARRAYS
  switch (type) {
    case "storySection":
      const newStorySection: StorySectionInterface = Object.assign(
        {},
        emptyStorySection,
        itemPayload
      )
      project!.storySections.push(newStorySection)
      break
    case "scene":
      const newScene: SceneInterface = Object.assign(
        {},
        emptyScene,
        itemPayload
      )
      project!.scenes.push(newScene)

      break
    default:
      project!.assets.push(itemPayload)
      break
  }

  if (referenceId && addToSceneOption === true) {
    const scene: SceneInterface | undefined = project!.scenes.find(
      (scene: SceneInterface) => scene.id === referenceId
    )
    scene!.items.push({ id: id, type: type, completed: false })
  }
}

const setScreenplayElementType = (
  state: StateInterface,
  elementType: string
) => {
  const project: ProjectInterface | null = currentProject(state)
  project!.currentScreenplayElementType = elementType
  project!.canBeSyncedToFirebase = true
}

const updateSceneItemsSort = (state: StateInterface, data: any) => {
  const project = currentProject(state)
  const scene = currentSceneObject(project!)
  scene.items = data.items
  project!.canBeSyncedToFirebase = true
}

const toggleHideNonHighlighted = (state: StateInterface, value: any) => {
  if (state.config.hideNonHighlighted === true) {
    state.config.hideNonHighlighted = false
  } else {
    state.config.hideNonHighlighted = true
  }
}

const moveItemFromOneSceneToAnother = (state: StateInterface, info: any) => {
  const project: ProjectInterface | null = currentProject(state)
  project!.canBeSyncedToFirebase = true
  const { id, source, type, destination } = info

  removeItemFromSpecificScene(state, { id, type, sceneId: source })
  const scene: SceneInterface | undefined = project!.scenes.find(
    (scene: SceneInterface) => scene.id === destination
  )

  if (scene!.items.filter((item: any) => item.id === id).length === 0) {
    scene!.items.push({ id, type })
  }
}

const cycleHighlightModes = (state: StateInterface) => {
  const config = state.config

  config.showHighlight++

  if (config.showHighlight > 4) {
    config.showHighlight = 0
  }
}

const toggleTypewriterMode = (state: StateInterface, newMode: boolean) => {
  const config = state.config
  config.typewriterMode = newMode
}

const toggleMobileMenu = (state: StateInterface, options: any) => {
  const project = currentProject(state)
  const mobileMenu: MobileOptionsInterface = project!.mobileMenu

  if (options.location === "write") {
    if (options.type === "sections") {
      mobileMenu.write.sections = !mobileMenu.write.sections
      mobileMenu.write.assets = false
    }

    if (options.type === "assets") {
      mobileMenu.write.assets = !mobileMenu.write.assets
      mobileMenu.write.sections = false
    }
  } else if (options.location === "notes") {
    if (options.type === "assets") {
      mobileMenu.notes.assets = !mobileMenu.notes.assets
    }
  }
}

const updateProjectPrintNotes = (state: StateInterface, newNote: string) => {
  const project = currentProject(state)
  project!.config.printNotes = newNote
  project!.canBeSyncedToFirebase = true
}

const togglePageBreakBetweenScenes = (state: StateInterface) => {
  const project = currentProject(state)
  project!.config.pageBreakBetweenScenes = !project!.config
    .pageBreakBetweenScenes
  project!.canBeSyncedToFirebase = true
}

const togglePrintTitlePage = (state: StateInterface) => {
  const project = currentProject(state)
  project!.config.printTitlePage = !project!.config.printTitlePage
  project!.canBeSyncedToFirebase = true
}

const togglePageBreakBetweenSections = (state: StateInterface) => {
  const project = currentProject(state)
  project!.config.pageBreakBetweenSections = !project!.config
    .pageBreakBetweenSections
  project!.canBeSyncedToFirebase = true
}

const addToProjectSync = (state: StateInterface, projectId: string) => {
  const projectSyncs: Array<ProjectSyncInterface> = state.projectSyncs

  if (
    projectSyncs.filter(
      (projectSync: ProjectSyncInterface) => projectSync.id === projectId
    ).length === 0
  ) {
    projectSyncs.push({ id: projectId, sync: true, updated: 0 })
  }

  state.projectSyncs = projectSyncs
}

const removeFromProjectSync = (state: StateInterface, projectId: string) => {
  const projectSyncs: Array<ProjectSyncInterface> = state.projectSyncs
  state.projectSyncs = projectSyncs.filter(
    (projectSync: ProjectSyncInterface) => projectSync.id !== projectId
  )
}

const startSyncingProject = (state: StateInterface, projectId: string) => {
  saveProjectToLocal(state, projectId, true)
  addToProjectSync(state, projectId)
}

const saveProjectToLocal = (
  state: StateInterface,
  project: any,
  synced: boolean = false,
  updated?: number
) => {
  let id: any = project
  if (project.projectId) id = project.projectId

  // add secondary check here?
  // possibly just compare date?

  const specifiedProject: ProjectInterface | undefined = state.projects.find(
    (proj: ProjectInterface) => proj.id === project.projectId
  )

  let project_updated_at: string = ""
  if (specifiedProject!) {
    project_updated_at = specifiedProject!.updated_at
  }
  db.collection("projects")
    .where("id", "==", id)
    .get()
    .then((querySnapshot: any) => {
      let found: number = 0
      querySnapshot.forEach(function (doc: any) {
        found++

        const d1: Date = new Date(doc.data().project.updated_at)
        const d2: Date = new Date(project_updated_at)
        if (
          project_updated_at === "" ||
          (project_updated_at > "" && d1.getTime() > d2.getTime())
        ) {
          Note.dispatch({
            type: "add_project_to_list",
            value: {
              data: doc.data().project,
              synced: synced,
              updated: doc.data().__updated.seconds,
            },
          })
        } else {
          Note.dispatch({
            type: "increment_user_load_count",
          })
        }
      })

      if (found === 0) {
        Note.dispatch({ type: "complete_user_load" })
      }
    })
    .catch(function (error) {
      console.error(error)
    })
}

const addProjectToList = (
  state: StateInterface,
  data: any,
  synced: boolean,
  updated?: any
) => {
  let project = Object.assign({}, emptyProject, {
    ...data.data,
    canBeSyncedToFirebase: false,
    id: data.data.id,
  })

  project = moveIndividualAssetsToAssetArray(project)

  state.projectSyncs.map((projectSync: ProjectSyncInterface) => {
    if (project.id === projectSync.id && updated) {
      projectSync.updated = updated
    }
    return projectSync
  })

  if (project.created_at === null) project.created_at = new Date().toString()
  if (project.updated_at === null) project.updated_at = new Date().toString()

  if (
    state.projects.filter((p: ProjectInterface) => p.id === project.id)
      .length === 0
  ) {
    state.projects.push(project)
  } else {
    state.projects = state.projects.map((p: ProjectInterface) => {
      if (project.id === p.id) return project
      else return p
    })
  }
  if (state.dataSentToFirebase !== true) {
    state.forceRerender = Math.random()
  }

  if (state.userLoadComplete === false) {
    if (state.projectCountForUserLoad >= state.projectSyncs.length) {
      state.userLoadComplete = true
    }
    state.projectCountForUserLoad = state.projectCountForUserLoad + 1
  }

  state.dataSentToFirebase = false

  if (state.currentProject === "") {
    state.currentProject = project.id
  }
}

const deleteNonLocalProject = (state: StateInterface, projectId: string) => {
  db.collection("projects")
    .where("id", "==", projectId)
    .get()
    .then((querySnapshot: any) => {
      querySnapshot.forEach(function (doc: any) {
        doc.ref.delete()
      })
    })
    .catch(function (error: any) {
      console.error(error)
    })
}

/* --- --- --- STORE --- --- --- */

export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item)
}

export const mergeDeep = (target: any, source: any) => {
  let output = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

const getDefaultState = () => {
  // if localstorage hilkerProjects exists, load it
  // then copy to indexeddb
  // then destroy the localstorage key
  // if local storage doesn't exist
  // just load indexeddb

  if (!window.localStorage) return defaultState

  if (!localStorage.getItem("hilkerProjects")) return defaultState

  try {
    const hilkerLocalStorage: string | null = localStorage.getItem(
      "hilkerProjects"
    )
    if (!hilkerLocalStorage) return defaultState
    // do some merging here to keep things up-to-date.
    const loadedState: StateInterface = JSON.parse(hilkerLocalStorage)
    let newState: any = mergeDeep(defaultState, loadedState)
    newState = mergeDeep(newState, {
      savePending: false,
      userLoadComplete: false,
      projectCountForUserLoad: null,
      isSaving: false,
      syncing: false,
      userProjects: [],
      config: {
        ...loadedState.config,
        focusMode: false,
        inspectHighlights: [],
        plotMode: "edit",
        showHighlight: 0,
        typewriterMode: false,
      },
    })

    if (!newState.config.contextMenuMode) {
      newState.config.contextMenuMode = "system"
    }

    const pathnameComponents: Array<string> = window.location.pathname.split(
      "/"
    )
    if (
      pathnameComponents &&
      pathnameComponents.length > 2 &&
      pathnameComponents[1] === "projects" &&
      pathnameComponents[2].length > 10
    ) {
      newState.currentProject = pathnameComponents[2]
    }

    detectDarkMode(newState.config.darkMode)

    if (!newState.dateOfLastSave) {
      newState.dateOfLastSave = new Date().toString()
    }

    // mostly pointless error corrections, only in place for "legacy" projects
    // that only exist on my iphone.
    newState.projects.forEach((project: ProjectInterface) => {
      if (!project.config) {
        project.config = defaultProjectConfig
      }

      project.versions = null

      project.mobileMenu = {
        write: { sections: false, assets: false },
        notes: { assets: false },
      }

      if (!project.assets) project.assets = []
      if (!project.activities) project.activities = []
      if (!project.scenes) project.scenes = []
      if (!project.notes) project.notes = []

      if (!project.goals) project.goals = []
      if (!project.showGoalSuccess) project.showGoalSuccess = false

      project.scenes.forEach((scene: SceneInterface) => {
        scene.wordCount = WordCount("scene", scene.text)
      })

      project.wordCount = WordCount("project", project)

      project = moveIndividualAssetsToAssetArray(project)
    })

    // if project doesn't exist?
    if (
      !newState.projects.find(
        (project: ProjectInterface) => project.id === newState.currentProject
      )
    ) {
      if (newState.projects.length > 0) {
        newState.currentProject = newState.projects[0].id
      }
    }

    return newState
  } catch (err) {
    return defaultState
  }
}

const moveIndividualAssetsToAssetArray = (project: ProjectInterface) => {
  let assets: Array<AssetInterface> = []

  if (project.characters && project.assets.length === 0) {
    assets = [
      ...project.assets,
      ...project.characters,
      ...project.storybeats,
      ...project.macguffins,
      ...project.dialogues,
      ...project.locations,
      ...project.arcs,
    ]
  } else {
    assets = project.assets
  }

  delete project.characters
  delete project.storybeats
  delete project.macguffins
  delete project.dialogues
  delete project.locations
  delete project.arcs

  project.assets = assets

  return project
}

const saveToLocalStorage = (state: StateInterface) => {
  const newState = Object.assign({}, state, {
    dateOfLastSave: new Date().toString(),
    userProjects: [],
    isSaving: true,
    user: null,
  })

  try {
    const serializedState = JSON.stringify(newState)
    localStorage.setItem("hilkerProjects", serializedState)

    if (shouldSync(state)) {
      saveProjectToFirebase(serializedState, state.user)
    }
  } catch (err) {
    // ignore write errors
  }
}

const shouldSync = (state: StateInterface) => {
  const projectSync: ProjectSyncInterface | undefined = state.projectSyncs.find(
    (projectSync: ProjectSyncInterface) =>
      projectSync.id === state.currentProject
  )
  const project = currentProject(state)
  if (
    projectSync &&
    projectSync.sync === true &&
    project!.canBeSyncedToFirebase === true
  ) {
    return true
  }
  return false
}

const saveProjectToFirebase = async (serializedState: string, user: any) => {
  const state: StateInterface = JSON.parse(serializedState)
  let uid: string = ""

  if (user && user.id) uid = user.id
  if (user && user.uid) uid = user.uid

  const project = currentProject(state)
  if (!project) return

  let found: boolean = false
  let idToUpdate: string = ""

  const projectDataToSend: any = {
    id: state.currentProject,
    project: project,
    uid: uid,
    __updated: firestore.FieldValue.serverTimestamp(),
  }

  state.dataSentToFirebase = true
  db.collection("projects")
    .where("id", "==", state.currentProject)
    .get()
    .then((querySnapshot: any) => {
      querySnapshot.forEach(function (doc: any) {
        if (found === false) {
          found = true
          idToUpdate = doc.id
        }
      })

      if (found) {
        // update
        db.collection("projects")
          .doc(idToUpdate)
          .set(projectDataToSend)
          .then(function () {
            // add entry into userprojects table
            // save updated time to local projectsynsects(project)
          })
          .catch(function (error) {
            console.error("Error writing document: ", error)
          })
      } else {
        // add new
        db.collection("projects")
          .add(projectDataToSend)
          .then(function (doc) {
            // get id of newly created item
            // update userProjects table;
            // get server time from item, then save to local projectSync
            saveOrUpdateUserProjects(project)
          })
          .catch(function (error) {
            console.error("Error writing document: ", error)
          })
      }
    })
    .catch(function (error) {
      console.error("Unable to query db for project list")
    })
}

const hideProjectGoalsSuccess = (state: StateInterface) => {
  let project = currentProject(state)
  project!.showGoalSuccess = false
}

const showProjectGoalsSuccess = (state: StateInterface) => {
  let project = currentProject(state)
  project!.showGoalSuccess = true
}

const saveOrUpdateUserProjects = (project: ProjectInterface) => {
  let uid: string = ""
  const user: UserInterface = Note.getState().user
  if (user && user.uid) uid = user.uid
  db.collection("userProjects")
    .where("projectId", "==", project.id)
    .where("userId", "==", uid)
    .get()
    .then((querySnapshot: any) => {
      const userProjectData: any = {
        projectId: project.id,
        userId: uid,
        name: project.name,
        updated: firestore.FieldValue.serverTimestamp(),
      }

      if (querySnapshot.size === 0) {
        db.collection("userProjects")
          .add(userProjectData)
          .then(() => {
            updateProjectSyncTimestamp(project.id, uid)
          })
          .catch(function (error) {
            console.error(error)
          })
      } else {
        // update
        querySnapshot.forEach(function (doc: any) {
          db.collection("userProjects")
            .doc(doc.id)
            .set(userProjectData)
            .then(() => {
              updateProjectSyncTimestamp(project.id, uid)
            })
            .catch(function (error) {
              console.error(error)
            })
        })
      }
    })
    .catch(function (error) {
      console.error(error)
    })
}

const updateProjectSyncTimestamp = (projectId: string, uid: string) => {
  db.collection("userProjects")
    .where("projectId", "==", projectId)
    .where("userId", "==", uid)
    .get()
    .then((querySnapshot: any) => {
      querySnapshot.forEach(function (doc: any) {
        Note.dispatch({
          type: "update_project_sync_timestamp_from_firestore",
          value: {
            projectId: projectId,
            updated: doc.data().updated.seconds.toString(),
          },
        })
      })
    })
}

const updateProjectSyncTimestampFromFirestore = (
  state: StateInterface,
  data: any
) => {
  let projectSyncs: Array<ProjectSyncInterface> = state.projectSyncs
  const projectToUpdate: ProjectSyncInterface | undefined = projectSyncs.find(
    (projectSync: ProjectSyncInterface) => projectSync.id === data.projectId
  )

  if (projectToUpdate) {
    projectToUpdate.updated = data.updated
    projectSyncs = projectSyncs.filter(
      (projectSync: ProjectSyncInterface) => projectSync.id !== data.projectId
    )
    projectSyncs.push(projectToUpdate)
  }

  state.projectSyncs = projectSyncs
}

let saveTimeout: any = null

const stopSaveTimeout = () => {
  if (saveTimeout) {
    clearTimeout(saveTimeout)
    saveTimeout = null
    Note.dispatch({ type: "set_save_not_pending" })
    Note.dispatch({ type: "set_is_saving" })
    Note.dispatch({ type: "update_date_of_last_save" })
    setTimeout(() => {
      setStateNotSaving()
    }, 2500)
  }
}

const startSaveTimeout = () => {
  if (saveTimeout) return

  saveTimeout = setTimeout(() => {
    saveToLocalStorage(Note.getState())
    stopSaveTimeout()
  }, 30000)
}

const setStateNotSaving = () => {
  Note.dispatch({ type: "set_is_not_saving", value: {} })
}

const saveProjectNow = (state: StateInterface) => {
  state.fileForImport = undefined
  saveToLocalStorage(state)
  state.isSaving = true
  state.savePending = false
  setTimeout(() => {
    setStateNotSaving()
  }, 2500)
  setTimeout(() => {
    stopSaveTimeout()
  }, 100)
}

const setSaveNotPending = (state: StateInterface) => {
  state.savePending = false
  state.isSaving = false
  stopSaveTimeout()
}

/* --- --- --- GENERIC --- --- --- */

const stateAction = (
  method: Function,
  state: StateInterface,
  action: any,
  saveState: boolean = true
) => {
  method(state, action)
  if (saveState === true) projectSaveRequired(state)
}

const saveActivity = (
  project: ProjectInterface,
  type: string,
  description: string = "",
  referenceId: string | null = null,
  referenceType: string | null = null
) => {
  const activityInfo = Activities.find(
    (activity: ActivitiesListInterface) => activity.id === type
  )

  if (activityInfo) {
    const activityInCurrentVersion = project.activities.find(
      (activity: ActivityInterface) =>
        activity.type === type && activity.referenceId === referenceId
    )

    if (!activityInCurrentVersion) {
      const newActivity: ActivityInterface = {
        id: generateRandomId(),
        type: type,
        description: description || "",
        created_at: new Date().toString(),
        referenceId: referenceId,
        referenceType: referenceType,
      }

      project.activities.push(newActivity)
    }
  }
}

const setIsNotSaving = (state: StateInterface) => {
  state.isSaving = false
}

const setIsSaving = (state: StateInterface) => {
  state.isSaving = true
}

const updateDateOfLastSave = (state: StateInterface) => {
  state.dateOfLastSave = new Date().toString()
}

let userProjectUpdatesSubscription: any = ""

const logUserIn = (state: StateInterface, user: any) => {
  state.user = {
    type: user.type,
    uid: user.uid,
    email: user.email,
    emailVerified: user.emailVerified,
    avatar: user.photoURL,
  }
  state.projectCountForUserLoad = 0

  state.userLoadComplete = false
  // register for userProjects
  userProjectUpdatesSubscription = db
    .collection("userProjects")
    .where("userId", "==", user.uid)
    .onSnapshot(function (querySnapshot) {
      Note.dispatch({ type: "clear_user_projects" })
      let foundProjects: number = 0

      querySnapshot.forEach(function (doc) {
        if (doc.data().updated && doc.data().updated.seconds) {
          const data: any = {
            projectId: doc.data().projectId,
            name: doc.data().name,
            updated: doc.data().updated.seconds,
          }
          Note.dispatch({ type: "update_project_sync", value: data })
          foundProjects++
        }
      })

      if (foundProjects === 0) {
        Note.dispatch({ type: "complete_user_load" })
      }
    })
}

const setProjectCountForUserLoad = (
  state: StateInterface,
  projectCount: number
) => {
  state.projectCountForUserLoad = projectCount
}

const completeUserLoad = (state: StateInterface) => {
  state.userLoadComplete = true
}

const incrementUserLoadCount = (state: StateInterface) => {
  state.projectCountForUserLoad = state.projectCountForUserLoad + 1
  if (state.projectCountForUserLoad >= state.projectSyncs.length) {
    state.userLoadComplete = true
  }
}

const clearUserProjects = (state: StateInterface) => {
  state.userProjects = []
}

const logUserOut = (state: StateInterface) => {
  state.user = null
  auth
    .signOut()
    .then(function () {
      // Sign-out successful.
      Note.dispatch({ type: "clear_login_error" })
    })
    .catch(function (error: any) {
      // An error happened.
    })

  // log out with firebase
  userProjectUpdatesSubscription = ""
}

const isLocalProjectCopyOutOfDate = (
  name: string,
  serverUpdated: number,
  localUpdated: number
) => {
  if (serverUpdated > localUpdated && serverUpdated - localUpdated > 50) {
    return true
  }
  return false
}
const updateProjectSync = (state: StateInterface, data: any) => {
  let userProjects: Array<UserProjectInterface> = state.userProjects
  userProjects = userProjects.filter(
    (userProject: UserProjectInterface) =>
      userProject.projectId !== data.projectId
  )

  userProjects.push({
    projectId: data.projectId,
    userId: data.userId,
    updated: data.updated,
    name: data.name,
  })
  state.userProjects = userProjects

  if (
    state.projectSyncs.filter(
      (projectSync: any) =>
        projectSync.id === data.projectId &&
        isLocalProjectCopyOutOfDate(
          data.name,
          data.updated,
          Number(projectSync.updated)
        ) === true
    ).length > 0
  ) {
    setTimeout(() => {
      Note.dispatch({
        type: "save_project_to_local",
        value: {
          projectId: data.projectId,
          synced: true,
          updated: data.updated,
        },
      })
    }, 100)
  } else {
    state.projectCountForUserLoad = state.projectCountForUserLoad + 1
    if (state.projectCountForUserLoad >= state.projectSyncs.length) {
      state.userLoadComplete = true
    }
  }
}

const noteReducer = createReducer(getDefaultState(), {
  update_date_of_last_save: (state, action) => updateDateOfLastSave(state),
  create_project: (state, action) =>
    stateAction(createProject, state, action.value),
  import_project: (state, action) =>
    stateAction(importProject, state, action.value),
  import_txt_file: (state, action) =>
    stateAction(importTxtFile, state, action.value),
  import_docx_file: (state, action) =>
    stateAction(importDocxFile, state, action.value),
  save_file_for_import: (state, action) =>
    stateAction(saveFileForImport, state, action.value, false),
  cancel_file_import: (state, action) =>
    stateAction(cancelFileImport, state, null, false),
  import_file_project: (state, action) =>
    stateAction(importFileProject, state, action.value),
  select_project: (state, action) =>
    stateAction(selectProject, state, action.value),
  delete_project: (state, action) =>
    stateAction(deleteProject, state, action.value),
  update_project: (state, action) =>
    stateAction(updateProject, state, action.value),
  update_project_config: (state, action) =>
    stateAction(updateProjectConfig, state, action.value),
  create_project_asset: (state, action) =>
    stateAction(createProjectAsset, state, action.value),
  delete_item_from_project: (state, action) =>
    stateAction(deleteItemFromProject, state, action.value),
  create_story_section: (state, action) =>
    stateAction(createStorySection, state, action.value),
  update_story_section: (state, action) =>
    stateAction(updateStorySection, state, action.value),
  delete_story_section: (state, action) =>
    stateAction(deleteStorySection, state, action.value),
  update_story_section_sort: (state, action) =>
    stateAction(updateStorySectionSort, state, action.value),
  toggle_show_story_section_scenes: (state, action) =>
    stateAction(toggleShowStorySectionScenes, state, action.value.id),
  add_new_goal: (state, action) => stateAction(addNewGoal, state, action.value),
  remove_goal: (state, action) =>
    stateAction(removeGoal, state, action.value.index),
  create_note: (state, action) =>
    stateAction(createNote, state, action.value.type),
  add_factoid_to_asset: (state, action) =>
    stateAction(addFactoidToAsset, state, action.value),
  delete_factoid: (state, action) =>
    stateAction(deleteFactoid, state, action.value),
  select_note: (state, action) =>
    stateAction(setCurrentNote, state, action.value),
  add_note_item: (state, action) =>
    stateAction(addNoteItem, state, action.value),
  set_is_saving: (state, action) => setIsSaving(state),
  set_is_not_saving: (state, action) => setIsNotSaving(state),
  update_note_item: (state, action) =>
    stateAction(updateNoteItem, state, action.value),
  update_note: (state, action) => stateAction(updateNote, state, action.value),
  update_note_item_locations: (state, action) =>
    stateAction(updateNoteItemLocations, state, action.value),
  remove_item_from_note: (state, action) =>
    stateAction(removeItemFromNote, state, action.value),
  create_scene: (state, action) =>
    stateAction(createScene, state, action.value),
  select_scene: (state, action) =>
    stateAction(selectScene, state, action.value),
  delete_scene: (state, action) =>
    stateAction(deleteScene, state, action.value),
  update_scene_sort: (state, action) =>
    stateAction(updateSceneSort, state, action.value),
  update_scene_config: (state, action) =>
    stateAction(updateSceneConfig, state, action.value),
  update_scene_content: (state, action) =>
    stateAction(updateSceneContent, state, action.value),
  add_item_to_scene: (state, action) =>
    stateAction(addItemToScene, state, action.value),
  complete_user_load: (state, action) =>
    stateAction(completeUserLoad, state, null, false),
  increment_user_load_count: (state, action) =>
    stateAction(incrementUserLoadCount, state, null, false),
  add_item_to_scene_and_create: (state, action) =>
    stateAction(addItemToSceneAndCreate, state, action.value),
  remove_item_from_scene: (state, action) =>
    stateAction(removeItemFromScene, state, action.value),
  remove_item_from_specific_scene: (state, action) =>
    stateAction(removeItemFromSpecificScene, state, action.value),
  toggle_show_description: (state, action) =>
    stateAction(toggleShowDescription, state, null),
  set_plot_scale: (state, action) =>
    stateAction(setPlotScale, state, action.value, false),
  set_project_count_for_user_load: (state, action) =>
    stateAction(setProjectCountForUserLoad, state, action.value, false),
  set_focus_mode: (state, action) =>
    stateAction(setFocusMode, state, action.value, false),
  set_plot_mode: (state, action) =>
    stateAction(setPlotMode, state, action.value, false),
  toggle_plot_scale_snap: (state, action) =>
    stateAction(togglePlotScaleSnap, state, null, false),
  save_project_now: (state, action) =>
    stateAction(saveProjectNow, state, null, false),
  set_save_not_pending: (state, action) =>
    stateAction(setSaveNotPending, state, null, false),
  toggle_highlight: (state, action) =>
    stateAction(toggleHighlight, state, action.value),
  add_item_from_scene: (state, action) =>
    stateAction(addItemFromScene, state, action.value),
  update_user_config: (state, action) =>
    stateAction(updateUserConfig, state, action.value),
  email_login: (state, action) =>
    stateAction(emailLogin, state, action.value, false),
  email_register: (state, action) =>
    stateAction(emailRegister, state, action.value, false),
  email_forgot_password: (state, action) =>
    stateAction(emailForgotPassword, state, action.value, false),
  login_success: (state, action) =>
    stateAction(loginSuccess, state, action.value.msg, false),
  login_failure: (state, action) =>
    stateAction(loginFailure, state, action.value.msg, false),
  clear_login_error: (state, action) =>
    stateAction(clearLoginError, state, null, false),
  set_asset_thumbnail: (state, action) =>
    stateAction(setAssetThumbnail, state, action.value, true),
  facebook_login: (state, action) =>
    stateAction(facebookLogin, state, null, false),
  google_login: (state, action) => stateAction(googleLogin, state, null, false),
  log_user_in: (state, action) =>
    stateAction(logUserIn, state, action.value, false),
  log_user_out: (state, action) => stateAction(logUserOut, state, null, false),
  successful_sync_up: (state, action) =>
    stateAction(successfulSyncUp, state, null, false),
  replace_state_with_synced_copy: (state, action) =>
    stateAction(replaceStateWithSyncedCopy, state, action.value, false),
  error_syncing: (state, action) =>
    stateAction(errorSyncing, state, null, false),
  set_screenplay_element_type: (state, action) =>
    stateAction(setScreenplayElementType, state, action.value, false),
  update_scene_items_sort: (state, action) =>
    stateAction(updateSceneItemsSort, state, action.value),
  toggle_hide_non_highlighted: (state, action) =>
    stateAction(toggleHideNonHighlighted, state, {}, false),
  toggle_tab_completed: (state, action) =>
    stateAction(toggleTabCompleted, state, action.value),
  set_tab_completed: (state, action) =>
    stateAction(setTabCompleted, state, action.value),
  project_save_required: (state, action) => {
    stateAction(projectSaveRequired, state, null, false)
  },
  move_item_from_one_scene_to_another: (state, action) =>
    stateAction(moveItemFromOneSceneToAnother, state, action.value),
  cycle_highlight_modes: (state, action) =>
    stateAction(cycleHighlightModes, state, null, false),
  toggle_typewriter_mode: (state, action) =>
    stateAction(toggleTypewriterMode, state, action.value, false),
  toggle_mobile_menu: (state, action) =>
    stateAction(toggleMobileMenu, state, action.value, false),
  update_project_print_notes: (state, action) =>
    stateAction(updateProjectPrintNotes, state, action.value),
  toggle_page_break_between_scenes: (state, action) =>
    stateAction(togglePageBreakBetweenScenes, state, null),
  toggle_page_break_between_sections: (state, action) =>
    stateAction(togglePageBreakBetweenSections, state, null),
  toggle_print_title_page: (state, action) =>
    stateAction(togglePrintTitlePage, state, null),
  add_to_project_sync: (state, action) =>
    stateAction(addToProjectSync, state, action.value.projectId, false),
  remove_from_project_sync: (state, action) =>
    stateAction(removeFromProjectSync, state, action.value.projectId, false),
  update_project_sync_timestamp_from_firestore: (state, action) =>
    stateAction(
      updateProjectSyncTimestampFromFirestore,
      state,
      action.value,
      false
    ),
  update_project_sync: (state, action) =>
    stateAction(updateProjectSync, state, action.value, false),
  start_syncing_project: (state, action) =>
    stateAction(startSyncingProject, state, action.value.projectId, true),
  save_project_to_local: (state, action) =>
    stateAction(saveProjectToLocal, state, action.value, true),
  add_project_to_list: (state, action) =>
    stateAction(addProjectToList, state, action.value, true),
  delete_non_local_project: (state, action) =>
    stateAction(deleteNonLocalProject, state, action.value, false),
  clear_user_projects: (state, action) =>
    stateAction(clearUserProjects, state, null, false),
  show_project_goals_success: (state, action) =>
    stateAction(showProjectGoalsSuccess, state, null, true),
  hide_project_goals_success: (state, action) =>
    stateAction(hideProjectGoalsSuccess, state, null, true),
})

// const reducers = combineReducers({ secondReducer, noteReducer })
let Note = configureStore({ reducer: noteReducer })

addLeaveSiteConfirmation()

export default Note

const projectSaveRequired = (state: StateInterface) => {
  startSaveTimeout()

  let project = currentProject(state) // what if action that specifies project?

  if (project!) {
    project!.updated_at = new Date().toString()
  }
  state.savePending = true
}

const getUserData = (uid: string) => {
  // db.collection("users")
  //   .doc(uid)
  //   .get()
  //   .then((doc: any) => {
  //     if (doc.exists) {
  //       // got user data; process as appropriate
  //     }
  //   });
}

/* ---- ---- Firebase ---- ---- */
auth.onAuthStateChanged((user: any) => {
  Note.dispatch({ type: "clear_login_error" })
  if (user) {
    let userType: string = "email"
    if (user.providerData && user.providerData.length > 0) {
      userType = user.providerData[0].providerId
    }

    const authUser: UserInterface = {
      type: userType,
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
    }
    getUserData(user.uid)

    Note.dispatch({ type: "log_user_in", value: authUser })
    setTimeout(() => {
      Note.dispatch({ type: "complete_user_load" })
    }, 4000)
  } else {
    Note.dispatch({ type: "log_user_out" })
    Note.dispatch({ type: "complete_user_load" })
  }
})

const successfulSyncUp = (state: StateInterface) => {
  state.syncError = null
  state.syncing = false
}

const noLongerSyncing = (state: StateInterface) => {
  state.syncError = null
  state.syncing = false
}

const errorSyncing = (state: StateInterface) => {
  state.syncing = false
  state.syncError = "There was an error syncing"
}

// TODO: Replace any with interface
const replaceStateWithSyncedCopy = (state: StateInterface, newState: any) => {
  const parsedNewState: StateInterface = JSON.parse(newState.data)
  state.config = parsedNewState.config
  state.currentProject = parsedNewState.currentProject
  state.projects = parsedNewState.projects
  state.user = parsedNewState.user

  noLongerSyncing(state)
  saveProjectNow(state)
}

const emailLogin = (state: StateInterface, params: any) => {
  state.login.submitting = true
  auth
    .signInWithEmailAndPassword(params.email, params.password)
    .catch((error: any) => {
      // Handle Errors here.
      Note.dispatch({ type: "login_failure", value: { msg: error.message } })
    })
}

const emailRegister = (state: StateInterface, params: any) => {
  state.login.submitting = true
  auth
    .createUserWithEmailAndPassword(params.email, params.password)
    .catch((error: any) => {
      // Handle Errors here.
      Note.dispatch({ type: "login_failure", value: { msg: error.message } })
    })
}

const emailForgotPassword = (state: StateInterface, params: any) => {
  state.login.submitting = true
  auth
    .sendPasswordResetEmail(params.email)
    .then(function () {
      Note.dispatch({
        type: "login_success",
        value: { msg: "A password reset email has been sent to you." },
      })
    })
    .catch(function (error: any) {
      // An error happened.
      Note.dispatch({ type: "login_failure", value: { msg: error.message } })
    })
}

const loginFailure = (state: StateInterface, msg: string) => {
  state.login.submitting = false
  state.login.success = false
  state.login.error = msg
}

const loginSuccess = (state: StateInterface, msg: string) => {
  state.login.submitting = false
  state.login.success = msg
  state.login.error = false
}

const clearLoginError = (state: StateInterface) => {
  state.login = {
    submitting: false,
    success: false,
    error: false,
  }
}

const facebookLogin = () => {
  facebookAuthProvider.setCustomParameters({
    display: "popup",
  })

  auth
    .signInWithPopup(facebookAuthProvider)
    .then((result: any) => {
      // const token = result.credential.accessToken;
      const loggedInUser = result.user

      let user = {
        type: "facebook",
        uid: loggedInUser.uid,
        email: loggedInUser.email,
        emailVerified: loggedInUser.emailVerified,
        avatar: loggedInUser.photoURL,
      }

      Note.dispatch({ type: "log_user_in", value: user })
    })
    .catch((error: any) => {})
}

const googleLogin = () => {
  auth
    .signInWithPopup(googleAuthProvider)
    .then((result: any) => {
      // const token = result.credential.accessToken;
      const loggedInUser = result.user

      let user = {
        type: "google",
        uid: loggedInUser.uid,
        email: loggedInUser.email,
        emailVerified: loggedInUser.emailVerified,
        avatar: loggedInUser.photoURL,
      }

      Note.dispatch({ type: "log_user_in", value: user })
      Note.dispatch({ type: "log_user_in", value: user })
    })
    .catch((error: any) => {})
}
