import React, { useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { StateInterface, SceneInterface } from "../../../utils/Interfaces"
import {
  getConfig,
  currentProject,
  currentSceneObject,
} from "../../../utils/State"

import SaveState from "../../../common/SaveState/SaveState"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdjust } from "@fortawesome/free-solid-svg-icons"
import {
  faHighlighter,
  faEyeSlash,
  faEye,
  faExpandArrows,
  faTypewriter,
  faBullseye,
  faPenNib
} from "@fortawesome/pro-regular-svg-icons"
import ProjectGoalsModal from "../../../modals/ProjectGoalsModal/ProjectGoalsModal"
import { formatWordCount } from "../../../utils/Numbers"

const Container = styled.div<{ focusMode: boolean }>`
  @media print {
    display: none;
  }
  position: fixed;
  bottom: 0px;
  background-color: ${(props) =>
    props.focusMode === true
      ? "var(--themed-bg-color)"
      : "var(--themed-light-bg)"};
  left: 0px;
  height: 30px;
  display: grid;
  grid-template-columns: 170px 1fr 65px;
  width: 100%;
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);

  @media (max-width: 480px) {
    grid-template-columns: 30px 1fr 65px;
    box-sizing: border-box;
    padding: 0px 12px;
  }
`

const DocumentMeta = styled.div<{ focusMode: boolean }>`
  display: grid;
  grid-template-columns: 100px 1fr;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 2.6em;
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);
  ${(props) =>
    props.focusMode === true
      ? "visibility: hidden; opacity: 0;"
      : "visibility: visible; opacity: 1;"}
`

const FocusModeLink = styled.div`
  text-align: right;
  font-weight: bold;
  font-family: var(--title-font);
  color: var(--themed-focus-color);
  text-transform: uppercase;
  line-height: 2.3em;
  font-size: 14px;
  padding-right: 5px;
  box-sizing: border-box;
  cursor: pointer;
  :hover {
    color: var(--themed-font-color);
  }

  @media (max-width: 480px) {
    opacity: 0;
  }
`

const Options = styled.div`
  display: grid;

  grid-template-columns: 102px 100px 1fr;

  grid-gap: 10px;

  @media (max-width: 600px) {
    grid-template-columns: 100px 1fr;
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`

const HighlightLink = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: var(--themed-focus-color);

  :hover {
    color: var(--themed-font-color);
  }

  @media (max-width: 480px) {
    grid-column: 1;
  }
`

const TypewriterLink = styled.div<{ active: boolean }>`
  cursor: pointer;
  font-size: 12px;
  color: ${(props) =>
    props.active === true ? "var(--black-color)" : "var(--themed-focus-color)"};

  :hover {
    color: var(--themed-font-color);
  }

  @media (max-width: 480px) {
    grid-column: 1;
  }
`

const ActiveHighlight = styled.span`
  color: var(--themed-font-color);
`

const Words = styled.div`
  user-select: none;
`

const WordCountAndGoals = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
`

const Goals = styled.div<{ active: boolean, success: boolean }>`
  user-select: none;
  font-size: 12px;
  cursor: pointer;
  ${props => props.active !== true ?
  'color: var(--themed-no-content-color);' : 'color: var(--themed-font-color);'}
  ${props => props.success === true && 'color: var(--okay-button-color--hover);'}
`

const WriteFooter = () => {
  const config = useSelector((state: StateInterface) => getConfig(state))
  const project = useSelector((state: StateInterface) => currentProject(state))
  const scene: SceneInterface = useSelector((state: StateInterface) =>
    currentSceneObject(project)
  )

  const [showProjectGoalsModal, setShowProjectGoalsModal] = useState(false)

  const dispatch = useDispatch()

  const toggleFocusMode = () => {
    let newFocusMode: boolean
    if (!config.focusMode) {
      newFocusMode = true
    } else {
      newFocusMode = !config.focusMode
    }

    const toDispatch = { type: "set_focus_mode", value: newFocusMode }
    dispatch(toDispatch)
  }

  const cycleHighlighModes = () => {
    const toDispatch = {
      type: "cycle_highlight_modes",
    }
    dispatch(toDispatch)
  }

  const toggleTypewriterMode = () => {
    const toDispatch = {
      type: "toggle_typewriter_mode",
      value: !config.typewriterMode,
    }

    dispatch(toDispatch)
  }

  const showGoalsModal = () => {
    setShowProjectGoalsModal(true)
  }

  return (
    <Container focusMode={config.focusMode} className="footer">
      <SaveState />
      <DocumentMeta focusMode={config.focusMode}>
        <WordCountAndGoals>
          {project!.currentScene !== "home" &&
            <Words title={`${formatWordCount(scene.wordCount, true)} in this scene`}>{formatWordCount(scene.wordCount, false)} <FontAwesomeIcon icon={faPenNib} /></Words>
          }
          <Goals success={project!.showGoalSuccess === true} onClick={showGoalsModal} title={"Goals Status"} active={project!.goals.length > 0}>
            <FontAwesomeIcon icon={faBullseye} />
          </Goals>
        </WordCountAndGoals>
        <Options>
          <HighlightLink
            title="Select Highlight Type"
            onClick={() => cycleHighlighModes()}
            onMouseDown={(e) => e.preventDefault()}
          >
            {(config.showHighlight === 0 || !config.showHighlight) && (
              <span>
                <FontAwesomeIcon icon={faEyeSlash} /> None
              </span>
            )}
            {config.showHighlight === 1 && (
              <ActiveHighlight>
                <FontAwesomeIcon icon={faEye} /> Focus
              </ActiveHighlight>
            )}
            {config.showHighlight === 2 && (
              <ActiveHighlight>
                <FontAwesomeIcon icon={faHighlighter} /> Highlight
              </ActiveHighlight>
            )}
            {config.showHighlight === 3 && (
              <ActiveHighlight>
                <FontAwesomeIcon icon={faAdjust} /> Fade
              </ActiveHighlight>
            )}
            {config.showHighlight === 4 && (
              <ActiveHighlight>
                <FontAwesomeIcon icon={faExpandArrows} /> Zoom
              </ActiveHighlight>
            )}
          </HighlightLink>
          <TypewriterLink
            active={config.typewriterMode}
            title="Typewriter Mode"
            onClick={() => toggleTypewriterMode()}
            onMouseDown={(e) => e.preventDefault()}
          >
            <FontAwesomeIcon icon={faTypewriter} />
          </TypewriterLink>
        </Options>
      </DocumentMeta>

      <FocusModeLink
        onClick={toggleFocusMode}
        onMouseDown={(e) => e.preventDefault()}
      >
        {config.focusMode === false ? "FOCUS." : "CHAOS!"}
      </FocusModeLink>
      {showProjectGoalsModal === true && <ProjectGoalsModal closeModal={() => setShowProjectGoalsModal(false)} />}
    </Container>
  )
}

export default WriteFooter
