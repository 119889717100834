import React from "react"
import styled from "styled-components"

const Wrapper = styled.div<{
  mode: "inverse" | "normal" | undefined | "transparent"
}>`
  cursor: pointer;
  font-size: 14px;
  text-align: center;

  font-weight: bold;
  border-radius: 8px;
  padding: 5px;
  color: var(--white-color);
  text-transform: uppercase;
  ${(props) =>
    props.mode !== "inverse" &&
    `background-color: var(--brand-primary);
  border: 2px solid var(--brand-primary);
  
    :hover {
      border: 2px solid var(--brand-primary);
      color: var(--themed-font-color);
    }`}
  ${(props) =>
    props.mode === "inverse" &&
    `background-color: var(--themed-bg-color);
  border: 2px solid var(--themed-bg-color);
  color: var(--themed-font-color);
  :hover {
    border: solid 2px var(--white-color);
  }`}
  ${(props) =>
    props.mode === "transparent" &&
    "color: var(--themed-bg-color); background-color: transparent; border: 0px; text-align: left; padding: 5px 0px; @media (min-width: 481px) { padding: 0px; } :hover { border: 0px; color: var(--themed-focus-color); }"}
`

const StandardButton = ({
  children,
  onClick,
  mode,
}: {
  children: any
  onClick: any
  mode?: "inverse" | "normal" | "transparent" | undefined
}) => {
  return (
    <Wrapper onClick={onClick} mode={mode}>
      {children}
    </Wrapper>
  )
}

export default StandardButton
