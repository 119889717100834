import styled from "styled-components"

export const Wrapper = styled.div<{ focusMode: boolean }>`
  transition: var(--focus-mode-transition);
  -webkit-transition: var(--focus-mode-transition);
  text-transform: uppercase;
  position: relative;
  font-size: 16px;
  line-height: 1.3em;
  padding: 5px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 20px 1fr;

  @media (max-width: 480px) {
    grid-template-columns: 20px;
  }

  ${props =>
    props.focusMode === true
      ? "visibility: hidden; opacity: 0;"
      : "visibility: visible; opacity: 1;"}
`

export const SaveWrapper = styled.div<{ savePending: boolean }>`
  position: relative;
  width: 14px;
  color: ${props =>
    props.savePending === false
      ? "var(--themed-header-color)"
      : "var(--save-indicator-color)"};
`

export const SaveIndicator = styled.div`
  position: absolute;
  width: 5px;
  height: 5px;
  top: -6px;
  color: var(--themed-save-notification-color);
  right: -3px;
  font-size: 8px;
`

export const SavePending = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  margin-left: 5px;
  color: var(--themed-font-color);

  @media (max-width: 480px) {
    display: none;
  }
`

export const NoSavePending = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  margin-left: 5px;
  color: var(--themed-font-color);
  @media (max-width: 480px) {
    display: none;
  }
`
