import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"

import { ProjectInterface, StateInterface, GoalInterface } from '../../../utils/Interfaces'
import { currentProject } from '../../../utils/State'
import { formatWordCount } from '../../../utils/Numbers'
import ProjectGoalItem from '../ProjectGoalItem/ProjectGoalItem'
import StandardButton from '../../../common/StandardButton/StandardButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'

const Wrapper = styled.div``

const NoGoals = styled.div`
  font-style: italic;
  color: var(--themed-no-content-color);
  font-size: 14px;
`

const GoalSuccess = styled.div`
  color: var(--okay-button-color--hover);
  font-style: italic;
`

const NewGoal = styled.div`
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 60px;
  grid-gap: 5px;
`

const NewGoalButton = styled.div`
  grid-column: 3;
`

const NewGoalDate = styled.div`
  grid-row: 2;
  grid-column: 1 / -1;
  font-size: 14px;

  input {
    font-size: 16px;
  }
`

const NewGoalStartDate = styled.div`
  grid-row: 3;
  grid-column: 1 / -1;
  font-size: 14px;

  input {
    font-size: 16px;
  }
`

const NewGoalSelect = styled.div<{ newGoalType: string }>`
  ${props => props.newGoalType === "" && 'grid-column: 1 / 3;'}
`

const GoalsWrapper = styled.div`
  max-height: 300px;
  border: 1px solid var(--themed-focus-color);
  overflow-y: scroll;
  overflow-x: hidden;
`

const ProjectGoals = ({ modal }: { modal: boolean }) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )

  let showGoalSuccess: boolean = project!.showGoalSuccess

  const goals: Array<GoalInterface> = project!.goals.slice().reverse();

  const [newGoalType, setNewGoalType] = useState("")
  const [newWordCount, setNewWordCount] = useState(0)
  const [newTargetDate, setNewTargetDate] = useState("")
  const [newStartDate, setNewStartDate] = useState("")
  const dispatch = useDispatch()

  React.useEffect(() => {
    showGoalSuccess = project!.showGoalSuccess
    const toDispatch = {
      type: "hide_project_goals_success"
    }

    dispatch(toDispatch)
  }, [])

  const removeGoal = (ind: number) => {
    const toDispatch = {
      type: "remove_goal",
      value: {
        index: ind
      }
    }

    dispatch(toDispatch)
  }

  const addNewGoal = () => {
    if (readyToAddNewGoal() === false) return

    let startDate = newStartDate
    if (startDate === "") {
      startDate = moment().format("YYYY-MM")
    }

    const toDispatch = {
      type: "add_new_goal",
      value: {
        type: newGoalType,
        wordCount: newWordCount,
        dateStart: startDate,
        dateEnd: newTargetDate
      }
    }
    dispatch(toDispatch)

    setNewGoalType("")
    setNewWordCount(0)
    setNewTargetDate("")
    setNewStartDate("")
  }

  const setTargetDateEndOfWeek = (e: any) => {
    e.preventDefault()
    let today = moment()
    let target = today.endOf("week").format("YYYY-MM-DD")
    setNewTargetDate(target)
  }

  const setTargetDateToday = (e: any) => {
    e.preventDefault()
    let today = moment()
    let target = today.format("YYYY-MM-DD")
    setNewStartDate(target)
  }

  const setTargetDateEndOfMonth = (e: any) => {
    e.preventDefault()
    let today = moment()
    let target = today.endOf("month").format("YYYY-MM-DD")
    setNewTargetDate(target)
  }

  const setTargetDateEndOfYear = (e: any) => {
    e.preventDefault()
    let today = moment()
    let target = today.endOf("year").format("YYYY-MM-DD")
    setNewTargetDate(target)
  }

  const readyToAddNewGoal = () => {
    if (newGoalType === "") { return false }
    if (newWordCount === 0) { return false }
    if (newGoalType === "date" && newTargetDate === "") { return false }
    return true
  }

  let index: number = project!.goals.length

  
  return (<Wrapper>
    {showGoalSuccess === true && <GoalSuccess>Way to Go!</GoalSuccess>}
    {modal === true &&
      <strong>You've already written {formatWordCount(project!.wordCount, true)}!</strong>
    }
    {goals.length === 0 &&
      <NoGoals>Your project doesn't have any goals. Create one!</NoGoals>
    }

    <NewGoal>
      <NewGoalSelect newGoalType={newGoalType}>
        <select onChange={(e) => setNewGoalType(e.target.value)} value={newGoalType}>
          <option value="">New Goal Type</option>
          <option value="project">Project</option>
          <option value="today">Today</option>
          <option value="date">Date</option>
        </select>
      </NewGoalSelect>
      {newGoalType !== "" &&
        <div><input type="number" placeholder="Word Count" onChange={(e) => setNewWordCount(Number(e.target.value))} value={String(newWordCount).replace(/^0+/, '')} /> </div>
      }

      <NewGoalButton>
        <StandardButton mode={"normal"} onClick={addNewGoal}>
          <FontAwesomeIcon icon={faPlus} /> NEW
        </StandardButton>
      </NewGoalButton>
      {newGoalType === "date" &&
        <NewGoalStartDate>
          Select a start date for your goal (this is optional).
          <input type="date"  value={newStartDate} onChange={(e) => setNewStartDate(e.target.value)} />
          <em>or: <a href="#" onClick={setTargetDateToday}>Today</a>.</em>
        </NewGoalStartDate>
      }
      {newGoalType === "date" &&
        <NewGoalDate>
          Select an end date for your goal.
          <input type="date"  value={newTargetDate} onChange={(e) => setNewTargetDate(e.target.value)} />
          <em>or: <a href="#" onClick={setTargetDateEndOfWeek}>This week</a>, <a href="#" onClick={setTargetDateEndOfMonth}>This month</a>, <a href="#" onClick={setTargetDateEndOfYear}>This year</a>.</em>
        </NewGoalDate>
      }
    </NewGoal>
    <GoalsWrapper>
      {goals.map((goal: GoalInterface) => {
        const today = moment().format("YYYY-MM-DD")
        const progress: number = (goal.wordsWritten / goal.wordCountTarget)*100
        index--
        return <ProjectGoalItem 
          key={`goal-${index}`}
          index={index} 
          removeGoal={removeGoal}
          today={today}
          progress={progress}
          goal={goal}
        />
      })}
    </GoalsWrapper>
   </Wrapper>)
}

export default ProjectGoals