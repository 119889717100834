import React, { useState, MouseEventHandler } from "react";
import styled from "styled-components";
import ModalBase from "../../common/Modal/ModalBase/ModalBase";
import { getFullItem, currentProject } from "./../../utils/State";
import { useSelector, useDispatch } from "react-redux";
import {
  StateInterface,
  ProjectInterface,
  NoteMetaInterface,
  SceneInterface,
} from "../../utils/Interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faEraser } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import AssetColorPicker from "../../common/AssetColorPicker/AssetColorPicker";
import { Link } from "@reach/router";
import ModalActions from "../../common/Modal/ModalActions/ModalActions";
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton";
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle";
import Factoids from "../../components/Assets/Factoids/Factoids";
import { customAssetFields } from "../../utils/Data";
import RelatedAssetSelector from "../../components/Assets/RelatedAssetSelector/RelatedAssetSelector";

const Supplemental = styled.textarea`
  width: 100%;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  overflow-y: hidden;
`;

const EditNoteModalSelection = styled.div`
  font-style: italic;
  font-size: 15px;
  color: var(--themed-font-color);
  background-color: var(--themed-input-focus-color);
  padding: 10px;
  margin-bottom: 10px;
  max-height: 100px;
  overflow-y: scroll;
`;

const AssetColourPickerToggle = styled.span`
  cursor: pointer;
  font-weight: bold;
  :hover {
    background-color: var(--themed-light-bg);
  }
`;

const AssetColourExample = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  border: 1px solid #cccccc;
  background-color: ${(props) => props.color};
  display: inline-block;
`;

const AssetMoreInfo = styled.div`
  a {
    color: var(--brand-primary);
  }
`;

const SpecificField = styled.div`
  margin-bottom: 10px;
`;

const SpecificField_Title = styled.span`
  text-transform: capitalize;
  font-weight: bold;
`;

const ScrollWrapper = styled.div`
  max-height: 340px;
  overflow-y: scroll;
`;

const SwapScenes = styled.div`
  display: grid;
  grid-template-columns: 145px 1fr;

  span {
    line-height: 2em;
  }

  select {
    margin-bottom: 0px;
  }
`;

const EditNoteModal = ({
  item,
  closeModal,
  updateItem,
  doCloseModal,
  mode,
  hideRemoveFromScene,
  sceneId,
}: {
  item: any;
  closeModal: MouseEventHandler;
  doCloseModal?: Function;
  updateItem: Function;
  mode: string;
  hideRemoveFromScene?: boolean;
  sceneId?: string;
}) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state),
  );

  let fullItem: any = { noteMeta: { supplemental: "" } };
  if (item && item.type !== "storySection") {
    fullItem = getFullItem(project, item.type, item.id);
  } else if (item && item.type === "storySection") {
    fullItem = item;
  }

  if (!fullItem) fullItem = { noteMeta: { supplemental: "" } };
  const getInitialNoteMeta = () => {
    if (fullItem && fullItem.noteMeta) return fullItem.noteMeta
    return { supplemental: "" }
  }

  const getInitialNoteTitle = () => {
    if (fullItem && fullItem.title) return fullItem.title
    return ""
  }

  const getInitialNoteTags = () => {
    if (fullItem && fullItem.tags) return fullItem.tags
    return ""
  }

  const getNoteType = () => {
    if (fullItem && fullItem.type) return fullItem.type
    return ""
  }
  const [noteMeta, setNoteMeta] = useState<NoteMetaInterface>(getInitialNoteMeta())

  
  const [noteTitle, setNoteTitle] = useState(getInitialNoteTitle());
  const [noteTags, setNoteTags] = useState(getInitialNoteTags());
  const [showColourPicker, setShowColourPicker] = useState(false);
  const dispatch = useDispatch();

  const getFieldSpecificValuesFromItem = (fullItem: any) => {
    if (!fullItem) return {};

    let objectToReturn: any = {};
    const theseCustomAssetFields = customAssetFields(getNoteType());
    Object.keys(fullItem).forEach((item: any) => {
      theseCustomAssetFields.forEach((field: any) => {
        if (field.id === item) {
          objectToReturn[item] = fullItem[item];
        }
      });
    });
    return objectToReturn;
  };

  const [fieldSpecificValues, setFieldSpecificValues] = useState<any>(
    getFieldSpecificValuesFromItem(fullItem),
  );

  if (!hideRemoveFromScene) {
    hideRemoveFromScene = false;
  }

  const updateNoteMeta = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNoteMeta: NoteMetaInterface = {
      supplemental: e.currentTarget.value,
    };

    setNoteMeta(newNoteMeta);
  };

  const updateNoteTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteTitle(e.currentTarget.value);
  };

  const submitUpdatedNote = () => {
    let noteItemValues: any = {
      id: item.id,
      noteMeta: noteMeta,
      title: noteTitle,
      type: item.type,
      tags: noteTags,
    };
    Object.keys(fieldSpecificValues).forEach((item: any) => {
      noteItemValues[item] = fieldSpecificValues[item];
    });

    updateItem(noteItemValues);
  };

  const removeNote = () => {
    if (mode === "write") {
      removeFromScene();
    } else if (mode === "note") {
      removeFromNote();
    }
  };

  const removeFromScene = () => {
    let toDispatch = {};
    if (sceneId) {
      toDispatch = {
        type: "remove_item_from_specific_scene",
        value: { id: item.id, type: item.type, sceneId: sceneId },
      };
    } else {
      toDispatch = {
        type: "remove_item_from_scene",
        value: { id: item.id, type: item.type },
      };
    }

    if (doCloseModal) doCloseModal();
    dispatch(toDispatch);
  };

  const removeFromNote = () => {
    const toDispatch = {
      type: "remove_item_from_note",
      value: { id: item.id, type: item.type },
    };

    if (doCloseModal) doCloseModal();
    dispatch(toDispatch);
  };

  const deleteFromProject = () => {
    const toDispatch = {
      type: "delete_item_from_project",
      value: { id: item.id, type: item.type },
    };

    if (doCloseModal) doCloseModal();
    dispatch(toDispatch);
  };

  const updateFieldSpecificValue = (e: any, n: string) => {
    let newValue = Object.assign({}, fieldSpecificValues);
    if (e.currentTarget) {
      newValue[n] = e.currentTarget.value;
    } else {
      newValue[n] = e;
    }
    setFieldSpecificValues(newValue);
  };

  const changeSceneAssetIsIn = (e: any) => {
    const toDispatch = {
      type: "move_item_from_one_scene_to_another",
      value: {
        id: item.id,
        type: item.type,
        item: item,
        source: sceneId,
        destination: e.currentTarget.value,
      },
    };

    dispatch(toDispatch);
  };

  if (!fullItem.id) {
    return (<ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>{item.type} Deleted</ModalTitle>

        <p>Unfortunately, this item has been deleted from the project.</p>

        <p>The ability to recreate a deleted asset from this view is on our to-do list.</p>
        <ModalActions>
          <ModalActionButton type="okay" title="Close Modal" onClick={closeModal}>
          <FontAwesomeIcon icon={faCheckCircle} />
          </ModalActionButton>
        </ModalActions>
        
      </React.Fragment>
    </ModalBase>)
  }

  if (!item) return <div />;
  const typeSpecificFields: Array<any> = customAssetFields(item.type!);
  return (
    <ModalBase closeModal={closeModal}>
      <React.Fragment>
        <ModalTitle>Update {fullItem.type}</ModalTitle>
        <ScrollWrapper>
          {fullItem.thumbnail && fullItem.thumbnail > "" && (
            <img src={fullItem.thumbnail} />
          )}
          <input
            type="text"
            defaultValue={fullItem.title}
            onChange={updateNoteTitle}
            autoFocus
            placeholder="Title"
          />
          {fullItem.selectedText > "" && (
            <EditNoteModalSelection>
              {fullItem.selectedText}
            </EditNoteModalSelection>
          )}
          <Supplemental
            placeholder="Elaborate Here"
            onChange={updateNoteMeta}
            defaultValue={noteMeta.supplemental}
          />
          <input
            type="text"
            value={noteTags}
            placeholder="Tags (comma-separated)"
            onChange={(e) => setNoteTags(e.currentTarget.value)}
          />
          {showColourPicker === false && (
            <AssetColourPickerToggle onClick={() => setShowColourPicker(true)}>
              Custom Colour: <AssetColourExample color={fullItem.color} />
            </AssetColourPickerToggle>
          )}
          {showColourPicker === true && (
            <AssetColorPicker
              asset={fullItem}
              onUpdateColour={() => setShowColourPicker(false)}
            />
          )}
          {typeSpecificFields.map((field) => {
            return (
              <SpecificField key={`type-specific-field-${field.id}`}>
                {field.type === "select" && field.id !== "role" && (
                  <React.Fragment>
                    <SpecificField_Title>
                      {field.id.replace(/_/g, " ")}:
                    </SpecificField_Title>
                    <RelatedAssetSelector
                      multiple={field.multiple || false}
                      assetType={field.id}
                      inModal={true}
                      selected={fieldSpecificValues[field.id]}
                      updateSelectedAssets={updateFieldSpecificValue}
                    />
                  </React.Fragment>
                )}
              </SpecificField>
            );
          })}
          {fullItem.type !== "storySection" && fullItem.type !== "scene" && (
            <React.Fragment>
              <hr />

              <Factoids fullItem={fullItem} />
            </React.Fragment>
          )}
        </ScrollWrapper>
        {sceneId && (
          <React.Fragment>
            <hr />
            <SwapScenes>
              <span>Move {fullItem.type} to:</span>
              <select
                defaultValue={sceneId}
                onChange={(e) => changeSceneAssetIsIn(e)}
              >
                {project!.scenes.map((theScene: SceneInterface) => {
                  return (
                    <option
                      key={`option-for-${theScene.id}`}
                      value={theScene.id}
                    >
                      {theScene.title}
                    </option>
                  );
                })}
              </select>
            </SwapScenes>
          </React.Fragment>
        )}
        <hr />

        <AssetMoreInfo>
          For more options, go to this item's{" "}
          <Link
            to={`/projects/${
              project!.id
            }/assets/${fullItem.type}/${fullItem.id}`}
          >
            Asset Page.
          </Link>
        </AssetMoreInfo>
        <hr />

        <ModalActions>
          <ModalActionButton type="cancel" title="Cancel" onClick={closeModal}>
            <FontAwesomeIcon icon={faBan} /> Cancel
          </ModalActionButton>
          <ModalActionButton
            type="delete"
            title="Delete from Project"
            onClick={deleteFromProject}
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Delete
          </ModalActionButton>
          {hideRemoveFromScene !== true && (
            <ModalActionButton
              type="remove"
              title="Remove From Scene"
              onClick={removeNote}
            >
              <FontAwesomeIcon icon={faEraser} /> Remove
            </ModalActionButton>
          )}
          <ModalActionButton
            type="okay"
            title="Save"
            onClick={submitUpdatedNote}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Update
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  );
};

export default EditNoteModal;
