import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudDownload } from "@fortawesome/pro-regular-svg-icons"
import {
  Wrapper,
  TopRow,
  ProjectName,
  ProjectActions,
  DownloadLink,
  DeleteLink,
  Downloading,
  ProjectDownload,
} from "./NonLocalProjectOverview.styles"
import { faSync, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import DeleteNonLocalProjectModal from "../../modals/DeleteNonLocalProjectModal/DeleteNonLocalProjectModal"

interface NonLocalProjectOverviewProps {
  project: any
  startSyncingProject: Function
}

const NonLocalProjectOverview = (props: NonLocalProjectOverviewProps) => {
  const [downloading, setDownloading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const { projectId, name } = props.project
  const { startSyncingProject } = props

  const dispatch = useDispatch()

  const deleteNonLocalProject = () => {
    const toDispatch = { type: "delete_non_local_project", value: projectId }
    setShowDeleteModal(false)
    dispatch(toDispatch)
  }
  return (
    <Wrapper>
      <TopRow>
        <ProjectDownload>
          {downloading === false && (
            <DownloadLink
              title="Download Project"
              onClick={(e: any) => {
                e.stopPropagation()
                startSyncingProject(projectId)
                setDownloading(true)
              }}
            >
              <FontAwesomeIcon icon={faCloudDownload} />
            </DownloadLink>
          )}
          {downloading === true && (
            <Downloading>
              <FontAwesomeIcon icon={faSync} />
            </Downloading>
          )}
        </ProjectDownload>
        <ProjectName>{name}</ProjectName>
        <ProjectActions>
          <DeleteLink
            title="Delete Project from Server"
            onClick={(e: any) => {
              e.stopPropagation()
              setShowDeleteModal(true)
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </DeleteLink>
        </ProjectActions>
      </TopRow>
      {showDeleteModal === true && (
        <DeleteNonLocalProjectModal
          closeModal={() => setShowDeleteModal(false)}
          deleteNonLocalProject={deleteNonLocalProject}
        />
      )}
    </Wrapper>
  )
}

export default NonLocalProjectOverview
