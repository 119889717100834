import React, { useState } from "react"
import styled from "styled-components"
import Header from "../../common/Header/Header"
import ProjectsFooter from "../../components/Projects/ProjectsFooter/ProjectsFooter"
import {
  ConfigObjectInterface,
  StateInterface,
  UserInterface,
} from "../../utils/Interfaces"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faGooglePlusSquare,
} from "@fortawesome/free-brands-svg-icons"
import { faSignOutAlt } from "@fortawesome/pro-regular-svg-icons"
import Spacer from "../../common/Spacer/Spacer"
import { Link } from "@reach/router"
import UsernameLogin from "../../components/User/UsernameLogin/UsernameLogin"

const Wrapper = styled.div``

interface ProjectsProps {
  path?: string
}

const UserName = styled.input`
  margin: 10px 0px;
  font-size: 16px;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`

const UserEmail = styled.input`
  margin: 0px 0px 10px 0px;
  font-size: 16px;
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`

const UserBio = styled.textarea`
  margin: 0px 0px 10px 0px;
  font-size: 16px;
  width: 100%;
  height: 150px;
  padding: 5px;
  box-sizing: border-box;
  font-family: var(--body-font);
`

const LoginButtons = styled.div`
  max-width: 500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 16px;
  grid-gap: 10px;
`

const FacebookButton = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 22px;
  background-color: var(--facebook-color);
  color: var(--white-color);
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  :hover {
    background-color: var(--facebook-color--hover);
  }
  svg {
    font-size: 24px;
  }
`

const GoogleButton = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 22px;
  background-color: var(--google-color);
  color: var(--white-color);
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  :hover {
    background-color: var(--google-color--hover);
  }
  svg {
    font-size: 24px;
  }
`

const ButtonText = styled.span`
  font-weight: bold;
  color: var(--white-color);
`

const LogoutButton = styled.span`
  cursor: pointer;
  color: var(--brand-primary);
  :hover {
    font-weight: bold;
  }
`

const Content = styled.div`
  margin: 10px;
  a,
  a:hover {
    color: var(--brand-primary);
  }
`

const User = (props: ProjectsProps) => {
  const config: ConfigObjectInterface = useSelector(
    (state: StateInterface) => state.config
  )

  const user: UserInterface | undefined | null = useSelector(
    (state: StateInterface) => state.user
  )
  const dispatch = useDispatch()

  const updateUserConfig = (updatedConfig: any) => {
    const toDispatch = {
      type: "update_user_config",
      value: Object.assign({}, config, updatedConfig),
    }

    dispatch(toDispatch)
  }

  const logoutUser = () => {
    const toDispatch = { type: "log_user_out" }
    dispatch(toDispatch)
  }

  const facebookLogin = () => {
    const toDispatch = { type: "facebook_login" }
    dispatch(toDispatch)
  }

  const googleLogin = () => {
    const toDispatch = { type: "google_login" }
    dispatch(toDispatch)
  }

  const updateName = (e: any) => {
    updateUserConfig({ userName: e.target.value })
  }

  const updateEmail = (e: any) => {
    updateUserConfig({ email: e.target.value })
  }

  const updateBio = (e: any) => {
    updateUserConfig({ bio: e.target.value })
  }

  const updateDarkMode = (e: any) => {
    updateUserConfig({ darkMode: e.target.value })
  }

  const updateContextMenuMode = (e: any) => {
    updateUserConfig({ contextMenuMode: e.target.value })
  }

  const loginType = (user: UserInterface) => {
    if (user!.type.toLowerCase().indexOf("google", 0) > -1) return "Google"
    if (user!.type.toLowerCase().indexOf("facebook", 0) > -1) return "Facebook"
    return "Email"
  }

  const loginEmail = (user: UserInterface) => {
    if (user!.email) return user!.email
    return "Email N/A"
  }

  const localStorageRemaining = () => {
    let data: string = ""

    for (var key in window.localStorage) {
      if (window.localStorage.hasOwnProperty(key) && key === "hilkerProjects") {
        data += window.localStorage[key]
      }
    }

    return ((data.length * 16) / (8 * 1024) / 1024).toFixed(2)
  }

  return (
    <Wrapper>
      <Header />
      <Content>
        <h1>Your Settings</h1>

        <p>
          This info will be used when you print and export your projects; it'll
          just get added to the author page.
        </p>

        <UserName
          placeholder="Your Name"
          type="text"
          value={config.userName}
          onChange={(e) => updateName(e)}
        />
        <UserEmail
          placeholder="your@email.com"
          type="email"
          value={config.email}
          onChange={(e) => updateEmail(e)}
        />
        <UserBio
          placeholder="A little about you..."
          value={config.bio}
          onChange={(e) => updateBio(e)}
        ></UserBio>

        <hr />
        <p>
          Dark Mode:{" "}
          <select
            defaultValue={config.darkMode}
            onChange={(e) => updateDarkMode(e)}
          >
            <option value="off">Off</option>
            <option value="on">On</option>
            <option value="system">Respect System Setting</option>
          </select>
          <br />
          <em>This lets you decide whether to use the dark mode theme or not, or whether to respect the system's setting.</em>
        </p>
        <hr />

        <p>
          Context Menu Mode:{" "}
          <select
            defaultValue={config.contextMenuMode}
            onChange={(e) => updateContextMenuMode(e)}
          >
            <option value="desktop">Desktop</option>
            <option value="mobile">Mobile</option>
            <option value="system">Respect System Setting</option>
          </select>
          <br />
          <em>This lets you override the default Context Menu Mode behaviour, which appears when you select text in Notes or Write. The Desktop behaviour is for the Menu to appear below the selected text, and the Mobile behaviour is for the Menu to appear, full-width, at the top of your browser window. This is useful if you want to use Hilker on an iPhone or a Tablet with a physical keyboard connected.</em>
        </p>


        <hr />

        <h3>Remaining Storage</h3>

        <p>
          Your projects are using <strong>{localStorageRemaining()}</strong> MB
          of space.
        </p>

        <hr />

        <div>
          {user && user.uid && (
            <div>
              <p>
                You're logged in via {loginType(user)} ({loginEmail(user)}){" "}
                <br />
                <br />
                <LogoutButton onClick={logoutUser}>
                  <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </LogoutButton>
              </p>
            </div>
          )}
          {(!user || !user.uid) && (
            <div>
              <h3>Login</h3>
              <p>
                In order to sync your work and access it with other browsers,
                you need to register/login. It's free.
              </p>
              <UsernameLogin />

              <p>OR</p>

              <LoginButtons>
                <FacebookButton onClick={facebookLogin}>
                  <ButtonText>Connect with </ButtonText>
                  <FontAwesomeIcon icon={faFacebookSquare} />
                </FacebookButton>
                <GoogleButton onClick={googleLogin}>
                  <ButtonText>Connect with </ButtonText>
                  <FontAwesomeIcon icon={faGooglePlusSquare} />
                </GoogleButton>
              </LoginButtons>
            </div>
          )}
        </div>
        <p>
          Read the <Link to={`/privacypolicy`}>Privacy Policy</Link> and{" "}
          <Link to={`/termsofservice`}>Terms of Service</Link>
        </p>

        <Spacer width={"100%"} height={"40px"} />
      </Content>
      <ProjectsFooter />
    </Wrapper>
  )
}

export default User
