import React from "react"
import styled from "styled-components"

import SubHeader from "../../../common/SubHeader/SubHeader"
import {
  ProjectInterface,
  StateInterface,
  MobileOptionsInterface
} from "../../../utils/Interfaces"
import { useSelector, useDispatch } from "react-redux"
import { currentProject } from "../../../utils/State"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBoxFull } from "@fortawesome/pro-regular-svg-icons"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

interface SubHeaderNoteProps {
  children?: any
}

const Content = styled.div``

const SectionsOption = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: inline-block;
  padding: 6px 0px 5px 5px;
  color: var(--themed-font-color);
  font-size: 12px;
  text-transform: uppercase;
  width: 100px;

  :hover {
    color: var(--themed-bg-color);
  }

  @media (min-width: 768px) {
    display: none;
  }
`
const SubHeaderNote = (props: SubHeaderNoteProps) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => currentProject(state)
  )
  const mobileMenu: MobileOptionsInterface = project!.mobileMenu
  const dispatch = useDispatch()

  const toggleMobileActive = () => {
    const toDispatch = {
      type: "toggle_mobile_menu",
      value: { location: "notes", type: "assets" }
    }

    dispatch(toDispatch)
  }

  return (
    <SubHeader>
      <Content>
        <SectionsOption
          active={mobileMenu.notes.assets !== true}
          onClick={() => toggleMobileActive()}
        >
          {mobileMenu.notes.assets !== true && (
            <span>
              <FontAwesomeIcon icon={faBoxFull} /> Assets
            </span>
          )}
          {mobileMenu.notes.assets === true && (
            <span>
              <FontAwesomeIcon icon={faTimes} /> Close
            </span>
          )}
        </SectionsOption>
      </Content>
    </SubHeader>
  )
}

export default SubHeaderNote
