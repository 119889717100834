import React from "react"
import styled from "styled-components"
import NoteTab from "../NoteTab/NoteTab"
import {
  NoteItemLocationInterface,
  NoteItemShallowReferenceInterface,
} from "../../../utils/Interfaces"

const Wrapper = styled.div`
  padding-left: 10px;
  position: relative;

  @media (max-width: 480px) {
    padding-left: 0px;
  }
`

type NoteTabsProps = {
  items: Array<NoteItemShallowReferenceInterface>
  showItemInfo: Function
  itemLocations: Array<NoteItemLocationInterface>
}

// TODO: Need some logic to stack overlapping tabs, probably a second visual for it that opens downward.

const NoteTabs = ({ items, showItemInfo, itemLocations }: NoteTabsProps) => {
  // build list of unique ys, with an id and type array for each
  let locations: Array<any> = []
  itemLocations.forEach((itemLocation: NoteItemLocationInterface) => {
    if (!locations.includes(itemLocation.y) && itemLocation.y !== -1) {
      locations.push(itemLocation.y)
    }
  })

  const getItemsAtLocation = (location: number) => {
    return itemLocations.filter(
      (item: NoteItemLocationInterface) => item.y === location
    )
  }

  return (
    <Wrapper>
      {locations.map((location: number) => {
        const currentItems: Array<NoteItemLocationInterface> = getItemsAtLocation(
          location
        )

        if (currentItems.length === 0) {
          return (
            <div key={`note-tab-${Math.floor(Math.random() * 100000000000)}`} />
          )
        } else {
          return (
            <NoteTab
              key={`note-tab-${Math.floor(Math.random() * 100000000000)}`}
              items={currentItems}
              showItemInfo={showItemInfo}
            />
          )
        }
      })}
    </Wrapper>
  )
}

export default NoteTabs
