import React, { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import ModalBase from "../../common/Modal/ModalBase/ModalBase";
import { projectById, getFullItem } from "./../../utils/State";
import { useSelector, useDispatch } from "react-redux";
import {
  StateInterface,
  ProjectInterface,
  StorySectionInterface,
  SceneInterface,
  ShallowSceneInterface,
  ConfigObjectInterface,
} from "../../utils/Interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import {
  faPrint,
  faCheckCircle,
  faCircle,
} from "@fortawesome/pro-regular-svg-icons";
import Showdown from "showdown";
import ModalActions from "../../common/Modal/ModalActions/ModalActions";
import ModalActionButton from "../../common/Modal/ModalActionButton/ModalActionButton";
import ModalTitle from "../../common/Modal/ModalTitle/ModalTitle";
import moment from "moment";

import { assetTypes, getAssetsByType } from "./../../utils/Assets";

const PrintContent = styled.div`
  visibility: hidden;
  height: 10px;
`;

const PrintSections = styled.div`
  margin-bottom: 10px;
`;
const PrintScenesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;
  box-sizing: border-box;
`;

const PrintOptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const PrintModalOptions = styled.div`
  max-height: calc(100% - 77px)
  
  overflow-y: scroll;
  border-top: 1px solid var(--themed-font-color);
  border-bottom: 1px solid var(--themed-font-color);

  @media print {
    overflow: hidden;
  }
`;

const SelectedScene = styled.div<{ active: boolean }>`
  margin: 0px 0px 5px 0px;
  border-radius: 4px;
  ${(props) =>
  props.active === true ? "svg { color: var(--option-active-color); }" : ""}
  cursor: pointer;

  :hover {
    background-color: var(--themed-no-content-color);
  }
`;

const PrintSectionTitle = styled.div`
  font-weight: bold;
  cursor: pointer;
  :hover {
    background-color: var(--themed-no-content-color);
  }
`;

const PrintModal = ({
  projectId,
  closeModal,
  printType,
  selectedId,
  defaultPrintMode,
}: {
  projectId: string;
  closeModal: MouseEventHandler;
  printType?: "text" | "note" | "plot";
  selectedId?: string;
  defaultPrintMode?: string;
}) => {
  const project: ProjectInterface | null = useSelector(
    (state: StateInterface) => projectById(state, projectId),
  );

  const config: ConfigObjectInterface = useSelector(
    (state: StateInterface) => state.config,
  );
  const dispatch = useDispatch();

  let initialScenesToPrint: Array<string> = [];
  project!.storySections.forEach((storySection: StorySectionInterface) => {
    storySection.scenes.forEach((scene: ShallowSceneInterface) => {
      if (!selectedId) initialScenesToPrint.push(scene.id);
      if (selectedId && selectedId === scene.id) {
        initialScenesToPrint.push(scene.id);
      }
    });
  });

  const converter = new Showdown.Converter();
  const [printMode, setPrintMode] = useState<string>(
    defaultPrintMode ? defaultPrintMode : "fulltext",
  );

  const [includeAssets, setIncludeAssets] = useState<boolean>(false);
  const [scenesToPrint, setScenesToPrint] = useState<Array<string>>(
    initialScenesToPrint,
  );
  const printRef = React.useRef<HTMLDivElement>(null);

  const doPrint = () => {
    if (printMode === "fulltext") {
      contentToPrint();
    } else {
      outlineToPrint();
    }
    document.execCommand("print", false);
  };

  const formattedToday = () => {
    return moment().format("LL");
  };

  const toggleIncludeAssets = () => {
    setIncludeAssets(!includeAssets);
  };

  const togglePrintOfAllInSection = (id: string) => {
    let scenesInSection: Array<string> = [];
    const storySection:
      | StorySectionInterface
      | undefined = project!.storySections.find(
        (storySection: StorySectionInterface) => storySection.id === id,
      );

    storySection!.scenes.forEach((scene: ShallowSceneInterface) => {
      scenesInSection.push(scene.id);
    });

    let newScenesList: Array<string> = [...scenesToPrint];

    if (printingAnyScenesInSection(storySection!)) {
      // remove all
      scenesInSection.forEach((id: string) => {
        if (isSelected(id)) {
          newScenesList = newScenesList.filter(
            (sceneId: string) => sceneId !== id,
          );
        }
      });
    } else {
      // add all
      newScenesList = [...newScenesList, ...scenesInSection];
    }
    setScenesToPrint(newScenesList);
  };

  const printingAnyScenesInSection = (storySection: StorySectionInterface) => {
    let found: boolean = false;
    storySection.scenes.forEach((scene: ShallowSceneInterface) => {
      if (scenesToPrint.includes(scene.id)) found = true;
    });

    return found;
  };

  const outlineToPrint = () => {
    let printText: Array<string> = [];

    printText.push(
      `<div class='outline-header' data-type='${project!.config.type}'>`,
    );
    printText.push(
      `<div class='outline-title'><strong>${
        project!.name
      }</strong> - Outline</div>`,
    );
    printText.push(`<hr />`);
    printText.push(
      `<div class='outline-date'><em>${
        moment().format("MMMM Do YYYY, h:mm:ss a")
      }</em>`,
    );
    printText.push(`</div>`);

    project!.storySections.forEach((storySection: StorySectionInterface) => {
      if (printingAnyScenesInSection(storySection)) {
        printText.push(
          `<div class='outline-section' data-type='${project!.config.type}'>`,
        );
        printText.push(
          `<div class='outline-section-title'>${storySection.title}</div>`,
        );
        printText.push(
          `<div class='outline-section-description'>${storySection.noteMeta.supplemental}</div>`,
        );
        storySection.scenes.forEach((sceneInSection: ShallowSceneInterface) => {
          const fullScene: SceneInterface | undefined = project!.scenes.find(
            (scene: SceneInterface) => scene.id === sceneInSection.id,
          );

          printText.push(`<div class='outline-scene'>`);
          if (fullScene && scenesToPrint.includes(sceneInSection.id)) {
            printText.push(
              `<div class='outline-scene-title'>${fullScene.title}</div>`,
            );
            printText.push(
              `<div class='outline-scene-description'>${fullScene.noteMeta.supplemental}</div>`,
            );

            if (includeAssets === true) {
              printText.push(`<div class='outline-scene-assets'>`);

              assetTypes.forEach((assetType: string) => {
                let sceneItemsByType: Array<any> = fullScene.items.filter((
                  asset: any,
                ) => asset.type == assetType);

                if (sceneItemsByType.length > 0) {
                  printText.push(
                    `<div class='outline-scene-assets-title'>${assetType}s:</div>`,
                  );
                  sceneItemsByType
                    .forEach((asset: any) => {
                      printText.push(
                        `<div class='outline-scene-asset-title'>${
                          getFullItem(project!, assetType, asset.id).title
                        }</div>`,
                      );
                    });
                }
              });
              printText.push(`</div>`);
            }
            printText.push("</div>");
          }
        });

        printText.push("</div>");
      }
    });
    document.getElementById("print-housing")!.innerHTML = printText.join("");
  };

  const contentToPrint = () => {
    let printText: Array<string> = [];
    if (project!.config.printTitlePage === true) {
      printText.push(
        `<div class='print-section' style="${project!.config
              .pageBreakBetweenSections !== true &&
          "page-break-after: auto;"}">`,
      );
      printText.push(
        `<div class='title-page' style='line-height:${
          project!.config.lineSpacing
        }em; ${
          project!.config.type === "screenplay"
            ? ' font-family: "Courier Prime", monospace;'
            : " "
        }'>`,
      );

      printText.push(
        `<div class='project-title' data-type='${
          project!.config.type
        }' ${project!.config.type === "screenplay" &&
          "style='font-family: \"Courier Prime\", monospace;'"}>${
          project!.name
        }</div>`,
      );
      printText.push(
        `<p class='project-credit' data-type='${
          project!.config.type
        }'>written by</p><p class='project-authors' data-type='${
          project!.config.type
        }'>${config.userName}</p>`,
      );
      printText.push(`<div class='project-meta'><p class="source"></p>`);
      printText.push(
        `<p class='notes' data-type='${project!.config.type}'>${
          project!.config.printNotes
        }</p>`,
      );
      printText.push(`<p class='copyright'>${formattedToday()}</p></div>`);
      printText.push("</div>");
      printText.push("</div>");
    }

    project!.storySections.forEach((storySection: StorySectionInterface) => {
      if (printingAnyScenesInSection(storySection)) {
        printText.push(
          `<div class='print-section' data-type='${
            project!.config.type
          }' style="${project!.config.pageBreakBetweenSections !== true &&
            "page-break-after: auto;"}">`,
        );
        if (project!.config.type !== "screenplay") {
          printText.push(
            "<div class='print-section-title'>" + storySection.title + "</div>",
          );
        }
        storySection.scenes.forEach((sceneInSection: ShallowSceneInterface) => {
          const fullScene: SceneInterface | undefined = project!.scenes.find(
            (scene: SceneInterface) => scene.id === sceneInSection.id,
          );
          if (fullScene && scenesToPrint.includes(sceneInSection.id)) {
            printText.push(
              `<div class='print-scene' style="${project!.config
                    .pageBreakBetweenScenes === true &&
                "page-break-after: always;"}">`,
            );
            if (fullScene.config.type !== "screenplay") {
              printText.push(
                "<div class='print-scene-title'>" + fullScene.title + "</div>",
              );
            }
            printText.push(
              "<div class='print-scene-text script' style='line-height: " +
                fullScene.config.lineSpacing +
                ";" +
                (fullScene.config.type === "screenplay"
                  ? ' font-family: "Courier Prime", monospace;'
                  : " ") +
                "'" +
                ">" +
                converter.makeHtml(fullScene.text as string) +
                "</div>",
            );
            printText.push("</div>");
          }
        });
        printText.push("</div>");
      }
    });

    document.getElementById("print-housing")!.innerHTML = printText.join("");
  };

  const isSelected = (id: string) => {
    return scenesToPrint.includes(id);
  };

  const toggleInSelectedScenes = (id: string) => {
    let newScenesList: Array<string>;

    if (isSelected(id)) {
      newScenesList = scenesToPrint.filter((sceneId: string) => sceneId !== id);
    } else {
      newScenesList = [...scenesToPrint, id];
    }

    setScenesToPrint(newScenesList);
  };

  const clearPrintHousing = () => {
    setTimeout(() => {
      const printHousing: any = document.getElementById("print-housing");
      if (printHousing) {
        printHousing.innerHTML = "";
      }
    }, 1000);
  };
  // TODO: Add front/back matter options to print

  const updateProjectPrintNotes = (newNotes: string) => {
    const toDispatch = { type: "update_project_print_notes", value: newNotes };
    dispatch(toDispatch);
  };

  const togglePageBreakBetweenScenes = () => {
    const toDispatch = { type: "toggle_page_break_between_scenes" };
    dispatch(toDispatch);
  };

  const togglePrintTitlePage = () => {
    const toDispatch = { type: "toggle_print_title_page" };
    dispatch(toDispatch);
  };

  const togglePageBreakBetweenSections = () => {
    const toDispatch = { type: "toggle_page_break_between_sections" };
    dispatch(toDispatch);
  };
  return (
    <ModalBase
      closeModal={(e: any) => {
        clearPrintHousing();

        closeModal(e);
      }}
    >
      <React.Fragment>
        <ModalTitle>Print Options</ModalTitle>

        <PrintModalOptions>
          <select
            value={printMode}
            onChange={(e) => setPrintMode(e.currentTarget.value)}
          >
            <option value="fulltext">Print Project Content</option>
            <option value="outline">Print Project Outline</option>
          </select>
          {printMode === "outline" &&
            <SelectedScene
              key={`print-scene-title-page`}
              active={includeAssets === true}
              onClick={() => toggleIncludeAssets()}
            >
              <FontAwesomeIcon
                icon={includeAssets === true ? faCheckCircle : faCircle}
              />
              {" "}
              INCLUDE ASSETS IN OUTLINE
            </SelectedScene>}
          {printMode === "fulltext" &&
            <React.Fragment>
              <SelectedScene
                key={`print-scene-title-page`}
                active={project!.config.printTitlePage === true}
                onClick={() => togglePrintTitlePage()}
              >
                <FontAwesomeIcon
                  icon={project!.config.printTitlePage === true
                    ? faCheckCircle
                    : faCircle}
                />
                {" "}
                PRINT TITLE PAGE
              </SelectedScene>
              <PrintOptionsWrapper>
                <SelectedScene
                  active={project!.config.pageBreakBetweenSections === true}
                  onClick={() => togglePageBreakBetweenSections()}
                >
                  <FontAwesomeIcon
                    icon={project!.config.pageBreakBetweenSections === true
                      ? faCheckCircle
                      : faCircle}
                  />
                  {" "}
                  PAGE BREAK BETWEEN SECTIONS
                </SelectedScene>
                <SelectedScene
                  active={project!.config.pageBreakBetweenScenes === true}
                  onClick={() => togglePageBreakBetweenScenes()}
                >
                  <FontAwesomeIcon
                    icon={project!.config.pageBreakBetweenScenes === true
                      ? faCheckCircle
                      : faCircle}
                  />
                  {" "}
                  PAGE BREAK BETWEEN SCENES
                </SelectedScene>
              </PrintOptionsWrapper>
              <textarea
                value={project!.config.printNotes}
                onChange={(e: any) =>
                  updateProjectPrintNotes(e.currentTarget.value)}
                placeholder="Enter notes to be printed on the title page"
              >
              </textarea>
            </React.Fragment>}
          <p>
            <em>Select which sections and scenes you want printed.</em>
          </p>
          <hr />
          {project!.storySections.map((storySection: StorySectionInterface) => {
            return (
              <PrintSections key={`print-section-${storySection.id}`}>
                <PrintSectionTitle
                  onClick={() => togglePrintOfAllInSection(storySection.id)}
                >
                  {storySection.title}
                  {" "}
                </PrintSectionTitle>
                <PrintScenesWrapper>
                  {storySection.scenes.map((scene: ShallowSceneInterface) => {
                    const thisScene:
                      | SceneInterface
                      | undefined = project!.scenes.find(
                        (sceneInProject: SceneInterface) =>
                          sceneInProject.id === scene.id,
                      );

                    return (
                      <SelectedScene
                        key={`print-scene-${scene.id}-in-section-${storySection.id}`}
                        active={isSelected(thisScene!.id)}
                        onClick={() => toggleInSelectedScenes(thisScene!.id)}
                      >
                        <FontAwesomeIcon
                          icon={isSelected(thisScene!.id)
                            ? faCheckCircle
                            : faCircle}
                        />
                        {" "}
                        {thisScene!.title}
                      </SelectedScene>
                    );
                  })}
                </PrintScenesWrapper>
              </PrintSections>
            );
          })}
        </PrintModalOptions>

        <PrintContent className="print-modal" ref={printRef}></PrintContent>

        <ModalActions>
          <ModalActionButton type="cancel" title="Cancel" onClick={closeModal}>
            <FontAwesomeIcon icon={faBan} /> Cancel
          </ModalActionButton>

          <ModalActionButton type="okay" title="Print" onClick={doPrint}>
            <FontAwesomeIcon icon={faPrint} /> Print
          </ModalActionButton>
        </ModalActions>
      </React.Fragment>
    </ModalBase>
  );
};

export default PrintModal;

// JS PDF STUFF
// var doc = new jsPDF();
// var elementHTML = $('#contnet').html();
// var specialElementHandlers = {
//     '#elementH': function (element, renderer) {
//         return true;
//     }
// };
// doc.fromHTML(elementHTML, 15, 15, {
//     'width': 170,
//     'elementHandlers': specialElementHandlers
// });

// // Save the PDF
// doc.save('sample-document.pdf');
