import React, { useState } from "react"
import styled from "styled-components"
import ProjectListing from "../../components/Projects/ProjectListing/ProjectListing"
import BetaStatus from "../../components/Beta/BetaStatus/BetaStatus"
import AlertBox from "../../common/AlertBox/AlertBox"
import { navigate } from "@reach/router"
import StandardButton from "../../common/StandardButton/StandardButton"
import Slideshow from "../../components/Projects/Slideshow/Slideshow"
import HomeDetail from "../../components/Projects/HomeDetail/HomeDetail"
import HomeFooter from "../../components/Home/HomeFooter/HomeFooter"
import HomeSmallFeatures from "../../components/Home/HomeSmallFeatures/HomeSmallFeatures"
import ViewScreenshotModal from "../../modals/ViewScreenshotModal/ViewScreenshotModal"
import SendFeedbackModal from "../../modals/SendFeedbackModal/SendFeedbackModal"

interface HomeProps {
  path?: string
}

const HeaderTitle = styled.h1`
  text-align: center;
  margin: 0px;

  span {
    background-color: var(--save-indicator-color);
  }

  @media (max-width: 768px) {
    font-size: 28px;
  }
`

const BetaButton = styled.span`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: var(--brand-primary);
  }
`

const BottomCTA = styled.div`
  @media (min-width: 651px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    max-width: 800px;
    margin: 0 auto;
  }
`

const HeaderWordMark = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50px 1fr;
  width: 222px;
  span {
    font-family: var(--title-font);
    background-color: transparent;
    font-size: 50px;
    line-height: 1.2em;
    text-align: left;

    @media (max-width: 480px) {
      font-size: 40px;
      line-height: initial;
      padding-left: 6px;
    }
  }

  @media (max-width: 480px) {
    margin: 0 auto 0 10px;
    font-size: 40px;
    grid-template-columns: 40px 1fr;
  }
`

const HeaderLogo = styled.div`
  background-image: var(--logo);
  background-repeat: no-repeat;
  background-size: contain;
  height: 40px;
  width: 40px;
  background-position: center;
  margin-top: 11px;
  border-radius: 4px;
  overflow: hidden;
  
  @media (max-width: 480px) {
    margin: 11px auto 0 0px;
  }
`

const HomeHelpButton = styled.div`
  margin: 20px auto;
  width: 300px;
`

const DetailButton = styled.div`
  margin-top: 20px;
`

const Home = (props: HomeProps) => {
  const [screenshotToShow, setScreenshotToShow] = useState("")
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  return (
    <div>
      <HeaderTitle>
        <HeaderWordMark>
          <HeaderLogo />
          <span>Hilker</span>
        </HeaderWordMark>
      </HeaderTitle>
      <Slideshow />
      <ProjectListing />

      <HomeDetail
        align={"left"}
        title={"Capture Your Ideas"}
        img={"/images/home_notes.png"}
      >
        With Notes in Hilker, you'll never lose an idea. Create a note. Jot down
        your thoughts. Highlight your best ideas and convert them to usable
        assets with the click of a button!
        <DetailButton>
          <StandardButton
            onClick={() => setScreenshotToShow("/images/screen_notes.png")}
          >
            Learn about Notes
          </StandardButton>
        </DetailButton>
      </HomeDetail>
      <HomeDetail
        align={"right"}
        title={"Keep Track of Everything"}
        img={"/images/home_assets.png"}
      >
        The Assets section manages all of the elements you'll need to keep track
        of in your project, including Characters, Locations, Arcs, Storylines,
        and more!
        <DetailButton>
          <StandardButton
            onClick={() => setScreenshotToShow("/images/screen_assets.png")}
          >
            Learn about Assets
          </StandardButton>
        </DetailButton>
      </HomeDetail>
      <HomeDetail
        align={"left"}
        title={"Organize your plot"}
        img={"/images/home_plot.png"}
      >
        The Plot section is a timeline view. The power of Plot allows you to
        review the structure of your story, where each of your assets are used,
        in order, to keep things writing smoothly.
        <DetailButton>
          <StandardButton
            onClick={() => setScreenshotToShow("/images/screen_plot.png")}
          >
            Learn about Plot
          </StandardButton>
        </DetailButton>
      </HomeDetail>
      <HomeDetail
        align={"right"}
        title={"Write!"}
        img={"/images/home_write.png"}
      >
        Once your project is structured, it's time to use the Write view to make
        your manuscript or screenplay happen. With words!
        <DetailButton>
          <StandardButton
            onClick={() => setScreenshotToShow("/images/screen_write.png")}
          >
            Learn about Write
          </StandardButton>
        </DetailButton>
      </HomeDetail>

      <HomeSmallFeatures />

      {screenshotToShow > "" && (
        <ViewScreenshotModal
          screenshot={screenshotToShow}
          closeModal={() => setScreenshotToShow("")}
        />
      )}

      <BottomCTA>
        <HomeHelpButton>
          <strong>Want more info?</strong> We've got a pretty sweet FAQ that
          will help you get started.
          <br />
          <br />
          <StandardButton onClick={() => navigate("/help")}>
            Read the Documentation
          </StandardButton>
        </HomeHelpButton>
        <HomeHelpButton>
          <strong>Ready to get started?</strong> Click the button below to
          create your first project.
          <br />
          <br />
          <StandardButton onClick={() => navigate("/projects")}>
            Start Writing for Free!
          </StandardButton>
        </HomeHelpButton>
      </BottomCTA>

      <AlertBox allowHide={false}>
        <HomeDetail
          align={"left"}
          title={"This is a Beta!"}
          img={"/images/home_beta.png"}
        >
          Hilker is currently in development. Things are being added, things are
          changing, and from time-to-time there may be errors. <BetaStatus />
          <BetaButton onClick={() => navigate("/beta")}>
            See what other features we're working on!
          </BetaButton>
          <br />
          <br />
          If you try Hilker and have any feedback, questions, concerns or
          requests, please{" "}
          <BetaButton onClick={() => setShowFeedbackModal(true)}>
            click here to let me know!
          </BetaButton>
        </HomeDetail>
      </AlertBox>
      <HomeFooter />
      {showFeedbackModal === true && (
        <SendFeedbackModal
          closeModal={() => setShowFeedbackModal(false)}
        ></SendFeedbackModal>
      )}
    </div>
  )
}

export default Home
