import { storage } from "../_config/firebase"

const _keyStr: string =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="

const removePaddingChars = (input: any) => {
  var lkey = _keyStr.indexOf(input.charAt(input.length - 1))
  if (lkey === 64) {
    return input.substring(0, input.length - 1)
  }
  return input
}

const decode = (input: any, arrayBuffer: any) => {
  //get last chars to see if are valid
  input = removePaddingChars(input)
  input = removePaddingChars(input)

  const val: number = (input.length / 4) * 3
  var bytes = parseInt(val.toString(), 10)

  var uarray
  var chr1, chr2, chr3
  var enc1, enc2, enc3, enc4
  var i = 0
  var j = 0

  if (arrayBuffer) uarray = new Uint8Array(arrayBuffer)
  else uarray = new Uint8Array(bytes)

  input = input.replace(/[^A-Za-z0-9+/=]/g, "")

  for (i = 0; i < bytes; i += 3) {
    //get the 3 octects in 4 ascii chars
    enc1 = _keyStr.indexOf(input.charAt(j++))
    enc2 = _keyStr.indexOf(input.charAt(j++))
    enc3 = _keyStr.indexOf(input.charAt(j++))
    enc4 = _keyStr.indexOf(input.charAt(j++))

    chr1 = (enc1 << 2) | (enc2 >> 4)
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
    chr3 = ((enc3 & 3) << 6) | enc4

    uarray[i] = chr1
    if (enc3 != 64) uarray[i + 1] = chr2
    if (enc4 != 64) uarray[i + 2] = chr3
  }

  return uarray
}
const decodeArrayBuffer = (input: string) => {
  var bytes = (input.length / 4) * 3
  var ab = new ArrayBuffer(bytes)
  decode(input, ab)

  return ab
}
const readOrientation = (file: string) => {
  const trimmedFile: string = file.substr(file.indexOf(",", 0))
  const view = new DataView(decodeArrayBuffer(trimmedFile))

  if (view.getUint16(0, false) != 0xffd8) {
    return
  }

  const length = view.byteLength

  let offset = 2

  while (offset < length) {
    const marker = view.getUint16(offset, false)

    offset += 2

    if (marker === 0xffe1) {
      offset += 2

      if (view.getUint32(offset, false) != 0x45786966) {
        return
      }

      offset += 6

      const little = view.getUint16(offset, false) === 0x4949
      offset += view.getUint32(offset + 4, little)
      const tags = view.getUint16(offset, little)
      offset += 2

      for (var i = 0; i < tags; i++) {
        if (view.getUint16(offset + i * 12, little) === 0x0112) {
          return view.getUint16(offset + i * 12 + 8, little)
        }
      }
    } else if ((marker & 0xff00) != 0xff00) {
      break
    } else {
      offset += view.getUint16(offset, false)
    }
  }

  return offset
}

export const scaleImageByURL = (
  dataURL: string,
  maxWidth: number,
  maxHeight: number
) => {
  return new Promise((done) => {
    var img = new Image()
    img.onload = () => {
      var scale, newWidth, newHeight, canvas
      if (img.width < maxWidth) {
        scale = maxWidth / img.width
      } else {
        scale = maxHeight / img.height
      }
      newWidth = img.width * scale
      newHeight = img.height * scale
      canvas = document.createElement("canvas")

      const orientation: number | undefined = readOrientation(dataURL)

      if (4 < orientation! && orientation! < 9) {
        canvas.width = newHeight
        canvas.height = newWidth
      } else {
        canvas.width = newWidth
        canvas.height = newHeight
      }
      const ctx: any = canvas.getContext("2d")

      switch (orientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, newWidth, 0)
          break
        case 3:
          ctx.transform(-1, 0, 0, -1, newWidth, newHeight)
          break
        case 4:
          ctx.transform(1, 0, 0, -1, 0, newHeight)
          break
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0)
          break
        case 6:
          ctx.transform(0, 1, -1, 0, newHeight, 0)
          break
        case 7:
          ctx.transform(0, -1, -1, 0, newHeight, newWidth)
          break
        case 8:
          ctx.transform(0, -1, 1, 0, 0, newWidth)
          break
        default:
          break
      }
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight)

      done(canvas.toDataURL())
    }
    img.src = dataURL
  })
}

export const uploadImageToStorage = ({
  assetType,
  assetId,
  projectId,
  src,
}: {
  assetType: string
  assetId: string
  projectId: string | undefined
  src: string
}) => {
  return new Promise((done) => {
    const storageRef = storage.ref()
    const imageUrl = `/projects/${projectId}/assets/${assetType}/${assetId}`

    const imageRef = storageRef.child(imageUrl)
    imageRef.putString(src, "data_url").then(function (snapshot: any) {
      return snapshot.ref.getDownloadURL().then(function (downloadURL: any) {
        done({
          url: downloadURL,
          assetType: assetType,
          assetId: assetId,
          projectId: projectId,
          src: src,
        })
      }) // done(snapshot)
    })
  })
}

export const deleteImageFromStorage = ({
  assetType,
  assetId,
  projectId,
  src,
}: {
  assetType: string
  assetId: string
  projectId: string | undefined
  src: string
}) => {
  return new Promise((done) => {
    const imageUrl = `/projects/${projectId}/assets/${assetType}/${assetId}`
    const storageRef = storage.ref()
    const imageRef = storageRef.child(imageUrl)
    imageRef.delete()
  })
}
