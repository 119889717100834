import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  max-width: 610px;
  margin: 40px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  @media (max-width: 619px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  @media (max-width: 480px) {
    margin: 20px auto;
  }
`

const DetailImage = styled.img<{ src: string }>`
  border: 0px;
  object-fit: cover;
  border-radius: 160px;
  overflow: hidden;
  width: 300px;
  height: 300px;
`

const TextAreaTitle = styled.h2`
  margin-top: 0px;
  font-size: 20px;
  font-family: var(--title-font);
`

type HomeDetailProps = {
  img: string
  align: "left" | "right"
  title: string
  children: any
}

const TextArea = styled.div<{ align: "left" | "right" }>`
  @media (min-width: 610px) {
    ${(props) => props.align === "left" && "grid-column: 1 / 2;"}
    ${(props) =>
      props.align === "right" && "grid-column: 2 / 3;"}
    max-width: 300px;
  }

  @media (max-width: 610px) {
    padding: 0px 10px;
    box-sizing: border-box;
    margin-bottom: 40px;
  }
  width: 100%;
  font-size: 16px;
`

const ImageArea = styled.div<{ align: "left" | "right" }>`
  @media (min-width: 610px) {
    ${(props) => props.align === "left" && "grid-column: 2;"}
    ${(props) => props.align === "right" && "grid-row: 1;"}
  }
  margin: 0px auto;
  width: 300px;
`

const HomeDetail = (props: HomeDetailProps) => {
  return (
    <Wrapper>
      <TextArea align={props.align}>
        <TextAreaTitle>{props.title}</TextAreaTitle>
        <div>{props.children}</div>
      </TextArea>

      <ImageArea align={props.align}>
        <DetailImage src={props.img} />
      </ImageArea>
    </Wrapper>
  )
}

export default HomeDetail
