import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  font-size: 14px;
  margin: 0px 20px;
  font-style: normal;

  h2 {
    margin-top: 0px;
    font-size: 18px;
  }
`
const WriteNoScene = () => {
  return (
    <Wrapper>
      <h2>This project has no scenes.</h2>
      In order to write, you need to add at least one section, and one scene.
    </Wrapper>
  )
}

export default WriteNoScene
